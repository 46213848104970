import { Component, Input, OnInit } from "@angular/core";
import { Observable } from "rxjs";

@Component({
  selector: 'chirpy-locked-content',
  templateUrl: './chirpy-locked-content.html',
  styleUrls: ['./chirpy-locked-content.scss'],
})
export class ChirpyLockedContentComponent implements OnInit {
  @Input() reason$: Observable<string>

  description: string = '';

  ngOnInit(): void {
    this.reason$.subscribe(reason => {
      let descriptionText = "This content has been locked by an administrator until further notice";

      descriptionText += !!reason
        ? " for the following reason:"
        : ".";

      this.description = descriptionText;
    });
  }
}