import { Component, Input } from '@angular/core';
import { CountryKey } from '@shared/constants/country';
import { IContent } from '@shared/models/content';
import { Observable } from 'rxjs';

@Component({
  selector: 'chirpy-content-view',
  templateUrl: './chirpy-content-view.component.html',
  styleUrls: ['./chirpy-content-view.component.scss']
})
export class ChirpyContentViewComponent {
  @Input() content: IContent;
  @Input() country: CountryKey;
  @Input() placeholder: string = '';
  @Input() showPlaceholder: boolean = false;

  constructor() {}
}
