import { Component, Input, OnChanges } from '@angular/core';
import { range } from '@shared/functions/range';
import { ChirpleTileVariant } from '../../models';

const UPPERCASE_CHARS = new Set(range(65, 90).map(x => String.fromCharCode(x)));

@Component({
  selector: 'chirple-tile',
  templateUrl: './chirple-tile.component.html',
  styleUrls: ['./chirple-tile.component.scss']
})
export class ChirpleTileComponent implements OnChanges {
  @Input() variant: ChirpleTileVariant = 'blank';
  @Input() value?: string;

  height = 64;
  strokeWidth = 2.5;
  statusClass = '';
  width = 64;

  ngOnChanges() {
    const isValidInput = this.isValidCharacter(this.value);

    if (!isValidInput) {
      this.value = '';
      this.variant = 'blank';
    }
  }

  private isValidCharacter(input: unknown): boolean {
    if (typeof input !== 'string') return false;
    if (input.length !== 1) return false;
    if (!UPPERCASE_CHARS.has(input.toUpperCase())) return false;

    return true;
  }
}
