import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'time' })
export class TimePipe implements PipeTransform {
  transform(seconds: number): string {
    if (!seconds) return `0:00`;

    const sec: string = (seconds % 60)
      .toFixed(0)
      .toString()
      .padStart(2, '0');
    const minutes: number = Math.trunc(seconds / 60);
    if (minutes < 60) return `${minutes}:${sec}`;

    const hr: number = Math.trunc(minutes / 60);
    const min: string = (minutes % 60).toString().padStart(2, '0');
    return `${hr}:${min}:${sec}`;
  }
}
