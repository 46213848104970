import { Component, HostListener, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LoadingController, ModalController } from '@ionic/angular';
import { AnalyticsAction, AnalyticsCategory, AnalyticsService } from '@shared/services/analytics';
import { SubscriptionService } from '@shared/services/subscription.service';
import { ToastService } from '@shared/services/toast.service';
import { BehaviorSubject, fromEvent, Subject, Subscription } from 'rxjs';
import { filter, map, skipWhile, take } from 'rxjs/operators';
import { CHIRPLE_CONSTANTS } from '../../constants';
import { IChirpleAttempt, IChirpleGame } from '../../models';
import { ChirpleGameService } from '../../services/chirple.service';
import { ChirpleHowToPlayComponent, ChirpleEndGameModalComponent } from '../../components';

@Component({
  selector: 'chirple-game-page',
  templateUrl: './chirple-game.component.html',
  styleUrls: ['./chirple-game.component.scss']
})
export class ChirpleGamePageComponent implements OnInit {
  private animationInProgress = false;
  private currentGuess = '';
  private keyboardEventsSub: Subscription;
  private sessionStartTime: number;

  get answer() {
    return this.game ? this.game.solution.toUpperCase() : '';
  }

  get attempts() {
    return this.game ? this.game.attempts : [];
  }

  get gameHasEnded() {
    if (!this.game || !this.game.attempts) {
      return true;
    }

    if (this.game.correctAttempt != null) {
      return true;
    }

    if (this.game.attempts.length === CHIRPLE_CONSTANTS.MAX_ATTEMPTS_PER_GAME) {
      return true;
    }

    return false;
  }

  game: IChirpleGame;

  attemptsAdded = new BehaviorSubject<IChirpleAttempt[]>(null);
  attemptsAdded$ = this.attemptsAdded.asObservable();

  letterAdded = new Subject<string>();
  letterAdded$ = this.letterAdded.asObservable();

  letterRemoved = new Subject();
  letterRemoved$ = this.letterRemoved.asObservable();

  rowError = new Subject();
  rowError$ = this.rowError.asObservable();

  showErrorView = false;

  constructor(private analyticsService: AnalyticsService, private gameService: ChirpleGameService, private modalController: ModalController, private loadingController: LoadingController, private router: Router, private subscriptionService: SubscriptionService, private toastService: ToastService) {}

  // If the app is loaded directly at the route /games/chirple/play this component is not destroyed on navigation to /games/chirple,
  // so we need to subscribe/unsubscribe to keyboard events in the ionView* hooks
  ionViewWillEnter() {
    this.initKeyboardEvents();
  }

  ionViewWillLeave() {
    this.subscriptionService.clearSubscription(this.keyboardEventsSub);

    if (!this.gameHasEnded) {
      this.analyticsService.eventTrack(AnalyticsCategory.CHIRPLE, AnalyticsAction.CHIRPLE_LEAVE_GAME, this.game.solutionId.toString());
      this.updateGameDuration();
    }
  }

  ngOnInit() {
    this.showLoading();
    this.loadGame();
  }

  onBackClicked() {
    // Other cleanup happens in ionViewWillLeave
    this.router.navigate(['/games/chirple']);
  }

  async onHowToPlayClicked() {
    const modal = await this.modalController.create({
      component: ChirpleHowToPlayComponent,
      componentProps: {
        continueText: 'Continue playing'
      }
    });
    this.analyticsService.eventTrack(AnalyticsCategory.CHIRPLE, AnalyticsAction.CHIRPLE_VIEW_HELP);
    await modal.present();
  }

  onRowAnimationComplete() {
    this.animationInProgress = false;

    this.updateAttempts(this.attemptsAdded.value);

    if (this.gameHasEnded) {
      this.endGame();
    }
  }

  onRowAnimationStarted() {
    this.animationInProgress = true;
  }

  onVirtualKeyboardKeyClicked(key: string) {
    const event = new KeyboardEvent('keyup', { key });
    document.dispatchEvent(event);
  }

  private addLetterToGuess(letter: string) {
    if (this.currentGuess.length >= CHIRPLE_CONSTANTS.SOLUTION_LENGTH) {
      return;
    }

    this.currentGuess += letter;
    this.letterAdded.next(letter);
  }

  private canSubmitGuess() {
    if (this.game.attempts.length >= CHIRPLE_CONSTANTS.MAX_ATTEMPTS_PER_GAME) {
      return false;
    }

    return this.currentGuess.length === CHIRPLE_CONSTANTS.SOLUTION_LENGTH;
  }

  private endGame() {
    [this.attemptsAdded, this.letterAdded, this.letterRemoved, this.rowError].forEach(subject => subject.complete());

    setTimeout(() => {
      this.showEndGameModal();
    }, 1200);
  }

  private hideLoading() {
    this.loadingController.dismiss();
  }

  private isKeyboardDisabled() {
    return this.animationInProgress || this.gameHasEnded;
  }

  private initKeyboardEvents() {
    this.keyboardEventsSub = fromEvent<KeyboardEvent>(document, 'keyup')
      .pipe(
        filter(e => !this.isKeyboardDisabled() && !this.isKeyCombination(e)),
        map(e => e.key.toUpperCase()),
        filter(input => this.isValidInput(input))
      )
      .subscribe(input => {
        if (input === 'BACKSPACE') {
          this.removeLetterFromGuess();
        } else if (input === 'ENTER') {
          this.submitGuess();
        } else {
          this.addLetterToGuess(input);
        }
      });

    this.subscriptionService.add(this.keyboardEventsSub);
  }

  private isKeyCombination({ altKey, metaKey, ctrlKey }: KeyboardEvent) {
    return altKey || metaKey || ctrlKey;
  }

  private isValidInput(input: string) {
    return /^([A-Z]|Backspace|Enter)$/i.test(input);
  }

  private loadGame() {
    this.gameService
      .loadGame()
      .pipe(
        skipWhile(x => !x),
        take(1)
      )
      .subscribe(
        game => {
          this.hideLoading();

          // We don't want to make these available until after all animations are complete
          const attempts = game.attempts || [];
          game.attempts = [];

          this.game = game;

          if (attempts.length > 0) {
            this.attemptsAdded.next(attempts); // This will trigger the attempts to be re-animated
            if (this.gameHasEnded) {
              this.analyticsService.eventTrack(AnalyticsCategory.CHIRPLE, AnalyticsAction.CHIRPLE_VIEW_COMPLETED_GAME, this.game.solutionId.toString());
            } else {
              this.sessionStartTime = Date.now(); // Don't set sessionStartTime unless the member has already made a guess
              this.analyticsService.eventTrack(AnalyticsCategory.CHIRPLE, AnalyticsAction.CHIRPLE_RESUME_GAME, this.game.solutionId.toString());
            }
          } else {
            if (!!this.game.duration) {
              this.analyticsService.eventTrack(AnalyticsCategory.CHIRPLE, AnalyticsAction.CHIRPLE_RESUME_GAME, this.game.solutionId.toString());
            } else {
              this.analyticsService.eventTrack(AnalyticsCategory.CHIRPLE, AnalyticsAction.CHIRPLE_START_GAME, this.game.solutionId.toString());
            }
          }
        },
        err => {
          this.hideLoading();
          this.showErrorView = true;

          // Throw so that the error is captured by the Sentry error handler
          throw err;
        }
      );
  }

  private removeLetterFromGuess() {
    if (this.currentGuess.length === 0) {
      return;
    }

    this.currentGuess = this.currentGuess.slice(0, -1);
    this.letterRemoved.next();
  }

  private async showEndGameModal() {
    const modal = await this.modalController.create({
      component: ChirpleEndGameModalComponent,
      componentProps: {
        answer: this.answer,
        didWin: this.game.correctAttempt != null,
        duration: this.game.duration,
        numberOfAttempts: this.attempts.length,
        playerId: this.game.userId
      }
    });

    await modal.present();
  }

  private async showError(errorMessage: string) {
    this.rowError.next();

    await this.toastService.presentToast(errorMessage, {
      duration: 1500
    });
  }

  private async showLoading() {
    const loading = await this.loadingController.create({
      message: 'Please wait...'
    });

    await loading.present();
  }

  private submitGuess() {
    if (!this.canSubmitGuess()) {
      return this.showError('Not enough letters');
    }

    const isValidWord = this.gameService.isValidWord(this.currentGuess);
    if (!isValidWord) {
      return this.showError(`${this.currentGuess.toUpperCase()} is not a valid word`);
    }

    const attempt = this.gameService.getAttemptFromGuess({
      guess: this.currentGuess,
      answer: this.answer,
      index: this.game.attempts.length
    });

    this.analyticsService.eventTrack(AnalyticsCategory.CHIRPLE, AnalyticsAction.CHIRPLE_SUBMIT_GUESS, this.game.solutionId.toString());
    this.gameService.storeAttempt(this.game.uid, attempt).then(() => {
      this.updateAttempts([attempt]); // This will be called again in onRowAnimationComplete for the case you resume a game but it's idempotent
      // Update duration every time a guess is made, to prevent members from shortening their time by refreshing the page before a final guess
      this.updateGameDuration();
      this.sessionStartTime = Date.now();
      if (attempt.isCorrect) {
        // Don't update leaderboard inside endGame() because this is also called when viewing a completed game
        this.updateLeaderboard();
        this.analyticsService.eventTrack(AnalyticsCategory.CHIRPLE, AnalyticsAction.CHIRPLE_GAME_OVER, this.game.solutionId.toString(), { type: 'win' }, this.game.attempts.length + 1);
      } else if (this.game.attempts.length === CHIRPLE_CONSTANTS.MAX_ATTEMPTS_PER_GAME) {
        this.analyticsService.eventTrack(AnalyticsCategory.CHIRPLE, AnalyticsAction.CHIRPLE_GAME_OVER, this.game.solutionId.toString(), { type: 'loss' }, this.game.attempts.length + 1);
      }
    });
    this.attemptsAdded.next([attempt]);

    this.currentGuess = '';
  }

  private updateAttempts(attempts: IChirpleAttempt[]) {
    const updatedAttempts = [...this.game.attempts];
    let correctAttempt: number = null;

    for (let i = 0; i < attempts.length; i++) {
      const attempt = attempts[i];

      updatedAttempts[attempt.index] = attempt;

      if (!correctAttempt && attempt.isCorrect) {
        correctAttempt = i;
      }
    }

    this.game.attempts = updatedAttempts;
    this.game.correctAttempt = correctAttempt;
  }

  private updateGameDuration() {
    // Don't start recording the duration until the member actually makes a guess.
    if (this.game.attempts.length === 0) return;

    const start = this.sessionStartTime || Date.now();
    const end = Date.now();
    const durationInSecs = Math.max((end - start) / 1000, 0);

    if (this.game.duration == null) {
      this.game.duration = 0;
    }
    this.game.duration += durationInSecs;
    this.gameService.updateGameDuration(this.game.uid, durationInSecs);
  }

  private updateLeaderboard() {
    this.gameService.updateLeaderboard(this.game);
  }
}
