import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { EventType } from '@infrastructure/constants/event-type';
import { IAttendance } from '@shared/models/attendance/attendance';
import { IEventAttendance } from '@shared/models/attendance/event-attendance';
import { IColumn } from '@shared/models/column';
import { IDataTableConfig } from '@shared/models/data-table-config';
import { AttendanceService } from '@shared/services/attendance/attendance.service';
import { AuthService } from '@shared/services/auth.service';
import { ConstantsService } from '@shared/services/constants.service';
import { DateTimeService } from '@shared/services/date-time.service';
import { Observable } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';

@Component({
  selector: 'app-catchup-attendance',
  styleUrls: ['./catchup-attendance.page.scss'],
  templateUrl: './catchup-attendance.page.html'
})
export class CatchupAttendancePage implements OnInit {
  columns: IColumn[] = [
    { label: 'Date', field: 'date', class: 'data-table__column-responsive-bold' },
    { label: 'CatchUp name', field: 'title' },
    { label: 'Group name', field: 'group' }
  ];
  config: IDataTableConfig = {}; // Don't make the rows click through to the corresponding event because some will have aged off, and members don't normally have access to them once they have finished
  data$: Observable<Partial<Record<EventType, IEventAttendance>>>;
  eventTypes: EventType[] = [EventType.CatchUp, EventType.Virtual];
  hasEvents: boolean = false;
  noDataMessage: string = 'No data';

  constructor(private attendanceService: AttendanceService, private authService: AuthService, private constantsService: ConstantsService, private dateTimeService: DateTimeService) {}

  ngOnInit() {
    this.data$ = this.authService._userProfileSubject.pipe(
      switchMap(member => this.attendanceService.getAttendanceByMember(member.uid)),
      map((attendance: IAttendance) => {
        const output: Partial<Record<EventType, IEventAttendance>> = {};
        for (const eventType of this.eventTypes) {
          let values = attendance[eventType] || [];
          if (values.length > 0) {
            values = values
              .sort((a, b) => b.datetime - a.datetime)
              .map(x => {
                x.date = this.dateTimeService.formatDate(x.datetime, 'DD MMM YYYY');
                return x;
              });
          }
          output[eventType] = values;
        }
        return output;
      }),
      tap(attendance => {
        this.hasEvents = Array.prototype.concat(...Object.values(attendance || {})).length > 0;
      })
    );

    this.noDataMessage = this.constantsService.constants.CATCHUPS.ATTENDANCE.noData;
  }
}
