import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UserObject } from '@shared/models/user-object';
import { UserDatabase } from '@shared/services/user/user.database';
import { map } from 'rxjs/operators';
import { IInput } from '@shared/models/input';
import { DataSearchPresenter } from './data-search.presenter';

@Component({
  selector: 'data-search',
  templateUrl: './data-search.component.html',
  styleUrls: ['./data-search.component.scss'],
  viewProviders: [DataSearchPresenter]
})
export class DataSearchComponent {
  get searchForm() {
    return this.presenter.form;
  }

  @Input() defaults: any = {};
  @Input() fields: IInput[] = [];
  @Output() search = new EventEmitter<any>();
  searchCriteria: Record<string, string>;
  searchModel: any;
  selectedItems: Record<string, string> = {};

  constructor(private presenter: DataSearchPresenter, private userDatabase: UserDatabase) {}

  ngOnInit() {
    this.presenter.init(this.defaults, this.fields);
  }

  onAddItem(field, singleSelect, item: Record<string, string>) {
    if (singleSelect) {
      this.selectedItems = { [item.key]: item.value };
      this.presenter.form.controls[field].setValue(item.key); // Currently all log searches are singleSelect, and require the key (rather than the human readable value) to be passed to the form
    } else {
      this.selectedItems[item.key] = item.value;
      const values = Object.values(this.selectedItems).join(',');
      this.presenter.form.controls[field].setValue(values);
    }
  }

  onRemoveItem(field, singleSelect, item: Record<string, string>) {
    delete this.selectedItems[item.key];
    const values = Object.values(this.selectedItems).join(','); // for singleSelect this will produce an empty field regardless of whether we use value or key
    this.presenter.form.controls[field].setValue(values);
  }

  onSearch() {
    const search = this.presenter.search();
    this.search.emit(search);
  }

  reset() {
    this.presenter.reset();
    this.onSearch();
  }

  searchItems(startsWith: string = '') {
    startsWith = startsWith != null ? startsWith.toLowerCase() : startsWith;
    return this.userDatabase.searchMembers(startsWith, 'searchName', '', true).pipe(
      map(results => {
        const members: Record<string, string> = {};
        results.map((r: UserObject) => (members[r.uid] = r.displayName));
        return members;
      })
    );
  }

  set(options: any) {
    this.presenter.setValue(options);
    // Don't trigger onSearch here, because the log.page has already changed the search, and this is just to keep the presenter in sync
  }
}
