import { Injectable } from '@angular/core';
import { IOrderCondition } from '@shared/models/order-condition';
import { IWhereCondition } from '@shared/models/where-condition';
import { IMarketplaceListing } from '@shared/models/marketplace/marketplace-listing';
import { BaseDatabase } from '@shared/services/base.database';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MarketplaceDatabase extends BaseDatabase {
  createMarketplaceListing(listing: IMarketplaceListing) {
    return this.createDocument(this.COLLECTION.MARKETPLACE_LISTINGS, listing);
  }

  deleteMarketplaceListing(uid: string) {
    return this.deleteDocument(this.COLLECTION.MARKETPLACE_LISTINGS, uid);
  }

  getMarketplaceListing(uid: string) {
    return this.getDocument<IMarketplaceListing>(this.COLLECTION.MARKETPLACE_LISTINGS, uid);
  }

  getMarketplaceListings(country: string, isAdmin: boolean, recordsToFetch: number, hideUnapproved: boolean = false) {
    const queryFn = this.getMarketplaceListingQuery(recordsToFetch, isAdmin, country, hideUnapproved);
    return this.getDocumentsByQuery<IMarketplaceListing>(this.COLLECTION.MARKETPLACE_LISTINGS, queryFn);
  }

  getMarketplaceListingsForApproval(): Observable<IMarketplaceListing[]> {
    const whereConditions: IWhereCondition[] = [
      {
        field: 'approved',
        operator: '==',
        value: false
      }
    ];

    const orderConditions: IOrderCondition[] = [{ field: 'created', direction: 'desc' }];

    const queryFn = this.createQueryFunction(whereConditions, orderConditions, 999);
    return this.getDocumentsByQuery<IMarketplaceListing>(this.COLLECTION.MARKETPLACE_LISTINGS, queryFn);
  }

  getMemberMarketplaceListings(memberId: string, recordsToFetch: number) {
    const queryFn = this.getMemberMarketplaceListingsQuery(memberId, recordsToFetch);
    return this.getDocumentsByQuery<IMarketplaceListing>(this.COLLECTION.MARKETPLACE_LISTINGS, queryFn);
  }

  updateMemberProfile(memberId: string, data) {
    return this.updateDocument(this.COLLECTION.CENTRAL_MEMBERS, memberId, data);
  }

  updatePrivateMemberData(memberId: string, data) {
    return this.updateDocument(this.COLLECTION.CENTRAL_MEMBERS_PRIVATE, memberId, data);
  }

  updateMarketplaceListing(marketplaceListing: any, merge: boolean = true) {
    return this.updateDocument(this.COLLECTION.MARKETPLACE_LISTINGS, marketplaceListing.uid, marketplaceListing, merge);
  }

  private getMemberMarketplaceListingsQuery(memberId: string, recordsToFetch: number) {
    const whereConditions: IWhereCondition[] = [{ field: 'memberId', operator: '==', value: memberId }];
    const orderConditions: IOrderCondition[] = [{ field: 'created', direction: 'desc' }];
    return this.createQueryFunction(whereConditions, orderConditions, recordsToFetch);
  }

  private getMarketplaceListingQuery(recordsToFetch: number, isAdmin: boolean, country: string, hideUnapproved: boolean) {
    const whereConditions: IWhereCondition[] = [];
    const orderConditions: IOrderCondition[] = [];

    if (!isAdmin) {
      whereConditions.push({ field: 'published', operator: '==', value: true });
      if (hideUnapproved) whereConditions.push({ field: 'approved', operator: '==', value: true }); // NB: This prevents you seeing your own unapproved listings. It's only enabled for the Marketplace box on My Chirpy
      // Searching by country can be enabled per environment. If it is, don't restrict by country here
      if (country) {
        whereConditions.push({
          field: 'country',
          operator: '==',
          value: country
        });
      }
    } else {
      orderConditions.push({ field: 'approved', direction: 'asc' });
    }

    orderConditions.push({ field: 'created', direction: 'desc' });

    return this.createQueryFunction(whereConditions, orderConditions, recordsToFetch);
  }
}
