export enum MetricType {
  AVERAGE = 'average',
  DISTRIBUTION = 'distribution',
  MAX = 'max',
  MAX_STREAK = 'maxStreak',
  MIN = 'min',
  PERCENTAGE = 'percentage',
  STREAK = 'streak',
  TOTAL = 'total'
}
