import { Component, Input, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { EventType } from '@infrastructure/constants/event-type';
import { AlertController } from '@ionic/angular';
import { ChirpyDataTableComponent } from '@shared/components/chirpy-data-table/chirpy-data-table.component';
import { AdminRole } from '@shared/constants/admin-role';
import { IAttendanceData } from '@shared/models/attendance/attendance-data';
import { IColumn } from '@shared/models/column';
import { IDataTableConfig } from '@shared/models/data-table-config';
import { UserObject } from '@shared/models/user-object';
import { IWhereCondition } from '@shared/models/where-condition';
import { AttendanceService } from '@shared/services/attendance/attendance.service';
import { AuthService } from '@shared/services/auth.service';
import { DateTimeService } from '@shared/services/date-time.service';
import { GroupService } from '@shared/services/groups/group.service';
import { UserService } from '@shared/services/user/user.service';
import { Observable, of } from 'rxjs';
import { mergeMap, map, switchMap } from 'rxjs/operators';

@Component({
  selector: 'app-group-members-report',
  styleUrls: ['./group-members-report.page.scss'],
  templateUrl: './group-members-report.page.html'
})
export class GroupMembersReportPage {
  canDownload: boolean = false;
  columns = [
    { label: 'Display name', field: 'displayName', class: 'data-table__column-responsive-bold' },
    { label: 'First name', field: 'firstName' },
    { label: 'Date joined', field: 'date' },
    { label: 'Location', field: 'locality' },
    { label: 'CatchUps attended', field: 'attendanceCount' },
    { label: 'First CatchUp bonus', field: 'firstEventAttended' },
    { label: 'First CatchUp host', field: 'firstEventOrganiser' }
  ];
  config = {
    routerLink: {
      path: '/members',
      id: [':uid']
    }
  };
  data$: Observable<any[]>;
  @ViewChild(ChirpyDataTableComponent, { static: false }) dataTable: ChirpyDataTableComponent;
  @Input() groupId: string;
  header: string;
  instructions: string = 'Loading';
  title: string = 'Members Report';

  constructor(private attendanceService: AttendanceService, private authService: AuthService, private dateTimeService: DateTimeService, private groupService: GroupService, private route: ActivatedRoute, private userService: UserService) {}

  loadData() {
    const whereCondition: IWhereCondition = {
      field: 'catchupGroupIds',
      operator: 'array-contains',
      value: this.groupId
    };
    const isPrivate = false;
    this.data$ = this.groupService.canManageGroup$(this.groupId).pipe(
      switchMap(canManageGroup => {
        if (!canManageGroup) return of([]); // If someone without permissions accesses the route directly, don't show data

        if (this.authService.isAdmin([AdminRole.HOSTS, AdminRole.SUPPORT])) {
          // Only admins can see phone numbers on this report, or download it
          this.canDownload = true;
          this.columns.splice(2, 0, { label: 'Phone', field: 'phone' });
        }

        return this.userService.getMembersByQuery(whereCondition, isPrivate);
      }),
      mergeMap(
        memberData =>
          this.attendanceService.getCountsByIds(
            memberData.map(x => x.uid),
            EventType.CatchUp
          ),
        (memberData: UserObject[], attendanceData: IAttendanceData[]) => {
          let data = {};
          for (const member of memberData) {
            data[member.uid] = member;
          }
          for (const member of attendanceData) {
            data[member.uid] = data[member.uid] ? Object.assign(data[member.uid], member) : member;
          }
          return Object.values(data);
        }
      ),
      map((members: (UserObject & IAttendanceData)[]) => {
        return members
          .map(member => {
            const date = member.dateRegistered ? this.dateTimeService.formatDate(member.dateRegistered, 'D MMM YYYY') : '';
            this.title = `Members report (${members.length})`;
            return Object.assign({}, member, { date });
          })
          .sort((a, b) => b.dateRegistered - a.dateRegistered);
      })
    );
  }
  ngOnInit() {
    this.groupId = this.route.snapshot.paramMap.get('id');
    this.loadData();
  }

  onDownloadCsv() {
    this.dataTable.downloadCsv();
  }
}
