import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CoinsTransactionType } from '@shared/constants/coins-transaction-type';
import { ICoinsSetting } from '@infrastructure/models/coins-setting';
import { ICoinsTransaction } from '@shared/models/coins/coins-transaction';
import { AuthService } from '@shared/services/auth.service';
import { CoinsService } from '@shared/services/coins/coins.service';
import { DateTimeService } from '@shared/services/date-time.service';
import { ToastService } from '@shared/services/toast.service';
import { Observable } from 'rxjs';
import { ReverseTransactionPresenter } from './reverse-transaction.presenter';

@Component({
  selector: 'reverse-transaction',
  styleUrls: ['./reverse-transaction.component.scss'],
  templateUrl: './reverse-transaction.component.html'
})
export class ReverseTransactionComponent {
  get form() {
    return this.presenter.form;
  }

  isUpdating: boolean = false;
  @Input() memberId: string;
  @Input() memberName: string;
  @Input() transactionToReverse: ICoinsTransaction;

  constructor(private authService: AuthService, private coinsService: CoinsService, private dateTimeService: DateTimeService, private presenter: ReverseTransactionPresenter, private toastService: ToastService) {}

  onUpdate() {
    if (this.isUpdating) return;
    this.isUpdating = true;

    let message: string;

    if (this.transactionToReverse.type === CoinsTransactionType.REVERSED || this.transactionToReverse.type === CoinsTransactionType.REVERSAL) {
      message = `A reversed transaction cannot be undone. Please make a new transaction`;
      this.resetPage(message);
      return;
    }

    const formValue = this.presenter.formValue.description;
    if (formValue == null || formValue.trim().length === 0) {
      message = `Please enter a reason for the reversal`;
      this.toastService.presentToast(message);
      this.isUpdating = false;
      return;
    }

    const reversedTransaction = this.transactionToReverse;
    const reversingTransaction = {
      amount: -this.transactionToReverse.amount,
      date: this.dateTimeService.getDateTime(),
      description: `Transaction ${this.transactionToReverse.uid} reversed: ${formValue}`,
      memberId: this.memberId,
      memberName: this.memberName,
      staffId: this.authService.member.uid,
      staffName: this.authService.member.fullName,
      type: CoinsTransactionType.REVERSAL
    };

    this.coinsService
      .reverseTransaction(reversedTransaction, reversingTransaction)
      .then(result => {
        message = `Transaction reversed for ${this.memberName}.`;
      })
      .catch(err => {
        message = `Couldn't reverse transaction for ${this.memberName}: ${err}`;
      })
      .finally(() => {
        this.resetPage(message);
      });
  }

  private resetPage(message: string) {
    this.toastService.presentToast(message);
    this.transactionToReverse = null;
    this.presenter.reset();
    this.isUpdating = false;
  }
}
