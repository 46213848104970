import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AlertController } from '@ionic/angular';
import { AdminRole } from '@shared/constants/admin-role';
import { ALL_COUNTRIES, CountryKey } from '@shared/constants/country';
import { IContent } from '@shared/models/content';
import { IInput } from '@shared/models/input';
import { AuthService } from '@shared/services/auth.service';
import { ConstantsService } from '@shared/services/constants.service';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { first, map } from 'rxjs/operators';
import { IPage } from '../models/page';
import { ContentPageService } from '../services/content-page.service';

@Component({
  selector: 'app-content-page-view',
  templateUrl: './content-page-view.page.html'
})
export class ContentPageViewPage {
  get ALLOWED_COUNTRIES() {
    return Object.values(this.constantsService.constants.APP.countries || {});
  }

  get canEdit() {
    return this.page != null && this.authService.isAdmin([AdminRole.SUPER, AdminRole.HOSTS, AdminRole.EDITOR]);
  }

  content$: Observable<IContent>;
  country$: BehaviorSubject<CountryKey> = new BehaviorSubject(ALL_COUNTRIES);
  page: IPage;

  constructor(private alertController: AlertController, private authService: AuthService, private constantsService: ConstantsService, private contentPageService: ContentPageService, private route: ActivatedRoute) {}

  ionViewWillEnter() {
    const pageId = this.route.snapshot.paramMap.get('pageId');
    const page$ = this.contentPageService.getPage(pageId).pipe(first(x => !!x));

    const memberCountry = this.authService.getCountry() || ALL_COUNTRIES;
    this.country$.next(memberCountry);

    this.content$ = combineLatest(this.country$, page$).pipe(
      map(([country, page]) => {
        this.page = page;
        const countryToShow = page.data[country] ? country : ALL_COUNTRIES;
        return page.data[countryToShow];
      })
    );
  }

  onCreate() {
    this.contentPageService.onCreate();
  }

  async onSelectCountry() {
    const existingPages = Object.entries(this.page.data || {})
      .filter(x => (x[1].blocks || []).length > 0)
      .map(y => y[0]);

    const allowedCountryInputs = [ALL_COUNTRIES, ...this.ALLOWED_COUNTRIES]
      .filter(x => existingPages.includes(x))
      .map(country => {
        const input = {
          label: country,
          type: 'radio',
          value: country
        } as IInput;
        if (country === this.country$.value) input['checked'] = true;
        return input;
      });

    const alert = await this.alertController.create({
      cssClass: 'wide-select',
      header: 'Select version',
      message: `If this page has country-specific versions, choose a version of the page to view.`,
      inputs: allowedCountryInputs,
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel'
        },
        {
          text: `Select`,
          handler: data => this.selectCountryHandler(data)
        }
      ]
    });

    await alert.present();
  }

  private selectCountryHandler(data: CountryKey) {
    this.country$.next(data);
  }
}
