import { UserObject } from '@shared/models/user-object';
import { IToolbarIcon } from '../../models/toolbar-icon';
import { AbstractFooterService } from '../abstract-footer.service';

export class ChirpyFooterService extends AbstractFooterService {
  getToolbar(user: UserObject): IToolbarIcon[] {
    return [
      {
        image: {
          active: '/assets/chirpy/footer/home-active.svg',
          inactive: '/assets/chirpy/footer/home-active.svg'
        },
        label: 'Home',
        routerLink: '/home'
      },
      {
        image: {
          active: '/assets/chirpy/footer/my-chirpy-active.svg',
          inactive: '/assets/chirpy/footer/my-chirpy-active.svg'
        },
        label: 'My Chirpy',
        routerLink: '/my-chirpy'
      },
      {
        badge: {
          counter: user.messageNotificationCount,
          showBadge: user.messageNotificationCount > 0
        },
        image: {
          active: '/assets/chirpy/footer/messages-active.svg',
          inactive: '/assets/chirpy/footer/messages-active.svg'
        },
        label: 'Messages',
        routerLink: '/messages'
      },
      {
        badge: {
          counter: user.coinsBalance,
          showBadge: user.coinsBalance > 0
        },
        image: {
          active: '/assets/chirpy/footer/rewards-active.svg',
          inactive: '/assets/chirpy/footer/rewards-active.svg'
        },
        label: 'Rewards',
        routerLink: '/rewards'
      }
    ];
  }
}
