import { MetricType } from '@shared/constants/metric-type';
import { IFraction } from '@shared/models/fraction';
import { ILeaderboardMetric } from '@shared/models/leaderboards/leaderboard-metric';

type UseMetricHandlerFunction = MetricHandler extends (...a: infer U) => infer R ? (metricType: MetricType, ...a: U) => R : never;
type MetricHandler = (data: unknown, currentValue: ILeaderboardMetric, relatedKeyValue?: ILeaderboardMetric['value']) => ILeaderboardMetric;

export const updateMetric: UseMetricHandlerFunction = (metricType, ...rest) => {
  const metricHandlers: Record<MetricType, MetricHandler> = {
    [MetricType.AVERAGE]: averageHandler,
    [MetricType.DISTRIBUTION]: distributionHandler,
    [MetricType.MAX]: maxHandler,
    [MetricType.MAX_STREAK]: maxStreakHandler,
    [MetricType.MIN]: minHandler,
    [MetricType.PERCENTAGE]: percentageHandler,
    [MetricType.STREAK]: streakHandler,
    [MetricType.TOTAL]: totalHandler
  };

  const handler = metricHandlers[metricType];

  return handler(...rest);
};

const averageHandler: MetricHandler = (data, currentValue) => {
  const updatedValue = { ...currentValue };

  const newValue: IFraction = isIFraction(data) ? (data as IFraction) : { numerator: data as number, denominator: 1 };
  updatedValue.numerator += newValue.numerator;
  updatedValue.denominator += newValue.denominator;
  updatedValue.value = +(updatedValue.numerator / updatedValue.denominator).toFixed(1);
  if (updatedValue.value > 10) Math.round(updatedValue.value); // return an integer value if > 10, or 1 decimal place if < 10, so we always have at least two digits

  return updatedValue;
};

const distributionHandler: MetricHandler = (data, currentValue) => {
  const updatedValue = { ...currentValue };

  if (Array.isArray(data) && data.length > 0) {
    for (const label of data) {
      if (!updatedValue.distribution[label]) updatedValue.distribution[label] = 0;
      updatedValue.distribution[label] += 1;
    }
    updatedValue.value = data.pop();
  } else if (typeof data === 'string' || typeof data == 'number') {
    if (!updatedValue.distribution[data]) updatedValue.distribution[data] = 0;
    updatedValue.distribution[data] += 1;
    updatedValue.value = data;
  }

  return updatedValue;
};

const maxHandler: MetricHandler = (data, currentValue, relatedKeyValue) => {
  if (typeof relatedKeyValue !== 'number') {
    return currentValue;
  }

  const updatedValue = { ...currentValue };
  updatedValue.value = Math.max((data || currentValue.value) as number, relatedKeyValue);

  return updatedValue;
};

const maxStreakHandler: MetricHandler = (_data, currentValue, relatedKeyValue) => {
  if (typeof relatedKeyValue !== 'number') {
    return currentValue;
  }
  if (typeof currentValue.value !== 'number') {
    return currentValue;
  }

  const updatedValue = { ...currentValue };
  updatedValue.value = Math.max(currentValue.value, relatedKeyValue);

  return updatedValue;
};

const minHandler: MetricHandler = (data, currentValue, relatedKeyValue) => {
  if (typeof relatedKeyValue !== 'number') {
    return currentValue;
  }

  const updatedValue = { ...currentValue };
  updatedValue.value = Math.min((data || currentValue.value) as number, relatedKeyValue);

  return updatedValue;
};

const percentageHandler: MetricHandler = (data, currentValue) => {
  const updatedValue = { ...currentValue };
  const newValue: IFraction = isIFraction(data) ? data : { numerator: data as number, denominator: 1 };

  updatedValue.numerator += newValue.numerator;
  updatedValue.denominator += newValue.denominator;

  // Treat 100% as special case to avoid it displaying as 1.0e+02
  if (updatedValue.numerator && updatedValue.numerator === updatedValue.denominator) {
    updatedValue.value = 100;
  } else {
    updatedValue.value = +((updatedValue.numerator / updatedValue.denominator) * 100).toPrecision(2);
  }
  return updatedValue;
};

const streakHandler: MetricHandler = (data, currentValue) => {
  const updatedValue = { ...currentValue };

  if (typeof data === 'boolean' && typeof updatedValue.value === 'number') {
    if (data) {
      updatedValue.value += 1;
    } else {
      updatedValue.value = 0;
    }
  }

  return updatedValue;
};

const totalHandler: MetricHandler = (data, currentValue) => {
  const updatedValue = { ...currentValue };

  if (typeof data === 'number' && typeof updatedValue.value === 'number') {
    updatedValue.value += data;
  }

  return updatedValue;
};

function isIFraction(val: unknown): val is IFraction {
  if (typeof val !== 'object') {
    return false;
  }
  if (Array.isArray(val)) {
    return false;
  }

  return val.hasOwnProperty('numerator') && val.hasOwnProperty('denominator');
}
