import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AdminOrHostOrCohostGuard } from '@shared/guards/admin-or-host-or-cohost-guard';
import { MembersGuard } from '@shared/guards/members-guard';
import { CatchupAttendancePage } from './catchup-attendance/catchup-attendance.page';
import { CatchupClaimPage } from './catchup-claim/catchup-claim.page';
import { CatchupEditPage } from './catchup-edit/catchup-edit.page';
import { CatchupsPage } from './catchups.page';
import { CatchupTemplateEditPage } from './catchup-template-edit/catchup-template-edit.page';
import { CatchupTemplateListPage } from './catchup-template-list/catchup-template-list.page';
import { CatchupAttendeesComponent } from './components/catchup-detail/components/catchup-attendees/catchup-attendees.component';
import { CatchupDetailComponent } from './components/catchup-detail/catchup-detail.component';
import { CatchupDescriptionComponent } from './components/catchup-detail/components/catchup-description/catchup-description.component';
import { CatchupGuestsComponent } from './components/catchup-detail/components/catchup-guests/catchup-guests.component';
import { CatchupMessagesComponent } from './components/catchup-detail/components/catchup-messages/catchup-messages.component';
import { CatchupPaymentsComponent } from './components/catchup-detail/components/catchup-payments/catchup-payments.component';
import { CatchupWaitlistComponent } from './components/catchup-detail/components/catchup-waitlist/catchup-waitlist.component';
import { VirtualCatchupPage } from './virtual-catchup/virtual-catchup.page';

export const routes: Routes = [
  {
    path: 'attendance',
    component: CatchupAttendancePage,
    canActivate: [MembersGuard],
    data: {
      title: 'CatchUp attendance'
    }
  },
  {
    path: 'claim',
    component: CatchupClaimPage,
    canActivate: [MembersGuard],
    data: {
      title: 'Claim CatchUp coins'
    }
  },
  {
    path: '',
    component: CatchupsPage,
    children: [
      {
        path: ':catchupId',
        component: CatchupDetailComponent,
        canActivate: [MembersGuard],
        children: [
          {
            path: '',
            redirectTo: 'description'
          },
          {
            path: 'attendees',
            component: CatchupAttendeesComponent,
            canActivate: [MembersGuard],
            data: {
              title: 'CatchUp attendees'
            }
          },
          {
            path: 'description',
            component: CatchupDescriptionComponent,
            canActivate: [MembersGuard],
            data: {
              title: 'CatchUp description'
            }
          },
          {
            path: 'guests',
            component: CatchupGuestsComponent,
            canActivate: [AdminOrHostOrCohostGuard],
            data: {
              title: 'CatchUp guests'
            }
          },
          {
            path: 'notes',
            component: CatchupMessagesComponent,
            canActivate: [MembersGuard],
            data: {
              title: 'CatchUp notes'
            }
          },
          {
            path: 'payments',
            component: CatchupPaymentsComponent,
            canActivate: [AdminOrHostOrCohostGuard],
            data: {
              title: 'CatchUp payments'
            }
          },
          {
            path: 'waitlist',
            component: CatchupWaitlistComponent,
            canActivate: [AdminOrHostOrCohostGuard],
            data: {
              title: 'CatchUp waitlist'
            }
          }
        ],
        data: {
          title: 'CatchUp details'
        }
      }
    ]
  },
  {
    path: 'group/:groupId/:groupName',
    component: CatchupsPage,
    children: [
      {
        path: ':catchupId',
        component: CatchupDetailComponent,
        canActivate: [MembersGuard],
        children: [
          {
            path: '',
            redirectTo: 'description'
          },
          {
            path: 'attendees',
            component: CatchupAttendeesComponent,
            canActivate: [MembersGuard],
            data: {
              title: 'CatchUp attendees'
            }
          },
          {
            path: 'description',
            component: CatchupDescriptionComponent,
            canActivate: [MembersGuard],
            data: {
              title: 'CatchUp description'
            }
          },
          {
            path: 'guests',
            component: CatchupGuestsComponent,
            canActivate: [AdminOrHostOrCohostGuard],
            data: {
              title: 'CatchUp guests'
            }
          },
          {
            path: 'notes',
            component: CatchupMessagesComponent,
            canActivate: [MembersGuard],
            data: {
              title: 'CatchUp notes'
            }
          },
          {
            path: 'payments',
            component: CatchupPaymentsComponent,
            canActivate: [AdminOrHostOrCohostGuard],
            data: {
              title: 'CatchUp payments'
            }
          },
          {
            path: 'waitlist',
            component: CatchupWaitlistComponent,
            canActivate: [AdminOrHostOrCohostGuard],
            data: {
              title: 'CatchUp waitlist'
            }
          }
        ],
        data: {
          title: 'CatchUp details'
        }
      }
    ]
  },
  {
    path: 'manage-templates/:groupId/:groupName',
    component: CatchupTemplateListPage,
    canActivate: [AdminOrHostOrCohostGuard],
    data: {
      title: 'Manage CatchUp templates'
    }
  },
  {
    path: 'update/:catchupId/:groupId',
    component: CatchupEditPage,
    canActivate: [AdminOrHostOrCohostGuard],
    data: {
      title: 'Update CatchUp'
    }
  },
  {
    path: 'update-template/:groupId/:templateId',
    component: CatchupTemplateEditPage,
    canActivate: [AdminOrHostOrCohostGuard],
    data: {
      title: 'Update CatchUp template'
    }
  },
  {
    path: 'virtual-catchup/:id',
    component: VirtualCatchupPage,
    canActivate: [MembersGuard],
    data: {
      title: 'Virtual CatchUp'
    }
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class CatchupsRoutingModule {}
