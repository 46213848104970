import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'bingo-tile-heading',
  styleUrls: ['./tile-heading.component.scss'],
  templateUrl: './tile-heading.component.html'
})
export class BingoTileHeadingComponent implements OnInit {
  @Input() heading: string = 'Bingo';

  headingChars: string[];

  ngOnInit() {
    this.headingChars = this.heading.toLocaleUpperCase().split('');
  }
}
