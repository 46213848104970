import { Component, OnInit } from '@angular/core';
import { AlertController } from '@ionic/angular';
import { CatchupRsvpStatus } from '@shared/constants/catchup-rsvp-status';
import { IColumn } from '@shared/models/column';
import { GroupService } from '@shared/services/groups/group.service';
import { first, map } from 'rxjs/operators';
import { IInput } from '@shared/models/input';
import { BaseReportComponent } from '../../base-report/base-report.component';

@Component({
  selector: 'app-reports-catchups-by-group',
  templateUrl: '../../base-report/base-report.component.html'
})
export class CatchupsByGroupComponent extends BaseReportComponent {
  columns = [
    { label: 'Date', field: 'date', class: 'data-table__column-responsive-bold' },
    { label: 'Name', field: 'title' },
    { label: 'Group Name', field: 'groupName' },
    { label: 'Host Name', field: 'ownerName' },
    { label: 'Going', field: 'going' },
    { label: 'Not Going', field: 'notGoing' },
    { label: "Didn't attend", field: 'didNotAttend' },
    { label: 'Claimed coins', field: 'goingClaimed' },
    { label: 'Shared', field: 'shared' }
  ];
  config = {
    routerLink: {
      path: '/catchups',
      id: [':uid', 'attendees']
    }
  };
  groupIds: string[] = [];
  parametersClass = 'wide-select';
  parametersHeader = 'Select groups';
  parametersMessage = '<p>Choose between 1 and 10 groups to see all CatchUps currently in the database for those groups.</p><p class="ion-no-margin">N.B. Catchups are automatically deleted 40 days after the date of the event.</p>';
  parametersInputs: IInput[] = [];
  instructions = '<p>Click on "select parameters" to choose groups for the report</p>';
  title = 'CatchUps Report by Group';

  constructor(alertController: AlertController, private groupService: GroupService) {
    super(alertController);
    this.loadGroups();
  }

  private loadGroups() {
    this.groupService
      .getAllGroupNames(true)
      .pipe(first(x => !!x))
      .subscribe(groups => {
        this.parametersInputs = groups
          .filter(x => x.name)
          .sort((a, b) => a.name.localeCompare(b.name))
          .map(group => {
            return {
              label: group.name,
              type: 'checkbox',
              value: group.uid
            };
          });
      });
  }

  loadData() {
    if (this.groupIds.length === 0) return;

    this.data$ = this.groupService.getAllCatchups(this.groupIds).pipe(
      map(catchups => {
        return catchups.map(catchup => {
          const attendees = Object.values(catchup.attendees || {});
          const numbers = {
            didNotAttend: attendees.filter(x => CatchupRsvpStatus.DID_NOT_ATTEND === x).length,
            goingClaimed: attendees.filter(x => CatchupRsvpStatus.GOING_CLAIMED === x).length,
            going: attendees.filter(x => CatchupRsvpStatus.GOING === x || CatchupRsvpStatus.GOING_CLAIMED === x).length,
            notGoing: attendees.filter(x => CatchupRsvpStatus.NOT_GOING === x).length,
            waiting: attendees.filter(x => CatchupRsvpStatus.WAITING_FOR_HOST === x).length
          };
          const other = { other: attendees.length - numbers.going - numbers.notGoing - numbers.waiting - numbers.didNotAttend };
          let isShared = Object.keys(catchup.sharedGroups || {}).length > 0 ? 'Yes' : 'No';
          // Some hosts share a catchup with their own group
          if (Object.keys(catchup.sharedGroups || {}).length === 1 && catchup.sharedGroups[catchup.groupId] === catchup.groupName) isShared = 'No';
          const shared = { shared: isShared };
          const ownerName = { ownerName: catchup.ownerName.replace(/\(([^(]*)$/, '').trim() }; // remove (firstName) from ownerName string
          return Object.assign({}, catchup, numbers, other, shared, ownerName);
        });
      })
    );
  }

  selectParametersHandler(data: any) {
    this.groupIds = data;
    this.loadData();
  }
}
