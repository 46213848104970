/**
 * Shuffles an array using the Dirstenfeld variant of the Fisher-Yates shuffle.
 * @param arr The array to be shuffled.
 * @returns The shuffled array.
 * @see {@link https://en.wikipedia.org/wiki/Fisher%E2%80%93Yates_shuffle#The_modern_algorithm|Wikipedia}
 */
export function shuffle<T>(arr: T[]) {
  const shuffled = arr.slice(0);

  for (let i = shuffled.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));

    [shuffled[i], shuffled[j]] = [shuffled[j], shuffled[i]];
  }

  return shuffled;
}