import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { IBingoTicket } from '../../../models/bingo-ticket';
import { IBingoTicketCell } from '../../../models/bingo-ticket-cell';
import { INumberDaubedEvent } from '../../models/number-daubed-event';

@Component({
  selector: 'bingo-ticket',
  styleUrls: ['./bingo-ticket.component.scss'],
  templateUrl: './bingo-ticket.component.html'
})
export class BingoTicketComponent implements OnInit {
  cells: IBingoTicketCell[] = [];

  @Input() ticket: IBingoTicket;
  @Input() disabled: boolean = false;

  @Output() onNumberDaubed = new EventEmitter<INumberDaubedEvent>();

  ngOnInit() {
    if (this.ticket) this.cells = this.ticket.rows.flat();
  }

  onNumberClicked(cell: IBingoTicketCell) {
    this.onNumberDaubed.emit({
      ticketId: this.ticket.id,
      index: cell.index
    });
  }
}
