import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ChirpleGamePageComponent as GamePage, ChirpleLandingPageComponent as LandingPage } from './pages';

const routes: Routes = [
  {
    path: '',
    component: LandingPage
  },
  {
    path: 'play',
    component: GamePage
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ChirpleRoutingModule {}
