import { Injectable } from '@angular/core';
import { AngularFireFunctions } from '@angular/fire/functions';
import { AngularFirestore } from '@angular/fire/firestore';
import { map } from 'rxjs/operators';
import { BaseDatabase } from '@shared/services/base.database';
import { CacheService } from '@shared/services/cache.service';
import { IChirpleGame, ChirpleValidWords } from '../models';

@Injectable({
  providedIn: 'root'
})
export class ChirpleDatabase extends BaseDatabase {
  private readonly ROOT_COLLECTION = `${this.COLLECTION.GAMES}/chirple`;

  constructor(afs: AngularFirestore, cache: CacheService, private fns: AngularFireFunctions) {
    super(afs, cache);
  }

  getGame() {
    const tzOffset = new Date().getTimezoneOffset();
    const getGameFn = this.fns.httpsCallable<{ tzOffset: number }, IChirpleGame>('getChirpleGame');

    return getGameFn({ tzOffset });
  }

  getValidWords() {
    // The list of valid words is static so we can afford to use
    // a very long cache time here
    const cacheTTL = 12 * 60 * 60 * 1000; // 12 hours
    const useCache = true;

    return this.getDocument<ChirpleValidWords>(`${this.ROOT_COLLECTION}/words`, 'valid', useCache, cacheTTL);
  }

  updateGame<T extends object>(gameId: string, data: T, merge = true) {
    const document = {
      ...data,
      uid: gameId,
      updated: Date.now()
    };

    return this.updateDocument(`${this.ROOT_COLLECTION}/games`, gameId, document, merge);
  }
}
