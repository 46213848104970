import { Component, Input, OnInit } from '@angular/core';
import { LimitPeriod } from '@infrastructure/constants/limit-period';
import { GameType } from '@shared/constants/game-type';
import { AuthService } from '@shared/services/auth.service';
import { LeaderboardService } from '@shared/services/leaderboards/leaderboard.service';
import { LeaderboardDataAttributes } from './models/leaderboard-attributes';

@Component({
  selector: 'app-game-landing-page',
  templateUrl: './game-landing-page.component.html',
  styleUrls: ['./game-landing-page.component.scss']
})
export class GameLandingPageComponent implements OnInit {
  @Input() bannerUrl: string;
  @Input() gameType: GameType;
  @Input() heading: string;
  @Input() leaderboardPeriod: LimitPeriod = LimitPeriod.MONTH;

  leaderboardData: LeaderboardDataAttributes;

  constructor(private authService: AuthService, private leaderboardService: LeaderboardService) {}

  ngOnInit() {
    this.initLeaderboardAttributes();
  }

  private initLeaderboardAttributes() {
    if (!this.gameType) {
      return;
    }

    this.authService._userProfileSubject.subscribe(profile => {
      if (!profile) {
        return;
      }

      const { customLabels, gameType, metricKeys, rows, sortOptions } = this.leaderboardService.getLandingPageOptionsForGame(this.gameType);

      this.leaderboardData = {
        customLabels,
        gameType,
        metricKeys,
        period: this.leaderboardPeriod,
        place: `${profile.country}_${profile.region}`,
        playerId: profile.uid,
        rows,
        sortKey: metricKeys[0],
        viewShowAll: true
      };
    });
  }
}
