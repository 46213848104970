import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AngularFireFunctions } from '@angular/fire/functions';
import { CancellationOption } from '@shared/constants/cancellation-option';
import { IChargebeeSubscription } from '@shared/models/chargebee-subscription';
import { UserObject } from '@shared/models/user-object';
import { AppOptionsService } from '@shared/services/app-options/app-options.service';
import { AuthService } from '@shared/services/auth.service';
import { ConstantsService } from '@shared/services/constants.service';
import { DateTimeService } from '@shared/services/date-time.service';
import { EmailService } from '@shared/services/email/email.service';
import { ToastService } from '@shared/services/toast.service';
import { Observable } from 'rxjs';
import { first } from 'rxjs/operators';
import { CancelSubscriptionService } from '../../services/cancel-subscription.service';
import { CancelOptionPresenter } from './cancel-option.presenter';

@Component({
  selector: 'app-cancel-option',
  templateUrl: 'cancel-option.page.html',
  styleUrls: ['cancel-option.page.scss']
})
export class CancelOptionPage implements OnInit {
  get form() {
    return this.presenter.form;
  }

  get formValid() {
    return this.presenter.form.valid;
  }

  alreadyCancelled: boolean = false;
  readonly CancellationOption = CancellationOption;
  CONSTANTS: any;
  endOfTerm: boolean = false;
  hasProcessed: boolean = false;
  isLoading: boolean = true;
  isOtherReason: boolean = false;
  isProcessing: boolean = false;
  member: UserObject;
  nextBilling: string;
  notices$: Observable<any>;
  showExtraQuestions: boolean = false;
  showMessage: boolean = false;
  subscriptions: IChargebeeSubscription[];
  submitButtonText: string = 'Submit';

  constructor(
    private appOptionsService: AppOptionsService,
    private authService: AuthService,
    private constantsService: ConstantsService,
    private dateTimeService: DateTimeService,
    private emailService: EmailService,
    private fns: AngularFireFunctions,
    private presenter: CancelOptionPresenter,
    private router: Router,
    private service: CancelSubscriptionService,
    private toastService: ToastService
  ) {}

  ngOnInit() {
    this.CONSTANTS = this.constantsService.constants.ACCOUNT.CANCEL;

    this.authService.userProfileObservable.pipe(first(x => !!x)).subscribe(member => {
      if (member == null) return;

      this.notices$ = this.appOptionsService.getOptionsValues<string>('cancellationNotice', this.authService._userProfileSubject.value.country);
      this.member = member;

      const data = {
        chargebeeId: member.chargebeeId,
        country: member.country
      };

      this.service
        .getSubscriptionDetails(data)
        .then(results => {
          this.isLoading = false;
          this.subscriptions = results || [];
          this.alreadyCancelled = this.subscriptions.map(x => x.status).includes('non_renewing');
          this.nextBilling = this.subscriptions.length === 0 ? '' : this.subscriptions.map(x => x.next_billing).join(','); // it is possible for a member to have more than one subscription, though currently this should only happen if they have a duplicate subscription
        })
        .catch(err => {
          console.log(err);
        });
    });
  }

  onSubmit() {
    if (this.validateForm()) {
      this.isProcessing = true;
      this.submitButtonText = 'Processing';

      // Don't do anything if we don't have subscription ids, or we haven't loaded member details
      if (this.subscriptions.length === 0 || !this.member || !this.member.country) {
        this.isProcessing = false;
        this.submitButtonText = 'Submit';
        this.toastService.presentToast(`Sorry, we can't retrieve your subscription details, please try again later`);
      }

      const { message, option, otherReason, reason, yesNo } = this.presenter.submit();
      this.service.option = option;
      this.endOfTerm = option === CancellationOption.CANCEL_END_OF_TERM;

      if (CancellationOption.SUPPORT_REQUEST === option) {
        this.emailService.sendSubscriptionHelpRequestToSupport(this.member, this.subscriptions, message);
        this.isProcessing = false;
        this.hasProcessed = true;
      } else if (CancellationOption.CANCEL_END_OF_TERM === option || CancellationOption.CANCEL_NOW === option) {
        this.isProcessing = false;
        this.submitButtonText = 'Submit';
        this.router.navigate(['/account/cancel-subscription/questions']);
      }
    }
  }

  onUpdateOption(event: any) {
    const option = event.detail.value;
    if (CancellationOption.SUPPORT_REQUEST === option) {
      this.showMessage = true;
    } else {
      this.showMessage = false;
    }
  }

  validateForm(): boolean {
    const { message, option } = this.presenter.submit();
    if (!option) {
      this.toastService.presentToast(`Please select an option.`);
      return false;
    }
    return true;
  }
}
