import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { EventType } from '@infrastructure/constants/event-type';
import { AlertController } from '@ionic/angular';
import { AdminRole } from '@shared/constants/admin-role';
import { Country } from '@shared/constants/country';
import { ICatchup } from '@shared/models/catchups/catchup';
import { ICatchupTemplate } from '@shared/models/catchups/catchup-template';
import { ISelectOption } from '@shared/models/select-option';
import { UserObject } from '@shared/models/user-object';
import { AuthService } from '@shared/services/auth.service';
import { CatchupTypeService } from '@shared/services/catchups/catchup-types.service';
import { DateTimeService } from '@shared/services/date-time.service';
import { RegionService } from '@shared/services/regions/region.service';
import { CatchupService } from '@shared/services/catchups/catchup.service';
import { ConstantsService } from '@shared/services/constants.service';
import { GroupService } from '@shared/services/groups/group.service';
import { SubscriptionService } from '@shared/services/subscription.service';
import { UIService } from '@shared/services/ui.service';
import { UserService } from '@shared/services/user/user.service';
import { Observable, of, Subscription } from 'rxjs';
import { first, skipWhile, switchMap, take, tap } from 'rxjs/operators';
import { CatchupEditPresenter } from './catchup-edit.presenter';

@Component({
  selector: 'app-catchup-edit',
  styleUrls: ['./catchup-edit.page.scss'],
  templateUrl: './catchup-edit.page.html',
  viewProviders: [CatchupEditPresenter]
})
export class CatchupEditPage implements OnInit, OnDestroy {
  get canShowAddress() {
    return this.catchupTypeService.canShowAddress(this.eventType);
  }

  get canShowRegion() {
    return this.catchupTypeService.canShowRegion(this.eventType);
  }

  get canShowPayment() {
    return this.catchupEditPresenter.hasPayments();
  }

  get canShowRSVP() {
    return this.catchupTypeService.canShowRSVP(this.eventType);
  }

  get eventType() {
    return this.catchupEditPresenter.eventType();
  }

  get CONSTANTS() {
    return this.constantsService.constants.CATCHUPS.EDIT;
  }

  get form() {
    return this.catchupEditPresenter.form;
  }

  get hasAutomaticRSVP() {
    return !!this.catchupEditPresenter.hasAutomaticRSVP();
  }

  get hasDate() {
    return !!this.catchupEditPresenter.date();
  }

  get isAdmin() {
    return this.authService.isAdmin([AdminRole.HOSTS]);
  }

  get isEdit() {
    return this.catchup != null && this.catchup.uid != null;
  }

  get searchCountry() {
    const allowOtherCountries = this.constantsService.constants.APP.allowOtherCountries;
    if (allowOtherCountries) return '';
    return this.user && !this.isAdmin ? this.user.country : '';
  }

  get title() {
    return this.isEdit ? `Update ${this.eventType || ''}` : `Create ${this.eventType || ''}`;
  }

  get updateButtonText() {
    return this.isEdit ? 'Update' : 'Create';
  }

  BRANDING: string;
  catchup: ICatchup;
  CatchupTypes: EventType[] = [];
  COUNTRIES: Record<string, Country> = {};
  groupId: string;
  groupName: string;
  groupSubscription: Subscription;
  hostsAndCohosts: Record<string, string> = {};
  interfaceOptions: any = { cssClass: 'wide-select' };
  isCreatingCatchUp: boolean;
  MAX_DATE: string;
  regionLabel: string = '';
  regions: ISelectOption[] = [];
  selectedTemplate: ICatchupTemplate;
  templates$: Observable<ICatchupTemplate[]>;
  user: UserObject;

  constructor(
    private alertController: AlertController,
    private authService: AuthService,
    private catchupEditPresenter: CatchupEditPresenter,
    private catchupService: CatchupService,
    private catchupTypeService: CatchupTypeService,
    private constantsService: ConstantsService,
    private dateTimeService: DateTimeService,
    private groupService: GroupService,
    private regionService: RegionService,
    private route: ActivatedRoute,
    private router: Router,
    private subscriptionService: SubscriptionService,
    private uiService: UIService,
    private userService: UserService
  ) {}

  getNumberOfRows() {
    return this.uiService.getNumberOfTextAreaRows();
  }

  goBack() {
    this.router.navigate(['/catchups']);
  }

  ngOnDestroy() {
    this.subscriptionService.clearSubscription(this.groupSubscription);
  }

  ngOnInit() {
    const uid = this.route.snapshot.paramMap.get('catchupId');
    this.groupId = this.route.snapshot.paramMap.get('groupId');

    this.BRANDING = this.constantsService.constants.CATCHUPS.branding;
    this.COUNTRIES = this.constantsService.constants.APP.countries;
    this.MAX_DATE = this.dateTimeService.MAX_DATE;
    this.templates$ = this.catchupService.getCatchupTemplates(this.groupId);

    // All CatchUps are now associated with a group. Non-group activites are Events
    // Admins/Hosts can create Catchups or Virtual Catchups for a Group via Group Details page.
    this.CatchupTypes = [EventType.CatchUp, EventType.Virtual];

    this.authService._userProfileSubject
      .pipe(
        skipWhile(x => x == null),
        take(1) // if we don't read once, the subscribe callback can be triggered by profile updates during the catchup creation
      )
      .subscribe(async user => {
        if (user == null) return;
        this.user = user;

        this.regions = this.regionService.catchupRegions;
        this.regionLabel = this.regionService.regionLabel;

        if (uid === 'new') {
          let eventType = EventType.CatchUp;

          // Pre-populate country from members profile
          const country = [this.user.country];

          this.catchup = {
            rsvp: false,
            allGroupIds: [],
            approved: false,
            attendanceCounted: false,
            attendees: {},
            eventType,
            country,
            created: 0,
            description: this.CONSTANTS.populateDescription,
            groupId: this.groupId,
            groupName: '',
            guestCount: 0,
            title: '',
            photoURL: '',
            sharedGroups: {},
            uid: null
          } as ICatchup;

          this.catchupEditPresenter.setValue(this.catchup);

          // Save group data against catchup to reduce Firebase collection reads when viewing catchups.
          this.subscriptionService.clearSubscription(this.groupSubscription);
          this.groupSubscription = this.groupService.getGroup(this.groupId).subscribe(group => {
            this.catchup.allGroupIds = [this.groupId];
            this.catchup.groupName = group.name;
            this.groupName = group.name;
            this.catchup.isGroupUnhosted = !!group.isUnhosted;
            this.catchup.largePhotoURL = group.largePhotoURL || '';
            this.catchup.ownerId = this.user.uid;
            this.catchup.ownerName = `${this.user.displayName} (${this.user.firstName})`;
            this.catchup.photoURL = group.photoURL || '';

            this.hostsAndCohosts = Object.assign({}, group.hosts, group.cohosts);

            // Pre-populate address from Group details.
            this.catchup.region = group.region;
            if (group.region.startsWith('Virtual')) {
              this.catchup.eventType = EventType.Virtual;
            }
            this.catchup.country = group.country;

            this.catchupEditPresenter.setValue(this.catchup);
          });
          this.subscriptionService.add(this.groupSubscription);

          return;
        }

        this.catchupService
          .getCatchup(uid)
          .pipe(
            first(x => !!x),
            switchMap((catchup: ICatchup) => {
              if (catchup == null) return;
              this.catchup = catchup;
              this.groupName = catchup.groupName;
              return this.groupService.getHostsAndCohosts$(catchup.groupId);
            }),
            first(x => !!x),
            tap((hostsAndCohosts: Record<string, string>) => {
              // Check whether you have permission to edit this CatchUp
              if (!this.isAdmin && !Object.keys(hostsAndCohosts).includes(this.user.uid)) {
                this.router.navigate(['/error/permission-denied']); // TODO: Create a no permissions page instead to avoid confusion
                return;
              }
            })
          )
          .subscribe((hostsAndCohosts: Record<string, string>) => {
            this.hostsAndCohosts = hostsAndCohosts;
            this.catchupEditPresenter.setValue(this.catchup);
          });
      });
  }

  onAddGroup(item: Record<string, string>) {
    this.catchup.sharedGroups = this.catchup.sharedGroups || {};
    this.catchup.sharedGroups[item.key] = item.value;

    const names = Object.values(this.catchup.sharedGroups);
    this.catchupEditPresenter.form.controls.sharedGroups.setValue(names);
  }

  onChangeDate(event: any) {
    // Most CatchUps do not span multiple days, so pre-fill the endDate field with the date when first entered
    const date = event.target.value;
    if (!this.catchupEditPresenter.endDate() || this.catchupEditPresenter.endDate() < date) this.catchupEditPresenter.patchValue({ endDate: date });
  }

  onChangeTemplate(event: any) {
    const template = event.detail.value;
    if (template) this.catchupEditPresenter.setValue(template);
  }

  async onClear() {
    const alert = await this.alertController.create({
      header: `Clear form?`,
      message: `Are you sure you want to clear all details for this ${this.BRANDING}?`,
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
          cssClass: 'secondary'
        },
        {
          text: `Yes`,
          handler: data => this.onClearHandler()
        }
      ]
    });

    await alert.present();
  }

  onClearHandler() {
    this.catchupEditPresenter.clear();
    this.catchup.sharedGroups = {};
    this.selectedTemplate = null;
  }

  onRemoveGroup(item: Record<string, string>) {
    delete this.catchup.sharedGroups[item.key];
    const names = Object.values(this.catchup.sharedGroups);
    this.catchupEditPresenter.form.controls.sharedGroups.setValue(names);
  }

  searchGroups(startsWith: string = '') {
    // There shouldn't be a double read when checking for clashes in updateCatchup because we are caching the result
    return this.groupService.searchGroupsWithoutCatchups(startsWith, this.catchupEditPresenter.date(), this.searchCountry);
  }

  updateCatchup() {
    // TODO: Diff old and new values and emit notification if significant
    // merge form values with catchup
    const formValue = this.catchupEditPresenter.catchup();
    Object.assign(this.catchup, formValue);

    // Update owner name
    if (this.hostsAndCohosts[this.catchup.ownerId]) {
      this.catchup.ownerName = this.hostsAndCohosts[this.catchup.ownerId];
    } else {
      // If something goes wrong then set the current member as the owner
      this.catchup.ownerId = this.authService._userProfileSubject.value.uid;
      this.catchup.ownerName = `${this.authService._userProfileSubject.value.displayName} (${this.authService._userProfileSubject.value.firstName})`;
    }

    // merge groupId with any shared group Ids
    const sharedGroups = Object.keys(this.catchup.sharedGroups || {});
    this.catchup.allGroupIds = [this.catchup.groupId, ...sharedGroups];

    // Check for clashes with CatchUps in shared groups
    // We need to do this here for CatchUps created from a template
    if (sharedGroups.length > 0) {
      this.catchupService
        .getCatchupsOnDate(this.catchup.date, this.searchCountry)
        .pipe(first(x => !!x))
        .subscribe(catchups => {
          for (const catchup of catchups) {
            if (this.catchup.sharedGroups[catchup.groupId]) delete this.catchup.sharedGroups[catchup.groupId];
          }
          this.catchupService.updateCatchup(this.user, this.catchup);
        });
    } else {
      this.catchupService.updateCatchup(this.user, this.catchup);
    }
  }
}
