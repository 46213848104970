import { NgModule } from '@angular/core';
import { SharedModule } from '@shared/shared.module';
import { GameLandingPageComponent } from './components/game-landing-page/game-landing-page.component';

@NgModule({
  declarations: [GameLandingPageComponent],
  exports: [GameLandingPageComponent, SharedModule],
  imports: [SharedModule]
})
export class GamesSharedModule {}
