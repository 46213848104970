import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AlertController } from '@ionic/angular';
import { AdminRole } from '@shared/constants/admin-role';
import { IBnBListing } from '@shared/models/bnb/bnb-listing';
import { IBnBReview } from '@shared/models/bnb/bnb-review';
import { IContactFormSetting } from '@shared/models/contact-form-setting';
import { IDifference } from '@shared/models/difference';
import { IListingImage } from '@shared/models/image/listing-image';
import { UserObject } from '@shared/models/user-object';
import { AnalyticsAction, AnalyticsCategory, AnalyticsService } from '@shared/services/analytics';
import { AuthService } from '@shared/services/auth.service';
import { ConstantsService } from '@shared/services/constants.service';
import { DateTimeService } from '@shared/services/date-time.service';
import { EmailService } from '@shared/services/email/email.service';
import { ImageService } from '@shared/services/image/image.service';
import { SubscriptionService } from '@shared/services/subscription.service';
import { ToastService } from '@shared/services/toast.service';
import { BnBDatabase } from './bnb.database';
import { firestore } from 'firebase/app';
import { BehaviorSubject, Observable, of, Subscription } from 'rxjs';
import { skipWhile, switchMap, take } from 'rxjs/operators';
import FieldValue = firestore.FieldValue;

@Injectable({
  providedIn: 'root'
})
export class BnBService {
  get CONSTANTS() {
    return this.constantsService.constants.BNB.SERVICE;
  }

  listingsSubscription: Subscription;
  recordsToFetch = 9999; // for the moment load all listings. If we start getting too many, then revisit implementation.

  approveBnBListing(uid: string) {
    const data = { uid, approved: true };
    return this.bnbDatabase.updateBnBListing(data);
  }

  approveBnBReview(listingId: string, reviewId: string) {
    const data = { approved: true };
    return this.bnbDatabase.updateBnBReview(listingId, reviewId, data).then(() => {
      this.bnbDatabase.updateBnBListing({ uid: listingId, reviewCount: FieldValue.increment(1) });
    });
  }

  constructor(
    private alertController: AlertController,
    private analyticsService: AnalyticsService,
    private authService: AuthService,
    private bnbDatabase: BnBDatabase,
    private constantsService: ConstantsService,
    private dateTimeService: DateTimeService,
    private emailService: EmailService,
    private imageService: ImageService,
    private router: Router,
    private subscriptionService: SubscriptionService,
    private toastService: ToastService
  ) {}

  async deleteBnBListing(bnbListing: IBnBListing) {
    const alert = await this.alertController.create({
      header: this.CONSTANTS.deleteHeader,
      message: this.CONSTANTS.deleteMessage,
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
          cssClass: 'secondary'
        },
        {
          text: 'OK',
          handler: data => this.deleteBnBListingHandler(bnbListing)
        }
      ]
    });

    await alert.present();
  }

  deleteBnBListingHandler(bnbListing: IBnBListing) {
    return this.bnbDatabase.deleteBnBListing(bnbListing.uid).then(() => {
      this.toastService.presentToast(this.CONSTANTS.deletedToast);
      this.router.navigate(['/bed-and-breakfast']);
    });
  }

  async deleteBnBReview(bnbReview: IBnBReview) {
    const alert = await this.alertController.create({
      header: this.CONSTANTS.deleteReviewHeader,
      message: this.CONSTANTS.deleteReviewMessage,
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
          cssClass: 'secondary'
        },
        {
          text: 'OK',
          handler: data => this.deleteBnBReviewHandler(bnbReview)
        }
      ]
    });

    await alert.present();
  }

  deleteBnBReviewHandler(bnbReview: IBnBReview) {
    return this.bnbDatabase.deleteBnBReview(bnbReview.listingId, bnbReview.uid).then(() => {
      if (bnbReview.approved) this.bnbDatabase.updateBnBListing({ uid: bnbReview.listingId, reviewCount: FieldValue.increment(-1) }); // don't decrement review count if an unapproved review is deleted
      this.toastService.presentToast(this.CONSTANTS.deletedReviewToast);
      this.router.navigate(['/bed-and-breakfast/', bnbReview.listingId, 'reviews']);
    });
  }

  getBnBListing(uid: string): Observable<IBnBListing> {
    return this.bnbDatabase.getBnBListing(uid);
  }

  getBnBListings() {
    const subject = new BehaviorSubject<IBnBListing[]>(null);

    this.authService._userProfileSubject
      .pipe(
        skipWhile(x => x == null),
        take(1)
      )
      .subscribe(member => {
        this.subscriptionService.clearSubscription(this.listingsSubscription);
        const country = this.constantsService.constants.APP.allowOtherCountries ? null : member.country;
        this.listingsSubscription = this.bnbDatabase.getBnBListings(country, this.authService.isAdmin([AdminRole.SUPER]), this.recordsToFetch).subscribe((bnbListings: IBnBListing[]) => {
          if (bnbListings != null) subject.next(bnbListings);
        });
        this.subscriptionService.add(this.listingsSubscription);
      });

    return subject;
  }

  getBnBReview(listingId: string, reviewId: string): Observable<IBnBReview> {
    return this.bnbDatabase.getBnBReview(listingId, reviewId);
  }

  getBnBReviews(listingId: string): Observable<IBnBReview[]> {
    return this.authService.isAdmin$([AdminRole.SUPER, AdminRole.MODERATOR]).pipe(switchMap(isAdmin => this.bnbDatabase.getBnBReviews(listingId, isAdmin)));
  }

  rejectBnBListing(uid: string) {
    const data = { uid, approved: false };
    return this.bnbDatabase.updateBnBListing(data);
  }

  rejectBnBReview(listingId: string, reviewId: string) {
    const data = { approved: false };
    return this.bnbDatabase.updateBnBReview(listingId, reviewId, data).then(() => {
      this.bnbDatabase.updateBnBListing({ uid: listingId, reviewCount: FieldValue.increment(-1) });
    });
  }

  requestBnBListingReview(bnbListing: IBnBListing, oldListing: IBnBListing, isNew: boolean, member: UserObject) {
    if (isNew) {
      this.emailService.sendBnBApprovalRequest(bnbListing, member);
      return;
    }

    // If the listing has been updated, show only the changes
    // Don't show fields which are not intended to be human readable, or not modifiable by member, e.g. memberId, created
    let differences: Record<string, IDifference> = {};
    const ignoredKeys = ['approved', 'country', 'created', 'locationId', 'memberId', 'memberName', 'reviewCount', 'uid'];

    for (const key of Object.keys(bnbListing)) {
      if (!ignoredKeys.includes(key) && bnbListing[key] !== oldListing[key]) {
        if (key === 'fields') {
          for (const fieldKey of Object.keys(bnbListing.fields)) {
            if (bnbListing.fields[fieldKey] !== oldListing.fields[fieldKey]) {
              differences[fieldKey] = {
                old: oldListing.fields[fieldKey],
                new: bnbListing.fields[fieldKey]
              };
            }
          }
        } else if (key === 'photos') {
          for (const photoKey of Object.keys(bnbListing.photos)) {
            if (bnbListing.photos[photoKey] !== oldListing.photos[photoKey]) {
              const oldUrl = this.imageService.getUrl(oldListing.photos[photoKey]);
              const newUrl = this.imageService.getUrl(bnbListing.photos[photoKey]);
              differences[photoKey] = {
                old: oldUrl ? `<img src="${oldUrl}" width="200"/>` : `deleted`,
                new: newUrl ? `<img src="${newUrl}" width="200"/>` : `deleted`
              };
            }
          }
        } else {
          differences[key] = {
            old: oldListing[key],
            new: bnbListing[key]
          };
        }
      }
    }

    this.emailService.sendBnBUpdatedRequest(bnbListing, differences, member);
  }

  updateBnBListing(bnbListing: IBnBListing, oldListing: IBnBListing, fields: IContactFormSetting[], photosToRemove: IListingImage[], regionLabel: string, member: UserObject) {
    if (!this.validateListingForm(bnbListing, fields, regionLabel)) return false;

    const isCreating = bnbListing.uid == null;
    if (isCreating) {
      bnbListing.created = this.dateTimeService.getDateTime();
    }

    // When a member edits their B&B listing it needs approving again unless they're an Admin.
    if (!this.authService.isAdmin([AdminRole.SUPER, AdminRole.SUPPORT])) bnbListing.approved = false;

    // convert first letter to upper case
    bnbListing.title = bnbListing.title[0].toUpperCase() + bnbListing.title.slice(1);

    // Delete images removed from listing
    if (photosToRemove.length > 0) {
      for (const photo of photosToRemove) {
        this.imageService.deleteImage(photo).catch(err => console.error(JSON.stringify(err)));
      }
    }

    // copy photos data for later upload, and remove any File objects (Can't upload them to Firestore, we can't upload the image to Cloud storage until we have a bnbListing uid)
    const photos = this.imageService.deepCopy(bnbListing.photos || {});
    for (let [key, photo] of Object.entries(bnbListing.photos || {})) {
      if (photo.file) {
        delete bnbListing.photos[key].file;
        bnbListing.photos[key].photoURL = '';
      }
    }

    const ref: Promise<any> = isCreating ? this.bnbDatabase.createBnBListing(bnbListing) : this.bnbDatabase.updateBnBListing(bnbListing, false);

    return ref.then(result => {
      const message = `Listing ${isCreating ? 'added' : 'updated'}.`;
      this.toastService.presentToast(message);

      const uid = bnbListing.uid || result.id;
      bnbListing.uid = uid;

      // Now upload photos
      this.imageService.uploadImages(uid, photos, 'bnb');

      this.requestBnBListingReview(bnbListing, oldListing, isCreating, member);

      // Log analytics
      const action = isCreating ? AnalyticsAction.BNB_ADD_LISTING : AnalyticsAction.BNB_UPDATE_LISTING;
      this.analyticsService.eventTrack(AnalyticsCategory.BNB, action, uid);

      this.router.navigate(['/bed-and-breakfast', bnbListing.uid]);
    });
  }

  updateBnBReview(bnbReview: IBnBReview, bnbListing: IBnBListing) {
    if (!this.validateReviewForm(bnbReview, bnbListing)) return false;

    const isCreating = bnbReview.uid == null;
    if (isCreating) {
      bnbReview.dateTime = this.dateTimeService.getDateTime();
    }

    const ref: Promise<any> = isCreating ? this.bnbDatabase.createBnBReview(bnbReview) : this.bnbDatabase.updateBnBReview(bnbReview.listingId, bnbReview.uid, bnbReview);
    return ref.then(result => {
      const uid = bnbReview.uid || result.id;
      bnbReview.uid = uid;

      let analyticsAction = AnalyticsAction.BNB_UPDATE_REVIEW;
      let message = `Review has been updated`;
      let route = ['/bed-and-breakfast', bnbReview.listingId, 'reviews'];

      if (isCreating) {
        this.emailService.sendBnBReviewRequest(bnbReview, bnbListing);
        analyticsAction = AnalyticsAction.BNB_ADD_REVIEW;
        message = `Your review has been created and will be moderated by our support team.`;
        route = ['/bed-and-breakfast', bnbReview.listingId];
      }
      this.analyticsService.eventTrack(AnalyticsCategory.BNB, analyticsAction, uid);
      this.toastService.presentToast(message);
      this.router.navigate(route);
    });
  }

  validateListingForm(data: IBnBListing, fields: IContactFormSetting[], regionLabel: string) {
    const missingFields = [];
    if (data.title.trim().length === 0) missingFields.push('a Title');
    if (data.cost.trim().length === 0) missingFields.push('a Cost');
    if (data.location.trim().length === 0) missingFields.push(`a ${regionLabel}`);
    if (data.location.trim().length === 0) missingFields.push('a Location');
    if (data.description.trim().length === 0) missingFields.push('a Description');

    for (const [id, value] of Object.entries(data.fields)) {
      const fieldConfig = fields.find(x => x.id === id);
      if (fieldConfig && fieldConfig.required && !value) missingFields.push(`a response for ${fieldConfig.title}`);
    }

    if (missingFields.length > 0) {
      const message = 'Please enter ' + missingFields.join(', ');
      this.toastService.presentToast(message);
      return false;
    }

    return true;
  }

  validateReviewForm(data: IBnBReview, listing: IBnBListing) {
    if (data.memberId === listing.memberId) {
      const message = 'You cannot review your own listing';
      this.toastService.presentToast(message);
      return false;
    }

    const missingFields = [];
    if (data.content.trim().length === 0) missingFields.push('your comments');
    if (data.content.trim().length > 500) missingFields.push('fewer than 500 characters');

    if (missingFields.length > 0) {
      const message = 'Please enter ' + missingFields.join(', ');
      this.toastService.presentToast(message);
      return false;
    }

    return true;
  }
}
