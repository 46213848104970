import { Injectable } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { IBnBReview } from '@shared/models/bnb/bnb-review';

@Injectable({
  providedIn: 'root'
})
export class BnBReviewEditPresenter {
  form = this.formBuilder.group({
    content: ['', Validators.required]
  });

  constructor(private formBuilder: FormBuilder) {}

  value() {
    return this.form.value;
  }

  setValue(bnbReview: IBnBReview) {
    this.form.setValue({
      content: bnbReview.content
    });
  }
}
