import { Component, Input } from '@angular/core';
import { AnalyticsService, IAnalyticsSetting } from '@shared/services/analytics';

@Component({
  selector: 'chirpy-paragraph-with-media',
  styleUrls: ['./chirpy-paragraph-with-media.component.scss'],
  templateUrl: './chirpy-paragraph-with-media.component.html'
})
export class ChirpyParagraphWithMediaComponent {
  @Input() align: string;
  @Input() analytics: IAnalyticsSetting = null;
  @Input() height: number;
  @Input() imageURL: string;
  @Input() text: string;
  @Input() videoURL: string;
  @Input() width: number;

  constructor(private analyticsService: AnalyticsService) {}

  eventTrackLink(event: any) {
    if (event.target.tagName === 'A') {
      this.analyticsService.eventTrack(this.analytics.category, this.analytics.action, event.target.pathname, { type: event.target.text });
    }
  }
}
