export function toHex(input: string) {
  return input
    .split('')
    .map(char => char.charCodeAt(0).toString(16))
    .join('');
}

export function fromHex(input: string) {
  const hexDigitLength = 2;

  let result = '';
  for (let i = 0; i < input.length; i += hexDigitLength) {
    const letter = input.slice(i, i + hexDigitLength);
    result += String.fromCharCode(parseInt(letter, 16));
  }

  return result;
}
