import { Component, EventEmitter, OnDestroy, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { AdminRole } from '@shared/constants/admin-role';
import { Gender } from '@shared/constants/gender';
import { IBnBListing } from '@shared/models/bnb/bnb-listing';
import { IBnBOptions } from '@shared/models/bnb/bnb-options';
import { AnalyticsAction, AnalyticsCategory, AnalyticsService } from '@shared/services/analytics';
import { AuthService } from '@shared/services/auth.service';
import { BnBService } from '@shared/services/bnb/bnb.service';
import { ConstantsService } from '@shared/services/constants.service';
import { DateTimeService } from '@shared/services/date-time.service';
import { SubscriptionService } from '@shared/services/subscription.service';
import { Subscription } from 'rxjs';
import { skipWhile, take } from 'rxjs/operators';
import { BnBSearchComponent } from '../bnb-search/bnb-search.component';

@Component({
  selector: 'bnb-list',
  styleUrls: ['bnb-list.component.scss'],
  templateUrl: './bnb-list.component.html'
})
export class BnBListComponent implements OnInit, OnDestroy {
  get CONSTANTS() {
    return this.constantsService.constants.BNB.LIST;
  }

  get isAdmin() {
    return this.authService.isAdmin([AdminRole.SUPER]);
  }

  get isSearch() {
    return Object.values(this.searchCriteria).length > 0;
  }

  allBnBListings: IBnBListing[] = [];
  defaultSearchModel: IBnBOptions = { country: null, region: '' };
  hasRecords = false;
  loading = false;
  myBnBListings: IBnBListing[] = [];
  noListingsMessage: string;
  ownBnBListingRef: Subscription;
  recordsLoaded = false;
  searchCriteria: Record<string, string> = {};
  searchModel: IBnBOptions | null;
  bnbListings: IBnBListing[] = [];
  bnbListingsRef: Subscription;
  showMyListings: boolean = true;
  readonly THREE_DAYS_IN_MILLISECONDS = 3 * 24 * 60 * 60 * 1000;
  today: number;

  constructor(
    private analyticsService: AnalyticsService,
    public authService: AuthService,
    private bnbService: BnBService,
    private constantsService: ConstantsService,
    private dateTimeService: DateTimeService,
    private modalController: ModalController,
    private subscriptionService: SubscriptionService
  ) {}

  getDateFormat(bnbListing: IBnBListing): string {
    return this.dateTimeService.getDateFormat(bnbListing.created);
  }

  loadBnBListings() {
    if (this.loading) return;
    this.loading = true;

    this.subscriptionService.clearSubscription(this.bnbListingsRef);
    this.bnbListingsRef = this.bnbService.getBnBListings().subscribe(bnbListings => {
      if (bnbListings == null) return;

      // Get own listings
      // TODO: Use a separate query to get these?
      this.myBnBListings = bnbListings.filter(x => x.memberId === this.authService._userProfileSubject.value.uid);

      // Filter out listings pending approval for non-admins
      if (!this.authService.isAdmin([AdminRole.SUPER])) {
        bnbListings = bnbListings.filter(x => x.approved);
      }

      this.bnbListings = bnbListings.filter(x => x.memberId !== this.authService._userProfileSubject.value.uid);
      this.allBnBListings = this.bnbListings;

      // If we allow filter by country, then show default country as search parameter
      if (this.constantsService.constants.APP.allowOtherCountries) {
        let initialSearch = Object.assign({}, this.defaultSearchModel, { country: this.authService._userProfileSubject.value.country || '' }); // TODO what if userProfile is not loaded yet?
        this.onSearch(initialSearch);
      }

      this.hasRecords = !!this.bnbListings.length;
      this.recordsLoaded = true;
      this.loading = false;
    });
    this.subscriptionService.add(this.bnbListingsRef);
  }

  ngOnDestroy() {
    this.subscriptionService.clearSubscription(this.bnbListingsRef);
  }

  ngOnInit() {
    this.today = this.dateTimeService.getDay();
    this.noListingsMessage = `${this.CONSTANTS.noRecordsFound}`;
    this.loadBnBListings();
    this.sendAnalytics();
  }

  onClickCriterion(key: string) {
    const criterion = this.searchModel[key];
    this.analyticsService.eventTrack(AnalyticsCategory.BNB, AnalyticsAction.BNB_CLEAR_SEARCH_CRITERION, criterion, { type: key });

    this.searchModel[key] = this.defaultSearchModel[key];
    this.onSearch(this.searchModel);
  }

  async onOpenBnBFilterModal() {
    await this.presentBnBFilterModal();
  }

  onSearch(search: IBnBOptions) {
    this.searchModel = search;
    if (search == null) return;

    this.bnbListings = this.allBnBListings.filter((listing: IBnBListing) => {
      let showListing = true;
      if (listing.region) {
        const normalisedListingRegion = listing.region.trim().toLowerCase();
        const normalisedSearchRegion = search.region.trim().toLowerCase();
        showListing = showListing && normalisedListingRegion.includes(normalisedSearchRegion);
      }
      if (this.constantsService.constants.APP.allowOtherCountries && search.country !== null) {
        showListing = showListing && listing.country === search.country;
      }
      return showListing;
    });
    this.hasRecords = this.bnbListings.length > 0;

    this.updateSearchCriteria(search);
  }

  onToggleMyListings() {
    this.showMyListings = !this.showMyListings;
  }

  async presentBnBFilterModal() {
    const eventEmitter = new EventEmitter<any>();
    eventEmitter.subscribe($event => {
      modal.dismiss();
      return this.onSearch($event);
    });

    const modal = await this.modalController.create({
      component: BnBSearchComponent,
      cssClass: 'bnb-search__modal',
      componentProps: {
        options: this.searchModel,
        search: eventEmitter
      }
    });

    return await modal.present();
  }

  sendAnalytics() {
    this.analyticsService.eventTrack(AnalyticsCategory.BNB, AnalyticsAction.BNB_VIEW_LISTINGS);
  }

  updateSearchCriteria(options: IBnBOptions = null) {
    // TODO: Could almost do this with key: value from IBnBOptions, but that doesn't allow hiding default criteria
    const criteria = {};
    if (options == null) this.searchCriteria = criteria;
    if ((options.country || '').trim().length > 0) criteria['country'] = `Country: ${options.country}`;
    if ((options.region || '').trim().length > 0) criteria['region'] = `Region: ${options.region}`;
    this.searchCriteria = criteria;
  }
}
