import { Component } from '@angular/core';
import { Platform } from '@ionic/angular';
import { AccessStatus } from '@shared/constants/access-status';
import { Country } from '@shared/constants/country';
import { IconSize } from '@shared/constants/icon-size';
import { IDashboardIcon } from '@shared/models/dashboard-icon';
import { UserObject } from '@shared/models/user-object';
import { AnalyticsAction, AnalyticsCategory, AnalyticsService } from '@shared/services/analytics';
import { AuthService } from '@shared/services/auth.service';
import { UIService } from '@shared/services/ui.service';
import { UrlHelperService } from '@shared/services/url-helper.service';
import { of, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.page.html',
  styleUrls: ['./dashboard.page.scss']
})
export class DashboardPage {
  icons = [
    {
      title: 'My Chirpy',
      url: '/my-chirpy',
      size: IconSize.LARGE,
      src: '/assets/chirpy/dashboard/my-chirpy.svg'
    },
    {
      title: 'CatchUps',
      url: '/catchups',
      size: IconSize.LARGE,
      src: '/assets/chirpy/dashboard/catchups.svg'
    },
    {
      title: 'Groups',
      url: '/groups',
      size: IconSize.LARGE,
      src: '/assets/chirpy/dashboard/groups.svg'
    },
    {
      title: 'Chirpy Chat',
      url: '/chit-chat-au',
      size: IconSize.LARGE,
      src: '/assets/chirpy/dashboard/chirpy-chat.svg',
      canShow: this.isAU$()
    },
    {
      title: 'Chirpy Chat',
      url: '/chit-chat-nz',
      size: IconSize.LARGE,
      src: '/assets/chirpy/dashboard/chirpy-chat.svg',
      canShow: this.isNZ$()
    },
    {
      title: 'Bed and Breakfast',
      url: '/bed-and-breakfast',
      size: IconSize.SMALL,
      src: '/assets/chirpy/dashboard/chirpy-bnb.svg',
      noAccessSrc: '/assets/chirpy/dashboard/chirpy-bnb-locked.svg',
      canShow: this.isNZ$(),
      hasAccess: this.authService.canAccessBnB$()
    },
    {
      title: 'Travel',
      url: '/travel-au', //redirected to /groups/50jOCeizq4wGL0genKQE in chirpy-routing.module
      size: IconSize.SMALL,
      src: '/assets/chirpy/dashboard/travel.svg',
      canShow: this.isAU$()
    },
    {
      title: 'Travel',
      url: '/travel-nz', //redirected to /groups/9BnrvUW2w6aeduG9qS2Z in chirpy-routing.module
      size: IconSize.SMALL,
      src: '/assets/chirpy/dashboard/travel.svg',
      canShow: this.isNZ$()
    },
    {
      title: 'Rewards',
      url: '/rewards',
      size: IconSize.SMALL,
      src: '/assets/chirpy/dashboard/rewards.svg'
    },
    {
      title: 'Chirpy Pix',
      url: '/pix/public/Chirpy Pix',
      size: IconSize.SMALL,
      src: '/assets/chirpy/dashboard/chirpy-pix.svg',
      canShow: this.isAU$()
    },
    {
      title: 'Find Local Chirpies',
      url: '/meeting-place',
      size: IconSize.SMALL,
      src: '/assets/chirpy/dashboard/meeting-place.svg'
    },
    {
      title: 'Romance',
      url: '/romance',
      size: IconSize.SMALL,
      hasAccess: this.authService.userProfileObservable.pipe(map(user => user != null && user.canAccessRomance === AccessStatus.GRANTED)),
      src: '/assets/chirpy/dashboard/romance.svg',
      noAccessSrc: '/assets/chirpy/dashboard/romance-locked.svg'
    },
    {
      title: 'Games',
      url: '/games',
      size: IconSize.SMALL,
      src: '/assets/chirpy/dashboard/games.svg'
    },
    {
      title: 'Chirpy Savings',
      url: '/pages/savings',
      size: IconSize.SMALL,
      src: '/assets/chirpy/dashboard/savings.svg',
      canShow: this.isNZ$()
    },
    {
      title: 'Advertisers',
      url: '/advertisers',
      size: IconSize.SMALL,
      src: '/assets/chirpy/dashboard/advertisers.svg',
      canShow: this.isNZ$()
    },
    {
      title: 'Admin',
      url: '/admin',
      size: IconSize.SMALL,
      src: '/assets/chirpy/dashboard/admin.svg',
      canShow: this.authService.isAdmin$()
    }
  ].map(icon => {
    return {
      ...icon,
      src: this.getSrc$(icon)
    };
  });

  uiServiceSubscription: Subscription;
  size = { LARGE: 6, SMALL: 4 };

  constructor(private analyticsService: AnalyticsService, public authService: AuthService, public platform: Platform, public uiService: UIService, private urlHelperService: UrlHelperService) {}

  getSize(iconSize: IconSize): number {
    return this.size[iconSize];
  }

  getTravelTagUrl() {
    return `https://app.chirpytraveltag${this.urlHelperService.domain}`;
  }

  isAU$() {
    return this.authService.userProfileObservable.pipe(map((user: UserObject) => user != null && user.country === Country.AUSTRALIA));
  }

  isNZ$() {
    return this.authService.userProfileObservable.pipe(map((user: UserObject) => user != null && user.country === Country.NEW_ZEALAND));
  }

  private getSrc$(icon: IDashboardIcon) {
    return icon.hasAccess && icon.noAccessSrc ? icon.hasAccess.pipe(map(accessGranted => (accessGranted ? icon.src : icon.noAccessSrc))) : of(icon.src);
  }

  ngOnInit() {
    this.uiServiceSubscription = this.uiService.isMediumDisplay$.subscribe(isMediumDisplay => {
      if (isMediumDisplay) {
        this.size = { LARGE: 3, SMALL: 2 };
      } else {
        this.size = { LARGE: 6, SMALL: 4 };
      }
    });
  }

  ngOnDestroy() {
    this.uiServiceSubscription.unsubscribe();
  }

  onClickIcon(icon: any, index: number) {
    this.analyticsService.eventTrack(AnalyticsCategory.NAVIGATION, AnalyticsAction.NAVIGATION_DASHBOARD_ICON, icon.title, { type: icon.size }, index);
  }

  showLoadingOverlay() {
    this.uiService.showLoadingOverlay();
  }
}
