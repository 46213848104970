import { Injectable } from '@angular/core';
import { IBnBListing } from '@shared/models/bnb/bnb-listing';
import { IBnBReview } from '@shared/models/bnb/bnb-review';
import { IOrderCondition } from '@shared/models/order-condition';
import { IWhereCondition } from '@shared/models/where-condition';
import { BaseDatabase } from '@shared/services/base.database';

@Injectable({
  providedIn: 'root'
})
export class BnBDatabase extends BaseDatabase {
  readonly MAX_RECORDS: number = 9999;

  createBnBListing(listing: IBnBListing) {
    return this.createDocument(this.COLLECTION.BNB_LISTINGS, listing);
  }

  createBnBReview(review: IBnBReview) {
    return this.createSubDocument(this.COLLECTION.BNB_LISTINGS, review.listingId, this.COLLECTION.REVIEWS, review);
  }

  deleteBnBListing(uid: string) {
    return this.deleteDocument(this.COLLECTION.BNB_LISTINGS, uid);
  }

  deleteBnBReview(listingId: string, reviewId: string) {
    return this.deleteSubDocument(this.COLLECTION.BNB_LISTINGS, listingId, this.COLLECTION.REVIEWS, reviewId);
  }

  getBnBListing(uid: string) {
    return this.getDocument<IBnBListing>(this.COLLECTION.BNB_LISTINGS, uid);
  }

  getBnBListings(country: string, isAdmin: boolean, recordsToFetch: number) {
    const queryFn = this.getBnBListingQuery(recordsToFetch, isAdmin, country);
    return this.getDocumentsByQuery<IBnBListing>(this.COLLECTION.BNB_LISTINGS, queryFn);
  }

  getBnBReview(listingId: string, reviewId: string) {
    return this.getSubDocument<IBnBReview>(this.COLLECTION.BNB_LISTINGS, listingId, this.COLLECTION.REVIEWS, reviewId);
  }

  getBnBReviews(listingId: string, isAdmin: boolean) {
    const queryFn = this.getBnBReviewQuery(isAdmin);
    return this.getDocumentsBySubcollectionQuery<IBnBReview>(this.COLLECTION.BNB_LISTINGS, listingId, this.COLLECTION.REVIEWS, queryFn);
  }

  updateBnBListing(bnbListing: Partial<IBnBListing>, merge: boolean = true) {
    return this.updateDocument(this.COLLECTION.BNB_LISTINGS, bnbListing.uid, bnbListing, merge);
  }

  updateBnBReview(listingId: string, reviewId: string, data: Partial<IBnBReview>, merge: boolean = true) {
    return this.updateSubDocument(this.COLLECTION.BNB_LISTINGS, listingId, this.COLLECTION.REVIEWS, reviewId, data, merge);
  }

  private getBnBListingQuery(recordsToFetch: number, isAdmin: boolean, country: string) {
    const whereConditions: IWhereCondition[] = [];
    const orderConditions: IOrderCondition[] = [];

    if (!isAdmin) {
      // Searching by country can be enabled per environment. If it is, don't restrict by country here
      if (country) {
        whereConditions.push({
          field: 'country',
          operator: '==',
          value: country
        });
      }
    } else {
      orderConditions.push({ field: 'approved', direction: 'asc' });
    }

    orderConditions.push({ field: 'created', direction: 'desc' });

    return this.createQueryFunction(whereConditions, orderConditions, recordsToFetch);
  }

  private getBnBReviewQuery(isAdmin: boolean) {
    const whereConditions: IWhereCondition[] = [];
    const orderConditions: IOrderCondition[] = [];

    if (!isAdmin) {
      whereConditions.push({ field: 'approved', operator: '==', value: true });
    }

    orderConditions.push({ field: 'dateTime', direction: 'desc' });

    return this.createQueryFunction(whereConditions, orderConditions, this.MAX_RECORDS);
  }
}
