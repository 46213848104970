import { Injectable, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { EventType } from '@infrastructure/constants/event-type';
import { Role } from '@infrastructure/constants/role';
import { IBaseEvent } from '@infrastructure/models/base-event';
import { ICoinsTrigger } from '@infrastructure/models/coins-trigger';
import { AlertController } from '@ionic/angular';
import { AdminRole } from '@shared/constants/admin-role';
import { CatchupRsvpStatus } from '@shared/constants/catchup-rsvp-status';
import { CoinsTransactionType } from '@shared/constants/coins-transaction-type';
import { ALL_COUNTRIES, Country } from '@shared/constants/country';
import { HostOnlyDocument } from '@shared/constants/host-only-document';
import { Ordinal } from '@shared/constants/ordinal';
import { UserObject } from '@shared/models/user-object';
import { IAttendeeData } from '@shared/models/catchups/attendee-data';
import { ICatchup } from '@shared/models/catchups/catchup';
import { ICatchupOptions } from '@shared/models/catchups/catchup-options';
import { ICatchupTemplate } from '@shared/models/catchups/catchup-template';
import { ICatchupWithData } from '@shared/models/catchups/catchup-with-data';
import { IGuest } from '@shared/models/catchups/guest';
import { CatchupShowOption } from '@shared/constants/catchup-show-option';
import { IPayment } from '@shared/models/catchups/payment';
import { ICoinsTransaction } from '@shared/models/coins/coins-transaction';
import { ActivityService, ActivityType } from '@shared/services/activity';
import { AnalyticsAction, AnalyticsCategory, AnalyticsService } from '@shared/services/analytics';
import { AppOptionsService } from '@shared/services/app-options/app-options.service';
import { AttendanceService } from '@shared/services/attendance/attendance.service';
import { AuthService } from '@shared/services/auth.service';
import { CoinsService } from '@shared/services/coins/coins.service';
import { CoinsTriggerService } from '@shared/services/coins/coins-trigger.service';
import { ConstantsService } from '@shared/services/constants.service';
import { GroupService } from '@shared/services/groups/group.service';
import { DateTimeService } from '@shared/services/date-time.service';
import { EmailService } from '@shared/services/email/email.service';
import { EnvironmentService } from '@shared/services/environment.service';
import { NotificationTarget, NotificationService } from '@shared/services/notifications';
import { RegionService } from '@shared/services/regions/region.service';
import { SubscriptionService } from '@shared/services/subscription.service';
import { ToastService } from '@shared/services/toast.service';
import { UserService } from '@shared/services/user/user.service';
import { BehaviorSubject, combineLatest, Observable, of, Subscription } from 'rxjs';
import { first, map, mergeMap, skipWhile, switchMap, take } from 'rxjs/operators';
import { CatchupTypeService } from './catchup-types.service';
import { CatchupDatabase } from './catchup.database';

@Injectable({
  providedIn: 'root'
})
export class CatchupService implements OnDestroy {
  get catchupBranding() {
    return this.constantsService.constants.CATCHUPS.branding;
  }

  get CLAIM_PERIOD() {
    return this.constantsService.constants.CATCHUPS.CLAIM.PERIOD;
  }

  get isAdmin() {
    return this.authService.isAdmin([AdminRole.HOSTS]);
  }

  get isSuperAdmin() {
    return this.authService.isAdmin([AdminRole.SUPER]);
  }

  get member() {
    return this.authService._userProfileSubject.value;
  }

  catchupsSubscription: Subscription;
  groupMembersSubscription: Subscription;
  hasMore: boolean;
  MAX_RECORDS: number;
  DAY_IN_MS: number = 24 * 60 * 60 * 1000;
  optionsSubscription: Subscription;

  constructor(
    private activityService: ActivityService,
    private alertController: AlertController,
    private analyticsService: AnalyticsService,
    private appOptionsService: AppOptionsService,
    private attendanceService: AttendanceService,
    private authService: AuthService,
    private catchupDatabase: CatchupDatabase,
    private coinsService: CoinsService,
    private coinsTriggerService: CoinsTriggerService,
    private constantsService: ConstantsService,
    private groupService: GroupService,
    private regionService: RegionService,
    private catchupTypeService: CatchupTypeService,
    private dateTimeService: DateTimeService,
    private emailService: EmailService,
    private environmentService: EnvironmentService,
    private notificationService: NotificationService,
    private route: ActivatedRoute,
    private router: Router,
    private subscriptionService: SubscriptionService,
    private toastService: ToastService,
    private userService: UserService
  ) {
    this.MAX_RECORDS = this.catchupDatabase.MAX_RECORDS;
  }

  approveCatchup(catchup: ICatchup) {
    const data = { uid: catchup.uid, approved: true };
    // NB: This will send notifications every time the CatchUp is approved
    // This is a feature not a bug as it allows resending the notifications if something goes wrong
    this.sendCatchupCreatedEmailNotificationToGroupMembers(catchup);
    return this.catchupDatabase.updateCatchup(data);
  }

  claimCoins(member: UserObject, catchupId: string): Promise<boolean> {
    const catchupData = {
      attendees: {
        [member.uid]: CatchupRsvpStatus.GOING_CLAIMED
      },
      uid: catchupId
    };

    const attendeeData = {
      list: {
        [member.uid]: {
          status: CatchupRsvpStatus.GOING_CLAIMED
        }
      }
    };

    const promise = new Promise<boolean>(resolve => {
      combineLatest(
        this.catchupDatabase.getCatchup(catchupId).pipe(
          skipWhile(x => x == null),
          take(1)
        ),
        this.attendanceService.hasAttendedEvents(member.uid).pipe(
          skipWhile(x => x == null),
          take(1)
        )
      ).subscribe(async ([catchup, hasAttendedEvents]) => {
        if (catchup.attendees && catchup.attendees[member.uid] === CatchupRsvpStatus.GOING) {
          await this.catchupDatabase.updateCatchup(catchupData);
          await this.catchupDatabase.updateHostOnlyDocument(catchupId, HostOnlyDocument.DATA, attendeeData);

          if (Date.now() - member.dateRegistered < this.CLAIM_PERIOD[Ordinal.FIRST] * this.DAY_IN_MS && !hasAttendedEvents[Ordinal.FIRST]) {
            this.analyticsService.eventTrack(AnalyticsCategory.COINS, AnalyticsAction.COINS_CLAIM_FIRST_CATCHUP, catchupId);
            this.attendanceService.updateTrackedEvent(Ordinal.FIRST, member.uid, catchup.ownerName);
            this.giveBonusCoinsToHost(catchup.ownerId, catchup.ownerName, catchup.title, member.uid, member.displayName, member.country, Ordinal.FIRST);
          } else if (Date.now() - member.dateRegistered < this.CLAIM_PERIOD[Ordinal.SECOND] * this.DAY_IN_MS && hasAttendedEvents[Ordinal.FIRST] && !hasAttendedEvents[Ordinal.SECOND]) {
            this.analyticsService.eventTrack(AnalyticsCategory.COINS, AnalyticsAction.COINS_CLAIM_SECOND_CATCHUP, catchupId);
            this.attendanceService.updateTrackedEvent(Ordinal.SECOND, member.uid, catchup.ownerName);
            this.giveBonusCoinsToHost(catchup.ownerId, catchup.ownerName, catchup.title, member.uid, member.displayName, member.country, Ordinal.SECOND);
          } else {
            // Admins can specify a custom amount of coins for attending a CatchUp. Pass this through to coinsTriggerService
            const customCoins = +catchup.coins > 0 ? +catchup.coins : 0;
            const showLimitReachedToast = true;
            this.analyticsService.eventTrack(AnalyticsCategory.COINS, AnalyticsAction.COINS_CLAIM_CATCHUP, catchupId, {}, null, customCoins);
          }
          resolve(true);
        } else {
          this.analyticsService.eventTrack(AnalyticsCategory.COINS, AnalyticsAction.COINS_CLAIM_CATCHUP_ERROR, catchupId);
          resolve(false);
        }
      });
    });

    return promise;
  }

  async deleteCatchup(catchup: ICatchup) {
    // TODO: Allow branding for other types (Virtual CatchUp, Trip)
    const branding = catchup.eventType === EventType.CatchUp ? this.catchupBranding : catchup.eventType;
    const alert = await this.alertController.create({
      header: `Delete ${catchup.eventType}`,
      message: `Are you sure you want to cancel this ${branding}?\n This will delete it and send a notification to all members who have RSVPed that it has been cancelled.`,
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
          cssClass: 'secondary'
        },
        {
          text: 'OK',
          handler: data => this.deleteCatchupHandler(catchup)
        }
      ]
    });

    await alert.present();
  }

  deleteCatchupHandler(catchup: ICatchup) {
    return this.catchupDatabase.deleteCatchup(catchup.uid).then(() => {
      this.sendCatchupCancelledEmailNotificationToCatchupAttendees(catchup);
      // The cloud function triggered by sendCatchupCancelledEmailNotificationToCatchupAttendees will delete the notification target
      //this.notificationService.deleteNotification(NotificationTarget.CATCHUP, catchup.uid);
      this.analyticsService.eventTrack(AnalyticsCategory.CATCHUPS, AnalyticsAction.CATCHUPS_DELETE, catchup.uid);
      this.toastService.presentToast(`${catchup.eventType} has been deleted.`);
      this.router.navigate(['/catchups']);
    });
  }

  deleteCatchupTemplate(groupId: string, templateId: string) {
    return this.catchupDatabase.deleteCatchupTemplate(groupId, templateId).then(() => {
      this.analyticsService.eventTrack(AnalyticsCategory.CATCHUPS, AnalyticsAction.CATCHUPS_DELETE_TEMPLATE, templateId, { type: groupId });
      this.toastService.presentToast(`Template has been deleted.`);
    });
  }

  getCatchup(uid: string): Observable<ICatchup> {
    return this.catchupDatabase.getCatchup(uid);
  }

  getCatchupAttendees(attendees: Record<string, CatchupRsvpStatus>, isHostOrAdmin: boolean = false): Observable<UserObject[]> {
    const memberIds = this.getAttendeesByStatus(attendees, [CatchupRsvpStatus.GOING, CatchupRsvpStatus.GOING_CLAIMED]);
    const showUnknown = true;
    return memberIds.length > 0 ? this.userService.getUsers(memberIds, showUnknown).pipe(skipWhile(u => !u)) : of([]);
  }

  getCatchupAttendeesCount(attendees: Record<string, CatchupRsvpStatus>, isHostOrAdmin: boolean = false): number {
    return this.getAttendeesByStatus(attendees, [CatchupRsvpStatus.GOING, CatchupRsvpStatus.GOING_CLAIMED]).length;
  }

  getCatchupAttendeesData(catchupId: string): Observable<Record<string, IAttendeeData>> {
    return this.catchupDatabase.getHostOnlyDocument(catchupId, HostOnlyDocument.DATA).pipe(
      map(doc => {
        if (doc != null) return doc.list || {};
        else return {};
      })
    );
  }

  getCatchupGuestCount(uid: string): Observable<number> {
    return this.getCatchup(uid).pipe(map(catchup => catchup.guestCount || 0));
  }

  getCatchupGuests(catchupId: string): Observable<IGuest[]> {
    return this.catchupDatabase.getHostOnlyDocument(catchupId, HostOnlyDocument.GUESTS).pipe(
      map(doc => {
        if (doc != null) return doc.list || [];
        else return [];
      })
    );
  }

  getCatchupPayments(catchupId: string): Observable<Record<string, IPayment>> {
    return this.catchupDatabase.getHostOnlyDocument(catchupId, HostOnlyDocument.PAYMENTS).pipe(
      map(doc => {
        return doc.list || {};
      })
    );
  }

  getCatchups(catchupOptions: ICatchupOptions, date: number, loadPastCatchups: boolean = false): Observable<ICatchup[]> {
    const showGroupIds: string[] = [];
    if (catchupOptions.groupId != null) {
      // Filter catchups on this group
      showGroupIds.push(catchupOptions.groupId);
    } else if (catchupOptions.show === CatchupShowOption.MY_GROUPS) {
      // Show My Groups for this member.
      const myGroupIds = this.member.catchupGroupIds || [];
      showGroupIds.push(...myGroupIds);
    }

    let fetchAllRecords: boolean = false;
    if (showGroupIds.length === 1) {
      fetchAllRecords = true;
      return this.groupService.isGroupHost$(this.member, showGroupIds[0]).pipe(
        first(),
        switchMap((isHost: boolean) => {
          return this.catchupDatabase.getCatchups(isHost, fetchAllRecords, catchupOptions, date, showGroupIds, loadPastCatchups, this.member.uid);
        })
      );
    } else {
      return this.catchupDatabase.getCatchups(this.isAdmin, fetchAllRecords, catchupOptions, date, showGroupIds, loadPastCatchups, this.member.uid);
    }
  }

  getCatchupsByDate(dateFrom: string, dateTo: string): Observable<ICatchup[]> {
    return this.catchupDatabase.getCatchupsByDate(dateFrom, dateTo);
  }

  getCatchupsOnDate(date: string, country: string): Observable<ICatchup[]> {
    const useCache = true;
    const cacheKey = `${country}-${date}`;
    return this.catchupDatabase.getCatchupsByDate(date, date, country, useCache, cacheKey);
  }

  getCatchupsById(ids: string[]): Observable<ICatchup[]> {
    return this.catchupDatabase.getCatchupsById(ids);
  }

  getCatchupsForMember(memberId: string, rsvpStatus: CatchupRsvpStatus[]): Observable<ICatchup[]> {
    return this.catchupDatabase.getCatchupsForMember(memberId, rsvpStatus).pipe(
      map(catchups => {
        return catchups.sort((a, b) => a.datetime - b.datetime);
      })
    );
  }

  getCatchupTemplate(groupId: string, templateId: string): Observable<ICatchupTemplate> {
    return this.catchupDatabase.getCatchupTemplate(groupId, templateId);
  }

  getCatchupTemplates(groupId: string): Observable<ICatchupTemplate[]> {
    return this.catchupDatabase.getCatchupTemplates(groupId).pipe(
      map(templates => {
        return templates.sort((a, b) => a.name.localeCompare(b.name));
      })
    );
  }

  getCatchupWaitlist(attendees: Record<string, CatchupRsvpStatus>): Observable<UserObject[]> {
    const memberIds = this.getAttendeesByStatus(attendees, [CatchupRsvpStatus.WAITING_FOR_HOST]);
    const showUnknown = true;
    return memberIds.length > 0 ? this.userService.getUsers(memberIds, showUnknown).pipe(skipWhile(u => !u)) : of([]);
  }

  getCatchupWaitlistCount(attendees: Record<string, CatchupRsvpStatus>): number {
    return this.getAttendeesByStatus(attendees, [CatchupRsvpStatus.WAITING_FOR_HOST]).length;
  }

  getRecentCatchupsForMember(member: UserObject, excludeRoles: Role[]): Observable<ICatchupWithData[]> {
    const rsvpStatus = [CatchupRsvpStatus.GOING, CatchupRsvpStatus.GOING_CLAIMED];
    const start = this.dateTimeService.subtractDaysFromCurrentDateAsString(2);
    const end = this.dateTimeService.getStartOfTodayAsString();
    const time = this.dateTimeService.getTimeAsString();

    return combineLatest(this.catchupDatabase.getCatchupsForMember(member.uid, rsvpStatus), this.coinsTriggerService.hasReachedLimitReadOnly$(member.uid, AnalyticsAction.COINS_CLAIM_CATCHUP)).pipe(
      map(([catchups, hasReachedLimit]) => {
        // Hide any CatchUps from more than CUTOFF days ago, CatchUps in the future, and CatchUps today that haven't started
        const cutoff = this.dateTimeService.subtractDaysFromCurrentDateAsString(7);
        return catchups
          .filter(x => {
            return !(x.date < cutoff || x.date > end || (x.date === end && x.time > time));
          })
          .map(catchup => {
            let note = '';

            const status: CatchupRsvpStatus = catchup.attendees[member.uid] || null;
            if (status === CatchupRsvpStatus.GOING_CLAIMED) note = `Claimed `; // trailing space for edge case where host had previously claimed coins for this CatchUp

            if (!note) {
              if (catchup.date < start) {
                note = `Claim period expired`;
              } else if (hasReachedLimit) {
                note = `Limit reached`;
              }
            }

            const output: ICatchupWithData = {
              catchup: catchup,
              note: note,
              status: status
            };
            return output;
          })
          .sort((a, b) => b.catchup.datetime - a.catchup.datetime);
      }),
      mergeMap(
        catchups => {
          const groupIds = Array.from(new Set(catchups.map(x => x.catchup.groupId)));
          return groupIds.length > 0 && excludeRoles.length > 0 && excludeRoles.includes(member.role) ? this.groupService.getGroupsById(groupIds).pipe(first(x => x.length > 0)) : of(null);
        },
        (catchupsWithData, groupData) => {
          // Prevent subsequent database reads until groupData has returned
          if (groupData !== null && groupData.length === 0) return [];

          let groups = {};
          for (const group of groupData || []) {
            groups[group.uid] = group;
          }

          return catchupsWithData.map(item => {
            const group = groups[item.catchup.groupId] || null;

            if (group) {
              for (const role of excludeRoles) {
                if (Object.keys(group[`${role}s`]).includes(member.uid)) item.note += this.constantsService.constants.CATCHUPS.CLAIM.excludeRoleMessages[role] + ' ';
              }
            }
            return item;
          });
        }
      )
    );
  }

  // Copy exists in CatchupRsvpService, to avoid components needing to inject this service for a single function
  isClosed(catchup: ICatchup): boolean {
    const today = this.dateTimeService.getStartOfTodayAsString();
    const now = this.dateTimeService.getTimeAsString();
    return today > catchup.date || (today === catchup.date && now > catchup.time);
  }

  isGoing(catchup: IBaseEvent) {
    if (catchup.attendees == null || this.member == null) return false;
    const attendees = catchup.attendees || {};
    const memberId = this.member.uid;
    return catchup.attendees[memberId] === CatchupRsvpStatus.GOING || catchup.attendees[memberId] === CatchupRsvpStatus.GOING_CLAIMED;
  }

  isOver(catchup: ICatchup): boolean {
    const today = this.dateTimeService.getStartOfTodayAsString();
    const now = this.dateTimeService.getTimeAsString();
    return today > catchup.endDate || (today === catchup.endDate && now > catchup.endTime); // differs from isClosed by using nndDate and endTime
  }

  isReallyOver(catchup: ICatchup): boolean {
    const WINDOW_IN_DAYS = 2;
    const today_minus_window = this.dateTimeService.subtractDaysFromCurrentDateAsString(WINDOW_IN_DAYS);
    return today_minus_window > catchup.endDate; // "today - window > endDate" is equivalent to "today > endDate + window"
  }

  isShared(catchup: ICatchup) {
    const sharedGroups = Object.keys(catchup.sharedGroups || {});
    if (sharedGroups.length > 1) return true;
    if (sharedGroups.length === 1) return sharedGroups[0] !== catchup.groupId;
    else return false;
  }

  ngOnDestroy() {
    this.subscriptionService.clearSubscription(this.catchupsSubscription);
    this.subscriptionService.clearSubscription(this.groupMembersSubscription);
    this.subscriptionService.clearSubscription(this.optionsSubscription);
  }

  numberGoing(catchup: IBaseEvent): string {
    const isGoing = this.isGoing(catchup);
    const memberCount = Object.values(catchup.attendees || {}).filter(x => x === CatchupRsvpStatus.GOING || x === CatchupRsvpStatus.GOING_CLAIMED).length;
    const guestCount = catchup.guestCount || 0;

    // TODO: Is there a more elegant way to code this?
    if (isGoing && memberCount === 1 && guestCount === 0) return `You are going`;
    if (isGoing && memberCount === 2 && guestCount === 0) return `You and 1 other member are going`;
    if (isGoing && memberCount === 1 && guestCount === 1) return `You and 1 guest are going`;
    if (isGoing && memberCount === 2 && guestCount === 1) return `You, 1 other member, and 1 guest are going`;
    if (isGoing && memberCount > 2 && guestCount === 0) return `You and ${memberCount - 1} other members are going`;
    if (isGoing && memberCount === 1 && guestCount > 1) return `You and ${guestCount} guests are going`;
    // Remove the word 'other' in the following cases, otherwise the string gets too long
    if (isGoing && memberCount > 2 && guestCount === 1) return `You, ${memberCount - 1} members, and 1 guest are going`;
    if (isGoing && memberCount === 2 && guestCount > 1) return `You, 1 member, and ${guestCount} guests are going`;
    if (isGoing && memberCount > 2 && guestCount > 1) return `You ${memberCount - 1} members, and ${guestCount} guests are going`;

    if (!isGoing && memberCount === 0 && guestCount === 0) return `Be the first to RSVP`;
    if (!isGoing && memberCount === 1 && guestCount === 0) return `1 member is going`;
    if (!isGoing && memberCount === 0 && guestCount === 1) return `1 guest is going`;
    if (!isGoing && memberCount === 1 && guestCount === 1) return `1 member and 1 guest are going`;
    if (!isGoing && memberCount > 1 && guestCount === 0) return `${memberCount} members are going`;
    if (!isGoing && memberCount === 0 && guestCount > 1) return `${guestCount} guests are going`;
    if (!isGoing && memberCount > 1 && guestCount === 1) return `${memberCount} members and 1 guest are going`;
    if (!isGoing && memberCount === 1 && guestCount > 1) return `1 member and ${guestCount} guests are going`;
    if (!isGoing && memberCount > 1 && guestCount > 1) return `${memberCount} members and ${guestCount} guests are going`;

    return '';
  }

  rejectCatchup(uid: string) {
    const data = { uid, approved: false };
    return this.catchupDatabase.updateCatchup(data);
  }

  requestCatchupReview(catchup: ICatchup, isNew: boolean) {
    const newOrUpdated = isNew ? 'New' : 'Updated';
    this.emailService.sendEmailToApproveCatchup(catchup, newOrUpdated);
  }

  updateCatchup(member: UserObject, catchup: ICatchup) {
    // Host can't change the CatchUp DateTime to the past, but they can edit other form values on a Past Event.
    const oldDateTime = catchup.datetime;
    const newDateTime = this.dateTimeService.createDate(catchup.date, catchup.time, true); //assign random value to milliseconds to allow simple ordering by datetime, rather than 2 dimensional startAfter(datetime, docId);
    const isCreating = catchup.uid == null;
    const validateDateTime = isCreating || (!isCreating && oldDateTime !== newDateTime) ? newDateTime : null;

    if (!this.validateForm(catchup, validateDateTime)) return false;

    // Don't update date time until it passes validation.
    catchup.datetime = newDateTime;

    // Recalculate deleteDate if the event date is updated
    catchup.deleteDate = this.dateTimeService.addDays(catchup.datetime, 90);

    if (isCreating) {
      catchup.created = this.dateTimeService.getDateTime();
      catchup.approved = false;
    }

    // Host details are now explicitly set on Catchup, but without first name, because that requires an extra DB read.
    // As a workaround/enhancement add the first name if someone's editing their own CatchUp
    if (catchup.ownerId === member.uid) {
      catchup.ownerName = `${member.displayName} (${member.firstName})`;
    }

    // Automatically RSVP the host of the CatchUp
    const isHostGoing = catchup.attendees[catchup.ownerId] === CatchupRsvpStatus.GOING || catchup.attendees[catchup.ownerId] === CatchupRsvpStatus.GOING_CLAIMED;
    if (!isHostGoing && !catchup.isGroupUnhosted) {
      catchup.attendees[catchup.ownerId] = CatchupRsvpStatus.GOING;
    }

    // Only change the country if the user has chosen a Region. (e.g. region = 'NSW' should set Country = Australia
    // For Virtual CatchUps, we hide the Region field and display a Country select list instead.
    const canShowRegion = this.catchupTypeService.canShowRegion(catchup.eventType);
    if (canShowRegion) {
      const regionCountry = this.regionService.getCountryForRegion(catchup.region);
      catchup.country = regionCountry;
    }

    // convert first letter to upper case
    catchup.title = catchup.title[0].toUpperCase() + catchup.title.slice(1);

    // Unset any fields that can be modified outside the catchup-edit page to prevent overwriting
    if (!isCreating) {
      delete catchup.attendees;
      delete catchup.guestCount;
    }
    // Use strict update to correctly handle removing shared groups
    const ref: Promise<any> = isCreating ? this.catchupDatabase.createCatchup(catchup) : this.catchupDatabase.strictUpdateCatchup(catchup);

    return ref.then(result => {
      const message = `${catchup.eventType} has been ${isCreating ? 'created' : 'updated'}.`;
      this.toastService.presentToast(message);

      const uid = catchup.uid || result.id;
      catchup.uid = uid;
      //this.requestCatchupReview(catchup, isCreating);

      // Update AttendeeData and Notification for host now that we are guaranteed to have a uid
      this.automaticallyRSVPHost(catchup.uid, catchup.ownerId, isHostGoing, !!catchup.isGroupUnhosted);

      // Log main analytic
      if (isCreating) {
        this.analyticsService.eventTrack(AnalyticsCategory.CATCHUPS, AnalyticsAction.CATCHUPS_ADD, uid, { type: catchup.rsvp });
      } else {
        this.analyticsService.eventTrack(AnalyticsCategory.CATCHUPS, AnalyticsAction.CATCHUPS_UPDATE, uid);
      }

      this.router.navigate(['/catchups', uid]);
    });
  }

  // Keep in sync with copy in CatchupRsvpService
  // NB data is of type IAttendeeData, but need to declare as any to be able to use FieldValue.arrayUnion
  updateCatchupAttendeeData(catchupId: string, memberId: string, data: any): void {
    const attendeeData = {
      list: {
        [memberId]: data
      }
    };
    this.catchupDatabase.updateHostOnlyDocument(catchupId, HostOnlyDocument.DATA, attendeeData);
  }

  updateCatchupGuests(catchupId: string, guests: IGuest[]): void {
    this.catchupDatabase.updateHostOnlyDocument(catchupId, HostOnlyDocument.GUESTS, { list: guests }).then(() => {
      this.catchupDatabase.updateCatchup({ uid: catchupId, guestCount: guests.length });
    });
  }

  updateCatchupPayments(catchupId: string, payments: Record<string, IPayment>, merge: boolean = true): void {
    this.catchupDatabase.updateHostOnlyDocument(catchupId, HostOnlyDocument.PAYMENTS, { list: payments }, merge).then(() => {
      this.catchupDatabase.updateCatchup({ uid: catchupId, paymentCount: Object.values(payments).length });
    });
  }

  updateCatchupTemplate(groupId: string, templateId: string, data: any, groupName: string) {
    const isCreating = templateId == null;
    if (!this.validateTemplate(data)) return false;

    // convert first letter to upper case
    data.name = data.name[0].toUpperCase() + data.name.slice(1);

    const ref: Promise<any> = isCreating ? this.catchupDatabase.createCatchupTemplate(groupId, data) : this.catchupDatabase.updateCatchupTemplate(groupId, templateId, data);
    return ref.then(result => {
      const message = `Template has been ${isCreating ? 'created' : 'updated'}.`;
      this.toastService.presentToast(message);

      const uid = templateId || result.id;

      // Log analytics
      if (isCreating) {
        this.analyticsService.eventTrack(AnalyticsCategory.CATCHUPS, AnalyticsAction.CATCHUPS_ADD_TEMPLATE, uid, { type: groupId });
      } else {
        this.analyticsService.eventTrack(AnalyticsCategory.CATCHUPS, AnalyticsAction.CATCHUPS_UPDATE_TEMPLATE, uid, { type: groupId });
      }

      this.router.navigate(['/catchups/manage-templates', groupId, groupName]);
    });
  }

  validateTemplate(data: any) {
    // The only required field is the template name
    if (data.name.trim().length === 0) {
      const message = `Please enter a template name`;
      this.toastService.presentToast(message);
      return false;
    }

    return true;
  }

  validateForm(data: ICatchup, validateDateTime: number = null): boolean {
    if (this.isClosed(data) && !this.isSuperAdmin) {
      const message = `Sorry, past ${this.catchupBranding}s cannot be edited`;
      this.toastService.presentToast(message);
      return false;
    }

    const validateAddress = this.catchupTypeService.canShowAddress(data.eventType);
    const validateRegion = this.catchupTypeService.canShowRegion(data.eventType);

    const missingFields = [];
    if (!data.title || data.title.trim().length === 0) missingFields.push('Title');
    if (validateAddress && (!data.address || data.address.trim().length === 0)) missingFields.push('Address');
    if (validateRegion && (!data.region || data.region.trim().length === 0)) missingFields.push(this.regionService.regionLabel);
    if (!validateRegion && (!data.country || data.country.length === 0)) missingFields.push('Country');
    if (!data.description || data.description.trim().length === 0) missingFields.push('Description');
    if (!data.date || data.date.length === 0) missingFields.push('Date');
    if (data.date && data.date.length > 0 && data.date > this.dateTimeService.MAX_DATE) missingFields.push(`date within the next ${this.dateTimeService.MAX_DATE_IN_YEARS} years`);
    if (!data.endDate || data.endDate.length === 0) missingFields.push('End date');
    if (data.endDate && data.endDate.length > 0 && data.endDate > this.dateTimeService.MAX_DATE) missingFields.push(`End date within the next ${this.dateTimeService.MAX_DATE_IN_YEARS} years`);
    if (!data.time || data.time.length === 0) missingFields.push('Start time');
    if (!data.endTime || data.endTime.length === 0) missingFields.push('End time');
    if (data.title && data.title.includes('#')) missingFields.push('Title cannot contain #'); // Causes problems with CatchupByDate report

    if (data.hasPayments) {
      if (!data.paymentAccountDetails || data.paymentAccountDetails.trim().length === 0) missingFields.push('Account details');
      if (!data.paymentAmount || data.paymentAmount.trim().length === 0) missingFields.push('Payment amount');
      if (!data.paymentDueDate || data.paymentDueDate.trim().length === 0) missingFields.push('Payment due date');
    }

    if (missingFields.length > 0) {
      const fields = missingFields.join(', ');
      let article = 'a ';
      if (fields.startsWith('End') || fields.startsWith('Address')) article = 'an ';
      if (fields.startsWith('Account')) article = '';

      const message = `Please enter ${article}${fields}`;
      this.toastService.presentToast(message);
      return false;
    }

    if (validateDateTime != null) {
      // Don't let member create a CatchUp in the past, but allow member to update a past CatchUp.
      if (this.dateTimeService.isPastDate(validateDateTime)) {
        const message = 'Date can not be in the past, please enter a future date.';
        this.toastService.presentToast(message);
        return false;
      }

      // Validate start time is before end time.
      const startDateTime = this.dateTimeService.createDate(data.date, data.time);
      const endDateTime = this.dateTimeService.createDate(data.date, data.endTime);
      const hasValidEndTime = this.dateTimeService.isBefore(startDateTime, endDateTime);
      if (!hasValidEndTime) {
        const message = "Choose an End time that's after the Start time";
        this.toastService.presentToast(message);
        return false;
      }
    }
    return true;
  }

  private automaticallyRSVPHost(catchupId: string, memberId: string, isHostGoing: boolean, isGroupUnhosted: boolean): void {
    // RSVP itself is handled in updateCatchup.
    // TODO: In the edge case that a host/cohost was on the waitlist, and has now been made the host of the event, this will overwrite the dateTime they initially went on the waitlist
    if (!isHostGoing && !isGroupUnhosted) this.updateCatchupAttendeeData(catchupId, memberId, { dateTime: this.dateTimeService.getDateTime() });
    // TODO: In another edge case that the host of a CatchUp for an unhosted group is changed after creation, there is no way to unsubscribe the original host from notifications
    this.notificationService.createNotificationForMember(NotificationTarget.CATCHUP, catchupId, memberId);
  }

  private getAttendeesByStatus(attendees: Record<string, CatchupRsvpStatus>, allowed: CatchupRsvpStatus[]): string[] {
    return Object.entries(attendees)
      .filter(keyValue => allowed.includes(keyValue[1]))
      .map(keyValue => keyValue[0]);
  }

  private giveBonusCoinsToHost(hostId: string, hostName: string, catchupName: string, memberId: string, memberName: string, memberCountry: Country, ordinal: Ordinal) {
    this.appOptionsService
      .getOptionsValues<ICoinsTrigger>('coinsTriggers', ALL_COUNTRIES)
      .pipe(first(x => !!x))
      .subscribe(values => {
        // TODO: Use switch if we start tracking more than two
        const key = ordinal === Ordinal.FIRST ? AnalyticsAction.COINS_CLAIM_FIRST_CATCHUP : AnalyticsAction.COINS_CLAIM_SECOND_CATCHUP;
        const claimCoinsTrigger: ICoinsTrigger[] = values.filter(x => x.key === key);
        if (claimCoinsTrigger.length > 0 && claimCoinsTrigger[0].setting.value > 0) {
          const bonusCoins = +claimCoinsTrigger[0].setting.value;
          const transaction: ICoinsTransaction = {
            amount: bonusCoins,
            date: this.dateTimeService.getDateTime(),
            decrementStatusPoints: false,
            description: `${claimCoinsTrigger[0].setting.label} (for ${memberName})`,
            memberId: hostId,
            memberName: hostName,
            staffId: memberId,
            staffName: memberName,
            type: CoinsTransactionType.CREDIT
          };
          this.coinsService.addMemberInitiatedTransaction(transaction);
          const days = this.CLAIM_PERIOD[ordinal];
          // catchup.ownerName is of the form Carol101 (Carol). We want to strip off the bracketted first name for the email
          const hostFirstName = hostName.replace(/(.*)\s\((\S*)\)$/, '$2');
          this.emailService.sendCatchupBonusCoinsNotificationToHost(hostId, hostFirstName, catchupName, memberName, memberCountry, bonusCoins, ordinal, days);
        }
      });
  }

  private sendCatchupCancelledEmailNotificationToCatchupAttendees(catchup: ICatchup): Promise<any> {
    const dateTime = this.dateTimeService.formatDatetime(catchup.date, catchup.time, 'ddd D MMM [at] hh:mm a');
    const shared = this.isShared(catchup) ? 'shared ' : '';
    const activity = {
      activityTypes: [ActivityType.FEED, ActivityType.EMAIL, ActivityType.DIGEST],
      data: {
        baseUrl: this.environmentService.url(catchup.country[0]),
        catchup: catchup,
        dateTime: dateTime
      },
      deleteTarget: true,
      message: `Your ${shared}${catchup.eventType} with <a href="/groups/${catchup.groupId}">${catchup.groupName}</a> on ${dateTime} has been cancelled:<br><em>${catchup.title}</em>`,
      notificationType: 'catchupCancelledNotifyMember',
      targetIds: [catchup.uid],
      targetType: NotificationTarget.CATCHUP,
      timestamp: Date.now()
    };
    return this.activityService.createActivity(activity);
  }

  private sendCatchupCreatedEmailNotificationToGroupMembers(catchup: ICatchup) {
    if (!catchup.groupId) return;
    const dateTime = this.dateTimeService.formatDatetime(catchup.date, catchup.time, 'ddd D MMM [at] hh:mm a');
    const shared = this.isShared(catchup) ? 'shared ' : '';

    const activity = {
      activityTypes: [ActivityType.FEED, ActivityType.EMAIL, ActivityType.DIGEST],
      data: {
        baseUrl: this.environmentService.url(catchup.country[0]),
        catchup: catchup,
        dateTime: dateTime,
        shared: shared
      },
      message: `<a href="/members/${catchup.ownerId}">${catchup.ownerName}</a> added a <a href="/catchups/${catchup.uid}">new ${shared}${catchup.eventType}</a> in <a href="/groups/${catchup.groupId}">${catchup.groupName}</a>:<br><em>${catchup.title}</em>`,
      notificationType: 'catchupCreatedNotifyMember',
      targetIds: catchup.allGroupIds,
      targetType: NotificationTarget.GROUP,
      timestamp: Date.now()
    };

    return this.activityService.createActivity(activity);
  }
}
