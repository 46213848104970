import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { GamePage } from './game/game.page';
import { StartPage } from './start/start.page';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'start',
    pathMatch: 'full'
  },
  {
    path: 'start',
    component: StartPage,
    data: {
      title: 'Bingo intro'
    }
  },
  {
    path: 'game/:gameId',
    component: GamePage,
    data: {
      title: 'Bingo game'
    }
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class BingoRoutingModule {}
