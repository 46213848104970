import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { Observable } from 'rxjs';
import { GameType } from '@shared/constants/game-type';
import { ILeaderboard, ILeaderboardPlayer } from '@shared/models/leaderboards';
import { BaseDatabase } from '@shared/services/base.database';
import { CacheService } from '@shared/services/cache.service';

@Injectable({
  providedIn: 'root'
})
export class LeaderboardDatabase extends BaseDatabase {
  constructor(afs: AngularFirestore, cache: CacheService) {
    super(afs, cache);
  }

  getLeaderboardForGame(gameType: GameType, competitionId: string, placeId: string, period: string, useCache: boolean): Observable<ILeaderboard> {
    const collection = `${this.COLLECTION.LEADERBOARDS}/${gameType}/${this.COLLECTION.COMPETITIONS}/${competitionId}/${this.COLLECTION.PLACES}/`; // concatenate here rather than creating getSubSubDocument in BaseDatabase
    return this.getSubDocument<ILeaderboard>(collection, placeId, this.COLLECTION.BOARDS, period, useCache);
  }

  getLeaderboardForPlayer(gameType: GameType, competitionId: string, playerId: string): Observable<ILeaderboardPlayer> {
    const collection = `${this.COLLECTION.LEADERBOARDS}/${gameType}/${this.COLLECTION.COMPETITIONS}/`; // concatenate here rather than creating getSubSubDocument in BaseDatabase
    return this.getSubDocument<ILeaderboardPlayer>(collection, competitionId, this.COLLECTION.PLAYERS, playerId);
  }

  updateLeaderboardForPlace(gameType: GameType, competitionId: string, placeId: string, boardId: string, playerId: string, data: Array<number | string>) {
    const collection = `${this.COLLECTION.LEADERBOARDS}/${gameType}/${this.COLLECTION.COMPETITIONS}/${competitionId}/${this.COLLECTION.PLACES}/`; // concatenate here rather than creating getSubSubDocument in BaseDatabase
    const merge = true;
    return this.updateSubDocument(collection, placeId, this.COLLECTION.BOARDS, boardId, { [playerId]: data }, merge);
  }

  updateLeaderboardForPlayer(gameType: GameType, competitionId: string, playerId: string, data: ILeaderboardPlayer) {
    const collection = `${this.COLLECTION.LEADERBOARDS}/${gameType}/${this.COLLECTION.COMPETITIONS}/`; // concatenate here rather than creating getSubSubDocument in BaseDatabase
    const merge = true;
    return this.updateSubDocument(collection, competitionId, this.COLLECTION.PLAYERS, playerId, data, merge);
  }
}
