import { Injectable, OnDestroy } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/firestore';
import { Router } from '@angular/router';
import { MembershipType } from '@infrastructure/constants/membership-type';
import { AdminRole } from '@shared/constants/admin-role';
import { Country } from '@shared/constants/country';
import { Role } from '@infrastructure/constants/role';
import { IChangePasswordResult } from '@shared/models/update-password-result';
import { UserObject } from '@shared/models/user-object';
import { AnalyticsAction, AnalyticsCategory, AnalyticsService } from '@shared/services/analytics';
import { DateTimeService } from '@shared/services/date-time.service';
import { EnvironmentService } from '@shared/services/environment.service';
import { SubscriptionService } from '@shared/services/subscription.service';
import { ToastService } from '@shared/services/toast.service';
import { BehaviorSubject, combineLatest, Observable, Subscription } from 'rxjs';
import { map, skipWhile } from 'rxjs/operators';
import UserCredential = firebase.auth.UserCredential;

@Injectable({
  providedIn: 'root'
})
export class AuthService implements OnDestroy {
  get isImpersonating() {
    return this.impersonatedMemberId != null;
  }

  _userProfileSubject: BehaviorSubject<UserObject> = new BehaviorSubject(null);
  impersonatedMemberId: string;
  impersonatedMemberName: string;
  member: UserObject;
  memberId: string;
  redirectUrl: string;
  userProfileObservable: Observable<UserObject>;
  private authObserver: firebase.Unsubscribe;
  private userProfileCollection: AngularFirestoreDocument<UserObject>;
  private userProfileObserver: Subscription;
  private userProfilePrivateCollection: AngularFirestoreDocument<UserObject>;

  canAccessBnB(profile: UserObject): boolean {
    if (profile == null) return false;
    return this.isCohostOrHostOrAdmin([AdminRole.SUPPORT]) || profile.membershipType === MembershipType.ANNUAL || profile.dateRegistered <= this.dateTimeService.subtractYearsFromCurrentDate(1);
  }

  canAccessBnB$(): Observable<boolean> {
    return this.userProfileObservable.pipe(
      skipWhile(u => !u),
      map((profile: UserObject) => this.canAccessBnB(profile))
    );
  }

  constructor(
    private afAuth: AngularFireAuth,
    private afs: AngularFirestore,
    private analyticsService: AnalyticsService,
    private dateTimeService: DateTimeService,
    private environmentService: EnvironmentService,
    private router: Router,
    private subscriptionService: SubscriptionService,
    private toastService: ToastService
  ) {
    this.init();
  }

  getCountry(): Country {
    const profile: UserObject = this._userProfileSubject.value;
    return profile != null && profile.country != null ? profile.country : null;
  }

  impersonateMember(memberId: string) {
    if (!this.isAdmin()) {
      throw new Error('Only admins can impersonate members');
    }

    this.initUserCollectionsForMemberId(memberId);

    this.userProfileObserver = combineLatest(this.userProfileCollection.valueChanges(), this.userProfilePrivateCollection.valueChanges()).subscribe(userData => {
      const data = Object.assign({}, ...userData) as UserObject;
      if (data != null) {
        data.uid = memberId;
      }

      if (this.impersonatedMemberId !== memberId) {
        this.impersonatedMemberId = memberId;
        this.impersonatedMemberName = data.displayName;

        this.toastService.presentToast(`Impersonating ${data.displayName}`);

        this._userProfileSubject.next(data);
      }

      return data;
    });
    this.subscriptionService.setMasterSubscription(this.userProfileObserver);

    return this._userProfileSubject;
  }

  // NB Keep these functions in sync with the versions in UserService
  isAdmin(adminRoles: AdminRole[] = [], profile: UserObject = this._userProfileSubject.value): boolean {
    if (!profile) {
      return false;
    }

    let isAdmin = profile != null && profile.role != null ? profile.role === Role.ADMIN : false;
    if (adminRoles.length === 0 || isAdmin === false) return isAdmin;
    if (isAdmin && (profile.adminRoles || []).includes(AdminRole.SUPER)) return true; // super admins can do anything

    return adminRoles.some(role => profile.adminRoles.includes(role));
  }

  isAdmin$(adminRoles: AdminRole[] = []): Observable<boolean> {
    return this.userProfileObservable.pipe(
      skipWhile(u => !u),
      map((profile: UserObject) => this.isAdmin(adminRoles, profile))
    );
  }

  isAdvisor(): boolean {
    return false;
    /*
    const profile: UserObject = this._userProfileSubject.value;
    return profile != null && profile.role != null ? profile.role === Role.ADVISOR : false;
    */
  }

  isCohost(profile: UserObject = null): boolean {
    if (!profile) profile = this._userProfileSubject.value;
    return profile != null && profile.badges != null ? profile.role === Role.COHOST : false;
  }

  isCohost$(): Observable<boolean> {
    return this.userProfileObservable.pipe(
      skipWhile(u => !u),
      map((profile: UserObject) => this.isCohost(profile))
    );
  }

  isCohostOrHostOrAdmin(adminRoles: AdminRole[] = []): boolean {
    return this.isCohost() || this.isHost() || this.isAdmin(adminRoles);
  }

  isCohostOrHostOrAdmin$(adminRoles: AdminRole[] = []): Observable<boolean> {
    return this.userProfileObservable.pipe(
      skipWhile(u => !u),
      map((profile: UserObject) => this.isCohost(profile) || this.isHost(profile) || this.isAdmin(adminRoles, profile))
    );
  }

  isHost(profile: UserObject = null): boolean {
    if (!profile) profile = this._userProfileSubject.value;
    return profile != null && profile.badges != null ? profile.role === Role.HOST : false;
  }

  isHost$(): Observable<boolean> {
    return this.userProfileObservable.pipe(
      skipWhile(u => !u),
      map((profile: UserObject) => this.isHost(profile))
    );
  }

  isHostOrAdmin(adminRoles: AdminRole[] = []): boolean {
    return this.isHost() || this.isAdmin(adminRoles);
  }

  isHostOrAdmin$(adminRoles: AdminRole[] = []): Observable<boolean> {
    return this.userProfileObservable.pipe(
      skipWhile(u => !u),
      map((profile: UserObject) => this.isHost(profile) || this.isAdmin(adminRoles))
    );
  }

  login(email: string, password: string) {
    return this.afAuth.auth
      .signInWithEmailAndPassword(email, password)
      .then(res => {
        // Can't send Firebase display name here because data not necessarily loaded from DB at this point.
        // Could send displayName from Firebase Auth, but we don't keep this up to date with the profile
        this.analyticsService.eventTrack(AnalyticsCategory.AUTH, AnalyticsAction.AUTH_LOGIN, res.user.uid);
        if (this.redirectUrl) {
          this.router.navigate([this.redirectUrl]);
          this.redirectUrl = null;
        } else {
          this.router.navigate(['/']);
        }
      })
      .catch(async err => {
        this.analyticsService.eventTrack(AnalyticsCategory.AUTH, AnalyticsAction.AUTH_LOGIN_ERROR, JSON.stringify(err));
        throw err;
      });
  }

  logout() {
    // need to stop listening to all database collections immediately before logging out
    // Otherwise the subscription persists on the /auth/login page and causes permissions errors on Firestore
    // as firestore.rules prevent you from reading these collections if you are not signed in
    this.subscriptionService.unsubscribeAll();
  }

  ngOnDestroy(): void {
    this.authObserver();
    this.userProfileObserver.unsubscribe();
  }

  onUserAuthenticated(user) {
    if (this.userProfileObserver) this.userProfileObserver.unsubscribe();

    if (user == null) {
      this._userProfileSubject.next(null);
      this.analyticsService.initMember(null);
      this.analyticsService.setUsername({ userId: null });
      this.analyticsService.setUserProperties({ userId: null });
      return;
    }

    this.initUserCollectionsForMemberId(user.uid);
    this.analyticsService.setUsername({ userId: user.uid });
    this.analyticsService.setUserProperties({ userId: user.uid }); // Google Analytics now supports user-scoped properties, but Angulartics doesn't, so this just sets an event-scoped parameter

    // Combine both user collections into single observable, monitor for changes,
    // and update userProfileSubject observable which is subscribed in components by async pipe
    this.userProfileObserver = combineLatest(this.userProfileCollection.valueChanges(), this.userProfilePrivateCollection.valueChanges()).subscribe(userData => {
      const data = Object.assign({}, ...userData) as UserObject;
      if (data != null) {
        data.uid = user.uid;
      }
      this._userProfileSubject.next(data);

      // remember your authenticated memberId to differentiate between you and who you are impersonating.
      if (this.impersonatedMemberId == null) {
        this.memberId = user.uid;
        this.member = data;
      }

      // don't change analytics when impersonating a user.
      if (this.impersonatedMemberId != null) {
        return data;
      }

      // send member data to analyticsService once rather than on every eventTrack call
      this.analyticsService.initMember(data);

      return data;
    });
    this.subscriptionService.setMasterSubscription(this.userProfileObserver);
  }

  redirectToLogin(url: string) {
    // Store the attempted URL for redirecting
    this.redirectUrl = url;

    // Navigate to the login page with extras
    this.router.navigate(['/auth/login']);
  }

  resetPassword(email: string) {
    const actionCodeSettings = {
      // After password reset, the user will be give the ability to go back to this page.
      // Use AU version of URL since we don't know the country
      url: `${this.environmentService.url()}/auth/login`
    };
    return this.afAuth.auth.sendPasswordResetEmail(email, actionCodeSettings);
  }

  stopImpersonating() {
    this.toastService.presentToast(`Refreshing the page to stop impersonating ${this.impersonatedMemberName}`);

    // refresh the page
    window.location.href = '/admin';
  }

  updatePassword(email: string, oldPassword: string, newPassword: string): Promise<IChangePasswordResult> {
    const result: IChangePasswordResult = {};

    return (
      this.afAuth.auth
        //On the client side a password can only be changed if you have authenticated recently (i.e. < 5 minutes) with the existing password
        .signInWithEmailAndPassword(email, oldPassword)
        .catch(reason => {
          if (reason.code === 'auth/wrong-password') {
            result.message = 'You have entered the wrong value for your current password, please try again.';
            result.error = reason;
          }
        })
        .then((userCredential: UserCredential) => {
          if (result.error) return result;

          return this.afAuth.auth.currentUser
            .updatePassword(newPassword)
            .then(() => {
              const success: IChangePasswordResult = {
                message: 'Password has been changed.',
                user: userCredential.user
              };
              return success;
            })
            .catch(error => {
              const errorResult: IChangePasswordResult = {
                message: error.message,
                error
              };
              return errorResult;
            });
        })
    );
  }

  private init() {
    // Init observer with null initial value
    this.userProfileObservable = this._userProfileSubject.asObservable();

    // Read user profile when user authenticated
    this.authObserver = this.afAuth.auth.onAuthStateChanged(user => this.onUserAuthenticated(user));
  }

  private initUserCollectionsForMemberId(memberId) {
    // Get public values as observable
    this.userProfileCollection = this.afs.collection<UserObject>('centralMembers').doc(memberId);

    // Get private values as observable
    this.userProfilePrivateCollection = this.afs.collection<UserObject>('centralMembersPrivate').doc(memberId);
  }
}
