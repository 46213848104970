import { Injectable } from '@angular/core';
import { UserObject } from '@shared/models/user-object';
import { AnalyticsAction } from '@shared/services/analytics/analytics-action';
import { AnalyticsCategory } from '@shared/services/analytics/analytics-category';
import { CoinsTriggerService } from '@shared/services/coins/coins-trigger.service';
import { LastActionService } from '@shared/services/last-action/last-action.service';
import { Angulartics2GoogleGlobalSiteTag } from 'angulartics2/gst';

@Injectable({
  providedIn: 'root'
})
export class AnalyticsService {
  member: UserObject;
  memberProperties: any;

  constructor(private angulartics: Angulartics2GoogleGlobalSiteTag, private coinsTriggerService: CoinsTriggerService, private lastActionService: LastActionService) {}

  eventTrack(analyticsCategory: AnalyticsCategory, analyticsAction: AnalyticsAction, label: string = '', gstCustom = {}, value = null, coinsAmount: number = 0) {
    const action = analyticsAction.toString();
    const category = analyticsCategory.toString();
    // event_label default to displayName if there is nothing more relevant to the event
    if (!label) {
      label = this.member ? this.member.displayName || 'no displayName' : 'no displayName';
    }

    gstCustom = { ...gstCustom, ...this.memberProperties };
    const properties: any = { label, category, gstCustom };
    if (value != null) {
      properties.value = value;
    }

    this.angulartics.eventTrack(action, properties);

    // this.member should only be null for the AnalyticsAction.AUTH_LOGIN event, which we are unlikely to give coins for
    if (this.member) {
      this.coinsTriggerService.trigger(this.member.uid, this.member.displayName, analyticsAction, coinsAmount);
      this.lastActionService.updateLastAction(this.member, analyticsAction);
    }
  }

  initMember(member: UserObject) {
    this.member = member;
    this.memberProperties = this.getMemberProperties(member);
  }

  setUsername(data: any) {
    this.angulartics.setUsername(data);
  }

  // NB This sets user-defined properties for analytics not User-scoped properties
  setUserProperties(data: any) {
    this.angulartics.setUserProperties(data);
  }

  private getMemberProperties(member: UserObject) {
    if (member == null) return {};
    return { gender: member.gender, displayName: member.displayName, country: member.country };
  }
}
