import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AdminRole } from '@shared/constants/admin-role';
import { SelectType } from '@shared/constants/select-type';
import { IBnBListing } from '@shared/models/bnb/bnb-listing';
import { IListingImage } from '@shared/models/image/listing-image';
import { ISelectOption } from '@shared/models/select-option';
import { IValueWithId } from '@shared/models/value-with-id';
import { UserObject } from '@shared/models/user-object';
import { AuthService } from '@shared/services/auth.service';
import { BnBService } from '@shared/services/bnb/bnb.service';
import { ConstantsService } from '@shared/services/constants.service';
import { DateTimeService } from '@shared/services/date-time.service';
import { EnforceProfileService } from '@shared/services/enforce-profile.service';
import { LocationService } from '@shared/services/location/location.service';
import { RegionService } from '@shared/services/regions/region.service';
import { UIService } from '@shared/services/ui.service';
import { BehaviorSubject, Subscription } from 'rxjs';
import { first } from 'rxjs/operators';
import { BnBEditPresenter } from './bnb-edit.presenter';

@Component({
  selector: 'app-bnb-edit',
  styleUrls: ['./bnb-edit.component.scss'],
  templateUrl: './bnb-edit.component.html',
  viewProviders: [BnBEditPresenter]
})
export class BnBEditComponent implements OnInit {
  get CONSTANTS() {
    return this.constantsService.constants.BNB.EDIT;
  }

  get INSTRUCTIONS() {
    return this.CONSTANTS.instructions;
  }

  get form() {
    return this.bnbEditPresenter.form;
  }

  get isEdit(): boolean {
    return this.bnbListing != null && this.bnbListing.uid != null;
  }

  get fields() {
    return this.bnbEditPresenter.form.controls.fields;
  }

  get title(): string {
    return this.isEdit ? this.CONSTANTS.updatePageHeading : this.CONSTANTS.createPageHeading;
  }

  get updateButtonText(): string {
    return this.isEdit ? this.CONSTANTS.updateButtonText : this.CONSTANTS.createButtonText;
  }

  bnbListing: IBnBListing = {
    approved: false,
    cost: '',
    description: '',
    fields: {},
    location: '',
    locationId: '',
    photos: {},
    region: '',
    title: ''
  } as IBnBListing;
  interfaceOptions: any = { cssClass: 'wide-select' };
  MAX_PHOTOS: number = 5;
  member: UserObject;
  oldListing: IBnBListing;
  regionLabel: string = '';
  regions: ISelectOption[] = [];
  selectedItems: Record<string, string> = {};
  selectType: SelectType = SelectType.PLACE;

  constructor(
    private authService: AuthService,
    public bnbEditPresenter: BnBEditPresenter,
    private bnbService: BnBService,
    private constantsService: ConstantsService,
    private dateTimeService: DateTimeService,
    private enforceProfileService: EnforceProfileService,
    private locationService: LocationService,
    private regionService: RegionService,
    private route: ActivatedRoute,
    private router: Router,
    private uiService: UIService
  ) {}

  getNumberOfRows() {
    // display textarea with 8 rows on tablets and bigger. this fits perfectly on iPad landscape, and within bounds of most desktop screens.
    // display textarea with 5 rows on mobile phones.
    return this.uiService.isMediumDisplay ? 8 : 5;
  }

  goBack() {
    this.router.navigate([`/bed-and-breakfast`]);
  }

  ionViewWillEnter() {
    this.enforceProfileService.enforce();
  }

  ionViewWillLeave() {
    // The BnBEditComponent with uid "new" is not destroyed when the form is submitted
    this.form.reset(); // this clears the form data
    this.bnbListing.uid = null; // this clears the value of isEdit
  }

  ngOnInit() {
    const uid = this.route.snapshot.paramMap.get('id');
    this.MAX_PHOTOS = this.CONSTANTS.maxPhotos;
    this.bnbEditPresenter.init(this.CONSTANTS.fields);
    this.authService._userProfileSubject.pipe(first(x => x != null)).subscribe(member => {
      this.member = member;

      this.regions = this.regionService.catchupRegions;
      this.regionLabel = this.regionService.regionLabel;

      // creating a new listing
      if ('new' === uid) {
        this.initialiseListing(member);
        return;
      }

      this.bnbService
        .getBnBListing(uid)
        .pipe(first())
        .subscribe((bnbListing: IBnBListing) => {
          // Shouldn't happen; if it does, create a new listing
          if (bnbListing == null) {
            this.initialiseListing(member);
            return;
          }

          this.bnbListing = bnbListing;
          this.oldListing = Object.assign({}, this.bnbListing);
          this.selectedItems = { [bnbListing.locationId]: bnbListing.location };
          this.bnbEditPresenter.setValue(this.bnbListing);
        });
    });
  }

  onAddPlace(item: IValueWithId) {
    this.bnbEditPresenter.patchValue({ location: item.name });
    this.bnbEditPresenter.patchValue({ locationId: item.uid });
    this.selectedItems = { [item.uid]: item.name };
  }

  searchPlaces(startsWith: string) {
    return this.locationService.search(startsWith, this.bnbListing.country, this.authService.isAdmin([AdminRole.SUPPORT]));
  }

  updateBnBListing() {
    // merge form values with bnbListing
    let formValue = this.bnbEditPresenter.bnbListing();
    // Delete any removed images
    const oldKeys = Object.keys(this.bnbListing.photos || {});
    let photosToRemove: IListingImage[] = [];
    if (oldKeys.length > 0) {
      const newKeys = Object.keys(formValue.photos || {});
      const keysToRemove = oldKeys.filter(x => !newKeys.includes(x));
      for (const key of keysToRemove) {
        photosToRemove.push(Object.assign({}, this.bnbListing.photos[key]));
      }
    }

    Object.assign(this.bnbListing, formValue);
    this.bnbService.updateBnBListing(this.bnbListing, this.oldListing, this.CONSTANTS.fields, photosToRemove, this.regionLabel, this.member);
  }

  private initialiseListing(member: UserObject) {
    this.bnbListing.country = member.country;
    this.bnbListing.location = member.locality;
    this.bnbListing.locationId = member.placeId;
    this.bnbListing.memberId = member.uid;
    this.bnbListing.memberName = member.displayName;
    this.bnbListing.region = member.region;
    this.bnbListing.fields = this.CONSTANTS.fields.reduce((output, x) => {
      output[x.id] = x.type === 'checkbox' ? false : '';
      return output;
    }, {});

    this.selectedItems = { [this.bnbListing.locationId]: this.bnbListing.location };
    this.bnbEditPresenter.setValue(this.bnbListing);
    this.oldListing = Object.assign({}, this.bnbListing);
  }
}
