import { Injectable, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { AlertController } from '@ionic/angular';
import { AdminRole } from '@shared/constants/admin-role';
import { ALL_COUNTRIES } from '@shared/constants/country';
import { IAdvertiser } from '@shared/models/advertisers/advertiser';
import { IAnnouncement } from '@shared/models/advertisers/announcement';
import { IListingImage } from '@shared/models/image/listing-image';
import { ActivityService, ActivityType } from '@shared/services/activity';
import { AnalyticsAction, AnalyticsCategory, AnalyticsService } from '@shared/services/analytics';
import { AppOptionsService } from '@shared/services/app-options/app-options.service';
import { AuthService } from '@shared/services/auth.service';
import { ConstantsService } from '@shared/services/constants.service';
import { EnvironmentService } from '@shared/services/environment.service';
import { ImageService } from '@shared/services/image/image.service';
import { NotificationTarget, NotificationService } from '@shared/services/notifications';
import { SubscriptionService } from '@shared/services/subscription.service';
import { ToastService } from '@shared/services/toast.service';
import { UserService } from '@shared/services/user/user.service';
import firebase from 'firebase/app';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { first, map, switchMap } from 'rxjs/operators';
import { AdvertiserDatabase } from './advertiser.database';
import FieldValue = firebase.firestore.FieldValue;

@Injectable({
  providedIn: 'root'
})
export class AdvertiserService implements OnDestroy {
  get ANNOUNCEMENT_BRANDING() {
    return this.constantsService.constants.ADVERTISERS.ANNOUNCEMENTS.branding;
  }

  get BRANDING() {
    return this.constantsService.constants.ADVERTISERS.branding;
  }

  get CONSTANTS() {
    return this.constantsService.constants.ADVERTISERS.SERVICE;
  }

  advertiserCategories: BehaviorSubject<string[]> = new BehaviorSubject<string[]>([]);
  advertiserIdSubscription: Subscription;
  categoriesSubscription: Subscription;
  isProcessingFollow: boolean = false;

  canEditAdvertiser(uid: string): Observable<boolean> {
    return this.authService._userProfileSubject.pipe(
      first(x => x != null),
      map(profile => profile.uid === uid || this.authService.isAdmin([AdminRole.SUPER]))
    );
  }

  constructor(
    private activityService: ActivityService,
    private advertiserDatabase: AdvertiserDatabase,
    private alertController: AlertController,
    private analyticsService: AnalyticsService,
    private appOptionsService: AppOptionsService,
    private authService: AuthService,
    private constantsService: ConstantsService,
    private environmentService: EnvironmentService,
    private imageService: ImageService,
    private notificationService: NotificationService,
    private router: Router,
    private subscriptionService: SubscriptionService,
    private toastService: ToastService,
    private userService: UserService
  ) {
    this.loadAdvertiserCategories();
  }

  createAdvertiser(uid: string): void {
    this.userService
      .getUserProfile(uid)
      .pipe(first(x => !!x))
      .subscribe(profile => {
        const advertiser: IAdvertiser = {
          categories: [],
          contact: '',
          country: profile.country || null,
          description: {
            blocks: []
          },
          displayName: profile.displayName || '',
          followerCount: 0,
          lastAnnouncement: null,
          memberName: profile.displayName || '',
          region: profile.region || '',
          searchName: profile.searchName || '',
          uid: uid
        };
        let message = ``;

        this.advertiserDatabase
          .updateAdvertiser(uid, advertiser)
          .then(result => {
            message = `${profile.displayName} now has access to ${this.BRANDING}.`;
          })
          .catch(err => {
            message = `Couldn't grant ${this.BRANDING} access for ${profile.displayName}: ${err}`;
          })
          .finally(() => {
            this.toastService.presentToast(message);
            this.router.navigate(['/admin']);
          });
      });
  }

  createId() {
    return this.advertiserDatabase.createId();
  }

  async deleteAdvertiser(uid: string) {
    const alert = await this.alertController.create({
      header: this.CONSTANTS.deleteHeader,
      message: this.CONSTANTS.deleteMessage,
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
          cssClass: 'secondary'
        },
        {
          text: 'OK',
          handler: data => this.deleteAdvertiserHandler(uid)
        }
      ]
    });

    await alert.present();
  }

  async deleteAnnouncement(advertiserId: string, announcementId: string) {
    const alert = await this.alertController.create({
      header: `Delete ${this.ANNOUNCEMENT_BRANDING}`,
      message: `Are you sure you want to delete this ${this.ANNOUNCEMENT_BRANDING}?`,
      buttons: [
        {
          text: 'Delete',
          handler: () => {
            this.deleteAnnouncementHandler(advertiserId, announcementId);
          }
        },
        {
          text: 'Cancel',
          role: 'cancel'
        }
      ]
    });

    await alert.present();
  }

  followAdvertiser(advertiserId: string, advertiserName: string, memberId: string): void {
    this.toggleFollowStatus(advertiserId, memberId, true).then(async () => {
      const message = `You are now following ${advertiserName}`;
      await this.toastService.presentToast(message);
    });
  }

  getAllAdvertisers(useCache: boolean = false): Observable<IAdvertiser[]> {
    return this.advertiserDatabase.getAllAdvertisers(useCache);
  }

  getAdvertiser(uid: string): Observable<IAdvertiser> {
    return this.advertiserDatabase.getAdvertiser(uid);
  }

  getAdvertisersById(ids: string[]) {
    const subject = new BehaviorSubject([]);
    if ((ids || []).length > 0) {
      this.subscriptionService.clearSubscription(this.advertiserIdSubscription);
      this.advertiserIdSubscription = this.advertiserDatabase.getAdvertisersById(ids).subscribe(advertisers => {
        subject.next(advertisers);
      });
      this.subscriptionService.add(this.advertiserIdSubscription);
    }
    return subject;
  }

  getAnnouncement(advertiserId: string, announcementId: string): Observable<IAnnouncement> {
    return this.advertiserDatabase.getAnnouncement(advertiserId, announcementId);
  }

  getAnnouncements(advertiserId: string): Observable<IAnnouncement[]> {
    return this.advertiserDatabase.getAnnouncements(advertiserId);
  }

  getCountrySuffix(advertiser: IAdvertiser): string {
    if (advertiser.region === 'Global' || advertiser.region === advertiser.country) return '';

    return `, ${advertiser.country}`;
  }

  getMyAdvertiserListing(): Observable<IAdvertiser> {
    return this.authService._userProfileSubject.pipe(switchMap(member => this.getAdvertiser(member.uid)));
  }

  loadAdvertiserCategories() {
    // TODO: Explicitly call this if categories are updated in Manage Settings?
    this.categoriesSubscription = this.appOptionsService.getOptionsValues<string>('advertiserCategories', ALL_COUNTRIES).subscribe((options: string[]) => {
      this.advertiserCategories.next(options || []);
    });
    this.subscriptionService.add(this.categoriesSubscription);
  }

  maybeCreateAdvertiser(uid: string, memberName: string): void {
    this.advertiserDatabase
      .getAdvertiser(uid)
      .pipe(
        first() // don't check for non-null value, otherwise it will wait forever if the member is not already an advertiser
      )
      .subscribe(advertiser => {
        if (advertiser) {
          const message = `${memberName} already has access to ${this.BRANDING}.`;
          this.toastService.presentToast(message);
        } else {
          this.createAdvertiser(uid);
        }
      });
  }

  ngOnDestroy() {
    this.subscriptionService.clearSubscription(this.categoriesSubscription);
  }

  async toggleFollowStatus(advertiserId: string, memberId: string, follow: boolean) {
    if (this.isProcessingFollow === true) return Promise.resolve(false);
    this.isProcessingFollow = true;

    // Add member to the Group
    const memberData = follow ? FieldValue.arrayUnion(advertiserId) : firebase.firestore.FieldValue.arrayRemove(advertiserId);
    return this.userService
      .updateUserField(memberId, 'advertiserIds', memberData)
      .then(() => {
        // Increment advertiser follower count
        const increment = follow ? 1 : -1;
        const advertiserData = { followerCount: FieldValue.increment(increment) };
        return this.advertiserDatabase.updateAdvertiser(advertiserId, advertiserData).then(async (data: any) => {
          this.notificationService.createNotificationForMember(NotificationTarget.ADVERTISER, advertiserId, memberId);
          this.isProcessingFollow = false;
          return false;
        });
      })
      .catch(() => {
        this.isProcessingFollow = false;
        return false;
      });
  }

  unfollowAdvertiser(advertiserId: string, advertiserName: string, memberId: string): void {
    this.toggleFollowStatus(advertiserId, memberId, false).then(async () => {
      const message = `You are no longer following ${advertiserName}`;
      await this.toastService.presentToast(message);
    });
  }

  updateAdvertiser(advertiser: IAdvertiser) {
    if (!this.validateForm(advertiser)) return false;

    return this.advertiserDatabase.updateAdvertiser(advertiser.uid, advertiser).then(result => {
      this.toastService.presentToast(this.CONSTANTS.updatedToast);

      // Log analytics
      this.analyticsService.eventTrack(AnalyticsCategory.ADVERTISERS, AnalyticsAction.ADVERTISERS_UPDATE_LISTING, advertiser.uid);

      this.router.navigate(['/advertisers', advertiser.uid]);
    });
  }

  updateAnnouncement(advertiserId: string, announcement: IAnnouncement) {
    if (!this.validateAnnouncementForm(announcement)) return false;

    return this.advertiserDatabase.updateAnnouncement(advertiserId, announcement.uid, announcement).then(result => {
      this.toastService.presentToast(this.CONSTANTS.updatedToast);

      // Save most recent announcement on Advertiser
      this.advertiserDatabase.updateAdvertiser(advertiserId, { lastAnnouncement: announcement });

      const isCreating = announcement.dateTimeSent === announcement.dateTimeLastUpdated;
      //Create notification for member
      if (isCreating) {
        this.getAdvertiser(advertiserId)
          .pipe(first(x => !!x))
          .subscribe(advertiser => {
            const activity = {
              activityTypes: [ActivityType.FEED, ActivityType.EMAIL, ActivityType.DIGEST],
              data: {
                baseUrl: this.environmentService.url(advertiser.country),
                advertiserId: advertiserId,
                advertiserName: advertiser.displayName
              },
              message: `<a href="/advertisers/${advertiserId}">${advertiser.displayName}</a> made a new ${this.ANNOUNCEMENT_BRANDING}:<br><em>${announcement.title}</em>`,
              notificationType: 'newAnnouncement',
              targetIds: [advertiserId],
              targetType: NotificationTarget.ADVERTISER,
              timestamp: Date.now()
            };
            this.activityService.createActivity(activity);
          });
      }
      // Log analytics
      const action = isCreating ? AnalyticsAction.ADVERTISERS_ADD_ANNOUNCEMENT : AnalyticsAction.ADVERTISERS_UPDATE_ANNOUNCEMENT;
      this.analyticsService.eventTrack(AnalyticsCategory.ADVERTISERS, AnalyticsAction.ADVERTISERS_UPDATE_ANNOUNCEMENT, advertiserId);

      this.router.navigate(['/advertisers', advertiserId]);
    });
  }

  validateAnnouncementForm(data: IAnnouncement) {
    const missingFields = [];
    if (data.title.trim().length === 0) missingFields.push('a title');
    if (data.content.blocks.length === 0) missingFields.push('some content');

    if (missingFields.length > 0) {
      const message = 'Please enter ' + missingFields.join(', ');
      this.toastService.presentToast(message);
      return false;
    }

    return true;
  }

  validateForm(data: IAdvertiser) {
    const missingFields = [];
    if (data.categories.length === 0) missingFields.push(`a Category`);
    if (data.contact.trim().length === 0) missingFields.push('contact details');
    //if (data.description.trim().length === 0) missingFields.push('a Description');

    if (missingFields.length > 0) {
      const message = 'Please enter ' + missingFields.join(', ');
      this.toastService.presentToast(message);
      return false;
    }

    return true;
  }

  private deleteAdvertiserHandler(uid: string) {
    return this.advertiserDatabase.deleteAdvertiser(uid).then(() => {
      this.toastService.presentToast(this.CONSTANTS.deletedToast);
      this.router.navigate(['/advertisers']);
    });
  }

  private deleteAnnouncementHandler(advertiserId: string, announcementId: string) {
    return this.advertiserDatabase.deleteAnnouncement(advertiserId, announcementId).then(() => {
      this.toastService.presentToast(this.CONSTANTS.deletedAnnouncementToast);
      this.router.navigate(['/advertisers', advertiserId]);
    });
  }
}
