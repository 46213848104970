import { Component } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ModalController } from '@ionic/angular';
import { LimitPeriod } from '@infrastructure/constants/limit-period';
import { GameType } from '@shared/constants/game-type';
import { AnalyticsAction, AnalyticsCategory, AnalyticsService } from '@shared/services/analytics';
import { AuthService } from '@shared/services/auth.service';
import { ChirpyGameStatsComponent } from '@shared/components/chirpy-game-stats/chirpy-game-stats.component';
import { ChirpleHowToPlayComponent } from '../../components';

@Component({
  selector: 'chirple-landing-page',
  styleUrls: ['./landing-page.component.scss'],
  templateUrl: './landing-page.component.html'
})
export class ChirpleLandingPageComponent {
  readonly bannerUrl = '/assets/chirpy/games/chirple.svg';
  readonly gameType = GameType.CHIRPLE;
  readonly leaderboardPeriod = LimitPeriod.DAY;

  constructor(private analyticsService: AnalyticsService, private authService: AuthService, private modalController: ModalController, private route: ActivatedRoute, private router: Router) {}

  onStartGameClicked() {
    // Record CHIRPLE_START_GAME analytics event on ChirpleGameComponent so we can distinguish between new games and resumed games
    this.router.navigate(['play'], { relativeTo: this.route });
  }

  async showHowToPlayModal() {
    const modal = await this.modalController.create({
      component: ChirpleHowToPlayComponent,
      componentProps: {
        continueText: 'Got it'
      }
    });

    this.analyticsService.eventTrack(AnalyticsCategory.CHIRPLE, AnalyticsAction.CHIRPLE_VIEW_HELP);
    await modal.present();
  }

  async showStatistics() {
    const modal = await this.modalController.create({
      component: ChirpyGameStatsComponent,
      componentProps: {
        gameType: this.gameType,
        playerId: this.authService._userProfileSubject.value.uid
      }
    });
    this.analyticsService.eventTrack(AnalyticsCategory.LEADERBOARDS, AnalyticsAction.LEADERBOARDS_VIEW_STATS, this.gameType);
    await modal.present();
  }
}
