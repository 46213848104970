import { Component } from "@angular/core";
import { AngularFireFunctions } from "@angular/fire/functions";
import { AlertController } from "@ionic/angular";
import { MembershipStatus } from "@infrastructure/constants/membership-status";
import { ToastService } from "@shared/services/toast.service";
import { BaseAdminComponent } from "../base-admin/base-admin.component";

@Component({
  selector: 'app-restore-member',
  templateUrl: '../base-admin/base-admin.component.html',
  styleUrls: ['../base-admin/base-admin.component.scss']
})
export class RestoreMemberComponent extends BaseAdminComponent {
  confirmHeader = 'Restore member';
  confirmButton = 'Restore';
  placeholder = 'Search for a member to restore their account';
  title = 'Restore member';

  constructor(alertController: AlertController, private fns: AngularFireFunctions, private toastService: ToastService) {
    super(alertController);
  }

  confirmMessage(_memberId: string, memberName: string): string {
    return `<p>Restore ${memberName}'s account?. This will restore their ability to access the site.</p>`;
  }

  async selectMemberHandler({ memberId, memberName }: { memberId: any; memberName: any; data: any; }): Promise<void> {
    let message = '';

    const callable = this.fns.httpsCallable('updateSubscriptionStatus');

    try {
      await callable({ uid: memberId, status: MembershipStatus.ACTIVE }).toPromise();

      message = `Member ${memberName}'s account has been restored.`;
    }
    catch(err) {
      message = `Couldn't restore member ${memberName}'s account: ${err}`
    }
    finally {
      this.toastService.presentToast(message);
    }
  }
}