import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { first } from 'rxjs/operators';
import { LimitPeriod } from '@infrastructure/constants/limit-period';
import { isGameType } from '@shared/constants/game-type';
import { ILeaderboardOptions } from '@shared/models/leaderboards/leaderboard-options';
import { AnalyticsAction, AnalyticsCategory, AnalyticsService } from '@shared/services/analytics';
import { DateTimeService } from '@shared/services/date-time.service';
import { LeaderboardService } from '@shared/services/leaderboards/leaderboard.service';

@Component({
  selector: 'app-games-leaderboard',
  templateUrl: 'leaderboard.page.html'
})
export class LeaderboardPage implements OnInit {
  boards: Partial<Record<LimitPeriod, string>>;
  lastReload: number = 0;
  options: ILeaderboardOptions;
  reload: number;

  constructor(private analyticsService: AnalyticsService, private dateTimeService: DateTimeService, private location: Location, private leaderboardService: LeaderboardService, private route: ActivatedRoute) {}

  ngOnInit() {
    this.lastReload = this.dateTimeService.getDateTime();

    this.route.paramMap.pipe(first()).subscribe(params => {
      const gameType = params.get('gameType');

      if (isGameType(gameType)) {
        this.boards = this.leaderboardService.getBoardsForGame(gameType);
        this.options = this.leaderboardService.getOptionsForGame(gameType);
      } else {
        this.location.back();
      }
    });
  }

  onReload() {
    this.reload = this.dateTimeService.getDateTime();
    const duration = this.reload - this.lastReload;
    this.analyticsService.eventTrack(AnalyticsCategory.LEADERBOARDS, AnalyticsAction.LEADERBOARDS_REFRESH_PAGE, this.options.gameType, {}, duration);
    this.lastReload = this.reload;
  }
}
