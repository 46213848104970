import { Component, Input } from '@angular/core';
import { ICatchup } from '@shared/models/catchups/catchup';
import { IMemberThreadRelatedType } from '@shared/models/messages/member-thread';
import { UserObject } from '@shared/models/user-object';
import { AnalyticsAction, AnalyticsCategory, AnalyticsService } from '@shared/services/analytics';
import { AuthService } from '@shared/services/auth.service';
import { ConstantsService } from '@shared/services/constants.service';
import { GroupService } from '@shared/services/groups/group.service';
import { CatchupTypeService } from '@shared/services/catchups/catchup-types.service';
import { UIService } from '@shared/services/ui.service';

@Component({
  selector: 'catchup-detail-header',
  templateUrl: './catchup-detail-header.component.html',
  styleUrls: ['./catchup-detail-header.component.scss']
})
export class CatchupDetailHeaderComponent {
  get ASSETS() {
    return this.constantsService.constants.ASSETS;
  }

  get canShowAddress() {
    return this.catchupTypeService.canShowAddress(this.catchup.eventType);
  }

  get canShowGroup() {
    return this.catchupTypeService.canShowGroup(this.catchup.eventType);
  }

  get canShowHost() {
    return this.catchupTypeService.canShowHost(this.catchup.eventType);
  }

  get canShowRegion() {
    return this.catchupTypeService.canShowRegion(this.catchup.eventType);
  }

  get CONSTANTS() {
    return this.constantsService.constants.CATCHUPS.DETAIL;
  }

  get isExtraLargeDisplay() {
    return this.uiService.isExtraLargeDisplay;
  }

  get isGroupMember() {
    return this.catchupTypeService.canShowGroup(this.catchup.eventType) && this.groupService.isGroupMember(this.catchup.groupId);
  }

  get isHost() {
    return this.authService.isHost();
  }

  get isShared() {
    return this.sharedGroupCount > 0;
  }

  get sharedGroupCount() {
    return Object.keys(this.catchup.sharedGroups || {}).length;
  }

  @Input() allowRsvp: boolean = true;
  @Input() catchup: ICatchup;
  relatedType = IMemberThreadRelatedType.Event;
  showAllSharedGroups: boolean = false;
  @Input() showAttendeesHyperlink = true;
  @Input() user: UserObject;

  constructor(private analyticsService: AnalyticsService, private authService: AuthService, private groupService: GroupService, private catchupTypeService: CatchupTypeService, private constantsService: ConstantsService, private uiService: UIService) {}

  onJoinGroup() {
    // TODO: Ignoring the group member count saves a DB lookup, but would result in
    // the newChitChat notification not being disabled if the member joins a large group from the CatchUp detail page
    const groupMemberCount = 0;
    this.groupService.addMemberToGroup(this.catchup.groupId, this.catchup.groupName, groupMemberCount, this.user.uid, this.user.displayName).then(() => {
      this.analyticsService.eventTrack(AnalyticsCategory.GROUPS, AnalyticsAction.GROUPS_JOIN, this.catchup.groupName);

      const isChat = false; // Chat groups are not going to be organising CatchUps
      const isVirtual = this.catchup.region.startsWith('Virtual'); // TODO: This could give a false positive if it is a Virtual Catchup arranged by a physical group
      return this.groupService.sendMemberJoinedGroupNotifications(this.catchup.groupId, this.catchup.groupName, { [this.catchup.ownerId]: this.catchup.ownerName }, this.user.uid, isVirtual, isChat);
    });
  }

  onLeaveGroup() {
    this.groupService.removeMemberFromGroup(this.catchup.groupId, this.catchup.groupName, this.user.uid, this.user.displayName);
    this.analyticsService.eventTrack(AnalyticsCategory.GROUPS, AnalyticsAction.GROUPS_LEAVE, this.catchup.groupName);
  }

  showOtherSharedGroups() {
    this.showAllSharedGroups = true;
  }
}
