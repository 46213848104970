// N.B. Keep these in-sync with the values found in theme.scss
const COLOURS = ['orange', 'yellow', 'green', 'blue', 'pink', 'purple'] as const;

export type BallColour = typeof COLOURS[number];
type BallColourRangeMap = Record<BallColour, [number, number]>;

export class BallColourExtensions {
  private static readonly NUMBER_OF_BALLS = 90;
  private static readonly RANGES: BallColourRangeMap = BallColourExtensions.generateRanges();

  static getColourForValue(n: number): BallColour {
    for (const [colour, [min, max]] of Object.entries(BallColourExtensions.RANGES)) {
      if (n <= max && n >= min) {
        return colour as BallColour;
      }
    }

    return COLOURS[0];
  }

  private static generateRanges(): BallColourRangeMap {
    const range = BallColourExtensions.NUMBER_OF_BALLS / COLOURS.length;

    return COLOURS.reduce((acc, val, i) => {
      const start = i * range;
      const end = Math.min(start + range, BallColourExtensions.NUMBER_OF_BALLS);

      acc[val] = [start + 1, end];

      return acc;
    }, {} as BallColourRangeMap);
  }
}
