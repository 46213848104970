import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AlertController } from '@ionic/angular';
import { GroupType } from '@shared/constants/group-type';
import { InterestStatus } from '@shared/constants/interest-status';
import { NotificationTarget } from '@shared/constants/notification-target';
import { IBaseEvent } from '@infrastructure/models/base-event';
import { CatchupRsvpStatus } from '@shared/constants/catchup-rsvp-status';
import { IGroup } from '@shared/models/groups/group';
import { IListingImage } from '@shared/models/image/listing-image';
import { ITrip } from '@shared/models/trips/trip';
import { ActivityService, ActivityType } from '@shared/services/activity';
import { AuthService } from '@shared/services/auth.service';
import { DateTimeService } from '@shared/services/date-time.service';
import { EnvironmentService } from '@shared/services/environment.service';
import { GroupDatabase } from '@shared/services/groups/group.database';
import { ImageService } from '@shared/services/image/image.service';
import { RegionService } from '@shared/services/regions/region.service';
import { ToastService } from '@shared/services/toast.service';
import { UserService } from '@shared/services/user/user.service';
import { firestore } from 'firebase/app';
import { Observable, of } from 'rxjs';
import { map, skipWhile } from 'rxjs/operators';
import { TripDatabase } from './trip.database';
import FieldValue = firestore.FieldValue;

@Injectable({
  providedIn: 'root'
})
export class TripService {
  constructor(
    private activityService: ActivityService,
    private alertController: AlertController,
    private authService: AuthService,
    private dateTimeService: DateTimeService,
    private groupDatabase: GroupDatabase,
    private environmentService: EnvironmentService,
    private imageService: ImageService,
    private regionService: RegionService,
    private router: Router,
    private toastService: ToastService,
    private tripDatabase: TripDatabase,
    private userService: UserService
  ) {}

  async deleteTrip(trip: ITrip) {
    const alert = await this.alertController.create({
      header: `Delete ${trip.title}`,
      message: `Are you sure you want to delete this trip? The list of interested/booked members will also be deleted.`,
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
          cssClass: 'secondary'
        },
        {
          text: 'OK',
          handler: data => this.deleteTripHandler(trip)
        }
      ]
    });

    await alert.present();
  }

  private deleteTripHandler(trip: ITrip) {
    return this.tripDatabase.deleteTrip(trip.uid).then(() => {
      this.toastService.presentToast(`${trip.title} has been deleted.`);
      this.router.navigate(['/groups', trip.groupId]);
    });
  }

  getBookedMembers(attendees: Record<string, string>) {
    return this.getAttendeesByStatus(attendees, CatchupRsvpStatus.GOING);
  }

  getImage(trip: ITrip, fallback: string) {
    const firstImage = Object.values(trip.photos || {})[0]; // TODO: sometimes this does not show the lowest numbered image
    return firstImage ? firstImage.mediumPhotoURL || firstImage.originalPhotoURL || fallback : fallback;
  }

  getInterestedMembers(interested: Record<string, string>) {
    return this.getAttendeesByStatus(interested, InterestStatus.INTERESTED);
  }

  getTrip(uid: string): Observable<ITrip> {
    return this.tripDatabase.getTrip(uid);
  }

  getGroupTrips(isAdmin: boolean, groupId: string = '', recordsToFetch: number = null): Observable<ITrip[]> {
    const today = this.dateTimeService.getStartOfTodayAsString();
    return this.tripDatabase.getGroupTrips(recordsToFetch, groupId, today, isAdmin);
  }

  isClosed(trip: ITrip): boolean {
    const today = this.dateTimeService.getStartOfTodayAsString();
    return today > trip.date;
  }

  isMemberInterested(uid: string, trip: IBaseEvent): boolean {
    return trip.attendees.hasOwnProperty(uid) || trip.interested.hasOwnProperty(uid);
  }

  // TODO: Delete this function, not currently used?
  moveToDraft(trip: ITrip) {
    const data = { published: false };
    return this.tripDatabase.updateTrip(trip.uid, data).then(() => {
      this.toastService.presentToast(`Your trip has been moved to Draft.`);
    });
  }

  numberInterestedAndGoing(trip: IBaseEvent): string {
    const count = Object.values(trip.attendees || {}).length + Object.values(trip.interested || {}).length;
    if (count === 0) return `0 interested`;

    let output = `${count} interested`;
    if (this.isMemberInterested(this.authService._userProfileSubject.value.uid, trip)) {
      if (count === 1) output = `You are interested`;
      else output = count === 2 ? `You and 1 other interested` : `You and ${count - 1} others interested`;
    }

    return output;
  }

  registerForTrip(tripId: string, memberId: string) {
    if (!tripId && !memberId) return;
    const status = InterestStatus.INTERESTED;
    const data = { interested: { [memberId]: status } };
    return this.tripDatabase.updateTrip(tripId, data);
  }

  publishTrip(trip: ITrip) {
    const data = { published: true };
    return this.tripDatabase.updateTrip(trip.uid, data).then(() => {
      this.sendTripCreatedNotifyGroup(trip);
      this.toastService.presentToast(`Your Trip has been published, it is now visible to all members.`);
    });
  }

  setStatus(tripId: string, memberId: string, status: InterestStatus | CatchupRsvpStatus, hiddenGroupId: string): void {
    let groupData: any = {};
    let memberData: any = {};
    let tripData: any = {};

    switch (status) {
      case InterestStatus.INTERESTED:
        tripData = { interested: { [memberId]: status }, attendees: { [memberId]: FieldValue.delete() } };
        memberData = { catchupGroupIds: FieldValue.arrayRemove(hiddenGroupId) };
        groupData = { memberCount: FieldValue.increment(-1) };
        break;

      case CatchupRsvpStatus.GOING:
        tripData = { attendees: { [memberId]: status }, interested: { [memberId]: FieldValue.delete() } };
        memberData = { catchupGroupIds: FieldValue.arrayUnion(hiddenGroupId) };
        groupData = { memberCount: FieldValue.increment(1) };
        break;

      case InterestStatus.NOT_INTERESTED:
        tripData = { interested: { [memberId]: FieldValue.delete() } };
        // Doesn't need to be removed from private group, because being removed from Interested status
        break;

      case CatchupRsvpStatus.REMOVED:
        tripData = { attendees: { [memberId]: FieldValue.delete() } };
        memberData = { catchupGroupIds: FieldValue.arrayRemove(hiddenGroupId) };
        groupData = { memberCount: FieldValue.increment(-1) };
        break;

      default:
        break;
    }
    if (Object.keys(tripData).length > 0) this.tripDatabase.updateTrip(tripId, tripData);
    if (Object.keys(memberData).length > 0)
      this.tripDatabase.updateMemberGroups(memberId, memberData).then(() => {
        return this.groupDatabase.updateGroup(hiddenGroupId, groupData);
      });
  }

  updateTrip(trip: ITrip, photosToRemove: IListingImage[]) {
    // Member can't change the Trip DateTime to the past, but they can edit other form values
    const oldDateTime = trip.datetime;
    const newDateTime = this.dateTimeService.createDate(trip.date, '00:00');
    const isCreating = trip.uid == null;
    const validateDateTime = isCreating || (!isCreating && oldDateTime !== newDateTime) ? newDateTime : null;

    if (!this.validateForm(trip, validateDateTime)) return false;

    // Don't update date time until it passes validation.
    trip.datetime = newDateTime;

    if (isCreating) {
      trip.created = this.dateTimeService.getDateTime();
    }

    // convert first letter to upper case
    trip.title = trip.title[0].toUpperCase() + trip.title.slice(1);

    // Delete images removed from listing
    if (photosToRemove.length > 0) {
      for (const photo of photosToRemove) {
        this.imageService.deleteImage(photo).catch(err => console.error(JSON.stringify(err)));
      }
    }

    // copy photos data for later upload, and remove any File objects (Can't upload them to Firestore, we can't upload the image to Cloud storage until we have a trip uid)
    const photos = this.imageService.deepCopy(trip.photos || {});
    for (let [key, photo] of Object.entries(trip.photos || {})) {
      if (photo.file) {
        delete trip.photos[key].file;
        trip.photos[key].photoURL = '';
      }
    }

    // When updating, use merge=false so that map-type fields are properly updated
    const ref: Promise<any> = isCreating ? this.tripDatabase.createTrip(trip) : this.tripDatabase.updateTrip(trip.uid, trip, false);

    return ref.then(result => {
      const uid = trip.uid || result.id;
      trip.uid = uid;

      if (isCreating) {
        this.createHiddenGroup(trip);
      }
      const message = `Your Trip has been ${isCreating ? 'created' : 'updated'}.`;
      this.toastService.presentToast(message);

      // Now upload photos
      this.imageService.uploadImages(uid, photos, 'trip');

      this.router.navigate(['/trips', uid]);
    });
  }

  validateForm(data: ITrip, validateDateTime: number = null) {
    const missingFields = [];
    const emptyCostShare =
      Object.values(data.costShare)
        .map(x => x.trim())
        .filter(x => x !== '').length === 0;
    const emptyCostSingle =
      Object.values(data.costSingle)
        .map(x => x.trim())
        .filter(x => x !== '').length === 0;
    if (data.title.trim().length === 0) missingFields.push('Title');
    if (data.address.trim().length === 0) missingFields.push('Duration');
    if (emptyCostShare && emptyCostSingle) missingFields.push('Cost');
    if (data.date.length === 0) missingFields.push('Start Date');
    if (data.date.length > 0 && data.date > this.dateTimeService.MAX_DATE) missingFields.push(`start date within the next ${this.dateTimeService.MAX_DATE_IN_YEARS} years`);
    if (data.endDate.length === 0) missingFields.push('End Date');
    if (data.endDate.length > 0 && data.endDate > this.dateTimeService.MAX_DATE) missingFields.push(`end date within the next ${this.dateTimeService.MAX_DATE_IN_YEARS} years`);

    if (missingFields.length > 0) {
      const message = 'Please enter a ' + missingFields.join(', ');
      this.toastService.presentToast(message);
      return false;
    }

    if (validateDateTime != null) {
      // Don't let member create a Trip in the past, but allow member to update a past Trip.
      if (this.dateTimeService.isPastDate(validateDateTime)) {
        const message = 'Date cannot be in the past, please enter a future date.';
        this.toastService.presentToast(message);
        return false;
      }
    }

    // Validate start date is before end date.
    if (data.endDate < data.date) {
      const message = 'Choose an End Date that is after the Start Date';
      this.toastService.presentToast(message);
      return false;
    }

    return true;
  }

  private createHiddenGroup(trip: ITrip) {
    const country = this.regionService.getCountryForRegion('Virtual_GLOBAL');
    const group: IGroup = {
      uid: null,
      canShowCatchups: true,
      canShowSocials: false,
      canShowTrips: false,
      created: this.dateTimeService.getDateTime(),
      country: country,
      name: `Private Group for ${trip.title}`,
      advisors: {},
      cohosts: {},
      hosts: {
        [this.authService._userProfileSubject.value.uid]: this.authService._userProfileSubject.value.displayName
      },
      description: `Private Group for ${trip.title}`,
      locations: {},
      memberCount: 0,
      groupType: GroupType.HIDDEN_GROUP,
      region: 'Virtual_GLOBAL',
      relatedId: trip.uid
    };

    this.groupDatabase.createGroup(group).then(result => {
      this.tripDatabase.updateTrip(trip.uid, { tripGroupId: result.id });
    });
  }

  private getAttendeesByStatus(memberList: Record<string, string>, status: InterestStatus | CatchupRsvpStatus) {
    const memberIds = [];
    for (let [memberId, memberStatus] of Object.entries(memberList)) {
      if (memberStatus === status) {
        memberIds.push(memberId);
      }
    }
    const showUnknown = true;
    return memberIds.length > 0 ? this.userService.getUsers(memberIds, showUnknown).pipe(skipWhile(u => !u)) : of([]);
  }

  private sendTripCreatedNotifyGroup(trip: ITrip) {
    const activity = {
      activityTypes: [ActivityType.FEED, ActivityType.EMAIL, ActivityType.DIGEST],
      data: {
        baseUrl: this.environmentService.url(this.authService.getCountry()), // TODO: store baseUrl or country per user in RTDB and insert into activity in Cloud Function
        trip: trip
      },
      message: `<a href="/groups/${trip.groupId}">${trip.groupName}</a> listed a <a href="/trip/${trip.uid}">new Trip</a>:<br><em>${trip.title}</em>`,
      notificationType: 'tripCreatedNotifyGroup',
      targetIds: [trip.groupId],
      targetType: NotificationTarget.GROUP,
      timestamp: Date.now()
    };

    return this.activityService.createActivity(activity);
  }
}
