import { Injectable } from '@angular/core';
import { Country } from '@shared/constants/country';
import { IChargebeePlan } from '@shared/models/chargebee-plan';
import { AppOptionsService } from '@shared/services/app-options/app-options.service';
import { Observable } from 'rxjs';
import { first, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class UrlHelperService {
  get domain() {
    // TODO: Handle this properly to support .com
    return window.location.toString().match(/\.co\.nz/) ? '.co.nz' : '.com.au';
  }

  country: Country;
  annualSubscription$: Observable<IChargebeePlan>;
  monthlySubscription$: Observable<IChargebeePlan>;
  signupUrl$: Observable<string[]>;
  subscriptions$: Observable<IChargebeePlan[]>;

  constructor(private appOptionsService: AppOptionsService) {
    // TODO: Handle this properly to support more than two countries
    this.country = window.location.toString().match(/\.co\.nz/) ? Country.NEW_ZEALAND : Country.AUSTRALIA;
    this.subscriptions$ = this.appOptionsService.getOptionsValues<IChargebeePlan>('chargebeeSubscriptions', this.country).pipe(first(x => !!x));
    this.annualSubscription$ = this.getChargebeePlan('annual');
    this.monthlySubscription$ = this.getChargebeePlan('monthly');
    this.signupUrl$ = this.appOptionsService.getOptionsValues<string>('signupUrl', this.country).pipe(first(x => !!x));
  }

  private getChargebeePlan(type: 'monthly' | 'annual'): Observable<IChargebeePlan> {
    return this.subscriptions$.pipe(
      map(values => {
        const matching = values.filter(x => type === x.label);
        return matching.length > 0 ? matching[0] : null;
      })
    );
  }
}
