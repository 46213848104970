import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AppOptionsService } from '@shared/services/app-options/app-options.service';
import { ILink } from '@shared/models/link';
import { UserObject } from '@shared/models/user-object';
import { AuthService } from '@shared/services/auth.service';
import { Observable } from 'rxjs';
import { first, switchMap } from 'rxjs/operators';

@Component({
  selector: 'notice-box',
  templateUrl: './notice-box.component.html'
})
export class NoticeBoxComponent implements OnInit {
  @Input() color: string;
  @Output() linkClicked = new EventEmitter();
  notice$: Observable<ILink[]>;

  constructor(private appOptionsService: AppOptionsService, private authService: AuthService) {}

  ngOnInit() {
    this.notice$ = this.authService._userProfileSubject.pipe(
      first(x => x != null),
      switchMap((member: UserObject) => {
        const values: Observable<ILink[]> = this.appOptionsService.getOptionsValues<ILink>('myChirpyNotice', member.country);
        return values;
      })
    );
  }

  onClick(event: any) {
    if (event.target.tagName === 'A') {
      this.linkClicked.emit(event.target.text);
    }
  }
}
