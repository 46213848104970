import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'bingo-play-game',
  templateUrl: './play-game.component.html'
})
export class BingoPlayGameComponent {
  @Input() gameInProgressId: string;

  @Output() resumeGame = new EventEmitter<string>();
  @Output() startNewGame = new EventEmitter<void>();

  onStartNewGame() {
    this.startNewGame.emit();
  }

  onResumeGame(gameId: string) {
    this.resumeGame.emit(gameId);
  }
}
