import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AlertController, ModalController } from '@ionic/angular';
import { ChirpyItemSearchComponent } from '@shared/components/chirpy-select-items/chirpy-item-search/chirpy-item-search.component';
import { AdminRole } from '@shared/constants/admin-role';
import { AvatarSize } from '@shared/constants/avatar-size';
import { ALL_COUNTRIES } from '@shared/constants/country';
import { RoomShareMatchStatus } from '@shared/constants/room-share-match-status';
import { IMemberThreadRelatedType } from '@shared/models/messages/member-thread';
import { IRoomShare } from '@shared/models/trips/room-share';
import { IRoomShareMatch } from '@shared/models/trips/room-share-match';
import { UserObject } from '@shared/models/user-object';
import { AnalyticsAction, AnalyticsCategory, AnalyticsService } from '@shared/services/analytics';
import { AuthService } from '@shared/services/auth.service';
import { ConstantsService } from '@shared/services/constants.service';
import { EmailService } from '@shared/services/email/email.service';
import { ToastService } from '@shared/services/toast.service';
import { RoomShareService } from '@shared/services/trips/room-share.service';
import { SubscriptionService } from '@shared/services/subscription.service';
import { TripService } from '@shared/services/trips/trip.service';
import { firestore } from 'firebase/app';
import { Observable, of, Subject, Subscription } from 'rxjs';
import { first, map, switchMap, tap } from 'rxjs/operators';
import FieldValue = firestore.FieldValue;

@Component({
  selector: 'room-share-match-page',
  styleUrls: ['./room-share-match.page.scss'],
  templateUrl: './room-share-match.page.html'
})
export class RoomShareMatchPage implements OnDestroy, OnInit {
  CONSTANTS: any;
  matches: Record<string, IRoomShareMatch> = {};
  memberId: string;
  myRoomShare$: Observable<IRoomShare>;
  readonly RoomShareMatchStatus = RoomShareMatchStatus;
  roomShares: IRoomShare[];
  roomShareSubscription: Subscription;
  size: AvatarSize = AvatarSize.LARGE;
  title: string;
  title$: Observable<string>;
  tripId: string;

  constructor(
    private alertController: AlertController,
    private analyticsService: AnalyticsService,
    private authService: AuthService,
    private constantsService: ConstantsService,
    private emailService: EmailService,
    private modalController: ModalController,
    private roomShareService: RoomShareService,
    private route: ActivatedRoute,
    private router: Router,
    private subscriptionService: SubscriptionService,
    private toastService: ToastService,
    private tripService: TripService
  ) {}

  ngOnDestroy() {
    this.subscriptionService.clearSubscription(this.roomShareSubscription);
  }

  ngOnInit() {
    this.CONSTANTS = this.constantsService.constants.TRIPS.ROOM_SHARE;
    this.tripId = this.route.snapshot.paramMap.get('tripId');
    this.memberId = this.authService._userProfileSubject.value.uid;
    this.title$ = this.tripService.getTrip(this.tripId).pipe(
      first(x => !!x),
      map(x => {
        this.title = x.title; // Save title for use in emails
        return x.title;
      })
    );

    this.myRoomShare$ = this.roomShareService.getRoomShareForMember(this.tripId, this.memberId).pipe(
      // Don't pipe first result, so that status updates immediately if another member agrees to Room Share
      tap(roomShare => {
        this.matches = roomShare.matches;
      })
    );

    this.roomShareSubscription = this.authService._userProfileSubject
      .pipe(
        first(x => !!x),
        switchMap(profile => {
          const country = this.authService.isAdmin([AdminRole.TRAVEL]) ? ALL_COUNTRIES : profile.country;
          return this.roomShareService.getRoomShareForTrip(this.tripId, false, country);
        })
      )
      .subscribe(roomShares => {
        this.roomShares = roomShares.filter(x => x.uid !== this.memberId); // Filter out your own listing, if you are still looking
      });
    this.subscriptionService.add(this.roomShareSubscription);
  }

  async onAddMatch(member: Record<string, string>) {
    const alert = await this.alertController.create({
      header: `Confirm Room Share`,
      message: `<p>Please confirm that you have arranged to share a room with <b>${member.value}</b>.</p> <p>Clicking 'Confirm' will send <b>${member.value}</b> a message asking them to verify this.</p>`,
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel'
        },
        {
          text: `Confirm`,
          handler: data => this.onAddMatchHandler(member)
        }
      ]
    });

    await alert.present();
  }

  onAddMatchHandler(member: Record<string, string>) {
    const isMatch = this.matches[member.key] != null;
    if (isMatch) return;

    const myMatch = {
      [member.key]: {
        displayName: member.value,
        status: RoomShareMatchStatus.INITIATED
      }
    };
    const theirMatch = {
      [this.memberId]: {
        displayName: this.authService._userProfileSubject.value.displayName,
        status: RoomShareMatchStatus.PENDING
      }
    };
    this.matches = Object.assign(this.matches, myMatch);

    this.roomShareService.updateRoomShareMatches(this.tripId, this.memberId, myMatch, member.key, theirMatch).then(() => {
      this.emailService.sendRoomShareRequest(member.key, this.tripId, this.title, this.memberId, this.authService._userProfileSubject.value.displayName, this.authService._userProfileSubject.value.country);
      this.analyticsService.eventTrack(AnalyticsCategory.TRIPS, AnalyticsAction.TRIPS_INITIATE_ROOM_SHARE, this.title, member.value);
    });
  }

  async onAgreeMatch(key: string, value: IRoomShareMatch) {
    const alert = await this.alertController.create({
      header: `Agree to Room Share`,
      message: `<p>Please confirm that you agree to share a room with <b>${value.displayName}</b>.</p>`,
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel'
        },
        {
          text: `Confirm`,
          handler: data => this.onAgreeMatchHandler(key, value)
        }
      ]
    });

    await alert.present();
  }

  onAgreeMatchHandler(key: string, value: IRoomShareMatch) {
    const foundMatches = true; // TODO: Make this dependent on capacity of room type
    const myMatch = {
      [key]: {
        displayName: value.displayName,
        status: RoomShareMatchStatus.AGREED
      }
    };
    const theirMatch = {
      [this.memberId]: {
        displayName: this.authService._userProfileSubject.value.displayName,
        status: RoomShareMatchStatus.AGREED
      }
    };
    this.matches = Object.assign(this.matches, myMatch);

    this.matches[key].status = RoomShareMatchStatus.AGREED;
    const data = { matches: this.matches, foundMatches };

    this.roomShareService.updateRoomShareMatches(this.tripId, this.memberId, myMatch, key, theirMatch, foundMatches).then(() => {
      this.emailService.sendRoomShareAgreement(key, this.tripId, this.title, this.memberId, this.authService._userProfileSubject.value.displayName, this.authService._userProfileSubject.value.country);
      this.analyticsService.eventTrack(AnalyticsCategory.TRIPS, AnalyticsAction.TRIPS_AGREE_ROOM_SHARE, this.title, value.displayName);
    });
  }

  async onRemoveMatch(key: string, value: IRoomShareMatch, foundMatches: boolean = null) {
    const alert = await this.alertController.create({
      header: `Confirm Room Share`,
      message: `<p>Please confirm that you no longer wish to share a room with <b>${value.displayName}</b>.</p> <p>Clicking 'Confirm' will send <b>${value.displayName}</b> a message to notify them.</p>`,
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel'
        },
        {
          text: `Confirm`,
          handler: data => this.onRemoveMatchHandler(key, value, foundMatches)
        }
      ]
    });

    await alert.present();
  }

  onRemoveMatchHandler(key: string, value: IRoomShareMatch, foundMatches: boolean) {
    const isMatch = this.matches[key] != null;
    if (!isMatch) return;

    if (this.matches[key]) delete this.matches[key];

    const myMatch = {
      [key]: FieldValue.delete()
    };
    const theirMatch = {
      [this.memberId]: FieldValue.delete()
    };

    // Use strictUpdate to correct unset removed entries in matches field
    this.roomShareService.updateRoomShareMatches(this.tripId, this.memberId, myMatch, key, theirMatch, foundMatches).then(() => {
      this.emailService.sendRoomShareCancellation(key, this.tripId, this.title, this.memberId, this.authService._userProfileSubject.value.displayName, this.authService._userProfileSubject.value.country);
      this.analyticsService.eventTrack(AnalyticsCategory.TRIPS, AnalyticsAction.TRIPS_CANCEL_ROOM_SHARE, this.title, value.displayName);
    });
  }

  async presentChooseMembersModal() {
    const modal = await this.modalController.create({
      component: ChirpyItemSearchComponent,
      cssClass: 'chirpy-item-search__modal',
      componentProps: {
        addItem: this.onAddMatch.bind(this),
        canRemoveItems: false,
        label: 'Register your Room Share',
        noResultsText: '',
        memberType: IMemberThreadRelatedType.Member,
        placeholder: 'Start typing a name...',
        removeItem: this.onRemoveMatch.bind(this),
        search: this.searchMembers.bind(this),
        selectedItems: this.matches,
        singleSelect: true
      }
    });

    return modal.present();
  }

  searchMembers(startWith: string) {
    const filteredMembers = !!startWith ? this.roomShares.filter(x => x.displayName.includes(startWith)) : this.roomShares;
    const members: Record<string, string> = {};
    for (const m of filteredMembers) {
      members[m.uid] = m.displayName;
    }
    return of(members);
  }
}
