import { Component } from '@angular/core';
import { AdminRole } from '@shared/constants/admin-role';
import { UserObject } from '@shared/models/user-object';
import { AuthService } from '@shared/services/auth.service';
import { ConstantsService } from '@shared/services/constants.service';
import { IVersion } from '@shared/services/version/models/version';
import { VersionService } from '@shared/services/version/version.service';
import { AdminMenuCategory } from './constants/admin-menu-category';
import { IAdminMenuItem } from './models/admin-menu-item';

@Component({
  selector: 'app-admin',
  styleUrls: ['./admin.page.scss'],
  templateUrl: './admin.page.html'
})
export class AdminPage {
  get CONSTANTS() {
    return this.constantsService.constants;
  }

  get firebaseVersion(): IVersion {
    return this.versionService.firebaseVersion;
  }

  get isImpersonating() {
    return this.authService.isImpersonating;
  }

  menuItems: Record<AdminMenuCategory, IAdminMenuItem[]> = {
    [AdminMenuCategory.MEMBER]: [
      {
        roles: [AdminRole.SUPER, AdminRole.CONCIERGE],
        routerLink: 'impersonate',
        title: 'Impersonate member'
      },
      {
        roles: [AdminRole.SUPER, AdminRole.CONCIERGE, AdminRole.HOSTS, AdminRole.SUPPORT],
        routerLink: 'search-member',
        title: 'Search for member'
      },
      {
        roles: [AdminRole.SUPER, AdminRole.SUPPORT],
        routerLink: 'set-password',
        title: 'Set password for member'
      },
      {
        roles: [AdminRole.SUPER, AdminRole.SUPPORT],
        routerLink: 'pause-member',
        title: 'Pause member'
      },
      {
        roles: [AdminRole.SUPER, AdminRole.SUPPORT],
        routerLink: 'restore-member',
        title: 'Restore member'
      },
      {
        roles: [AdminRole.SUPER],
        routerLink: 'delete-member-messages',
        title: 'Delete member messages'
      },
      {
        roles: [AdminRole.SUPER, AdminRole.DELETE],
        routerLink: 'delete-member',
        title: 'Delete member'
      }
    ],
    [AdminMenuCategory.COINS]: [
      {
        roles: [AdminRole.SUPER, AdminRole.FINANCIAL],
        routerLink: 'add-remove-coins',
        title: 'Manage coins for member'
      },
      {
        roles: [AdminRole.SUPER, AdminRole.FINANCIAL],
        routerLink: 'logs/coins-log',
        title: 'Coins log'
      },
      {
        roles: [AdminRole.SUPER],
        routerLink: 'settings/coinsTriggers',
        title: 'Manage settings for automatic coins'
      },
      {
        roles: [AdminRole.SUPER],
        routerLink: 'settings/coinsGivenFor',
        title: 'Manage settings for manual coins'
      },
      {
        roles: [AdminRole.SUPER],
        routerLink: 'settings/coinsRedeemedFor',
        title: 'Manage settings for redeeming coins'
      }
    ],
    [AdminMenuCategory.REPORTS]: [
      {
        roles: [AdminRole.SUPER, AdminRole.HOSTS],
        routerLink: 'search-attendance',
        title: 'Catchups attended report by member'
      },
      {
        roles: [AdminRole.SUPER, AdminRole.HOSTS],
        routerLink: 'reports/catchups-by-date',
        title: 'CatchUps report by date'
      },
      {
        roles: [AdminRole.SUPER, AdminRole.HOSTS],
        routerLink: 'reports/catchups-by-group',
        title: 'CatchUps report by group'
      },
      {
        roles: [AdminRole.SUPER, AdminRole.HOSTS],
        routerLink: 'reports/last-activity-by-date',
        title: 'Last activity report by date'
      },
      {
        roles: [AdminRole.SUPER, AdminRole.HOSTS],
        routerLink: 'reports/member-activity',
        title: 'Member reports'
      },
      {
        roles: [AdminRole.SUPER, AdminRole.HOSTS],
        routerLink: 'reports/next-catchup',
        title: 'Next CatchUp report'
      },
      {
        roles: [AdminRole.SUPER, AdminRole.SUPPORT],
        routerLink: 'reports/socials-by-date',
        title: 'Socials report by date'
      },
      {
        roles: [AdminRole.SUPER, AdminRole.FINANCIAL],
        routerLink: 'reports/coins-claimed-by-date',
        title: 'Coins claimed report by date'
      },
      {
        roles: [AdminRole.SUPER, AdminRole.FINANCIAL],
        routerLink: 'search-coins-claimed',
        title: 'Coins claimed report by member'
      }
    ],
    [AdminMenuCategory.STATUS]: [
      {
        roles: [AdminRole.SUPER, AdminRole.HOSTS, AdminRole.MODERATOR],
        routerLink: 'add-icon-badge',
        title: 'Add icon badge to member'
      },
      {
        roles: [AdminRole.SUPER, AdminRole.HOSTS, AdminRole.MODERATOR],
        routerLink: 'add-text-badge',
        title: 'Add text badge to member'
      },
      {
        roles: [AdminRole.SUPER, AdminRole.HOSTS, AdminRole.MODERATOR],
        routerLink: 'change-role',
        title: 'Change member role'
      },
      {
        roles: [AdminRole.SUPER, AdminRole.HOSTS, AdminRole.MODERATOR],
        routerLink: 'remove-icon-badge',
        title: 'Remove icon badge from member'
      },
      {
        roles: [AdminRole.SUPER, AdminRole.HOSTS, AdminRole.MODERATOR],
        routerLink: 'remove-text-badge',
        title: 'Remove text badge from member'
      }
    ],
    [AdminMenuCategory.LOGS]: [
      {
        roles: [AdminRole.SUPER, AdminRole.HOSTS],
        routerLink: 'logs/cancellation-log',
        title: 'View Cancellation log'
      },
      {
        roles: [AdminRole.SUPER],
        routerLink: 'logs/coins-log',
        title: 'View Coins log'
      },
      {
        roles: [AdminRole.SUPER, AdminRole.SUPPORT],
        routerLink: 'logs/email-log',
        title: 'View Email log'
      },
      {
        roles: [AdminRole.SUPER],
        routerLink: 'logs/message-log',
        title: 'View Message log'
      },
      {
        roles: [AdminRole.SUPER],
        routerLink: 'logs/note-log',
        title: 'View Note log'
      }
    ],
    [AdminMenuCategory.MODERATION]: [
      {
        roles: [AdminRole.SUPER, AdminRole.MODERATOR],
        routerLink: 'approve-messages',
        title: `Approve Messages`
      }
    ],
    [AdminMenuCategory.SETTINGS]: [
      {
        roles: [AdminRole.SUPER],
        routerLink: 'settings',
        title: 'Manage settings'
      },
      {
        roles: [AdminRole.SUPER],
        routerLink: '/pages/cancel-offer/edit',
        title: 'Edit Cancel Offer page'
      }
    ]
  };

  constructor(public authService: AuthService, private constantsService: ConstantsService, private versionService: VersionService) {}

  canShowItem(item: IAdminMenuItem, user: UserObject): boolean {
    if (!item.roles || item.roles.length === 0) return true;
    if (!user.adminRoles || user.adminRoles.length === 0) return false;
    if (item.routerLink === 'impersonate' && this.isImpersonating) return false; // Don't show impersonate link while impersonating

    return item.roles.some(role => user.adminRoles.includes(role as AdminRole));
  }

  filterItems(items: IAdminMenuItem[], user: UserObject): IAdminMenuItem[] {
    const filteredItems = items.filter(item => this.canShowItem(item, user));
    return filteredItems.length > 0 ? filteredItems : null;
  }

  ngOnInit() {
    if (this.CONSTANTS.ADVERTISERS.enabled) {
      this.menuItems[AdminMenuCategory.STATUS].push(
        {
          roles: [AdminRole.SUPER],
          routerLink: 'add-advertiser-access',
          title: `Add ${this.CONSTANTS.ADVERTISERS.branding} access to member`
        },
        {
          roles: [AdminRole.SUPER],
          routerLink: 'remove-advertiser-access',
          title: `Remove ${this.CONSTANTS.ADVERTISERS.branding} access from member`
        }
      );
    }

    if (!this.CONSTANTS.COINS.enabled) {
      delete this.menuItems[AdminMenuCategory.COINS];
      this.menuItems[AdminMenuCategory.LOGS] = this.menuItems[AdminMenuCategory.LOGS].filter(x => !x.routerLink.includes('coins'));
      this.menuItems[AdminMenuCategory.REPORTS] = this.menuItems[AdminMenuCategory.REPORTS].filter(x => !x.routerLink.includes('coins'));
    }

    if (this.CONSTANTS.GROUPS.CHIT_CHAT.branding) {
      this.menuItems[AdminMenuCategory.LOGS] = [
        {
          roles: [AdminRole.SUPER],
          routerLink: 'logs/chit-chat-log',
          title: `View ${this.CONSTANTS.GROUPS.CHIT_CHAT.branding} log`
        },
        ...this.menuItems[AdminMenuCategory.LOGS]
      ];
      this.menuItems[AdminMenuCategory.LOGS].sort((a, b) => a.title.localeCompare(b.title));
      // TODO sort logs to keep in alphabetical order. Not needed at the moment because (chit-)chat comes before email anyway
    }

    if (this.CONSTANTS.ROMANCE.enabled && !this.CONSTANTS.ROMANCE.INTRO.requireListing) {
      // This is frequently used, so add it at the top of the section
      this.menuItems[AdminMenuCategory.STATUS] = [
        {
          roles: [AdminRole.SUPER, AdminRole.ROMANCE],
          routerLink: 'add-romance-access',
          title: `Add Romance access to member`
        },
        {
          roles: [AdminRole.SUPER, AdminRole.ROMANCE],
          routerLink: 'remove-romance-access',
          title: `Remove Romance access from member`
        },
        ...this.menuItems[AdminMenuCategory.STATUS]
      ];
    }

    if (this.CONSTANTS.MARKETPLACE.enabled) {
      this.menuItems[AdminMenuCategory.MODERATION].push({
        roles: [AdminRole.SUPER, AdminRole.MODERATOR],
        routerLink: 'approve-marketplace',
        title: `Approve ${this.CONSTANTS.MARKETPLACE.branding}`
      });
    }

    if (this.CONSTANTS.SHARE.enabled) {
      this.menuItems[AdminMenuCategory.MODERATION].push({
        roles: [AdminRole.SUPER, AdminRole.MODERATOR],
        routerLink: 'approve-share',
        title: `Approve ${this.CONSTANTS.SHARE.branding}`
      });
    }

    if (this.CONSTANTS.SOCIAL.enabled && !this.CONSTANTS.SOCIAL.onlyInGroup) {
      this.menuItems[AdminMenuCategory.MODERATION].push({
        roles: [AdminRole.SUPER, AdminRole.MODERATOR],
        routerLink: 'approve-socials',
        title: `Approve ${this.CONSTANTS.SOCIAL.branding}`
      });
    }

    if (this.menuItems[AdminMenuCategory.MODERATION].length === 0) {
      delete this.menuItems[AdminMenuCategory.MODERATION];
    }
  }

  onStopImpersonatingMember() {
    return this.authService.stopImpersonating();
  }

  sortNull() {}
}
