import { Injectable } from '@angular/core';
import { AdminRole } from '@shared/constants/admin-role';
import { Country } from '@shared/constants/country';
import { UserObject } from '@shared/models/user-object';
import { AuthService } from '@shared/services/auth.service';
import { UrlHelperService } from '@shared/services/url-helper.service';
import { IAppMenuItem } from '../../models/app-menu-item';
import { AbstractMenuService } from '../abstract-menu.service';

@Injectable({
  providedIn: 'root'
})
export class ChirpyMenuService extends AbstractMenuService {
  constructor(private authService: AuthService, private urlHelperService: UrlHelperService) {
    super();
  }

  getMenuItems(user: UserObject): IAppMenuItem[] {
    return [
      {
        title: 'Admin menu',
        url: '/admin',
        canShow: this.authService.isAdmin$()
      },
      {
        title: 'Host-only pages',
        url: '/pages/hosts-only',
        canShow: this.authService.isCohostOrHostOrAdmin$()
      },
      {
        title: 'Home',
        url: '/home'
      },
      {
        title: `Getting started`,
        url: '/pages/whats-next'
      },
      {
        title: 'Help',
        children: [
          {
            title: 'Help Documents',
            url: 'https://chirpy-plus.helpscoutdocs.com/',
            external: true,
            fullExternalUrl: true,
            forCountry: Country.AUSTRALIA
          },
          {
            title: 'Help Documents',
            url: 'https://nz-member-help.helpscoutdocs.com/',
            external: true,
            fullExternalUrl: true,
            forCountry: Country.NEW_ZEALAND
          },
          {
            title: 'Contact Us',
            url: '/contact-us'
          },
          {
            title: 'Legal Policies',
            children: [
              {
                title: 'Code of Conduct',
                url: 'https://chirpy-plus.helpscoutdocs.com/article/281-chirpy-plus-code-of-conduct',
                external: true,
                fullExternalUrl: true
              },
              {
                title: 'Terms and Conditions',
                url: 'https://chirpy-plus.helpscoutdocs.com/article/282-chirpy-plus-terms-and-conditions',
                external: true,
                fullExternalUrl: true
              },
              {
                title: 'Membership Terms & Conditions',
                url: 'https://chirpy-plus.helpscoutdocs.com/article/280-chirpy-plus-membership-terms-and-conditions',
                external: true,
                fullExternalUrl: true
              },
              {
                title: 'Competitions Terms & Conditions',
                url: 'https://chirpy-plus.helpscoutdocs.com/article/285-chirpy-plus-competition-terms-and-conditions',
                external: true,
                fullExternalUrl: true
              },
              {
                title: 'Acceptable Use Policy',
                url: 'https://chirpy-plus.helpscoutdocs.com/article/279-chirpy-plus-acceptable-use-policy',
                external: true,
                fullExternalUrl: true
              },
              {
                title: 'Privacy Policy',
                url: 'https://chirpy-plus.helpscoutdocs.com/article/278-chirpy-plus-privacy-policy',
                external: true,
                fullExternalUrl: true
              },
              {
                title: 'Security Policy',
                url: 'https://chirpy-plus.helpscoutdocs.com/article/277-chirpy-plus-security-policy',
                external: true,
                fullExternalUrl: true
              }
            ]
          }
        ]
      },
      {
        title: `My Chirpy`,
        url: '/my-chirpy'
      },
      {
        title: 'About Me',
        children: [
          {
            title: 'My Profile',
            url: '/members/me'
          },
          {
            title: 'My Account',
            url: '/account'
          },
          {
            title: 'My Groups',
            url: '/groups'
          },
          {
            title: 'My Messages',
            url: '/messages'
          },
          {
            title: 'My Rewards',
            url: '/rewards'
          }
        ]
      },
      {
        title: 'Meet Other Chirpies',
        children: [
          {
            title: 'CatchUps & Events',
            url: '/catchups'
          },
          {
            title: 'Groups',
            url: '/groups'
          },
          {
            title: 'Find Local Chirpies',
            url: '/meeting-place'
          },
          {
            title: 'Looking for Romance',
            url: '/romance'
          }
        ]
      },
      {
        title: 'Chirpy Pix',
        url: '/pix/public/Chirpy Pix',
        forCountry: Country.AUSTRALIA
      },
      // For convenience also show Chirpy Pix for NZ-based admins
      {
        title: 'Chirpy Pix',
        url: '/pix/public/Chirpy Pix',
        canShow: this.authService.isAdmin$(),
        forCountry: Country.NEW_ZEALAND
      },
      {
        title: 'Advertisers',
        url: '/advertisers',
        forCountry: Country.NEW_ZEALAND
      },
      {
        title: 'Chirpy Bed and Breakfast',
        url: '/bed-and-breakfast',
        forCountry: Country.NEW_ZEALAND
      },
      {
        title: 'Travel',
        url: '/travel-au', // redirected to /groups/50jOCeizq4wGL0genKQE in chirpy-routing.module
        forCountry: Country.AUSTRALIA
      },
      {
        title: 'Travel',
        url: '/travel-nz', // redirected to /groups/9BnrvUW2w6aeduG9qS2Z in chirpy-routing.module
        forCountry: Country.NEW_ZEALAND
      },
      {
        title: 'Fitness',
        url: '/pages/fitness'
      },
      {
        title: 'Games',
        children: [
          {
            title: 'Bingo',
            url: '/games/bingo'
          },
          {
            title: 'Chirple',
            url: '/games/chirple'
          },
          {
            title: 'Quizzes',
            url: '/groups/ZMuefJlM4mTpNV679Y1W'
          },
          {
            title: 'Fun & Games',
            url: '/pages/fun-and-games'
          }
        ]
      },
      {
        title: 'Upgrade',
        url: '/pages/upgrade-subscription'
      },
      {
        title: 'Chirpy Savings',
        url: '/pages/savings',
        forCountry: Country.NEW_ZEALAND
      },
      {
        title: 'Code of Conduct',
        url: 'https://chirpy-plus.helpscoutdocs.com/article/281-chirpy-plus-code-of-conduct',
        external: true,
        fullExternalUrl: true
      },
      {
        title: 'Benefits of being a Chirpy Host or Co-Host',
        url: '/pages/become-a-host'
      },
      {
        title: 'Logout',
        url: '/auth/logout'
      }
    ];
  }

  getTravelTagUrl() {
    return `https://app.chirpytraveltag${this.urlHelperService.domain}`;
  }
}
