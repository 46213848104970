import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Align } from '@shared/constants/align';
import { ConstantsService } from '@shared/services/constants.service';
import { Observable } from 'rxjs';
import { first } from 'rxjs/operators';
import { ISocial } from '@shared/models/social/social';
import { SocialService } from '@shared/services/social/social.service';

@Component({
  selector: 'chirpy-social-list',
  templateUrl: './chirpy-social-list.component.html',
  styleUrls: ['chirpy-social-list.component.scss']
})
export class ChirpySocialListComponent {
  @Input() align: Align = Align.CENTER;
  @Output() buttonClicked = new EventEmitter();
  @Input() buttonLink: string = '';
  @Input() buttonText: string = '';
  CONSTANTS: Record<string, string>;
  @Input() canCreateSocials: boolean = true;
  @Input() createSocialsMessage: string = '';
  @Input() heading: string = '';
  @Input() hasMap: boolean;
  @Output() linkClicked = new EventEmitter();
  @Input() socials$: Observable<ISocial[]>;
  @Input() noSocialsMessage: string = '';
  constructor(private constantsService: ConstantsService, private socialService: SocialService) {}

  isGoing(social: ISocial): boolean {
    return this.socialService.isGoing(social);
  }

  isInterested(social: ISocial): boolean {
    return this.socialService.isInterested(social);
  }

  ngOnInit() {
    this.CONSTANTS = this.constantsService.constants.SOCIAL.LIST;
  }

  numberInterested(social: ISocial): string {
    return this.socialService.numberInterested(social);
  }

  onButtonClick(title: string) {
    this.buttonClicked.emit(title);
  }

  onLinkClick(title: string) {
    this.linkClicked.emit(title);
  }
}
