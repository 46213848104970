/**
 * Implements the ROT-13 Caesar cipher.
 * @param input The input to rotate.
 * @see https://en.wikipedia.org/wiki/ROT13
 */
export function rot13(input: string) {
  return input.replace(/[A-Z]/gi, char => {
    const n = char.toUpperCase() <= 'M' ? 13 : -13;

    return String.fromCharCode(char.charCodeAt(0) + n);
  });
}
