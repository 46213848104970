import { Injectable } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { skipWhile, take } from 'rxjs/operators';
import { IBnBOptions } from '@shared/models/bnb/bnb-options';
import { BnBService } from '@shared/services/bnb/bnb.service';

@Injectable({
  providedIn: 'root'
})
export class BnBSearchPresenter {
  defaultBnBSearch: IBnBOptions;
  form = this.formBuilder.group({
    country: [''],
    region: ['']
  });

  constructor(private bnbService: BnBService, private formBuilder: FormBuilder) {}

  reset() {
    this.setValue({ country: null, region: '' });
  }

  search() {
    return this.form.value;
  }

  setValue(bnbOptions: IBnBOptions) {
    this.form.setValue({
      country: bnbOptions.country || null,
      region: bnbOptions.region
    });
  }
}
