import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AlertController } from '@ionic/angular';
import { ALL_COUNTRIES } from '@shared/constants/country';
import { BadgeType } from '@shared/constants/badge-type';
import { IIcon } from '@shared/models/icon';
import { AppOptionsService } from '@shared/services/app-options/app-options.service';
import { ConstantsService } from '@shared/services/constants.service';
import { ToastService } from '@shared/services/toast.service';
import { UserService } from '@shared/services/user/user.service';
import { first } from 'rxjs/operators';
import { BaseAdminComponent } from '../base-admin/base-admin.component';

@Component({
  selector: 'app-remove-text-badge',
  templateUrl: '../base-admin/base-admin.component.html',
  styleUrls: ['../base-admin/base-admin.component.scss']
})
export class RemoveTextBadgeComponent extends BaseAdminComponent {
  confirmHeader = 'Remove text badge';
  confirmButton = 'Remove badge';
  confirmInputs = [];
  member: string;
  placeholder = 'Search for the member you want to remove a badge from';
  title = 'Remove badge';

  constructor(alertController: AlertController, private appOptionsService: AppOptionsService, private constantsService: ConstantsService, private router: Router, private toastService: ToastService, private userService: UserService) {
    super(alertController);
  }

  confirmMessage(memberId: string, memberName: string) {
    // This function is called once when the dialog is initialised, so save member name
    this.member = memberName;
    return `<p>Choose a badge from the options below.</p>`;
  }

  selectMemberHandler({ memberId, memberName, data }) {
    let message = '';
    this.userService
      .setBadge(memberId, data, BadgeType.TEXT, false)
      .then(result => {
        message = `${memberName} no longer has the ${data} badge.`;
      })
      .catch(err => {
        message = `Couldn't remove ${data} badge from ${memberName}: ${err}`;
      })
      .finally(() => {
        this.toastService.presentToast(message);
        this.router.navigate(['/admin']);
      });
  }

  setup() {
    this.appOptionsService
      .getOptionsValues<string>('textBadges', ALL_COUNTRIES)
      .pipe(first(x => !!x))
      .subscribe(badges => {
        this.confirmInputs = [];
        for (const badge of badges) {
          if (badge) {
            this.confirmInputs.push({
              handler: input => {
                this.updateText(input.value);
              },
              label: badge,
              name: 'badge',
              type: 'radio',
              value: badge
            });
          }
        }
      });
  }

  updateText(badge: string) {
    this.alert.header = `Remove ${badge} badge`;
    this.alert.message = `<p>Remove ${badge} badge from ${this.member}?</p>`;
  }
}
