import { Component, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AdminRole } from '@shared/constants/admin-role';
import { GroupType } from '@shared/constants/group-type';
import { IButtonGroup } from '@shared/models/button-group';
import { IGroup } from '@shared/models/groups/group';
import { ISelectOption } from '@shared/models/select-option';
import { UserObject } from '@shared/models/user-object';
import { AnalyticsAction, AnalyticsCategory, AnalyticsService } from '@shared/services/analytics';
import { AuthService } from '@shared/services/auth.service';
import { RegionService } from '@shared/services/regions/region.service';
import { ConstantsService } from '@shared/services/constants.service';
import { EnforceProfileService } from '@shared/services/enforce-profile.service';
import { GroupService } from '@shared/services/groups/group.service';
import { SubscriptionService } from '@shared/services/subscription.service';
import { UIService } from '@shared/services/ui.service';
import { Subscription } from 'rxjs';
import { skipWhile, take } from 'rxjs/operators';

@Component({
  selector: 'app-group-detail',
  templateUrl: './group-detail.page.html',
  styleUrls: ['./group-detail.page.scss']
})
export class GroupDetailPage implements OnDestroy {
  get ASSETS() {
    return this.constantsService.constants.ASSETS;
  }

  get canDeleteGroup(): boolean {
    return this.authService.isAdmin([AdminRole.SUPER]);
  }

  get canLockGroup(): boolean {
    return !this.isLocked && this.isSupport;
  }

  get canUpdateGroup(): boolean {
    return this.group != null && this.groupService.canManageGroup(this.group);
  }

  get canUnlockGroup(): boolean {
    return this.isLocked && this.isSupport;
  }

  get CONSTANTS() {
    return this.constantsService.constants.GROUPS.DETAIL;
  }

  get hasAdvisors(): boolean {
    return this.group != null && this.group.advisors != null && Object.values(this.group.advisors).length > 0;
  }

  get hasCohosts(): boolean {
    return this.group != null && this.group.cohosts != null && Object.values(this.group.cohosts).length > 0;
  }

  get hasHosts(): boolean {
    return this.group != null && this.group.hosts != null && Object.values(this.group.hosts).length > 0;
  }

  get isAdmin(): boolean {
    return this.groupService.isAdmin();
  }

  get isChatGroup(): boolean {
    return this.group != null && this.group.groupType === GroupType.CHAT_GROUP;
  }

  get isCohost(): boolean {
    // It is possible for a Host of one group to be a cohost of another group
    return this.group != null && (this.authService.isHost() || this.authService.isCohost()) && this.group.cohosts[this.user.uid] != null;
  }

  get isDesktop(): boolean {
    return this.uiService.isExtraLargeDisplay;
  }

  get isOpenGroup(): boolean {
    return this.group != null && this.group.groupType !== GroupType.HIDDEN_GROUP && this.group.groupType !== GroupType.CLOSED_GROUP;
  }

  get isGroupMember(): boolean {
    return this.groupService.isGroupMember(this.group.uid);
  }

  get isHostOrCohost(): boolean {
    return this.authService.isHost() || this.authService.isCohost();
  }

  get isLocked(): boolean {
    return (this.group != null && this.group.isLocked) || false;
  }

  get isSupport(): boolean {
    return this.authService.isAdmin([AdminRole.SUPER, AdminRole.SUPPORT]);
  }

  get title() {
    return this.group != null ? this.group.name : 'View group';
  }

  headerButtons: IButtonGroup[] = [];
  group: IGroup;
  groupSubscription: Subscription;
  regionLabel: string = '';
  regions: ISelectOption[] = [];
  private user: UserObject;

  constructor(
    private analyticsService: AnalyticsService,
    private authService: AuthService,
    private groupService: GroupService,
    private regionService: RegionService,
    private constantsService: ConstantsService,
    private enforceProfileService: EnforceProfileService,
    private route: ActivatedRoute,
    private router: Router,
    private subscriptionService: SubscriptionService,
    private uiService: UIService
  ) {}

  lockGroup() {
    if (this.group != null) {
      this.groupService.lockGroup(this.group);
    }
  }

  unlockGroup() {
    if (this.group != null) {
      this.groupService.unlockGroup(this.group);
    }
  }

  deleteGroup() {
    this.groupService.deleteGroup(this.group);
  }

  eventTrack(eventLabel: string) {
    this.analyticsService.eventTrack(AnalyticsCategory.GROUPS, AnalyticsAction.GROUPS_CLICK_LINK, eventLabel, { type: this.group.name });
  }

  getRegion(region: string) {
    if (!region) return '';
    return region.startsWith('Virtual') ? 'Virtual' : region;
  }

  ionViewWillEnter() {
    this.enforceProfileService.enforce();
    const uid = this.route.snapshot.paramMap.get('id');
    if (uid == null) return;

    this.loadGroup(uid);
  }

  loadGroup(uid: string) {
    this.authService._userProfileSubject
      .pipe(
        skipWhile(u => !u),
        take(1)
      )
      .subscribe(user => {
        this.user = user;

        this.regions = this.regionService.groupRegions;
        this.regionLabel = this.regionService.regionLabel;

        this.groupSubscription = this.groupService.getGroup(uid).subscribe((group: IGroup) => {
          if (group == null) return;
          if (GroupType.HOST_ONLY_GROUP === group.groupType && !this.authService.isCohostOrHostOrAdmin()) this.router.navigate(['/groups']);
          if (!this.group || this.group.uid !== group.uid) {
            this.analyticsService.eventTrack(AnalyticsCategory.GROUPS, AnalyticsAction.GROUPS_VIEW_DETAIL, group.name);
          }
          this.group = group;
          this.headerButtons = this.generateHeaderButtons();
        });
        this.subscriptionService.add(this.groupSubscription);
      });
  }

  ngOnDestroy() {
    this.subscriptionService.clearSubscription(this.groupSubscription);
  }

  onJoinGroup() {
    this.analyticsService.eventTrack(AnalyticsCategory.GROUPS, AnalyticsAction.GROUPS_CLICK_LINK, 'Join group', { type: this.group.name });
    this.groupService.addMemberToGroup(this.group.uid, this.group.name, this.group.memberCount, this.user.uid, this.user.displayName).then(() => {
      this.sendGroupJoinedNotifications();
      this.analyticsService.eventTrack(AnalyticsCategory.GROUPS, AnalyticsAction.GROUPS_JOIN, this.group.name);
    });
  }

  onLeaveGroup() {
    this.analyticsService.eventTrack(AnalyticsCategory.GROUPS, AnalyticsAction.GROUPS_CLICK_LINK, 'Leave group', { type: this.group.name });
    this.groupService.removeMemberFromGroup(this.group.uid, this.group.name, this.user.uid, this.user.displayName);
    this.analyticsService.eventTrack(AnalyticsCategory.GROUPS, AnalyticsAction.GROUPS_LEAVE, this.group.name);
  }

  private generateHeaderButtons(): IButtonGroup[] {
    if (!this.group) return [];

    return [
      {
        condition: true,
        buttons: [
          {
            condition: this.isGroupMember,
            text: 'Notifications',
            icon: 'notifications',
            color: 'light',
            routerLink: ['/groups/notifications', this.group.uid]
          },
          {
            condition: !this.isChatGroup && (this.isOpenGroup || this.isGroupMember || this.isAdmin),
            text: 'Members',
            icon: 'people',
            color: 'light',
            routerLink: ['/groups/members', this.group.uid]
          }
        ]
      },
      {
        condition: this.canUpdateGroup || this.canDeleteGroup,
        buttons: [
          {
            condition: this.canUpdateGroup,
            text: 'Update',
            icon: 'create',
            color: 'light',
            routerLink: ['/groups/update/' + this.group.uid]
          },
          {
            condition: this.canDeleteGroup,
            text: 'Delete',
            icon: 'trash',
            color: 'light',
            click: this.deleteGroup.bind(this)
          }
        ]
      },
      {
        condition: this.canLockGroup || this.canUnlockGroup,
        buttons: [
          {
            condition: this.canLockGroup,
            text: 'Lock',
            icon: 'lock',
            color: 'light',
            click: this.lockGroup.bind(this)
          },
          {
            condition: this.canUnlockGroup,
            text: 'Unlock',
            icon: 'unlock',
            color: 'light',
            click: this.unlockGroup.bind(this)
          }
        ]
      }
    ];
  }

  private sendGroupJoinedNotifications() {
    const hasHosts = Object.keys(this.group.hosts).length > 0;
    if (hasHosts == null) {
      console.warn(`"Member has joined group" emails were not sent because group ${this.group.name} does not have a host.`);
      return;
    }

    const isChat = this.group.groupType === GroupType.CHAT_GROUP;
    const isVirtual = this.group.groupType === GroupType.SPECIAL_INTEREST_GROUP;
    this.groupService.sendMemberJoinedGroupNotifications(this.group.uid, this.group.name, this.group.hosts, this.user.uid, isVirtual, isChat);
  }
}
