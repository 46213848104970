import { NgModule } from '@angular/core';
import { SharedModule } from '@shared/shared.module';
import { BnBPage } from './bnb.page';
import { BnBReviewEditPage } from './bnb-review-edit/bnb-review-edit.page';
import { BnBReviewListPage } from './bnb-review-list/bnb-review-list.page';
import { BnBRoutingModule } from './bnb-routing.module';
import { BnBDetailComponent } from './components/bnb-detail/bnb-detail.component';
import { BnBEditComponent } from './components/bnb-edit/bnb-edit.component';
import { BnBListComponent } from './components/bnb-list/bnb-list.component';
import { BnBSearchComponent } from './components/bnb-search/bnb-search.component';

@NgModule({
  entryComponents: [BnBSearchComponent],
  imports: [BnBRoutingModule, SharedModule],
  declarations: [BnBDetailComponent, BnBEditComponent, BnBListComponent, BnBPage, BnBReviewEditPage, BnBReviewListPage, BnBSearchComponent]
})
export class BnBModule {}
