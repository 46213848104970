import { Injectable } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ICoinsSetting } from '@infrastructure/models/coins-setting';

@Injectable({
  providedIn: 'root'
})
export class AddCoinsPresenter {
  get coinsSetting() {
    return this.form.value as ICoinsSetting;
  }

  form = this.formBuilder.group({
    value: [0, Validators.required],
    label: ['', Validators.required]
  });

  constructor(private formBuilder: FormBuilder) {}

  reset() {
    this.form.reset();
  }

  setValue(data: ICoinsSetting) {
    this.form.setValue({
      value: +data.value || 0,
      label: data.label || ''
    });
  }
}
