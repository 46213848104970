export enum MessageType {
  CATCHUP_NOTE = 'catchup-note',
  CATCHUP_REPLY = 'catchup-reply',
  CHIT_CHAT = 'chit-chat',
  COMMENT = 'comment', //media comment
  GAMES_CHAT = 'games-chat',
  GAMES_REPLY = 'games-reply',
  REPLY = 'reply',
  THREAD = 'thread'
}
