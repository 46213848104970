import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { EventType } from '@infrastructure/constants/event-type';
import { IBaseEvent } from '@infrastructure/models/base-event';
import { ConstantsService } from '@shared/services/constants.service';
import { GroupService } from '@shared/services/groups/group.service';
import { Observable } from 'rxjs';

@Component({
  selector: 'next-catchup',
  templateUrl: './next-catchup.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NextCatchupComponent implements OnInit {
  branding: string;
  catchups$: Observable<IBaseEvent[]> | null;
  @Input() groupId: string = '';
  @Input() groupName: string = '';
  @Input() hideIfEmpty: boolean;
  @Output() linkClicked = new EventEmitter();
  @Input() type: EventType;

  constructor(private constantsService: ConstantsService, private groupService: GroupService) {}

  ngOnInit() {
    this.branding = this.constantsService.constants.CATCHUPS.branding;

    const recordsToFetch = 1;
    const shared = true;
    switch (this.type) {
      case EventType.CatchUp:
        this.catchups$ = this.groupService.getGroupCatchups(this.groupId, recordsToFetch, shared);
        break;

      case EventType.Social:
        this.catchups$ = this.groupService.getGroupSocials(this.groupId, recordsToFetch);
        break;

      case EventType.Trip:
        this.catchups$ = this.groupService.getGroupTrips(this.groupId, recordsToFetch);
        break;

      default:
        break;
    }
  }

  onClick(title: string) {
    this.linkClicked.emit(title);
  }
}
