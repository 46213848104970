import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AdminRole } from '@shared/constants/admin-role';
import { IBnBListing } from '@shared/models/bnb/bnb-listing';
import { IBnBReview } from '@shared/models/bnb/bnb-review';
import { UserObject } from '@shared/models/user-object';
import { AuthService } from '@shared/services/auth.service';
import { BnBService } from '@shared/services/bnb/bnb.service';
import { ConstantsService } from '@shared/services/constants.service';
import { Observable } from 'rxjs';
import { first, tap } from 'rxjs/operators';
import { BnBReviewEditPresenter } from './bnb-review-edit.presenter';

@Component({
  selector: 'app-bnb-review-edit',
  templateUrl: './bnb-review-edit.page.html',
  viewProviders: [BnBReviewEditPresenter]
})
export class BnBReviewEditPage implements OnInit {
  bnbListing: IBnBListing;
  bnbListing$: Observable<IBnBListing>;
  bnbReview: IBnBReview = {
    approved: false,
    content: '',
    dateTime: 0,
    listingId: '',
    memberId: '',
    memberName: ''
  };
  listingId: string;
  updateButtonText: string = 'Submit';

  get CONSTANTS() {
    return this.constantsService.constants.BNB.REVIEW;
  }

  get form() {
    return this.bnbReviewEditPresenter.form;
  }

  constructor(private authService: AuthService, private bnbReviewEditPresenter: BnBReviewEditPresenter, private bnbService: BnBService, private constantsService: ConstantsService, private route: ActivatedRoute, private router: Router) {}

  ngOnInit() {
    this.listingId = this.route.snapshot.paramMap.get('listingId');
    const reviewId = this.route.snapshot.paramMap.get('reviewId');

    this.bnbListing$ = this.bnbService.getBnBListing(this.listingId).pipe(
      tap(bnbListing => {
        this.bnbListing = bnbListing; // save for including in email to support
      })
    );
    this.authService._userProfileSubject.pipe(first(x => x != null)).subscribe(member => {
      // creating a new listing
      if ('new' === reviewId) {
        this.initialiseListing(member);
        return;
      }

      this.bnbService
        .getBnBReview(this.listingId, reviewId)
        .pipe(first())
        .subscribe((bnbReview: IBnBReview) => {
          // Shouldn't happen; if it does, create a new listing
          if (bnbReview == null) {
            this.initialiseListing(member);
            return;
          }

          this.bnbReview = bnbReview;
          this.bnbReviewEditPresenter.setValue(this.bnbReview);
          this.updateButtonText = 'Update';
        });
    });
  }

  onCancel() {
    this.router.navigate(['../../'], { relativeTo: this.route });
  }

  updateBnBReview() {
    const formValue = this.bnbReviewEditPresenter.value();
    Object.assign(this.bnbReview, formValue);
    this.bnbService.updateBnBReview(this.bnbReview, this.bnbListing);
  }

  private initialiseListing(member: UserObject) {
    this.bnbReview.listingId = this.listingId;
    this.bnbReview.memberId = member.uid;
    this.bnbReview.memberName = member.displayName;
  }
}
