import { Component, EventEmitter, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { AdminRole } from '@shared/constants/admin-role';
import { IGroup } from '@shared/models/groups/group';
import { GroupType } from '@shared/constants/group-type';
import { IMapState } from '@shared/models/map-state';
import { IPlace } from '@shared/models/place';
import { UserObject } from '@shared/models/user-object';
import { AnalyticsAction, AnalyticsCategory, AnalyticsService } from '@shared/services/analytics';
import { AuthService } from '@shared/services/auth.service';
import { CatchupService } from '@shared/services/catchups/catchup.service';
import { ConstantsService } from '@shared/services/constants.service';
import { GroupService } from '@shared/services/groups/group.service';
import { LocationService } from '@shared/services/location/location.service';
import { SubscriptionService } from '@shared/services/subscription.service';
import { ToastService } from '@shared/services/toast.service';
import { UIService } from '@shared/services/ui.service';
import * as L from 'leaflet';
import { BehaviorSubject, Subscription } from 'rxjs';
import { skipWhile, take } from 'rxjs/operators';
import { GroupSearchComponent } from './components/group-search/group-search.component';
import { IGroupList } from './models/group-list';
import { IGroupSearchModel } from './models/group-search-model';

@Component({
  selector: 'app-groups',
  styleUrls: ['./groups.page.scss'],
  templateUrl: './groups.page.html',
  viewProviders: [GroupSearchComponent]
})
export class GroupsPage implements OnInit, OnDestroy {
  allGroups: IGroup[] = [];
  allowOtherCountries: boolean;
  allPlaces: IPlace[] = [];
  analyticsCategory: AnalyticsCategory = AnalyticsCategory.GROUPS;
  canCreateGroup: boolean = false;
  CONSTANTS: any;
  currentView: string = 'map-view';
  groupList: IGroupList[] = [];
  groups$: BehaviorSubject<IGroup[]> = new BehaviorSubject<IGroup[]>([]);
  private groupsSubscription: Subscription;
  groupTypes: any;
  isDesktop: boolean = false;
  isDoingSearch: boolean = false;
  isListView: boolean = false;
  isMapView: boolean = true;
  isMineView: boolean = false;
  isSearchView: boolean = false;
  @ViewChild('locator', { static: false }) locator: any;
  myGroups: IGroup[] = [];
  noGroupsMessage: string = '';
  placeholders: string[] = [];
  places$: BehaviorSubject<IPlace[]> = new BehaviorSubject<IPlace[]>([]);
  private placesSubscription: Subscription;
  private platformSubscription: Subscription;
  searchModel: IGroupSearchModel = {} as IGroupSearchModel;
  showPlaceholders: boolean = true;

  constructor(
    private analyticsService: AnalyticsService,
    private authService: AuthService,
    private catchupService: CatchupService,
    private groupService: GroupService,
    private constantsService: ConstantsService,
    private locationService: LocationService,
    private subscriptionService: SubscriptionService,
    private toastService: ToastService,
    private uiService: UIService
  ) {}

  ngOnInit() {
    this.allowOtherCountries = this.constantsService.constants.APP.allowOtherCountries;
    this.CONSTANTS = this.constantsService.constants.GROUPS.LIST;
    this.groupTypes = this.constantsService.constants.GROUPS.GROUP_TYPES;

    this.platformSubscription = this.uiService.isLargeDisplay$.subscribe(isLargeDisplay => {
      this.isDesktop = isLargeDisplay;
      if (this.isDesktop) {
        this.isSearchView = true;
      } else {
        this.isSearchView = this.currentView === 'search-view';
      }

      // if changing from small to large and you were on the search view, change to the list view
      // two-way binding triggers onChangeView
      if (isLargeDisplay && this.currentView === 'search-view') {
        this.currentView = 'list-view';
      }
    });
    this.subscriptionService.add(this.platformSubscription);
    // if default currentView changes, need to update this event too
    this.analyticsService.eventTrack(AnalyticsCategory.GROUPS, AnalyticsAction.GROUPS_VIEW_MAP);
    this.init();
  }

  ngOnDestroy() {
    this.subscriptionService.clearSubscription(this.groupsSubscription);
    this.subscriptionService.clearSubscription(this.placesSubscription);
    this.subscriptionService.clearSubscription(this.platformSubscription);
  }

  init() {
    this.authService._userProfileSubject
      .pipe(
        skipWhile(x => x == null),
        take(1)
      )
      .subscribe((profile: UserObject) => {
        this.placeholders = profile != null ? profile.catchupGroupIds : [];
        this.loadGroups(profile);
        this.loadPlaces(this.allowOtherCountries || this.groupService.isAdmin(), profile.country);
      });
  }

  onChangeView(event: any) {
    switch (event.detail.value) {
      case 'list-view':
        this.isListView = true;
        this.isMapView = false;
        this.isMineView = false;
        this.isSearchView = this.isDesktop;
        this.analyticsService.eventTrack(AnalyticsCategory.GROUPS, AnalyticsAction.GROUPS_VIEW_LIST);
        break;

      case 'mine-view':
        this.isListView = false;
        this.isMapView = false;
        this.isMineView = true;
        this.isSearchView = this.isDesktop;
        this.analyticsService.eventTrack(AnalyticsCategory.GROUPS, AnalyticsAction.GROUPS_VIEW_MINE);
        break;

      case 'search-view':
        this.isListView = false;
        this.isMapView = false;
        this.isMineView = false;
        this.isSearchView = true;
        break;

      case 'map-view':
      default:
        this.isListView = false;
        this.isMapView = true;
        this.isMineView = false;
        this.isSearchView = this.isDesktop;
        this.analyticsService.eventTrack(AnalyticsCategory.GROUPS, AnalyticsAction.GROUPS_VIEW_MAP);
        break;
    }
  }

  onMapBoundsChange(state: IMapState) {
    if (this.places$.value != null && (this.isNullSearch(this.searchModel) || this.searchModel.missingLocation !== true)) {
      const placesOnMap = this.places$.value.filter(place => state.mapBounds.contains([place.coordinates.latitude, place.coordinates.longitude]));

      let groupsOnMap: IGroup[];
      if (placesOnMap.length === 0) {
        groupsOnMap = this.groups$.value.filter(group => group.groupType !== GroupType.GROUP);
      } else {
        const placeIdsOnMap = placesOnMap.map(x => x.uid);
        groupsOnMap = this.groups$.value.filter(group => Object.keys(group.locations || {}).some(place => placeIdsOnMap.includes(place)) || group.groupType !== GroupType.GROUP);
      }
      this.sortGroupsByType(groupsOnMap);
    }
  }

  onSearch(searchModel: IGroupSearchModel) {
    this.searchModel = searchModel;
    //Close search panel on mobile, and show list or map depending on search
    if (searchModel.virtualGroups === true) {
      this.currentView = 'list-view'; // Virtual groups don't have a location, so show a list
    } else if (this.isNullSearch(searchModel)) {
      this.currentView = this.isDesktop ? 'mine-view' : 'search-view';
    } else {
      this.currentView = !this.isNullOrEmpty(searchModel.placeId) ? 'map-view' : 'list-view';
    }

    if (this.isNullSearch(searchModel)) {
      //Should only get here by clicking the reset button, show all groups and zoom map to fit
      this.resetData();
    } else {
      //Because we load all groups/places at the start, we can just filter on them for a search
      let groupResults: IGroup[] = this.allGroups;
      if (searchModel.virtualGroups === true) {
        groupResults = groupResults.filter(group => group.groupType !== GroupType.GROUP); // TODO Put this back to type === Group when we add a search for Travel groups only
      }
      if (searchModel.missingLocation === true) {
        groupResults = groupResults.filter(group => group.groupType === GroupType.GROUP && Object.keys(group.locations || {}).length === 0);
      }
      if (!this.isNullOrEmpty(searchModel.groupName)) {
        groupResults = groupResults.filter(group => group.name && group.name.toLowerCase().includes(searchModel.groupName));
      }

      if (groupResults.length === this.allGroups.length) {
        // no groups were filtered out
        this.resetData();
      } else if (searchModel.missingLocation === true || searchModel.virtualGroups === true) {
        this.groups$.next(groupResults);
        this.places$.next([]);
        this.sortGroupsByType(groupResults); // Need to update the list manually because there is no update to the map
      } else {
        const placeIdsOnMap = groupResults.map(x => Object.keys(x.locations || {})).flat();
        const placesOnMap = this.allPlaces.filter(place => placeIdsOnMap.includes(place.uid));

        // Update list (if search results are only virtual then updating the map won't trigger an update of the list)
        this.sortGroupsByType(groupResults);

        // Update map
        this.groups$.next(groupResults);
        this.places$.next(placesOnMap);
        this.locator.fitToData();
      }
      if (searchModel.placeId != null && searchModel.placeId !== '') {
        //TODO Clear subscription
        //lookup coordinates for placename
        this.locationService.getPlace(searchModel.placeId).subscribe((location: IPlace) => {
          if (location == null || !location.coordinates) {
            this.toastService.presentToast('Sorry, that location is invalid.');
            return;
          }
          //Need to convert from Firestore geoPoint to Leaflet LatLng
          this.locator.map.setView([location.coordinates.latitude, location.coordinates.longitude], location.zoomTo);
          //Moving the map triggers this.onMapBoundsChange which updates the list of groups to show only those on the map
        });
      }
    }
  }

  private isNullOrEmpty(query: string) {
    return query == null || query.trim().length === 0;
  }

  private isNullSearch(searchModel: IGroupSearchModel) {
    if (Object.keys(searchModel).length === 0) return true;
    return Object.values(searchModel).filter(x => x).length === 0;
  }

  private loadGroups(profile: UserObject) {
    if (profile == null) return;
    this.groupsSubscription = this.groupService.getGroups().subscribe(results => {
      if (results == null) return;
      this.allGroups = results;
      this.sortGroupsByType(results);
      this.groups$.next(results);
      this.showPlaceholders = false;

      const myGroupIds = profile.catchupGroupIds || [];
      this.myGroups = this.allGroups.filter(group => myGroupIds.includes(group.uid));
      this.canCreateGroup = this.authService.isAdmin([AdminRole.SUPER]);
    });
    this.subscriptionService.add(this.groupsSubscription);
  }

  private loadPlaces(showAllCountries: boolean, country: string) {
    this.placesSubscription = this.locationService.getPlacesWithGroups(showAllCountries, country).subscribe(results => {
      if (results == null) return;
      this.allPlaces = results;
      this.places$.next(results);
      this.locator.fitToData();
    });
    this.subscriptionService.add(this.placesSubscription);
  }

  private resetData() {
    this.groups$.next(this.allGroups);
    this.places$.next(this.allPlaces);
    this.locator.fitToData();
  }

  private sortGroupsByType(input: IGroup[]) {
    this.groupTypes
      .slice()
      .reverse()
      .filter(x => !x.hidden)
      .forEach(item => {
        const heading = item.name;
        const groupType = item.groupType;
        const groups = input.filter(g => g.groupType === groupType).sort((a: IGroup, b: IGroup) => a.name.localeCompare(b.name));
        let noGroupsMessage = this.CONSTANTS.noGroupsMessage[groupType] || '';
        if (this.searchModel.virtualGroups === true && groupType !== GroupType.SPECIAL_INTEREST_GROUP) {
          noGroupsMessage = '';
        }

        const index = this.groupList.findIndex(g => g.heading === heading);
        if (index === -1) {
          // populate groups for the first time
          this.groupList.push({ heading, groups, noGroupsMessage });
        } else {
          // update groups page when Search has changed.
          this.groupList[index].groups = groups;
          this.groupList[index].noGroupsMessage = noGroupsMessage;
        }
      });
  }
}
