import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';

@Component({
  selector: 'chirpy-bar-chart',
  styleUrls: ['./chirpy-bar-chart.component.scss'],
  templateUrl: './chirpy-bar-chart.component.html'
})
export class ChirpyBarChartComponent implements OnChanges {
  @Input() keys: Array<number | string>;
  @Input() data: Record<number | string, number>;
  maxValue: number = 1;
  @Input() noDataMessage: string = 'No data';

  ngOnChanges(changes: SimpleChanges) {
    if (changes.data) {
      const values: number[] = Object.values(changes.data.currentValue);
      this.maxValue = Math.max(...values, 1); // prevent divide by zero error
    }
  }
}
