import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AdminRole } from '@shared/constants/admin-role';
import { Align } from '@shared/constants/align';
import { ISocial } from '@shared/models/social/social';
import { AnalyticsAction, AnalyticsCategory, AnalyticsService } from '@shared/services/analytics';
import { AuthService } from '@shared/services/auth.service';
import { ConstantsService } from '@shared/services/constants.service';
import { GroupService } from '@shared/services/groups/group.service';
import { SocialService } from '@shared/services/social/social.service';
import { Observable } from 'rxjs';
import { first, switchMap } from 'rxjs/operators';

@Component({
  selector: 'app-group-socials',
  templateUrl: './group-socials.page.html'
})
export class GroupSocialsPage implements OnInit {
  align: Align = Align.CENTER;
  cantCreateSocials$: Observable<boolean>;
  CONSTANTS: Record<string, string>;
  groupId: string;
  groupName: string;
  HAS_MAP: boolean;
  heading: string;
  MAX_RECORDS: number = 9999;
  socials$: Observable<ISocial[]>;

  constructor(private route: ActivatedRoute, private authService: AuthService, private constantsService: ConstantsService, private groupService: GroupService, private socialService: SocialService) {}

  ngOnInit() {
    this.cantCreateSocials$ = this.authService.isCohostOrHostOrAdmin$();

    this.CONSTANTS = this.constantsService.constants.GROUPS.SOCIALS;
    this.HAS_MAP = this.constantsService.constants.SOCIAL.hasMap;
    this.heading = this.CONSTANTS.heading;

    this.groupId = this.route.snapshot.paramMap.get('id');
    const useCache = true;
    this.groupService
      .getGroup(this.groupId, useCache)
      .pipe(first(x => !!x))
      .subscribe(group => {
        if (group.name) this.heading += ` for ${group.name}`;
      });

    const showDrafts = true;
    this.socials$ = this.authService.isAdmin$([AdminRole.SUPPORT]).pipe(
      first(), // can't do first(x => !!x) because isAdmin$ returns a boolean
      switchMap(
        isAdmin => this.groupService.getGroupSocials(this.groupId, this.MAX_RECORDS, showDrafts),
        (isAdmin, socials) => {
          return isAdmin ? socials : socials.filter(x => x.approved || x.memberId === this.authService._userProfileSubject.value.uid);
        }
      )
    );
  }
}
