import { TitleCasePipe } from '@angular/common';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AlertController } from '@ionic/angular';
import { AdminRole } from '@shared/constants/admin-role';
import { Country } from '@shared/constants/country';
import { GameType } from '@shared/constants/game-type';
import { MessageType } from '@shared/constants/message-type';
import { IThread } from '@shared/models/messages/thread';
import { AnalyticsAction, AnalyticsCategory, AnalyticsService } from '@shared/services/analytics';
import { AuthService } from '@shared/services/auth.service';
import { ConstantsService } from '@shared/services/constants.service';
import { EmailService } from '@shared/services/email/email.service';
import { MessageService } from '@shared/services/messages/message.service';
import { SubscriptionService } from '@shared/services/subscription.service';
import { Observable, of, Subscription } from 'rxjs';

@Component({
  selector: 'app-games-chat',
  templateUrl: './chat.page.html',
  styleUrls: ['./chat.page.scss'],
  providers: [TitleCasePipe]
})
export class ChatPage implements OnInit, OnDestroy {
  get canSendMessage() {
    return !this.authService.isImpersonating && !this.isChatLocked;
  }

  get title() {
    return `${this.gameType} Chat`;
  }

  canManageChat: boolean = false;
  chatName: string = '';
  CONSTANTS: Record<string, string>;
  gameType: GameType;
  isChatLocked: boolean = false;
  lockedReason$: Observable<string>;
  instructions$: Observable<string>;
  messageType: MessageType = MessageType.GAMES_CHAT;
  ref: Subscription;
  thread: IThread;
  threadId: string;

  constructor(
    private alertController: AlertController,
    private analyticsService: AnalyticsService,
    private authService: AuthService,
    private constantsService: ConstantsService,
    private emailService: EmailService,
    private messageService: MessageService,
    private route: ActivatedRoute,
    private subscriptionService: SubscriptionService,
    private titleCasePipe: TitleCasePipe
  ) {}

  ionViewWillEnter() {
    this.analyticsService.eventTrack(AnalyticsCategory.GAMES, AnalyticsAction.GAMES_VIEW_CHAT, this.gameType);
  }

  ngOnDestroy() {
    this.subscriptionService.clearSubscription(this.ref);
  }

  ngOnInit() {
    this.CONSTANTS = this.constantsService.constants.GAMES.CHAT;

    this.authService._userProfileSubject.subscribe(profile => {
      this.canManageChat = this.authService.isAdmin([AdminRole.SUPPORT]);
    });

    this.route.paramMap.subscribe(params => {
      this.gameType = params.get('id') as GameType;
      if (this.gameType == null) {
        return;
      }

      this.chatName = this.titleCasePipe.transform(`${this.gameType} Chat`);
      this.threadId = `games_${this.gameType}`;

      if (this.ref) this.ref.unsubscribe();
      this.ref = this.messageService.getThread(this.threadId).subscribe(thread => {
        this.instructions$ = of(thread.notice || '');
        this.isChatLocked = !!thread.isLocked;
        this.lockedReason$ = of(thread.lockedReason || '');
        this.thread = thread;
      });
      this.subscriptionService.add(this.ref);
    });
  }

  async onEditNotice() {
    const alert = await this.alertController.create({
      cssClass: 'wide-select',
      header: `Update notice`,
      message: 'Enter notice text below. HTML tags e.g. &lt;b&gt; are permitted.',
      inputs: [
        {
          name: 'notice',
          placeholder: 'Enter text here...',
          type: 'text',
          value: this.thread.notice
        }
      ],
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
          cssClass: 'secondary'
        },
        {
          text: 'OK',
          handler: data => {
            this.messageService.updateThread(this.threadId, { notice: data.notice });
          }
        }
      ]
    });

    await alert.present();
  }

  async onLockChat() {
    const alert = await this.alertController.create({
      header: `Lock ${this.chatName}`,
      message: 'Are you sure you want to lock this chat? All members will be unable to post or reply.',
      inputs: [
        {
          name: 'reason',
          placeholder: 'Reason for locking chat',
          type: 'text'
        }
      ],
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
          cssClass: 'secondary'
        },
        {
          text: 'OK',
          handler: data => {
            this.lockChatHandler(data.reason);
          }
        }
      ]
    });

    await alert.present();
  }

  onSendMessage(messageData, threadId: string) {
    const messageType = MessageType.GAMES_CHAT;
    const threadType = null;
    const emailData = { chatName: this.chatName, gameType: this.gameType };
    this.messageService.createMessage(messageData, threadId, messageType, threadType, emailData);
  }

  async onUnlockChat() {
    const alert = await this.alertController.create({
      header: `Unlock ${this.chatName}`,
      message: 'Are you sure you want to unlock this group? Members will be able to resume posting.',
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
          cssClass: 'secondary'
        },
        {
          text: 'OK',
          handler: _ => this.unlockChatHandler()
        }
      ]
    });

    await alert.present();
  }

  private async lockChatHandler(reason?: string): Promise<void> {
    const currentUser = this.authService._userProfileSubject.value;

    const data = {
      isLocked: true,
      lockedReason: reason
    };

    await this.messageService.updateThread(this.threadId, data);
    this.emailService.sendChatLockedByAdminNotification(this.gameType, this.chatName, currentUser.fullName, reason);
  }

  private async unlockChatHandler(): Promise<void> {
    const data = {
      isLocked: false,
      lockedReason: null
    };

    await this.messageService.updateThread(this.threadId, data);
  }
}
