import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CoinsTransactionType } from '@shared/constants/coins-transaction-type';
import { ICoinsSetting } from '@infrastructure/models/coins-setting';
import { ICoinsTransaction } from '@shared/models/coins/coins-transaction';
import { UserObject } from '@shared/models/user-object';
import { AppOptionsService } from '@shared/services/app-options/app-options.service';
import { AuthService } from '@shared/services/auth.service';
import { CoinsService } from '@shared/services/coins/coins.service';
import { DateTimeService } from '@shared/services/date-time.service';
import { ToastService } from '@shared/services/toast.service';
import { UserService } from '@shared/services/user/user.service';
import { Observable } from 'rxjs';
import { first, switchMap } from 'rxjs/operators';
import { AddCoinsPresenter } from './add-coins.presenter';

@Component({
  selector: 'add-coins',
  templateUrl: './add-coins.component.html'
})
export class AddCoinsComponent implements OnInit {
  get form() {
    return this.presenter.form;
  }

  appOptionsUid: string;
  balance: number = 0;
  hasChosenOption: boolean = false;
  interfaceOptions: any = { cssClass: 'wide-select' };
  isUpdating: boolean = false;
  manualEntry: ICoinsSetting = { label: '', value: 0 };
  @Input() memberId: string;
  @Input() memberName: string;
  options$: Observable<ICoinsSetting[]>;
  placeholder: string = '';
  selectedOption: ICoinsSetting;
  @Input() type: CoinsTransactionType;

  constructor(
    private appOptionsService: AppOptionsService,
    private authService: AuthService,
    private coinsService: CoinsService,
    private dateTimeService: DateTimeService,
    private presenter: AddCoinsPresenter,
    private router: Router,
    private toastService: ToastService,
    private userService: UserService
  ) {}

  ngOnInit() {
    if (this.type === CoinsTransactionType.ADD) {
      this.appOptionsUid = 'coinsGivenFor';
      this.placeholder = 'This member has earned coins for...';
    } else if (this.type === CoinsTransactionType.REDEEM) {
      this.appOptionsUid = 'coinsRedeemedFor';
      this.placeholder = 'This member is redeeming coins for...';
    }

    this.options$ = this.userService.getUserProfile(this.memberId).pipe(
      first(x => x != null),
      switchMap((member: UserObject) => {
        this.balance = member.coinsBalance; // Admins get centralMembersPrivate data in a user profile
        const values: Observable<ICoinsSetting[]> = this.appOptionsService.getOptionsValues<ICoinsSetting>(this.appOptionsUid, member.country);
        return values;
      })
    );
  }

  onChange(event: any) {
    this.hasChosenOption = true;
    this.presenter.setValue(event.detail.value);
  }

  onUpdate() {
    if (this.isUpdating) return;
    this.isUpdating = true;
    let message = '';

    const formValue = this.presenter.coinsSetting;
    if (!this.validateForm(formValue)) {
      message = `Please enter a reason and/or non-zero amount`;
      this.toastService.presentToast(message);
      this.isUpdating = false;
      return;
    }

    if (this.memberId === this.authService.memberId) {
      message = `You can't give yourself coins`;
      this.resetPage(message);
      this.router.navigate(['/admin/add-remove-coins']);
      return;
    }

    if (this.type === CoinsTransactionType.REDEEM && this.balance < formValue.value) {
      message = `${this.memberName} does not have enough coins for ${formValue.label}`;
      this.toastService.presentToast(message);
      this.isUpdating = false;
      return;
    }

    const coinsTransaction: ICoinsTransaction = {
      amount: 0,
      date: this.dateTimeService.getDateTime(),
      memberId: this.memberId,
      memberName: this.memberName,
      description: '',
      staffId: this.authService.member.uid,
      staffName: this.authService.member.fullName,
      type: this.type
    };
    // merge form values with transaction
    Object.assign(coinsTransaction, { amount: this.type === CoinsTransactionType.REDEEM ? -formValue.value : formValue.value, description: formValue.label });
    this.coinsService
      .addCoinsTransaction(coinsTransaction)
      .then(result => {
        message = `${Math.abs(coinsTransaction.amount)} coins ${this.type}ed for ${this.memberName}.`;
      })
      .catch(err => {
        message = `Couldn't ${this.type} coins for ${this.memberName}: ${err}`;
      })
      .finally(() => {
        this.resetPage(message);
      });
  }

  private resetPage(message: string) {
    this.toastService.presentToast(message);
    this.selectedOption = null;
    this.presenter.reset();
    this.isUpdating = false;
  }

  private validateForm(data: any) {
    let isValid = true;
    if (data.label == null || data.label.trim().length === 0) isValid = false;
    if (data.value == null || data.value === 0) isValid = false;
    return isValid;
  }
}
