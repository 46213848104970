type OnTickFunction = () => void;

export class PausableTimer {
  private get now() {
    return new Date().getTime();
  }

  private intervalId: ReturnType<typeof setInterval>;
  private paused: boolean;
  private remaining: number;
  private startTime: number;
  private tickCount: number;
  private timeoutId: ReturnType<typeof setTimeout>;

  constructor(private intervalInMs: number, private onTick: OnTickFunction) {
    this.tickCount = 0;
    this.paused = true;
  }

  configure(options: { intervalInMs: number }): this {
    this.intervalInMs = options.intervalInMs;

    return this;
  }

  pause() {
    if (this.paused) {
      return;
    }

    this.paused = true;

    this.clearTimers();

    const elapsedMs = this.now - this.startTime;
    this.remaining = (this.remaining || this.intervalInMs) - elapsedMs;
  }

  resume() {
    if (!this.paused) {
      return;
    }

    this.paused = false;
    this.startTime = this.now;

    if (this.remaining) {
      this.timeoutId = setTimeout(() => {
        this.tick();

        this.startLoop();
      }, this.remaining);
    } else {
      this.startLoop();
    }
  }

  stop() {
    this.remaining = 0;
    this.startTime = 0;
    this.tickCount = 0;

    this.clearTimers();
  }

  private clearTimers() {
    if (this.intervalId) {
      clearInterval(this.intervalId);
    }

    if (this.timeoutId) {
      clearTimeout(this.timeoutId);
    }
  }

  private startLoop() {
    this.clearTimers();

    // Ignore delay on first tick
    if (this.tickCount === 0) {
      this.tick();
    }

    this.intervalId = setInterval(() => {
      this.tick();
    }, this.intervalInMs);
  }

  private tick() {
    this.startTime = this.now;
    this.remaining = 0;
    this.tickCount++;

    this.onTick();
  }
}
