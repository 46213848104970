export enum MembershipType {
  // Members paying us an annual subscription (at whatever rate)
  ANNUAL = "annual",
  // Hosts and other special cases where members have been granted free membership
  FREE = "free",
  // Members paying us a monthly subscription (at whatever rate)
  MONTHLY = "monthly",
  // Members paying us a monthly subscription (at whatever rate)
  SIX_MONTHLY = "six-monthly"
}
