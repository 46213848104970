type WithRetriesOptions = {
  onError?: (err: Error) => void;
  onFailure?: () => void;
}

export function withRetries<T>(numAttempts: number, fn: () => T, { onError, onFailure }: WithRetriesOptions = {}) {
  let attemptsRemaining = numAttempts;

  while (--attemptsRemaining > 0) {
    try {
      return fn();
    }
    catch (err) {
      if (onError) {
        onError(err)
      };
    }
  }

  if (onFailure) {
    onFailure();
  }
}