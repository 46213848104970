import { Injectable } from '@angular/core';
import { ALL_COUNTRIES, CountryKey } from '@shared/constants/country';
import { AuthService } from '@shared/services/auth.service';
import { AppOptionsService } from '@shared/services/app-options/app-options.service';
import { Observable, of } from 'rxjs';
import { first, switchMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class OrganisationService {
  organisations: string[];

  constructor(private appOptionsService: AppOptionsService, private authService: AuthService) {
    this.init();
  }

  init(): void {
    this.authService._userProfileSubject
      .pipe(
        first(x => !!x),
        switchMap(member => {
          const country = this.authService.isAdmin() ? ALL_COUNTRIES : member.country;
          return this.appOptionsService.getOptionsValues<string>('organisations', country);
        }),
        first(x => !!x)
      )
      .subscribe((organisations: string[]) => {
        this.organisations = organisations || [];
      });
  }

  search(search: string): Observable<string[]> {
    if (search == null || search.length === 0) return of(this.organisations);
    return of(this.organisations.filter(i => i.toLowerCase().indexOf(search.toLowerCase()) > -1));
  }
}
