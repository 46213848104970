import { formatDate } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CatchupRsvpStatus } from '@shared/constants/catchup-rsvp-status';
import { ICatchup } from '@shared/models/catchups/catchup';
import { IBaseEvent } from '@infrastructure/models/base-event';
import { AuthService } from '@shared/services/auth.service';
import { BaseEventService } from '@shared/services/base.event.service';
import { Observable } from 'rxjs';
import { first, map } from 'rxjs/operators';

@Component({
  selector: 'events-box',
  templateUrl: './events-box.component.html'
})
export class EventsBoxComponent implements OnInit {
  events$: Observable<IBaseEvent[]>;
  eventDates$: Observable<string[]>;
  @Input() isClaimCoinsEnabled: boolean = true;
  @Input() link: string;
  @Output() linkClicked = new EventEmitter();
  @Input() placeholder: string;
  userId: string;

  constructor(private authService: AuthService, private baseEventService: BaseEventService) {}

  ngOnInit() {
    this.authService._userProfileSubject.pipe(first(x => x != null)).subscribe(member => {
      this.userId = member.uid;
      // This component shows upcoming catchups. If you've attended and claimed on the same day, then you don't want to see it
      // i.e. don't retrieve catchups with CatchupRsvpStatus.GOING_CLAIMED
      this.events$ = this.baseEventService.getEventsForMember(member.uid).pipe(
        map(catchups => {
          const today = formatDate(Date.now(), 'yyyy-MM-dd', 'en-US'); // en-US is the only locale supported by default
          return catchups.filter(x => x.date >= today);
        })
      );
      this.eventDates$ = this.events$.pipe(map((events: IBaseEvent[]) => events.map(x => x.date)));
    });
  }

  onClick(title: string) {
    this.linkClicked.emit(title);
  }
}
