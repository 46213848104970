import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { UserObject } from '@shared/models/user-object';
import { AuthService } from '@shared/services/auth.service';
import { UserDatabase } from '@shared/services/user/user.database';
import { Observable } from 'rxjs';
import { first, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class OnboardingGuard implements CanActivate {
  constructor(private authService: AuthService, private router: Router, private userDatabase: UserDatabase) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<UrlTree> {
    return this.authService.userProfileObservable.pipe(
      first(x => !!x),
      map((member: UserObject) => {
        //Unset isOnboarding flag in centralMembersPrivate
        if (member != null && member.uid != null && member.isOnboarding === true) this.userDatabase.updatePrivateMemberData(member.uid, { isOnboarding: false });
        const newUrl = route.url.pop().path.replace('pages-', '/pages/'); // redirect from signup/pages-whats-next to /pages/whats-next
        return this.router.parseUrl(newUrl);
      })
    );
  }
}
