import { Component, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'chirple-how-to-play',
  templateUrl: './how-to-play.component.html',
  styleUrls: ['./how-to-play.component.scss']
})
export class ChirpleHowToPlayComponent {
  @Input() continueText = 'Got it';

  constructor(private modalController: ModalController) {}

  onContinueButtonClicked() {
    this.modalController.dismiss();
  }
}
