import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AlertController, LoadingController, ModalController, Platform } from '@ionic/angular';
import { range } from '@shared/functions/range';
import { shuffle } from '@shared/functions/shuffle';
import { AnalyticsService, AnalyticsCategory, AnalyticsAction } from '@shared/services/analytics';
import { AuthService } from '@shared/services/auth.service';
import { Observable, Subject, timer, combineLatest, BehaviorSubject } from 'rxjs';
import { bufferTime, filter, first, map, repeatWhen, scan, skipWhile, takeUntil, takeWhile, tap } from 'rxjs/operators';
import { IBingoRoundResult } from '../models/bingo-round-result';
import { BingoWinType } from '../models/bingo-win-type';
import { GameStatus } from '../models/game-status';
import { BingoService } from '../services/bingo.service';
import { BingoPauseMenuComponent } from './components/pause-menu/pause-menu.component';
import { AnimationType } from './models/animation-type';
import { BingoGameLoopEvent } from './models/game-loop-event';
import { IBingoGameState } from './models/game-state';
import { INumberDaubedEvent } from './models/number-daubed-event';
import { PausableTimer } from './models/pausable-timer';
import { IPauseMenuDismissedAction } from './models/pause-menu-dismissed-event';
import { IRoundOverEvent } from './models/round-over-event';
import { IPreferencesUpdatedEvent } from './models/user-preferences-updated-event';
import { IBingoGameViewModel } from './models/view-model';

@Component({
  selector: 'app-bingo-game',
  templateUrl: 'game.page.html',
  styleUrls: ['game.page.scss']
})
export class GamePage implements OnInit, OnDestroy {
  get CONSTANTS() {
    return {
      countdownDurationInSeconds: 5,
      defaultState: {
        calledNumbers: [],
        tickets: [],
        loopEvent: BingoGameLoopEvent.GAME_OVER,
        loading: true,
        countdownRunning: false,
        gamePaused: true
      } as IBingoGameState,
      ballsPerGame: 70,
      roundsPerGame: 6
    } as const;
  }

  get currentTicket() {
    return this.state.game ? this.state.tickets[this.state.game.currentRound] : undefined;
  }

  get gameId() {
    return this.state.game && this.state.game.uid;
  }

  get isLastRound() {
    if (!this.state.game) {
      return false;
    }

    return (this.state.game.currentRound || 0) === this.CONSTANTS.roundsPerGame - 1;
  }

  countdownTimer$: Observable<number>;

  emojis = {
    PARTYING_FACE: '\u{1f973}',
    TADA: '\u{1f389}'
  };

  readonly LoopEvent = BingoGameLoopEvent;

  vm$: Observable<IBingoGameViewModel>;
  private cancelCountdown = new Subject();
  private gameTimer: PausableTimer;
  private onDestroy = new Subject();
  private showNoBingo = new BehaviorSubject(false);
  private startCountdown = new Subject();

  private state: IBingoGameState = this.CONSTANTS.defaultState;
  private state$: Observable<IBingoGameState>;
  private stateUpdates = new Subject<Partial<IBingoGameState>>();

  constructor(
    private analyticsService: AnalyticsService,
    private bingoService: BingoService,
    public platform: Platform,
    private route: ActivatedRoute,
    private router: Router,
    public authService: AuthService,
    private modalController: ModalController,
    public alertController: AlertController,
    public loadingController: LoadingController
  ) {}

  ionViewDidEnter() {
    if (this.state.loopEvent === BingoGameLoopEvent.GAME_IN_PROGRESS) {
      if (this.gameTimer && !this.state.countdownRunning) {
        this.gameTimer.resume();
      }
    }
  }

  ionViewWillEnter() {
    if (this.state.loopEvent === BingoGameLoopEvent.GAME_IN_PROGRESS && this.state.countdownRunning) {
      this.startCountdown.next();
    }
  }

  ionViewWillLeave() {
    this.cancelCountdown.next();

    if (this.gameTimer) {
      this.gameTimer.pause();
    }
  }

  ngOnDestroy() {
    this.onDestroy.next();
    this.onDestroy.complete();

    this.cancelCountdown.next();
    this.cancelCountdown.complete();

    if (this.gameTimer) {
      this.gameTimer.stop();
    }
  }

  ngOnInit() {
    this.route.paramMap.pipe(first()).subscribe(params => {
      const gameId = params.get('gameId');

      if (gameId == null) {
        this.navigateBack();
      }

      this.loadGameData(gameId);
    });

    this.initCountdownTimer();
    this.initState();
    this.initViewModel();

    this.startCountdown.pipe(takeUntil(this.onDestroy)).subscribe(_ => {
      this.stateUpdates.next({ countdownRunning: true });
    });
  }

  onBackClicked() {
    this.navigateBack();
  }

  onBingoClicked() {
    if (this.state.loopEvent !== BingoGameLoopEvent.GAME_IN_PROGRESS) {
      return;
    }

    this.analyticsService.eventTrack(AnalyticsCategory.BINGO, AnalyticsAction.BINGO_CLICK_BINGO, this.gameId);
    this.gameTimer.pause();

    // Investigate this claim
    const bingo = this.checkForBingo();

    // Nice try...
    if (bingo === BingoWinType.NO_BINGO) {
      this.showNoBingo.next(true);

      return;
    }

    // Congratulations!
    this.onRoundOver({ bingo });
  }

  onExitClicked() {
    this.navigateBack();
  }

  onNoBingoAnimationEnd(animationType: AnimationType) {
    if (animationType === 'exit') {
      this.showNoBingo.next(false);
      this.gameTimer.resume();
    }
  }

  onNumberDaubed(e: INumberDaubedEvent) {
    const columnsPerRow = 9;

    if (!this.state.game || this.state.game.currentRound !== e.ticketId) {
      return;
    }

    const ticket = this.state.tickets[e.ticketId];
    const rowNumber = Math.floor(e.index / columnsPerRow);
    const index = e.index - rowNumber * columnsPerRow;
    const cell = ticket.rows[rowNumber][index];

    if (this.canDaubCell()) {
      cell.daubed = !cell.daubed;
    }
  }

  onPauseClicked() {
    this.analyticsService.eventTrack(AnalyticsCategory.BINGO, AnalyticsAction.BINGO_PAUSE_GAME, this.gameId);

    this.gameTimer.pause();
    this.stateUpdates.next({ gamePaused: true });

    this.showPauseMenu();
  }

  async onStartNewGameClicked() {
    const newGame = await this.bingoService.createNewGame();
    this.analyticsService.eventTrack(AnalyticsCategory.BINGO, AnalyticsAction.BINGO_START_GAME, newGame.uid);

    this.router.navigate(['../', newGame.uid], { relativeTo: this.route });
  }

  onStartNextRoundClicked() {
    this.state.game.currentRound++;
    this.stateUpdates.next({ loopEvent: BingoGameLoopEvent.GAME_IN_PROGRESS });
    this.startCountdown.next();

    this.analyticsService.eventTrack(AnalyticsCategory.BINGO, AnalyticsAction.BINGO_START_ROUND, this.gameId, null, this.state.game.currentRound);
  }

  private *calledNumbersGenerator(count = this.CONSTANTS.ballsPerGame) {
    const numbers = shuffle(range(1, 90));

    for (let i = 0; i < count; i++) {
      yield numbers[i];
    }
  }

  private canDaubCell(): boolean {
    if (this.state.game.status !== GameStatus.PLAYING) {
      return false;
    }

    if (this.state.loopEvent !== BingoGameLoopEvent.GAME_IN_PROGRESS) {
      return false;
    }

    if (this.state.gamePaused) {
      return false;
    }

    return true;
  }

  private checkForBingo(): BingoWinType {
    const ticket = this.currentTicket;

    if (!ticket) {
      return BingoWinType.NO_BINGO;
    }

    const rowsFilled = this.currentTicket.rows.filter(row => {
      return row.filter(cell => cell.value).every(cell => cell.daubed && this.state.calledNumbers.includes(cell.value));
    }).length;

    // The number of rows filled directly correlates to the `BingoWinType` enum value
    return Math.min(rowsFilled, BingoWinType.FULL_HOUSE);
  }

  private getSubtitle(state: IBingoGameState) {
    if (state.countdownRunning) {
      return 'Starts in...';
    }

    const ballsRemaining = Math.max(this.CONSTANTS.ballsPerGame - state.calledNumbers.length);

    switch (ballsRemaining) {
      case 0:
        return 'Last ball!';
      case 1:
        return '1 ball remaining';
      default:
        return `${ballsRemaining} balls remaining`;
    }
  }

  private initCountdownTimer() {
    this.countdownTimer$ = timer(0, 1000).pipe(
      takeUntil(this.cancelCountdown),
      map(n => this.CONSTANTS.countdownDurationInSeconds - n),
      takeWhile(n => n > 0, true),
      // We don't want to use finalize here as that will cause the
      // round to start whenever the stream is completed (e.g. when cancelling the countdown on navigation)
      tap(n => {
        if (n === 0) {
          this.startRound();
        }
      }),
      repeatWhen(() => this.startCountdown)
    );
  }

  private initGameTimer() {
    const numbersGenerator = this.calledNumbersGenerator();

    const interval = this.state.userPreferences.ballDelayInMs;

    this.gameTimer = new PausableTimer(interval, () => {
      const next = numbersGenerator.next();

      if (next.done) {
        // Give the player more of a chance to click bingo if it's the last ball
        setTimeout(() => {
          this.onRoundOver({ bingo: BingoWinType.NO_BINGO });
        }, interval / 2);
      } else {
        this.stateUpdates.next({
          calledNumbers: [...this.state.calledNumbers, next.value]
        });
      }
    });
  }

  private initState() {
    this.state$ = this.stateUpdates.pipe(
      filter(update => !!update),
      bufferTime(200),
      filter(updates => updates.length > 0),
      map(updates => {
        return updates.reduce((state, nextUpdate) => Object.assign({}, state, nextUpdate), {});
      }),
      scan((acc, value) => Object.assign({}, acc, value), this.CONSTANTS.defaultState) // Merge with previous state object
    );

    this.state$
      .pipe(
        skipWhile(state => !state),
        takeUntil(this.onDestroy)
      )
      .subscribe(next => {
        this.state = next;
      });
  }

  private initViewModel() {
    this.vm$ = combineLatest([this.state$, this.showNoBingo]).pipe(
      map(([state, showNoBingo]) => {
        const { calledNumbers, game, result, tickets, loopEvent } = state;
        const didBingo = !!(result && result.bingo);

        return {
          ...state,
          ticket: !!game && tickets[game.currentRound],
          calledNumbers,
          currentBall: calledNumbers.slice(-1)[0],
          countdown$: this.countdownTimer$,
          infoPane: {
            title: this.isLastRound ? 'Final Round' : `Round ${game.currentRound + 1}`,
            subtitle: this.getSubtitle(state),
            showCountdown: state.countdownRunning,
            showNoBingo
          },
          showFireworks: (loopEvent === BingoGameLoopEvent.ROUND_OVER && didBingo) || loopEvent === BingoGameLoopEvent.GAME_OVER
        };
      })
    );
  }

  private loadGameData(gameId: string) {
    const game$ = this.bingoService.getGame(gameId).pipe(first());
    const userPreferences$ = this.bingoService.getUserPreferences().pipe(first());

    combineLatest([game$, userPreferences$]).subscribe(([game, userPreferences]) => {
      this.stateUpdates.next({
        game,
        userPreferences,
        loading: false
      });

      if (game.status === GameStatus.FINISHED) {
        this.stateUpdates.next({
          loopEvent: BingoGameLoopEvent.GAME_OVER
        });
      } else if (game.status === GameStatus.PLAYING) {
        const tickets = this.bingoService.generateTickets(game);

        this.stateUpdates.next({
          loopEvent: BingoGameLoopEvent.GAME_IN_PROGRESS,
          tickets
        });

        this.startCountdown.next();
      }
    });
  }

  private navigateBack() {
    this.analyticsService.eventTrack(AnalyticsCategory.BINGO, AnalyticsAction.BINGO_LEFT, this.gameId, { type: this.state.loopEvent });

    this.router.navigate(['/games/bingo/start']);
  }

  private onPauseMenuDismissed(action: IPauseMenuDismissedAction) {
    // Modal was dismissed by keyboard or clicking backdrop.
    // Assume the player just wants to resume the game.
    if (!action || !action.data) {
      return this.resumeRound();
    }

    if (action.data) {
      this.updateUserPreferences({ updatedPreferences: action.data });
    }

    if (action.type === 'resume') {
      this.resumeRound();
    } else if (action.type === 'restart') {
      this.restartRound();
    }
  }

  private onRoundOver(event: IRoundOverEvent) {
    this.analyticsService.eventTrack(AnalyticsCategory.BINGO, AnalyticsAction.BINGO_GAME_OVER, this.gameId, { type: event.bingo }, this.state.game.currentRound);

    this.gameTimer.stop();

    const result: IBingoRoundResult = {
      called: this.state.calledNumbers,
      ticket: this.currentTicket,
      bingo: event.bingo
    };

    const round = this.bingoService.generateRoundFromResult(result);
    this.bingoService.storeRoundResult(this.gameId, round);
    this.state.game.rounds.push(round);

    if (this.isLastRound) {
      this.stateUpdates.next({ loopEvent: BingoGameLoopEvent.GAME_OVER });

      this.bingoService.endGame(this.gameId);
      this.bingoService.updateLeaderboard(this.state.game);
    } else {
      this.analyticsService.eventTrack(AnalyticsCategory.BINGO, AnalyticsAction.BINGO_ROUND_OVER, this.gameId, { type: event.bingo }, this.state.game.currentRound);

      this.stateUpdates.next({ loopEvent: BingoGameLoopEvent.ROUND_OVER });
      this.bingoService.incrementRound(this.gameId);
    }

    this.stateUpdates.next({ result: event, calledNumbers: [] });
  }

  private restartRound() {
    this.initGameTimer();

    this.currentTicket.rows.forEach(row => [row.forEach(cell => (cell.daubed = false))]);

    this.stateUpdates.next({
      calledNumbers: []
    });

    this.startCountdown.next();
  }

  private resumeRound() {
    this.gameTimer.resume();
    this.stateUpdates.next({ gamePaused: false });
  }

  private async showPauseMenu() {
    const modal = await this.modalController.create({
      component: BingoPauseMenuComponent,
      componentProps: {
        settings: this.state.userPreferences,
        gameId: this.gameId
      }
    });

    modal.onDidDismiss().then(({ data }) => {
      this.onPauseMenuDismissed(data);
    });

    await modal.present();
  }

  private startRound() {
    this.stateUpdates.next({ loopEvent: BingoGameLoopEvent.GAME_IN_PROGRESS, gamePaused: false, countdownRunning: false });

    this.initGameTimer();
    this.gameTimer.resume();
  }

  private updateUserPreferences({ updatedPreferences }: IPreferencesUpdatedEvent) {
    this.state.userPreferences = { ...this.state.userPreferences, ...updatedPreferences };

    if (updatedPreferences.ballDelayInMs) {
      this.gameTimer.configure({ intervalInMs: updatedPreferences.ballDelayInMs });
    }

    this.bingoService.updateUserPreferences(updatedPreferences);
  }
}
