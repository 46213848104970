import { Injectable } from '@angular/core';
import { IOrderCondition } from '@shared/models/order-condition';
import { IWhereCondition } from '@shared/models/where-condition';
import { IShareListing } from '@shared/models/share/share-listing';
import { BaseDatabase } from '@shared/services/base.database';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ShareDatabase extends BaseDatabase {
  createShareListing(listing: IShareListing) {
    return this.createDocument(this.COLLECTION.SHARE_LISTINGS, listing);
  }

  deleteShareListing(uid: string) {
    return this.deleteDocument(this.COLLECTION.SHARE_LISTINGS, uid);
  }

  getMemberShareListings(memberId: string, recordsToFetch: number) {
    const queryFn = this.getMemberShareListingsQuery(memberId, recordsToFetch);
    return this.getDocumentsByQuery<IShareListing>(this.COLLECTION.SHARE_LISTINGS, queryFn);
  }

  getShareListing(uid: string) {
    return this.getDocument<IShareListing>(this.COLLECTION.SHARE_LISTINGS, uid);
  }

  getShareListings(country: string, isAdmin: boolean, recordsToFetch: number, hideUnapproved: boolean = false) {
    const queryFn = this.getShareListingQuery(recordsToFetch, isAdmin, country, hideUnapproved);
    return this.getDocumentsByQuery<IShareListing>(this.COLLECTION.SHARE_LISTINGS, queryFn);
  }

  getShareListingsForApproval(): Observable<IShareListing[]> {
    const whereConditions: IWhereCondition[] = [
      {
        field: 'approved',
        operator: '==',
        value: false
      }
    ];

    const orderConditions: IOrderCondition[] = [{ field: 'created', direction: 'desc' }];

    const queryFn = this.createQueryFunction(whereConditions, orderConditions, 999);
    return this.getDocumentsByQuery<IShareListing>(this.COLLECTION.SHARE_LISTINGS, queryFn);
  }

  updateMemberProfile(memberId: string, data) {
    return this.updateDocument(this.COLLECTION.CENTRAL_MEMBERS, memberId, data);
  }

  updatePrivateMemberData(memberId: string, data) {
    return this.updateDocument(this.COLLECTION.CENTRAL_MEMBERS_PRIVATE, memberId, data);
  }

  updateShareListing(shareListing: any, merge: boolean = true) {
    return this.updateDocument(this.COLLECTION.SHARE_LISTINGS, shareListing.uid, shareListing, merge);
  }

  private getMemberShareListingsQuery(memberId: string, recordsToFetch: number) {
    const whereConditions: IWhereCondition[] = [{ field: 'memberId', operator: '==', value: memberId }];
    const orderConditions: IOrderCondition[] = [{ field: 'created', direction: 'desc' }];
    return this.createQueryFunction(whereConditions, orderConditions, recordsToFetch);
  }

  private getShareListingQuery(recordsToFetch: number, isAdmin: boolean, country: string, hideUnapproved: boolean) {
    const whereConditions: IWhereCondition[] = [];
    const orderConditions: IOrderCondition[] = [];

    if (!isAdmin) {
      whereConditions.push({ field: 'published', operator: '==', value: true });
      if (hideUnapproved) whereConditions.push({ field: 'approved', operator: '==', value: true }); // NB: This prevents you seeing your own unapproved listings. It's only enabled for the Share box on My Chirpy
      // Searching by country can be enabled per environment. If it is, don't restrict by country here
      if (country) {
        whereConditions.push({
          field: 'country',
          operator: '==',
          value: country
        });
      }
    } else {
      orderConditions.push({ field: 'approved', direction: 'asc' });
    }

    orderConditions.push({ field: 'created', direction: 'desc' });

    return this.createQueryFunction(whereConditions, orderConditions, recordsToFetch);
  }
}
