import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MediaCollectionType } from '@shared/constants/media-collection-type';
import { AnalyticsAction, AnalyticsCategory, AnalyticsService } from '@shared/services/analytics';
import { ConstantsService } from '@shared/services/constants.service';
import { GroupService } from '@shared/services/groups/group.service';
import { Observable } from 'rxjs';
import { first, map } from 'rxjs/operators';

@Component({
  selector: 'app-group-photo-upload',
  templateUrl: './group-photo-upload.page.html'
})
export class GroupPhotoUploadPage implements OnInit {
  canMakePublic$: Observable<boolean>;
  canUploadPhotos$: Observable<boolean>;
  collectionId: string;
  collectionTitle: string;
  collectionType: MediaCollectionType = MediaCollectionType.GROUP;

  constructor(private analyticsService: AnalyticsService, private constantsService: ConstantsService, private groupService: GroupService, private route: ActivatedRoute) {}

  ngOnInit() {
    this.collectionId = this.route.snapshot.paramMap.get('id');
    this.collectionTitle = this.route.snapshot.paramMap.get('title');
    if (this.collectionTitle) this.collectionTitle = this.collectionTitle.replace(/(%20|\+)/g, ' ');

    this.canMakePublic$ = this.groupService.canMakeMediaPublic(this.collectionId);
    // For the moment each group has one collection. If we allow multiple collections, needs to use different way of specifying who has admin privileges
    this.canUploadPhotos$ = this.groupService.canManageGroup$(this.collectionId);

    this.analyticsService.eventTrack(AnalyticsCategory.PIX, AnalyticsAction.PIX_VIEW_UPLOAD_PAGE, this.collectionId, { type: this.collectionTitle });
  }
}
