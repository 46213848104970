import { Injectable, OnDestroy } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { ChirpyItemSearchComponent } from '@shared/components/chirpy-select-items/chirpy-item-search/chirpy-item-search.component';
import { IGroup } from '@shared/models/groups/group';
import { IMemberThreadRelatedType } from '@shared/models/messages/member-thread';
import { UserObject } from '@shared/models/user-object';
import { AnalyticsAction, AnalyticsCategory, AnalyticsService } from '@shared/services/analytics';
import { AuthService } from '@shared/services/auth.service';
import { GroupDatabase } from '@shared/services/groups/group.database';
import { GroupService } from '@shared/services/groups/group.service';
import { SubscriptionService } from '@shared/services/subscription.service';
import { ToastService } from '@shared/services/toast.service';
import { UserDatabase } from '@shared/services/user/user.database';
import { UserService } from '@shared/services/user/user.service';
import { Subject, Subscription } from 'rxjs';
import { first, takeUntil } from 'rxjs/operators';

@Injectable()
export class GroupMemberListService {
  groupId: string;
  groupMembers: UserObject[] = [];
  groupName: string = '';
  hasMembers: boolean;
  memberCount: number;
  membersSubscription: Subscription;
  showLoadingMembersSpinner: boolean;
  stopUpdating$ = new Subject();

  constructor(
    private analyticsService: AnalyticsService,
    private authService: AuthService,
    private groupDatabase: GroupDatabase,
    private groupService: GroupService,
    private userDatabase: UserDatabase,
    private userService: UserService,
    private modalController: ModalController,
    private subscriptionService: SubscriptionService,
    private toastService: ToastService
  ) {}

  getGroupMembers(memberCount: number) {
    this.memberCount = memberCount;
    this.hasMembers = memberCount > 0;
    this.showLoadingMembersSpinner = this.hasMembers;

    this.subscriptionService.clearSubscription(this.membersSubscription);
    this.membersSubscription = this.groupService
      .getGroupMembers(this.groupId)
      .pipe(takeUntil(this.stopUpdating$))
      .subscribe(groupMembers => {
        if (groupMembers == null) return;

        this.groupMembers = groupMembers.sort((a, b) => {
          // Sort blank display names to the end
          if (a.displayName == '') return 1;
          if (b.displayName == '') return -1;
          return a.displayName.localeCompare(b.displayName);
        });
        this.showLoadingMembersSpinner = false;

        if (this.groupMembers.length === memberCount) this.stopUpdating$.next();
      });
    this.subscriptionService.add(this.membersSubscription);
  }

  initGroupDetails(uid: string) {
    const subject$ = new Subject<IGroup>();

    this.groupDatabase
      .getGroup(uid)
      .pipe(first())
      .subscribe(group => {
        if (group == null) return;
        group.hosts = group.hosts || {};

        this.groupId = group.uid;
        this.groupName = group.name;
        this.analyticsService.eventTrack(AnalyticsCategory.GROUPS, AnalyticsAction.GROUPS_VIEW_MEMBERS, this.groupName);
        subject$.next(group); // update the group name on the page while members populate.
      });

    return subject$;
  }

  ngOnDestroy() {
    this.subscriptionService.clearSubscription(this.membersSubscription);
  }

  async onAddMemberToGroup(member: Record<string, string>) {
    const isGroupMember = this.groupMembers[member.key] != null;
    if (isGroupMember) return;

    const result = await this.groupService.addMemberToGroup(this.groupId, this.groupName, this.memberCount, member.key, member.value);
    // Add member to the UI
    if (result === true) {
      this.groupMembers[member.key] = member.value;
      this.analyticsService.eventTrack(AnalyticsCategory.GROUPS, AnalyticsAction.GROUPS_ADD_MEMBER, this.groupName);
    }
  }

  async onRemoveMemberFromGroup(member: UserObject) {
    const isGroupMember = this.groupMembers.some(g => g.uid === member.uid);
    if (!isGroupMember) return;

    const handler = () => {
      if (this.groupMembers.some(g => g.uid === member.uid)) delete this.groupMembers[member.uid]; // Remove member from the UI
    };
    await this.groupService.removeMemberFromGroup(this.groupId, this.groupName, member.uid, member.displayName, handler.bind(this));
    this.analyticsService.eventTrack(AnalyticsCategory.GROUPS, AnalyticsAction.GROUPS_REMOVE_MEMBER, this.groupName);
  }

  async presentChooseMembersModal() {
    const modal = await this.modalController.create({
      component: ChirpyItemSearchComponent,
      cssClass: 'chirpy-item-search__modal',
      componentProps: {
        addItem: this.onAddMemberToGroup.bind(this),
        canRemoveItems: false,
        label: 'Add member to the group',
        noResultsText: '',
        memberType: IMemberThreadRelatedType.Member,
        placeholder: 'Add a member to the group...',
        removeItem: this.onRemoveMemberFromGroup.bind(this),
        search: this.searchMembers.bind(this),
        selectedItems: this.groupMembers
      }
    });

    return modal.present();
  }

  recalculateMemberCount() {
    if (!this.groupId) return;

    this.groupService
      .getGroupMembers(this.groupId)
      .pipe(first())
      .subscribe(members => {
        const memberCount = members.length;
        this.groupDatabase.updateGroup(this.groupId, { memberCount });
        this.memberCount = memberCount;
        this.toastService.presentToast(`Member count is now ${memberCount}`);
        this.analyticsService.eventTrack(AnalyticsCategory.GROUPS, AnalyticsAction.GROUPS_RECOUNT_MEMBERS, this.groupName);
      });
  }

  searchMembers(startWith: string) {
    return this.groupDatabase.searchMembers(startWith, this.authService._userProfileSubject.value.country);
  }
}
