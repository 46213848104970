import { Component } from '@angular/core';
import { BaseAnimatableComponent } from '../base-animatable/base-animatable.component';
import { AnimationType } from '../../models/animation-type';

@Component({
  selector: 'no-bingo-indicator',
  templateUrl: './no-bingo-indicator.component.html',
  styleUrls: ['./no-bingo-indicator.component.scss']
})
export class NoBingoIndicatorComponent extends BaseAnimatableComponent {
  width = 64;
  height = 64;
  crossWidth = 7;
  offset = this.crossWidth * Math.sin(this.toRadians(45)) * 2;
  strokeWidth = 3;

  path: string = '';

  ngOnInit() {
    super.ngOnInit();

    this.animationEndEvent$.subscribe(([_, animationType]) => {
      if (animationType === 'entry') {
        this.animateIn.next(false);
        this.animateOut.next(true);
      }

      if (animationType === 'exit' && !this.animateOut.isStopped) {
        this.animateOut.next(false);
      }
    });

    this.path = this.generatePath();

    this.animateIn.next(true);
  }

  getAnimationType({ animationName }: AnimationEvent): AnimationType {
    if (animationName === 'shake') {
      return 'entry';
    }

    if (animationName === 'fade-out') {
      return 'exit';
    }
  }

  // Apparently the compiler doesn't like interpolating numbers in [attr.d] on the path element
  // so either we add .toString() to everything or do this. This seems the lesser of two evils.
  private generatePath() {
    const middleX = this.width / 2;
    const middleY = this.height / 2;

    return `
M${this.offset} ${this.strokeWidth}
L${middleX} ${middleY - this.offset + this.strokeWidth}
L${this.width - this.offset} ${this.strokeWidth}
L${this.width - this.strokeWidth} ${this.offset}
L${middleX + this.offset - this.strokeWidth} ${middleY}
L${this.width - this.strokeWidth} ${this.height - this.offset}
L${this.width - this.offset} ${this.height - this.strokeWidth}
L${middleX} ${middleY + this.offset - this.strokeWidth}
L${this.offset} ${this.height - this.strokeWidth}
L${this.strokeWidth} ${this.height - this.offset}
L${middleX - this.offset + this.strokeWidth} ${middleY}
L${this.strokeWidth} ${this.offset}
Z
`;
  }

  private toRadians(degrees: number) {
    return (degrees * Math.PI) / 180;
  }
}
