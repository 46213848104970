import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { EventType } from '@infrastructure/constants/event-type';
import { AdminRole } from '@shared/constants/admin-role';
import { SelectType } from '@shared/constants/select-type';
import { IMapState } from '@shared/models/map-state';
import { ISelectOption } from '@shared/models/select-option';
import { ISocial } from '@shared/models/social/social.ts';
import { IValueWithId } from '@shared/models/value-with-id';
import { IPlace } from '@shared/models/place';
import { UserObject } from '@shared/models/user-object';
import { AnalyticsAction, AnalyticsCategory, AnalyticsService } from '@shared/services/analytics';
import { AuthService } from '@shared/services/auth.service';
import { ConstantsService } from '@shared/services/constants.service';
import { DateTimeService } from '@shared/services/date-time.service';
import { EnforceProfileService } from '@shared/services/enforce-profile.service';
import { GroupService } from '@shared/services/groups/group.service';
import { LocationService } from '@shared/services/location/location.service';
import { SocialService } from '@shared/services/social/social.service';
import { SubscriptionService } from '@shared/services/subscription.service';
import { ToastService } from '@shared/services/toast.service';
import { UIService } from '@shared/services/ui.service';
import { UserService } from '@shared/services/user/user.service';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { first, map, skipWhile, take } from 'rxjs/operators';
import { SocialLocatorComponent } from '../components/social-locator-map/social-locator.component';
import { SocialEditPresenter } from './social-edit.presenter';

@Component({
  selector: 'app-social-edit',
  templateUrl: './social-edit.page.html',
  styleUrls: ['./social-edit.page.scss'],
  viewProviders: [SocialLocatorComponent]
})
export class SocialEditPage implements OnDestroy, OnInit {
  get form() {
    return this.socialEditPresenter.form;
  }

  get isEdit() {
    return this.social != null && this.social.uid != null;
  }

  get searchCountry() {
    const allowOtherCountries = this.constantsService.constants.APP.allowOtherCountries;
    if (allowOtherCountries) return '';
    return this.member && !this.authService.isAdmin() ? this.member.country : '';
  }

  get title() {
    return this.isEdit || !!this.errorMessage ? this.CONSTANTS.updatePageHeading : this.CONSTANTS.createPageHeading;
  }

  get updateButtonText() {
    return this.isEdit ? this.CONSTANTS.updateButtonText : this.CONSTANTS.createButtonText;
  }

  CONSTANTS: Record<string, string | boolean | Object>;
  DISCLAIMER: string;
  errorMessage: string;
  HAS_MAP: boolean;
  oldSocial: ISocial; // for identifying changes
  ONLY_IN_GROUP: boolean;
  social: ISocial;
  socialSubscription: Subscription;
  social$: BehaviorSubject<ISocial[]> = new BehaviorSubject<ISocial[]>(null);
  initialLocation: string = null;
  isSmallMap: boolean = true;
  @ViewChild('locator', { static: false }) locator: any;
  MAX_DATE: string;
  placeSubscription: Subscription;
  places$: BehaviorSubject<IPlace[]> = new BehaviorSubject<IPlace[]>(null);
  selectType: string = SelectType.PLACE;
  selectedItems: Record<string, string>;
  sharedGroups: Record<string, string> = {};
  private member: UserObject;

  constructor(
    private analyticsService: AnalyticsService,
    private authService: AuthService,
    private constantsService: ConstantsService,
    private dateTimeService: DateTimeService,
    private enforceProfileService: EnforceProfileService,
    private groupService: GroupService,
    private locationService: LocationService,
    private route: ActivatedRoute,
    private router: Router,
    private socialEditPresenter: SocialEditPresenter,
    private socialService: SocialService,
    private subscriptionService: SubscriptionService,
    private toastService: ToastService,
    private uiService: UIService,
    private userService: UserService
  ) {}

  getNumberOfRows() {
    return this.uiService.getNumberOfTextAreaRows();
  }

  ionViewWillEnter() {
    this.enforceProfileService.enforce();
    this.loadSocial();
  }

  loadSocial() {
    const uid = this.route.snapshot.paramMap.get('id');
    const groupId = this.route.snapshot.paramMap.get('groupId');

    this.authService._userProfileSubject
      .pipe(
        skipWhile(u => !u),
        take(1)
      )
      .subscribe(member => {
        this.member = member;

        if (uid === null || uid === 'new') {
          if (this.authService.isHost() || this.authService.isCohost()) {
            this.errorMessage = this.constantsService.constants.SOCIAL.PAGE.cantCreateMessage;
            return;
          }

          this.social = {
            address: '',
            approved: false,
            eventType: EventType.Social,
            coordinates: null,
            country: this.member.country,
            created: 0,
            date: '', // Human-readable date yyyy-mm-dd
            datetime: 0,
            description: '',
            endTime: '',
            interested: {},
            locationId: null,
            locationName: null,
            memberId: this.member.uid,
            memberName: `${this.member.displayName} (${this.member.firstName})`,
            sharedGroupIds: [],
            sharedGroupNames: [],
            time: '',
            title: '',
            uid: null
          } as ISocial;

          this.oldSocial = Object.assign({}, this.social);
          this.socialEditPresenter.setValue(this.social);

          if (groupId) {
            const useCache = true;
            this.groupService
              .getGroup(groupId, useCache)
              .pipe(
                first(x => !!x),
                map(group => group.name)
              )
              .subscribe(groupName => {
                this.social.sharedGroupIds = [groupId];
                this.social.sharedGroupNames = [groupName];
                this.sharedGroups[groupId] = groupName;

                this.oldSocial = Object.assign({}, this.social);
                this.socialEditPresenter.setValue(this.social);
              });
          }

          return;
        }

        this.socialSubscription = this.socialService.getSocial(uid).subscribe((social: ISocial) => {
          // Doesn't exist, you're creating a new Social.
          if (social == null) return;

          // Shouldn't be able to get here if you are not the owner, but check permissions anyway
          const canEdit = social.memberId === this.member.uid || this.authService.isAdmin();
          if (!canEdit) {
            this.errorMessage = `You don't have permission to update this Social.`;
            return;
          }

          this.social = social;
          this.oldSocial = Object.assign({}, social);
          this.initialLocation = social.locationId || null; // Save this so we can correctly unset flags if the location is updated

          // location can be empty if the social has been updated but the location is not set
          if (!this.isNullOrEmpty(social.locationId) && !this.isNullOrEmpty(social.locationName)) {
            this.selectedItems = { [social.locationId]: social.locationName };
            if (this.HAS_MAP) this.updateMapInputs(this.social);
          }

          if ((this.social.sharedGroupIds || []).length > 0) {
            this.social.sharedGroupIds.forEach((id, index) => {
              this.sharedGroups[id] = this.social.sharedGroupNames[index];
            });
          }

          this.socialEditPresenter.setValue(this.social);
        });
        this.subscriptionService.add(this.socialSubscription);
      });
  }

  ngOnDestroy() {
    this.subscriptionService.clearSubscription(this.socialSubscription);
    this.subscriptionService.clearSubscription(this.placeSubscription);
  }

  ngOnInit() {
    this.CONSTANTS = this.constantsService.constants.SOCIAL.EDIT;
    this.DISCLAIMER = this.constantsService.constants.SOCIAL.disclaimer;
    this.HAS_MAP = this.constantsService.constants.SOCIAL.hasMap;
    this.MAX_DATE = this.dateTimeService.MAX_DATE;
    this.ONLY_IN_GROUP = this.constantsService.constants.SOCIAL.onlyInGroup;
  }

  onAddGroup(item: Record<string, string>) {
    this.sharedGroups = this.sharedGroups || {};
    this.sharedGroups[item.key] = item.value;

    const names = Object.values(this.sharedGroups);
    this.socialEditPresenter.form.controls.sharedGroups.setValue(names);
  }

  onAddPlace(item: IValueWithId) {
    this.selectedItems = { [item.uid]: item.name };
    this.social.locationId = item.uid;
    this.social.locationName = item.name;
    this.socialEditPresenter.patchValue({
      locationId: this.social.locationId,
      locationName: this.social.locationName
    });
    this.updateMapInputs(this.social);
  }

  onRemoveGroup(item: Record<string, string>) {
    delete this.sharedGroups[item.key];
    const names = Object.values(this.sharedGroups);
    this.socialEditPresenter.form.controls.sharedGroups.setValue(names);
  }

  onMapBoundsChange(state: IMapState) {
    // TODO: Show other places on map?
  }

  searchGroups(startsWith: string = '') {
    return this.groupService.searchGroups(startsWith, this.searchCountry);
  }

  searchPlaces(startsWith: string) {
    return this.locationService.search(startsWith, this.member.country, this.authService.isAdmin([AdminRole.HOSTS]));
  }

  // Create a new social or Update existing social, depending whether social.uid is null.
  updateSocial() {
    // merge form values with non-form editable social values.
    const formValue = this.socialEditPresenter.social();
    Object.assign(this.social, formValue);

    // Explicitly set locationId to null rather than empty string when it is not used, to match logic elsewhere
    if (this.isNullOrEmpty(this.social.locationId)) this.social.locationId = null;
    if (this.isNullOrEmpty(this.social.locationName)) this.social.locationName = null;

    // Split shared group IDs and names into separate fields so we can query DB on IDs
    this.social.sharedGroupIds = Object.keys(this.sharedGroups);
    this.social.sharedGroupNames = Object.values(this.sharedGroups);

    this.socialService.updateSocial(this.social, this.oldSocial).then(() => {
      this.updateLocationFlag();
    });
  }

  private isNullOrEmpty(query: string) {
    return query == null || query.trim().length === 0;
  }

  private updateMapInputs(social: ISocial) {
    this.social$.next([social]);
    this.subscriptionService.clearSubscription(this.placeSubscription);

    if (!this.isNullOrEmpty(this.social.locationId)) {
      this.placeSubscription = this.locationService.getPlace(social.locationId).subscribe(place => {
        if (place == null) return;

        this.social.coordinates = place.coordinates;
        const locationName = place.displayName;
        this.social.locationName = locationName; // This gives the full name, including state/region
        this.social$.next([social]);
        this.socialEditPresenter.patchValue({ coordinates: place.coordinates, locationName: locationName });
        // Sometimes the map isn't loaded when we want to set the view. If so, wait a second
        const timeout = this.locator ? 0 : 1000;
        setTimeout(() => {
          this.locator.map.setView([place.coordinates.latitude, place.coordinates.longitude], place.zoomTo);
          Object.assign(place, { uid: social.locationId });
          // Add a placeholder to hasSocial (if necessary) to show a non-zero count
          if (!place.hasSocial) {
            place.hasSocial = ['temp'];
          } else if (!place.hasSocial.includes(social.uid) && !place.hasSocial.includes('temp')) {
            place.hasSocial.push('temp');
          }
          this.places$.next([place as IPlace]);
        }, timeout);
      });
      this.subscriptionService.add(this.placeSubscription);
    } else {
      // Reset map to member's location
      const timeout = this.locator ? 0 : 1000;
      if (this.member.coordinates) {
        setTimeout(() => {
          this.locator.map.setView([this.member.coordinates.latitude, this.member.coordinates.longitude], this.member.zoomTo || 15);
        });
      }
    }
  }

  private updateLocationFlag() {
    // place.hasSocial gets set for the first time when the social is approved.
    // if the location is subsequently edited, we update the flags on the relevant places
    if (this.social.approved && this.social.locationId != null && this.social.locationId !== this.initialLocation) {
      this.locationService.setHasSocialFlag(this.social.locationId, this.social.uid);
      if (this.initialLocation != null) this.locationService.unsetHasSocialFlag(this.initialLocation, this.social.uid);
    }
  }
}
