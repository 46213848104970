import { Injectable } from '@angular/core';
import { LimitPeriod } from '@infrastructure/constants/limit-period';
import { Country } from '@shared/constants/country';
import { GameType } from '@shared/constants/game-type';
import { MetricType } from '@shared/constants/metric-type';
import { PlayerType } from '@shared/constants/player-type';
import { ILeaderboard, ILeaderboardData, ILeaderboardDataConfig, ILeaderboardMetric, ILeaderboardMetricConfig, ILeaderboardPlayer, ILeaderboardRow, LeaderboardMetricKey, ILeaderboardOptions } from '@shared/models/leaderboards';
import { ConstantsService } from '@shared/services/constants.service';
import { DateTimeService } from '@shared/services/date-time.service';
import { Observable } from 'rxjs';
import { first, map } from 'rxjs/operators';
import { LeaderboardDatabase } from './leaderboard.database';
import { updateMetric } from './leaderboard-metric.handler';
import { landingPageLeaderboardOptions, leaderboardOptions } from './leaderboard-options';

@Injectable({
  providedIn: 'root'
})
export class LeaderboardService {
  get CONSTANTS() {
    return this.constantsService.constants.LEADERBOARDS;
  }

  readonly DEFAULT_COMPETITION: string = 'general';

  constructor(private constantsService: ConstantsService, private dateTimeService: DateTimeService, private leaderboardDatabase: LeaderboardDatabase) {}

  getBoardsForGame(gameType: GameType): Partial<Record<LimitPeriod, string>> {
    const boards = {
      [LimitPeriod.DAY]: 'Daily Leaderboard',
      [LimitPeriod.WEEK]: 'Weekly Leaderboard',
      [LimitPeriod.MONTH]: 'Monthly Leaderboard',
      [LimitPeriod.YEAR]: 'Yearly Leaderboard'
    };

    if (gameType === GameType.BINGO) {
      delete boards[LimitPeriod.DAY];
    }

    return boards;
  }

  getLandingPageOptionsForGame(gameType: GameType): ILeaderboardOptions {
    return landingPageLeaderboardOptions[gameType];
  }

  getOptionsForGame(gameType: GameType): ILeaderboardOptions {
    return leaderboardOptions[gameType];
  }

  getLeaderboardForGame(gameType: GameType, place: string, boardId: string, competitionId: string = this.DEFAULT_COMPETITION, useCache: boolean = false): Observable<ILeaderboard> {
    return this.leaderboardDatabase.getLeaderboardForGame(gameType, competitionId, place, boardId, useCache);
  }

  getLeaderboardForPlayer(playerId: string, gameType: GameType, competitionId: string = this.DEFAULT_COMPETITION): Observable<ILeaderboardPlayer> {
    return this.leaderboardDatabase.getLeaderboardForPlayer(gameType, competitionId, playerId);
  }

  getStatisticsForPlayer(playerId: string, gameType: GameType, period: LimitPeriod = LimitPeriod.EVER, competitionId: string = this.DEFAULT_COMPETITION): Observable<ILeaderboardData> {
    return this.leaderboardDatabase.getLeaderboardForPlayer(gameType, competitionId, playerId).pipe(map(playerLeaderboard => (playerLeaderboard && playerLeaderboard.data ? playerLeaderboard.data[period] || null : null)));
  }

  sortLeaderboard(leaderboard: ILeaderboard, gameType: GameType, sortKey: LeaderboardMetricKey, rows: number, ownId: string, sortKey2: LeaderboardMetricKey = null): ILeaderboardRow[] {
    if (!leaderboard) return [];
    delete leaderboard.uid;

    // Convert playerId: [playerName, values] to ILeaderboardRow object
    const players: ILeaderboardRow[] = Object.entries(leaderboard || {}).map(x => {
      const playerName = x[1][0];
      const output: ILeaderboardRow = {
        playerId: x[0],
        playerName: playerName as string,
        playerType: PlayerType.MEMBER, // Determine correct type when we implement teams
        values: x[1].slice(1)
      };
      return output;
    });

    // Work out which field to sort on
    // get index of metric, as data are unkeyed to save space
    const metrics: ILeaderboardMetricConfig[] = this.CONSTANTS[gameType].metrics;
    const index: number = metrics.filter(x => x.public).findIndex(x => x.key === sortKey);
    if (index === -1) return players.filter((player, index) => index < rows || player.playerId === ownId);

    const metric = metrics[index];
    let index2 = -1;
    let metric2 = null;
    if (sortKey2) {
      index2 = metrics.findIndex(x => x.key === sortKey2);
      if (index2 > -1) metric2 = metrics[index2];
    }

    return players
      .sort((a, b) => (index2 > -1 ? this.typeSafeSort(a.values[index], b.values[index], metric.order, a.values[index2], b.values[index2], metric2.order) : this.typeSafeSort(a.values[index], b.values[index], metric.order)))
      .map((player, index, allPlayers) => this.assignPosition(player, index, allPlayers))
      .filter((player, index) => index < rows || player.playerId === ownId);
  }

  updateLeaderboardForPlayer(playerId: string, playerName: string, regionId: string, country: Country, gameType: GameType, data: Record<string, unknown>, competitionId: string = this.DEFAULT_COMPETITION) {
    this.getLeaderboardForPlayer(playerId, gameType, competitionId)
      .pipe(first()) // Don't check for existence, otherwise we need to set up leaderboards on account creation
      .subscribe((leaderboard: ILeaderboardPlayer) => {
        if (!leaderboard) {
          leaderboard = {
            name: playerName,
            data: {},
            previous: {}
          };
        }
        // Get day, week, etc strings for current time
        const currentDate = this.dateTimeService.getPeriod();
        const previousDate = this.dateTimeService.getPreviousPeriod();

        const config: ILeaderboardDataConfig = this.CONSTANTS[gameType] || { metrics: [], periods: [] };
        const metrics = config.metrics;
        // Loop over leaderboards for different periods (week, month, etc)
        for (const period of config.periods) {
          // Check if the leaderboard is for the current period, or it has aged off
          let periodData = leaderboard && leaderboard.data[period] ? leaderboard.data[period] : this.generateNewLeaderboardData(config, currentDate[period]);
          if (periodData.currentPeriod !== currentDate[period]) {
            if (periodData.currentPeriod === previousDate[period]) {
              // Move current data to previous period
              leaderboard.previous[period] = Object.assign({}, leaderboard.data[period]);
            } else {
              // Clear values for previous period, because it has expired
              leaderboard.previous[period] = null;
            }
            // Clear data for current period
            leaderboard.data[period] = this.generateNewLeaderboardData(config, currentDate[period]);
            periodData = leaderboard.data[period];
          }

          let leaderboardArray: Array<number | string> = [playerName];
          for (const metric of metrics) {
            if (data[metric.key] !== null) {
              // Aliases for readability
              const key = metric.key;
              const handlerData = data[key];
              const currentValue = periodData[key];
              const relatedKeyValue = metric.relatedKey ? periodData[metric.relatedKey].value : undefined;

              const updatedValue = updateMetric(metric.type, handlerData, currentValue, relatedKeyValue);
              if (metric.public) leaderboardArray.push(updatedValue.value);
              periodData[key] = updatedValue;
            } else {
              if (metric.public) leaderboardArray.push(''); // Otherwise leaderboardArray will be different lengths if certain metrics are not updated e.g. Average guesses
            }
          }
          // This approach works for documents up to ~10k players since Firebase has max doc size of 1MB.
          // When we have more than 10k members, could replace country leaderboard by summing region leaderboards
          this.leaderboardDatabase.updateLeaderboardForPlace(gameType, competitionId, `${country}_${regionId}`, currentDate[period], playerId, leaderboardArray);
          this.leaderboardDatabase.updateLeaderboardForPlace(gameType, competitionId, country, currentDate[period], playerId, leaderboardArray);

          leaderboard.data[period] = periodData;
        }

        this.leaderboardDatabase.updateLeaderboardForPlayer(gameType, competitionId, playerId, leaderboard);
      });
  }

  private assignPosition(player: ILeaderboardRow, index: number, allPlayers: ILeaderboardRow[]) {
    return Object.assign(player, { position: index + 1 });
  }

  private generateNewLeaderboardData(config: ILeaderboardDataConfig, currentPeriod: string): ILeaderboardData {
    let output: ILeaderboardData = {
      currentPeriod: currentPeriod,
      metric1: null
    };
    for (const metric of config.metrics) {
      let initialValue: ILeaderboardMetric = { value: 0 };
      switch (metric.type) {
        case MetricType.AVERAGE:
        case MetricType.PERCENTAGE:
          initialValue = {
            numerator: 0,
            denominator: 0,
            value: 0
          };
          break;

        case MetricType.DISTRIBUTION:
          initialValue = {
            distribution: {},
            value: 0
          };
          break;

        case MetricType.MIN:
          initialValue = { value: Number.POSITIVE_INFINITY };
          break;

        case MetricType.MAX:
        case MetricType.MAX_STREAK:
        case MetricType.STREAK:
        case MetricType.TOTAL:
        // use { value: 0 } as set at start of switch block
      }
      Object.assign(output, { [metric.key]: initialValue });
    }
    return output;
  }

  private typeSafeSort(a: string | number, b: string | number, order: firebase.firestore.OrderByDirection, a2: string | number = null, b2: string | number = null, order2: firebase.firestore.OrderByDirection = null) {
    if (a === b && order2) return this.typeSafeSort(a2, b2, order2);

    if (typeof a === 'string' && typeof b === 'string') {
      return order === 'asc' ? a.localeCompare(b) : b.localeCompare(a);
    } else if (typeof a === 'number' && typeof b === 'number') {
      return order === 'asc' ? a - b : b - a;
    } else {
      return 0;
    }
  }
}
