import { IconChevronLeft, IconChevronRight } from '@codexteam/icons';
import { BlockTune, API, BlockAPI } from '@editorjs/editorjs';

export enum Alignment {
  LEFT = 'left',
  NONE = 'none',
  RIGHT = 'right'
}

export type AlignmentData = { align: Alignment };

export class AlignmentTune implements BlockTune {
  static get isTune() {
    return true;
  }

  api: API;
  block: BlockAPI;
  config: Record<string, string>; // not used
  data: AlignmentData;
  wrapper: HTMLElement;

  set alignment(align: Alignment) {
    this.data = { align };

    Object.values(Alignment).forEach(alignment => {
      this.wrapper.classList.toggle(`ce-block--align-${alignment}`, alignment === this.data.align);
    });
  }

  constructor({ api, data, config, block }) {
    this.api = api;
    this.block = block;
    this.config = config;
    this.data = data || { align: Alignment.NONE };
    this.wrapper = undefined;
  }

  onActivate(align: Alignment) {
    this.alignment = this.data.align === align ? Alignment.NONE : align;
  }

  render() {
    return [
      {
        icon: IconChevronLeft,
        isActive: this.data['align'] === Alignment.LEFT,
        label: 'Align left',
        toggle: true,
        onActivate: this.onActivate.bind(this, Alignment.LEFT)
      },
      {
        icon: IconChevronRight,
        isActive: this.data['align'] === Alignment.RIGHT,
        label: 'Align right',
        toggle: true,
        onActivate: this.onActivate.bind(this, Alignment.RIGHT)
      }
    ];
  }

  save() {
    return this.data;
  }

  wrap(blockContent: any) {
    this.wrapper = document.createElement('div');
    this.alignment = this.data.align;
    this.wrapper.appendChild(blockContent);
    return this.wrapper;
  }
}
