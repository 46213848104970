import { Injectable, OnDestroy } from '@angular/core';
import { AngularFirestoreCollection } from '@angular/fire/firestore';
import { AngularFireFunctions } from '@angular/fire/functions';
import { Storage } from '@ionic/storage';
import { LimitPeriod } from '@infrastructure/constants/limit-period';
import { ILimit } from '@infrastructure/models/limit';
import { MessageType } from '@shared/constants/message-type';
import { IListingImage } from '@shared/models/image/listing-image';
import { IMemberThread, IMemberThreadRelatedType } from '@shared/models/messages/member-thread';
import { IMemberThreadType } from '@shared/models/messages/member-thread-type';
import { IMessage } from '@shared/models/messages/message';
import { IMessageData } from '@shared/models/messages/message-data';
import { IMessageOptions } from '@shared/models/messages/message-options';
import { IThread } from '@shared/models/messages/thread';
import { UserObject } from '@shared/models/user-object';
import { ActivityService, ActivityType } from '@shared/services/activity';
import { AnalyticsAction, AnalyticsCategory, AnalyticsService } from '@shared/services/analytics';
import { AuthService } from '@shared/services/auth.service';
import { ConstantsService } from '@shared/services/constants.service';
import { DateTimeService } from '@shared/services/date-time.service';
import { EmailService } from '@shared/services/email/email.service';
import { EnvironmentService } from '@shared/services/environment.service';
import { ImageService } from '@shared/services/image/image.service';
import { LimitService } from '@shared/services/limits/limit.service';
import { MessageDatabase } from '@shared/services/messages/message.database';
import { SubscriptionService } from '@shared/services/subscription.service';
import { ThreadOpenService } from '@shared/services/messages/thread-open.service';
import { NotificationService, NotificationTarget } from '@shared/services/notifications';
import { ToastService } from '@shared/services/toast.service';
import { UserService } from '@shared/services/user/user.service';
import { firestore } from 'firebase/app';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { first, map, skipWhile, take } from 'rxjs/operators';
import FieldValue = firestore.FieldValue;

@Injectable({
  providedIn: 'root'
})
export class MessageService implements OnDestroy {
  memberThreads: IMemberThread[];
  memberThreadsSubscription: Subscription;
  readonly STORAGE_KEY: string = 'last-message-sent';
  user: UserObject;
  private memberThreads$: BehaviorSubject<IMemberThread[]> = new BehaviorSubject([]);

  constructor(
    private activityService: ActivityService,
    private analyticsService: AnalyticsService,
    private authService: AuthService,
    private constantsService: ConstantsService,
    private dateTimeService: DateTimeService,
    private emailService: EmailService,
    private fns: AngularFireFunctions,
    private environmentService: EnvironmentService,
    private imageService: ImageService,
    private limitService: LimitService,
    private messageDatabase: MessageDatabase,
    private notificationService: NotificationService,
    private threadOpenService: ThreadOpenService,
    private storage: Storage,
    private subscriptionService: SubscriptionService,
    private toastService: ToastService,
    private userService: UserService
  ) {
    this.init();
  }

  addMemberToThread(threadId: string, relatedIds: string[], memberId: string, lastMessage: string, relatedType: IMemberThreadRelatedType, name: string, type: IMemberThreadType, ownerIds: string[] = []) {
    const dateTimeLastRead = 0;
    const dateTimeLastUpdated = this.dateTimeService.getDateTime();
    const isArchived = false;
    const isOwner = ownerIds && ownerIds.includes(memberId);

    const memberThread: IMemberThread = {
      threadId,
      memberId,
      lastMessage,
      dateTimeLastRead, // allows isUnread to work correctly when a memberThread is first created
      dateTimeLastUpdated,
      relatedType,
      relatedIds,
      type,
      name, // a semi-colon delimited title will be updated by thread-members.service:updateMemberThreads (when memberThread.type = IMemberThreadType.Group)
      isOwner,
      isArchived
    };

    if (type === IMemberThreadType.Group || relatedType === IMemberThreadRelatedType.Event) {
      if (name == null || name.length === 0) {
        console.error('must specify the event name when creating an event thread.');
      }
    }

    const notificationKey = type === IMemberThreadType.DirectMessage ? 'directMessage' : 'conversation';
    return Promise.all([this.notificationService.createNotificationForMember(NotificationTarget.MESSAGE, threadId, memberId, notificationKey), this.createMemberThread(memberThread)]);
  }

  archiveMemberThread(threadId: string, memberIds: string[], archiveMemberId: string) {
    // Archive the MemberThread for archiveMemberId. This retains message history if they're re-added to the Thread later.
    const archiveThreadData = {
      relatedIds: memberIds,
      isArchived: true
    };

    return this.updateMemberThread(threadId, archiveMemberId, archiveThreadData);
  }

  async canSendMessage(senderId: string, threadId: string, threadType: IMemberThreadType): Promise<boolean> {
    // Admins, hosts, cohosts aren't rate limited
    if (this.authService.isCohostOrHostOrAdmin()) {
      return true;
    }

    // Only Direct Messages are rate limited
    if (threadType !== IMemberThreadType.DirectMessage) {
      return true;
    }

    // If member has already triggered the spam filter, hold all subsequent messages until they are dealt with by support
    const hasHeldMessages = await this.hasHeldMessages(senderId);
    if (hasHeldMessages) {
      return false;
    }

    // If two members are using messages like chat, then don't want to interrupt conversation by rate-limiting them
    const lastMessageId = await this.storage.get(this.STORAGE_KEY);
    if (lastMessageId === threadId) {
      return true;
    }

    // Else check if rate limit has been exceeded for the current hour
    // Rate limit is now set in database in appOptions/limits
    const hasReachedLimit = await this.limitService.hasReachedLimit(senderId, AnalyticsAction.MESSAGES_SEND_MESSAGE);
    return !hasReachedLimit;
  }

  createMemberThread(memberThread: IMemberThread): Promise<any> {
    return this.messageDatabase.createMemberThread(memberThread);
  }

  async createMessage(messageData: IMessageData, threadId: string, messageType: MessageType = MessageType.THREAD, threadType: IMemberThreadType = null, emailData: any = {}, senderName: string = '', senderId: string = '') {
    const isAutomated = senderId !== '';
    // name and senderId are only explicitly specified for automated messages
    senderName = senderName || this.user.displayName;
    senderId = senderId || this.user.uid;

    const hasMedia: boolean = Object.keys(messageData.media || {}).length > 0;
    let files = {};
    if (hasMedia) {
      // copy photos data for later upload, and remove any File objects (Can't upload them to Firestore, we can't upload the image to Cloud storage until we have a message uid)
      files = this.imageService.deepCopy(messageData.media || {});
      for (let [key, photo] of Object.entries(messageData.media || {})) {
        if (photo.file) {
          delete messageData.media[key].file;
          messageData.media[key].photoURL = '';
        }
      }
    }
    const messageBody = this.createMessageBody(messageData, senderName, senderId, messageType);
    const content = messageData.text;
    const preview = content.length > 70 ? content.slice(0, 70) + '…' : content;
    const gstCustom = {};

    const canSend = await this.canSendMessage(senderId, threadId, threadType).catch(err => {
      const message = `Sorry, we have encountered an error: ${JSON.stringify(err)}.`;
      this.toastService.presentToast(message);
      throw err;
    });

    if (!canSend) {
      this.messageDatabase.createHeldMessage(threadId, messageBody, emailData.threadName || '').then(result => {
        this.analyticsService.eventTrack(AnalyticsCategory.MESSAGES, AnalyticsAction.MESSAGES_HOLD_MESSAGE, threadId);
        if (hasMedia) this.uploadMedia(threadId, result.id, files, true);
        const message = `Sorry, your pattern of sending messages has triggered our spam filter. Our support team will review this message, and release or delete as appropriate.`;
        this.toastService.presentToast(message);
        this.emailService.sendSpamAlertToSupport(senderId, senderName);
      });
    } else {
      this.messageDatabase
        .createMessage(threadId, messageBody)
        .then(result => {
          this.storage.set(this.STORAGE_KEY, threadId);
          if (hasMedia) this.uploadMedia(threadId, result.id, files);
          this.sendNotifications(threadId, result.id, content, preview, messageBody, messageType, hasMedia, isAutomated, gstCustom, emailData);
        })
        .catch(err => {
          throw err;
        });
    }
  }

  createMessageBody(messageData: IMessageData, name: string, senderId: string, messageType: MessageType): IMessage {
    const currentDateTime = this.dateTimeService.getDateTime();
    return Object.assign(
      {},
      {
        content: messageData.text,
        dateTimeSent: currentDateTime,
        dateTimeLastUpdated: currentDateTime,
        name: name,
        photos: messageData.media,
        senderId: senderId,
        type: messageType
      }
    );
  }

  // creates a thread and a memberthread for each memberId
  createThread(members: Record<string, string>, relatedType: IMemberThreadRelatedType, memberThreadType: IMemberThreadType, title?: string, relatedId?: string, ownerIds?: string[]) {
    members[this.authService._userProfileSubject.value.uid] = this.authService._userProfileSubject.value.displayName;

    let threadType = '';
    switch (memberThreadType) {
      case 0:
        threadType = 'Conversation';
        break;
      case 1:
        threadType = 'DirectMessage';
        break;
      case 2:
        threadType = 'Group';
        break;
      default:
        break;
    }
    const gstCustom = { type: threadType };

    const threadData = {} as IThread;

    const memberIds = Object.keys(members);

    threadData.memberIds = [...memberIds];
    if (relatedId && relatedType) {
      threadData.relatedId = relatedId;
      threadData.relatedType = relatedType;
    }
    if (ownerIds) {
      threadData.ownerIds = ownerIds;
    }
    return this.messageDatabase
      .createThread(threadData)
      .then(async saved => {
        threadData.uid = saved.id;
        this.analyticsService.eventTrack(AnalyticsCategory.MESSAGES, AnalyticsAction.MESSAGES_START_THREAD, saved.id, gstCustom, memberIds.length);

        await Promise.all(
          memberIds.map(async memberId => {
            const relatedIds = threadData.memberIds.filter(x => x !== memberId);
            const isGroup = memberThreadType === IMemberThreadType.Group;
            const threadName = isGroup ? title : this.createThreadNameForMembers(relatedIds, members);

            await this.addMemberToThread(threadData.uid, relatedIds, memberId, null, relatedType, threadName, memberThreadType, ownerIds);
          })
        );

        return saved;
      })
      .catch(err => {
        throw err;
      });
  }

  createThreadNameForMembers(memberIds: string[], members: Record<string, string>) {
    const memberThreadNames = [];
    memberIds.forEach((id: string) => {
      memberThreadNames.push(members[id]);
    });
    const threadName = memberThreadNames.join('; ');

    return threadName;
  }

  deleteHeldMessage(uid: string) {
    this.messageDatabase.deleteHeldMessage(uid);
  }

  deleteMemberThread(threadId: string, memberId: string) {
    this.notificationService.removeNotificationForMember(NotificationTarget.MESSAGE, threadId, memberId);
    return this.messageDatabase.deleteMemberThread(threadId, memberId);
  }

  deleteMessage(threadId: string, messageId: string) {
    return this.messageDatabase.deleteMessage(threadId, messageId).then(() => {
      const callable = this.fns.httpsCallable('deleteFileNow');
      threadId = threadId.replace('/Messages', '');
      const threadIdParts = threadId.split('/');
      const data = threadIdParts.length > 1 ? { threadId: threadIdParts[0], messageId: threadIdParts[1], replyId: messageId } : { threadId: threadIdParts[0], messageId: messageId };
      return callable(data).toPromise();
    });
  }

  getAllMessages(search: IMessageOptions, lastTimestamp: number, messageTypes: MessageType[]) {
    const limit = 50;
    //TODO: Add some sort of type field to IMessage so we can actually query by message type (MemberThreadType + Chit Chat), rather than filtering after querying the db
    return this.messageDatabase.getAllMessages(search, limit, lastTimestamp, messageTypes);
  }

  getArchivedMemberThread(threadId: string): Observable<IMemberThread> {
    return this.messageDatabase.getMemberThreadById(threadId, this.user.uid).pipe(first(x => !!x));
  }

  getArchivedMemberThreads(): Observable<IMemberThread[]> {
    return this.messageDatabase.getMemberThreadsForMember(this.user.uid, true); // Don't just take the first result, as we want the list to update if we archive/unarchive threads
  }

  getHeldMessagesForApproval(): Observable<any> {
    return this.messageDatabase.getHeldMessagesForApproval();
  }

  getLastMessage(threadId: string) {
    return this.messageDatabase.getLastMessage(threadId);
  }

  // gets thread details for this user, e.g. have I read these messages etc.
  getMemberThread(threadId: string): Observable<IMemberThread> {
    return this.memberThreads$.pipe(
      skipWhile(x => !x),
      map(x => x.filter(t => t.threadId === threadId)[0])
    );
  }

  getMemberThreads(): Observable<IMemberThread[]> {
    return this.memberThreads$.pipe(skipWhile(x => !x));
  }

  getMemberThreadsByDate(date: number): Observable<IMemberThread[]> {
    return this.messageDatabase.getMemberThreadsByDate(date).pipe(first(x => !!x)); // Without first this would fire every time a new message was sent!
  }

  getMemberThreadsByIds(uids: string[][]): Observable<IMemberThread[]> {
    return this.messageDatabase.getMemberThreadsByIds(uids);
  }

  getMessages(threadId: string, loadPastMessages: boolean, dateStart: number, maxRecordsToFetch: number): BehaviorSubject<any> {
    const subject = new BehaviorSubject(null);
    const subscription = this.messageDatabase.getMessages(threadId, loadPastMessages, dateStart, maxRecordsToFetch).subscribe(messages => {
      if (messages.length > 0) {
        subject.next(messages);
      } else if (loadPastMessages) {
        subject.next([]);
      }
    });
    this.subscriptionService.add(subscription);

    return subject;
  }

  getReplies(threadId: string, messageId: string): Observable<IMessage[]> {
    return this.messageDatabase.getReplies(threadId, messageId);
  }

  getThread(threadId: string): Observable<IThread> {
    return this.messageDatabase.getThread(threadId).pipe(skipWhile(x => !x));
  }

  hasHeldMessages(memberId: string): Promise<boolean> {
    return this.messageDatabase.hasHeldMessages(memberId);
  }

  init() {
    this.authService._userProfileSubject.subscribe(user => {
      if (user == null) {
        return;
      }

      // Without this check we would re-initMemberThreads every time the profile changed
      if (user != this.user) {
        this.user = user;
        this.initMemberThreads();
      }
    });
  }

  isGroupNameUnique(memberThreads: IMemberThread[], groupName: string) {
    let result = true;
    memberThreads.forEach(thread => {
      const groupExists = thread.type === IMemberThreadType.Group && thread.name.toLowerCase().trim() === groupName.toLowerCase().trim();

      if (groupExists) {
        this.toastService.presentToast(`Please choose a different group name, there is already a group named ${thread.name}.`);
        result = false;
      }
    });

    return result;
  }

  ngOnDestroy() {
    this.subscriptionService.clearSubscription(this.memberThreadsSubscription);
  }

  releaseHeldMessage(message: IMessage) {
    const threadId = message.threadId;
    delete message.threadId;
    this.messageDatabase
      .updateMessage(threadId, message.uid, message) // update rather than create, because we want to use the existing messageId
      .then(result => {
        const messageType = MessageType.THREAD; // Chats and replies within a group are not rate-limited
        const isAutomated = true; // Don't give coins to the sending admin. TODO: Give coins to the member? Tricky, and if they've sent 10 messages in a hour they have probably hit the monthly limit anyway
        const hasMedia = false; // media already uploaded when message was held
        const preview = message.content.length > 70 ? message.content.slice(0, 70) + '…' : message.content;
        const gstCustom = {};
        const emailData = {};
        this.sendNotifications(threadId, message.uid, message.content, preview, message, messageType, hasMedia, isAutomated, gstCustom, emailData);
        this.messageDatabase.deleteHeldMessage(message.uid);
      })
      .catch(err => {
        throw err;
      });

    // TODO: Clear limit for current hour?
    // Edge case: member hits limit within the hour, support responds within the hour, member would still be blocked until next hour
  }

  renameThread(threadId: string, name: string) {
    const promises = [];

    // change name for all MemberThreads, ensure the type is Group (only Conversations have multiple members without a thread name)
    promises.push(this.updateMemberThreads(threadId, { name, type: IMemberThreadType.Group }));

    // change name for Thread
    promises.push(this.updateThread(threadId, { name }));

    return Promise.all(promises);
  }

  async sendMessageNotifications(threadId: string, messageType: MessageType = MessageType.THREAD, firstMemberThread: IMemberThread, hasMedia: boolean, preview: string, emailData: any = {}) {
    const sender = this.authService._userProfileSubject.value; // TODO: use correct info when admin releasing held messages
    const data = Object.assign({}, emailData, {
      baseUrl: this.environmentService.url(sender.country),
      senderName: sender.displayName,
      threadId: threadId
    });

    const branding = this.constantsService.constants.GROUPS.CHIT_CHAT.branding || 'Chit-Chat';

    const previewContent = preview ? `:<br><em>${preview}</em>` : '';
    let activity, authorLink, contentType, groupName, message, notificationType, targetId, targetType;

    switch (messageType) {
      case MessageType.CHIT_CHAT:
        // TODO: Link directly to the new comment
        notificationType = 'newChitChat';
        targetId = threadId.replace(/^group_/, '');
        targetType = NotificationTarget.GROUP;
        contentType = hasMedia ? 'comment with images' : 'comment';
        groupName = emailData.groupName
          .replace(/%20/g, ' ')
          .replace(/\+/g, ' ')
          .replace(/  /g, ' ');
        emailData.groupName = groupName; // Replace with sanitised version for use in email template later
        message = `<a href="/members/${sender.uid}">${sender.displayName}</a> posted a ${contentType} in <a href="/groups/chit-chat/${emailData.groupId}">${groupName} ${branding}</a>${previewContent}`;
        activity = {
          activityTypes: [ActivityType.FEED, ActivityType.EMAIL, ActivityType.DIGEST],
          data: data,
          excludeMembers: [sender.uid],
          message: message,
          notificationType: notificationType,
          targetIds: [targetId],
          targetType: targetType,
          timestamp: Date.now()
        };
        return this.activityService.createActivity(activity);

        break;

      case MessageType.CATCHUP_NOTE:
        // TODO: Link directly to the new comment
        notificationType = 'newCatchupNote';
        targetId = threadId.replace(/^catchup_/, '');
        targetType = NotificationTarget.CATCHUP;
        contentType = hasMedia ? 'note with images' : 'note';
        message = `<a href="/members/${sender.uid}">${sender.displayName}</a> posted a ${contentType} for <a href="/catchups/${emailData.catchupId}/notes">${emailData.catchupName}</a>${previewContent}`;
        activity = {
          activityTypes: [ActivityType.FEED, ActivityType.EMAIL, ActivityType.DIGEST],
          data: data,
          excludeMembers: [sender.uid],
          message: message,
          notificationType: notificationType,
          targetIds: [targetId],
          targetType: targetType,
          timestamp: Date.now()
        };
        return this.activityService.createActivity(activity);

        break;

      case MessageType.CATCHUP_REPLY:
        // TODO: Link directly to the new reply
        notificationType = 'newCatchupReply';
        targetId = threadId.replace(/^catchup_/, '');
        targetId = targetId.split('/')[0]; // threadId for replies is {catchupId}/Messages/{commentId}.
        targetType = NotificationTarget.CATCHUP;
        authorLink = sender.uid !== emailData.messageAuthorId ? ` by <a href="/members/${emailData.messageAuthorId}">${emailData.messageAuthorName}</a>` : '';
        message = `<a href="/members/${sender.uid}">${sender.displayName}</a> replied to a note${authorLink} in <a href="/catchups/${emailData.catchupId}/notes">${emailData.catchupName}</a>${previewContent}`;
        activity = {
          activityTypes: [ActivityType.FEED, ActivityType.EMAIL, ActivityType.DIGEST],
          data: data,
          //includeMembers: emailData.includeMembers || [],
          excludeMembers: [sender.uid],
          message: message,
          notificationType: notificationType,
          targetIds: [targetId],
          targetType: targetType,
          timestamp: Date.now()
        };
        return this.activityService.createActivity(activity);
        break;

      case MessageType.GAMES_CHAT:
        // No notifications
        break;

      case MessageType.GAMES_REPLY:
        // TODO: Link directly to the new reply
        notificationType = 'newGamesReply';
        targetId = threadId.replace(/^games_/, '');
        targetId = targetId.split('/')[0]; // threadId for replies is {gameId}/Messages/{commentId}.
        targetType = NotificationTarget.GAME;
        authorLink = sender.uid !== emailData.messageAuthorId ? ` by <a href="/members/${emailData.messageAuthorId}">${emailData.messageAuthorName}</a>` : '';
        message = `<a href="/members/${sender.uid}">${sender.displayName}</a> replied to a post${authorLink} in <a href="/games/chat/${emailData.gameType}">${emailData.chatName}</a>${previewContent}`;
        activity = {
          activityTypes: [ActivityType.FEED, ActivityType.EMAIL, ActivityType.DIGEST],
          data: data,
          includeMembers: emailData.includeMembers || [],
          excludeMembers: [sender.uid],
          message: message,
          notificationType: notificationType,
          targetIds: [targetId],
          targetType: targetType,
          timestamp: Date.now()
        };
        return this.activityService.createActivity(activity);
        break;

      case MessageType.REPLY:
        // TODO: Link directly to the new reply
        notificationType = 'newChitChatReply';
        targetId = threadId.replace(/^group_/, '');
        targetId = targetId.split('/')[0]; // threadId for replies is {groupId}/Messages/{commentId}.
        targetType = NotificationTarget.GROUP;
        groupName = emailData.groupName
          .replace(/%20/g, ' ')
          .replace(/\+/g, ' ')
          .replace(/  /g, ' ');
        emailData.groupName = groupName; // Replace with sanitised version for use in email template later
        authorLink = sender.uid !== emailData.messageAuthorId ? ` by <a href="/members/${emailData.messageAuthorId}">${emailData.messageAuthorName}</a>` : '';
        message = `<a href="/members/${sender.uid}">${sender.displayName}</a> replied to a comment${authorLink} in <a href="/groups/chit-chat/${emailData.groupId}">${groupName} ${branding}</a>${previewContent}`;
        const activity1 = {
          activityTypes: [ActivityType.FEED, ActivityType.EMAIL, ActivityType.DIGEST],
          data: data,
          includeMembers: emailData.includeMembers || [], // fallback needs to be empty array not null, otherwise entire group will be notified
          excludeMembers: [sender.uid], // also exclude emailData.messageAuthorId if doing
          message: message,
          notificationType: notificationType,
          targetIds: [targetId],
          targetType: targetType,
          timestamp: Date.now()
        };
        return this.activityService.createActivity(activity1);
        /*
        .then(() => {
          // TODO: Do we still want this extra notification for the person who started the thread
          if(sender.uid !== emailData.messageAuthorId){
            // Notify author of original message that there is a reply
            const message2 = `<a href="/members/${sender.uid}">${sender.displayName}</a> replied to your comment in <a href="/groups/chit-chat/${emailData.groupId}">${groupName} ${branding}</a>${previewContent}`;
            const activity2 = Object.assign({}, activity1, { excludeMembers: [], includeMembers: null, message: message2, notificationType: 'newReply', targetIds: [emailData.messageAuthorId], targetType: NotificationTarget.MEMBER });
            this.activityService.createActivity(activity2);
          }
        });
        */
        break;

      case MessageType.THREAD:
      default:
        notificationType = 'newMessage';
        targetId = threadId;
        targetType = NotificationTarget.MESSAGE;
        if (firstMemberThread.type === IMemberThreadType.Group) {
          const contentAction = hasMedia ? 'sent a message with images' : 'sent a new message';
          message = `<a href="/members/${sender.uid}">${sender.displayName}</a> ${contentAction} to the <a href="/messages/threads/${threadId}">${firstMemberThread.name}</a> thread${previewContent}`;
        } else {
          const contentType = hasMedia ? 'message with images' : 'new message';
          let otherCount = '';
          switch (firstMemberThread.relatedIds.length) {
            case 0:
            case 1:
              break;

            case 2:
              otherCount = ` and 1 other`;
              break;

            default:
              otherCount = ` and ${firstMemberThread.relatedIds.length - 1} others`;
          }
          message = `<a href="/members/${sender.uid}">${sender.displayName}</a> sent you${otherCount} a <a href="/messages/threads/${threadId}">${contentType}</a>${previewContent}`;
        }
        activity = {
          activityTypes: [ActivityType.FEED, ActivityType.EMAIL, ActivityType.DIGEST],
          data: data,
          excludeMembers: [sender.uid],
          message: message,
          notificationType: notificationType,
          targetIds: [targetId],
          targetType: targetType,
          timestamp: Date.now()
        };
        return this.activityService.createActivity(activity);

        break;
    }
  }

  unarchiveMemberThread(threadId: string, archiveMemberId: string) {
    const archiveThreadData = {
      isArchived: false
    };

    return this.updateMemberThread(threadId, archiveMemberId, archiveThreadData);
  }

  updateMemberThread(threadId: string, memberId: string, newData: any) {
    return this.messageDatabase.updateMemberThread(threadId, memberId, newData);
  }

  updateMemberThreads(threadId: string, newData: any) {
    return this.messageDatabase.updateMemberThreads(threadId, newData);
  }

  updateMemberThreadsAndNotifications(
    collection: AngularFirestoreCollection,
    ref: AngularFirestoreCollection,
    messageId: string,
    threadId: string,
    newData: any,
    hasMedia: boolean,
    preview: string,
    isAutomated: boolean = false,
    messageType: MessageType = MessageType.THREAD,
    updateNotificationCount: boolean = true,
    emailData: any = {}
  ) {
    //Doing it this way means we only update the memberThread once,
    //regardless of whether the thread is open or not
    return collection
      .get()
      .toPromise()
      .then(async response => {
        const gstCustom = {};
        if (!isAutomated) this.analyticsService.eventTrack(AnalyticsCategory.MESSAGES, AnalyticsAction.MESSAGES_SEND_MESSAGE, threadId, gstCustom, response.docs.length);

        const firstMemberThread = response.docs.length ? (response.docs[0].data() as IMemberThread) : null;
        await response.docs.reduce(async (previousPromise, doc) => {
          const dummyAccumulator = await previousPromise;
          const data = doc.data();

          const updatedData = Object.assign({}, newData); //updatedData = newData just creates a reference, we need a copy
          const isOpen = await this.threadOpenService.isOpenForMemberAnywhere(data.memberId, doc.id);
          //For newly created thread, it is not opened until after these member threads are updated
          if (isOpen === true || (this.authService._userProfileSubject.value.uid === data.memberId && !isAutomated)) {
            //update dateTimeLastRead and lastMessageSeenId because they are looking at the thread on at least one device
            updatedData.dateTimeLastRead = newData.dateTimeLastUpdated;
            updatedData.lastMessageSeenId = messageId;
            this.updateSeenBy(data.memberId, threadId, data.lastMessageSeenId || null, messageId);
          } else {
            //add firestore increment to data
            updatedData.messageNotificationCount = FieldValue.increment(1);
            //increment user messageNotificationCount
            if (data.memberId) {
              if (updateNotificationCount) {
                this.userService.incrementMessageNotificationCount(data.memberId, 1);
              }
            }
          }

          ref.doc(doc.id).update({ ...updatedData }); // TODO: Move dependency on AngularFirestoreCollection to messageDatabase?
          return dummyAccumulator;
        }, Promise.resolve({}));

        await this.sendMessageNotifications(threadId, messageType, firstMemberThread, hasMedia, preview, emailData);
      });
  }

  updateMessage(threadId: string, messageId: string, data: any) {
    return this.messageDatabase.updateMessage(threadId, messageId, data, true);
  }

  updateOwnMemberThread(memberThread: IMemberThread, newData: any) {
    if (memberThread == null) {
      return;
    }

    return this.messageDatabase.updateOwnMemberThread(memberThread, newData);
  }

  updateParentMessage(threadId: string, message: IMessage, dateTimeLastUpdated: number, memberIds: string[], increment: number = 1) {
    const data: any = {
      dateTimeLastUpdated: dateTimeLastUpdated,
      lastReply: message,
      replyCount: FieldValue.increment(increment)
    };

    if (memberIds != null) data.memberIds = memberIds;

    // for a reply, threadId has the form {parent}/Messages/{message}
    const threadParts = threadId.split('/');
    const parentId = threadParts[0];
    const messageId = threadParts[threadParts.length - 1];
    this.messageDatabase.strictUpdateMessage(parentId, messageId, data);
  }

  updateSeenBy(memberId: string, threadId: string, oldMessageId: string, newMessageId: string) {
    if (oldMessageId === newMessageId) return;
    const now = this.dateTimeService.getDateTime();
    // NB: If in future any ordering of messages relies on dateTimeLastUpdated instead of dateTimeSent, the following could cause a problem
    // e.g. if there are multiple new messages, then when a member views the thread the last message they had seen will have a newer dateTimeLastUpdated than all but the most recent message
    if (oldMessageId) this.messageDatabase.updateMessage(threadId, oldMessageId, { seenBy: FieldValue.arrayRemove(memberId), dateTimeLastUpdated: now });
    // NB: Need to use different times for updating old and new messages, because chirpy-message-list uses > dateTimeLastUpdated, so won't pick up the updated newMessage if it has the same timestamp as the oldMessage
    if (newMessageId) this.messageDatabase.updateMessage(threadId, newMessageId, { seenBy: FieldValue.arrayUnion(memberId), dateTimeLastUpdated: now + 1 });
  }

  updateThread(threadId: string, newData: {}) {
    return this.messageDatabase.updateThread(threadId, newData);
  }

  private initMemberThreads() {
    this.subscriptionService.clearSubscription(this.memberThreadsSubscription);
    this.memberThreadsSubscription = this.messageDatabase.getMemberThreadsForMember(this.user.uid).subscribe(parsed => {
      this.memberThreads = parsed;
      this.memberThreads$.next(parsed);
    });
    this.subscriptionService.add(this.memberThreadsSubscription);
  }

  private sendNotifications(threadId: string, messageId: string, content: string, preview: string, messageBody: any, messageType: MessageType, hasMedia: boolean, isAutomated: boolean, gstCustom: any, emailData: any) {
    switch (messageType) {
      case MessageType.THREAD:
        const threadData = {
          lastMessage: content,
          lastMessageId: messageId,
          isArchived: false,
          dateTimeLastUpdated: this.dateTimeService.getDateTime()
        };
        const memberThreadsCollection = this.messageDatabase.getMemberThreadsForThread(threadId);
        const memberThreadsRef = this.messageDatabase.getMemberThreads();
        this.updateMemberThreadsAndNotifications(memberThreadsCollection, memberThreadsRef, messageId, threadId, threadData, hasMedia, preview, isAutomated);
        break;

      case MessageType.CHIT_CHAT: {
        // TODO: Get group member count?
        this.analyticsService.eventTrack(AnalyticsCategory.GROUPS, AnalyticsAction.GROUPS_SEND_CHIT_CHAT, threadId, gstCustom);
        const isChatGroup = emailData.isChatGroup || false;
        if (isChatGroup) {
          this.analyticsService.eventTrack(AnalyticsCategory.GROUPS, AnalyticsAction.GROUPS_POST_CHAT, threadId, gstCustom);
        } else {
          // Don't generate notifications for Chat groups, because it's too noisy
          this.sendMessageNotifications(threadId, messageType, null, hasMedia, preview, emailData);
        }
        break;
      }

      case MessageType.CATCHUP_NOTE:
        // TODO: Get group member count?
        this.analyticsService.eventTrack(AnalyticsCategory.CATCHUPS, AnalyticsAction.CATCHUPS_SEND_NOTE, threadId, gstCustom);
        this.sendMessageNotifications(threadId, messageType, null, hasMedia, preview, emailData);
        break;

      case MessageType.CATCHUP_REPLY:
        this.analyticsService.eventTrack(AnalyticsCategory.CATCHUPS, AnalyticsAction.CATCHUPS_SEND_NOTE_REPLY, threadId, gstCustom);
        Object.assign(messageBody, { uid: messageId }); // Needed to prevent race condition when updating photoURLs after image resizing
        this.updateParentMessage(threadId, messageBody, this.dateTimeService.getDateTime(), emailData.includeMembers);
        this.sendMessageNotifications(threadId, messageType, null, hasMedia, preview, emailData);
        break;

      case MessageType.GAMES_CHAT: {
        // No notifications because too noisy
        this.analyticsService.eventTrack(AnalyticsCategory.GAMES, AnalyticsAction.GAMES_NEW_CHAT, emailData.gameType, gstCustom);
        this.analyticsService.eventTrack(AnalyticsCategory.GAMES, AnalyticsAction.GAMES_POST_CHAT, emailData.gameType, gstCustom);
        break;
      }

      case MessageType.GAMES_REPLY: {
        this.analyticsService.eventTrack(AnalyticsCategory.GAMES, AnalyticsAction.GAMES_REPLY_CHAT, emailData.gameType, gstCustom);
        this.analyticsService.eventTrack(AnalyticsCategory.GAMES, AnalyticsAction.GAMES_POST_CHAT, emailData.gameType, gstCustom);
        Object.assign(messageBody, { uid: messageId }); // Needed to prevent race condition when updating photoURLs after image resizing
        this.updateParentMessage(threadId, messageBody, this.dateTimeService.getDateTime(), emailData.includeMembers);
        this.sendMessageNotifications(threadId, messageType, null, hasMedia, preview, emailData);
        break;
      }

      case MessageType.REPLY: {
        this.analyticsService.eventTrack(AnalyticsCategory.GROUPS, AnalyticsAction.GROUPS_SEND_CHIT_CHAT_REPLY, threadId, gstCustom);
        const isChatGroup = emailData.isChatGroup || false;
        if (isChatGroup) this.analyticsService.eventTrack(AnalyticsCategory.GROUPS, AnalyticsAction.GROUPS_POST_CHAT, threadId, gstCustom);
        Object.assign(messageBody, { uid: messageId }); // Needed to prevent race condition when updating photoURLs after image resizing
        this.updateParentMessage(threadId, messageBody, this.dateTimeService.getDateTime(), emailData.includeMembers);
        this.sendMessageNotifications(threadId, messageType, null, hasMedia, preview, emailData);
        break;
      }

      default:
        return;
    }
  }

  private uploadMedia(threadId: string, messageId: string, media: Record<string, File>, isHeld: boolean = false) {
    // If the message to which the media are attached is being held, we need to prefix 'held' to the filename
    // to act as a flag to the onImageUpload cloud function as to which document to update with resized URL
    if (isHeld) {
      const renamedMedia: Record<string, File> = {};
      for (let [key, file] of Object.entries(media || {})) {
        const renamedKey = `held${key}`;
        renamedMedia[renamedKey] = file;
      }
      media = renamedMedia;
    }
    // threadId is either {threadId} or {threadId}/Messages/{messageId}
    // path is message/{threadId}/{messageId} or message/{threadId}/{messageId}/{replyId}
    const path: string = 'message/' + threadId.replace('/Messages', '');
    return this.imageService.uploadImages(messageId, media, path);
  }
}
