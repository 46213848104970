import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Country } from '@shared/constants/country';
import { GroupType } from '@shared/constants/group-type';
import { MessageType } from '@shared/constants/message-type';
import { IGroup } from '@shared/models/groups/group';
import { IThread } from '@shared/models/messages/thread';
import { AnalyticsAction, AnalyticsCategory, AnalyticsService } from '@shared/services/analytics';
import { AppOptionsService } from '@shared/services/app-options/app-options.service';
import { AuthService } from '@shared/services/auth.service';
import { ConstantsService } from '@shared/services/constants.service';
import { EnvironmentService } from '@shared/services/environment.service';
import { GroupService } from '@shared/services/groups/group.service';
import { MessageService } from '@shared/services/messages/message.service';
import { ThreadDetailCatchupService } from '@shared/services/messages/thread-detail-catchup.service';
import { SubscriptionService } from '@shared/services/subscription.service';
import { BehaviorSubject, Observable, of, Subscription } from 'rxjs';
import { map, skipWhile } from 'rxjs/operators';

@Component({
  selector: 'app-group-chit-chat',
  templateUrl: './group-chit-chat.page.html',
  styleUrls: ['./group-chit-chat.page.scss']
})
export class GroupChitChatPage implements OnInit, OnDestroy {
  get canSendMessage() {
    // TODO: Check if member of group?
    return !this.authService.isImpersonating && !this.isGroupLocked
  }

  get CONSTANTS() {
    return this.constantsService.constants.GROUPS.CHIT_CHAT;
  }

  get inCatchup(): boolean {
    return this.threadDetailCatchupService.getCatchup() !== '' && this.threadDetailCatchupService.getCatchup() === this.virtualCatchupName;
  }

  get isGroupMember(): boolean {
    return this.groupService.isGroupMember(this.groupId);
  }

  get title() {
    if (!this.group.value) return this.CONSTANTS.branding;
    if (this.isChatGroup.value) return this.group.value.name;

    return `${this.group.value.name} ${this.CONSTANTS.branding}`;
  }

  get virtualCatchupName() {
    // Use shortName form of environment name; daily.co does not allow hyphens in room name and has limit of 41 characters including subdomain
    return this.thread != null && this.thread.virtualCatchupName != null && this.thread.virtualCatchupName !== '' ? this.environmentService.shortName + this.thread.uid : null;
  }

  get isGroupLocked() {
    return this.group.value && this.group.value.isLocked;
  }

  containerHasTitleBar: boolean = true;
  groupId: string;
  group: BehaviorSubject<IGroup> = new BehaviorSubject(null);
  lockedReason$: Observable<string>;
  instructions$: Observable<string>;
  isChatGroup: BehaviorSubject<boolean> = new BehaviorSubject(false);
  isJoiningVirtualCatchup: boolean;
  messageType: MessageType = MessageType.CHIT_CHAT;
  overrideMemberNotificationLabel = 'Notify everyone in the group about this message. (They will receive a notification even if they have muted notifications.)';
  ref: Subscription;
  takeTwo: boolean = true; // Load messages twice to get past cached result which only has the last message
  thread: IThread;
  threadId: string;

  constructor(
    private analyticsService: AnalyticsService,
    private appOptionsService: AppOptionsService,
    private authService: AuthService,
    private constantsService: ConstantsService,
    private environmentService: EnvironmentService,
    private messageService: MessageService,
    private route: ActivatedRoute,
    private subscriptionService: SubscriptionService,
    private threadDetailCatchupService: ThreadDetailCatchupService,
    private groupService: GroupService
  ) {}

  ionViewWillEnter() {
    this.group.pipe(skipWhile(x => !x)).subscribe(group => {
      this.analyticsService.eventTrack(AnalyticsCategory.GROUPS, AnalyticsAction.GROUPS_VIEW_CHIT_CHAT, group.name);
    });
  }

  ionViewWillLeave() {
    this.onLeaveVirtualCatchup();
  }

  ngOnDestroy() {
    this.subscriptionService.clearSubscription(this.ref);
  }

  ngOnInit() {
    this.route.paramMap.subscribe(params => {
      this.groupId = params.get('id');
      if (this.groupId == null) {
        return;
      }

      const useCache = true;
      this.groupService
        .getGroup(this.groupId, useCache)
        .pipe(skipWhile(x => !x))
        .subscribe(group => {
          this.group.next(group);
          this.lockedReason$ = of(group.lockedReason || '');

          this.isChatGroup.next(group.groupType === GroupType.CHAT_GROUP);
          if (this.isChatGroup.value) {
            this.instructions$ = this.appOptionsService.getOptionsValues<string>('chatInstructions', group.country[0] as Country).pipe(map(instructions => instructions[0] || ''));
          } else {
            this.instructions$ = of('');
          }
        });

      this.threadId = `group_${this.groupId}`;

      if (this.ref) this.ref.unsubscribe();
      this.ref = this.messageService.getThread(this.threadId).subscribe(thread => {
        this.thread = thread;
      });
      this.subscriptionService.add(this.ref);
    });
  }

  onJoinedVirtualCatchup() {
    // Unset flag so that members can leave chat using native Daily.co leave button
    this.isJoiningVirtualCatchup = false;
  }

  onJoinVirtualCatchup() {
    // Prevent double clicking Join Meeting button while Daily.co room is being created.
    if (this.isJoiningVirtualCatchup) return;
    this.isJoiningVirtualCatchup = true;
    this.threadDetailCatchupService.setCatchup(this.virtualCatchupName, this.threadId, false);
  }

  onLeaveVirtualCatchup() {
    this.isJoiningVirtualCatchup = false;
    this.threadDetailCatchupService.unsetCatchup();
  }

  onSendMessage(messageData, threadId: string) {
    const messageType = MessageType.CHIT_CHAT;
    const threadType = null;
    const emailData = { groupId: this.groupId, groupName: this.group.value.name, isChatGroup: this.isChatGroup.value };
    this.messageService.createMessage(messageData, threadId, messageType, threadType, emailData);
  }

  showVirtualCatchup(): boolean {
    return this.threadDetailCatchupService.getCatchup() !== '';
  }
}
