import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { ALL_COUNTRIES } from '@shared/constants/country';
import { ILink } from '@shared/models/link';
import { AppOptionsService } from '@shared/services/app-options/app-options.service';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

@Component({
  selector: 'buttons-box',
  templateUrl: './buttons-box.component.html'
})
export class ButtonsBoxComponent implements OnInit {
  buttons$: Observable<ILink[]>;
  @Output() hasNoButtons = new EventEmitter();
  @Output() linkClicked = new EventEmitter();

  constructor(private appOptionsService: AppOptionsService) {}

  ngOnInit() {
    this.buttons$ = this.appOptionsService.getOptionsValues<ILink>('claimCoinsButtons', ALL_COUNTRIES).pipe(
      tap(buttons => {
        this.hasNoButtons.emit((buttons || []).length === 0);
      })
    );
  }

  onClick(title: string) {
    this.linkClicked.emit(title);
  }
}
