import { Injectable, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { IBaseEvent } from '@infrastructure/models/base-event';
import { AlertController } from '@ionic/angular';
import { AdminRole } from '@shared/constants/admin-role';
import { Country } from '@shared/constants/country';
import { InterestStatus } from '@shared/constants/interest-status';
import { IDifference } from '@shared/models/difference';
import { ISocial } from '@shared/models/social/social';
import { IPlace } from '@shared/models/place';
import { IResultsModel } from '@shared/models/results-model';
import { ISearchModel } from '@shared/models/search-model';
import { ActivityService, ActivityType } from '@shared/services/activity';
import { AnalyticsAction, AnalyticsCategory, AnalyticsService } from '@shared/services/analytics';
import { AuthService } from '@shared/services/auth.service';
import { ConstantsService } from '@shared/services/constants.service';
import { DateTimeService } from '@shared/services/date-time.service';
import { EnvironmentService } from '@shared/services/environment.service';
import { EmailService } from '@shared/services/email/email.service';
import { LimitService } from '@shared/services/limits/limit.service';
import { LocationService } from '@shared/services/location/location.service';
import { NotificationTarget, NotificationService } from '@shared/services/notifications';
import { SubscriptionService } from '@shared/services/subscription.service';
import { ToastService } from '@shared/services/toast.service';
import { UserService } from '@shared/services/user/user.service';
import firebase from 'firebase/app';
import { LatLngBounds } from 'leaflet';
import { BehaviorSubject, combineLatest, of, Observable, Subject, Subscription } from 'rxjs';
import { first, map, mergeMap, skipWhile, take } from 'rxjs/operators';
import { SocialDatabase } from './social.database';

@Injectable({
  providedIn: 'root'
})
export class SocialService implements OnDestroy {
  get HAS_MAP(): boolean {
    return this.constantsService.constants.SOCIAL.hasMap;
  }

  get INTEREST_ENABLED(): boolean {
    return this.constantsService.constants.SOCIAL.INTERESTED.enabled;
  }

  get ONLY_IN_GROUP(): boolean {
    return this.constantsService.constants.SOCIAL.onlyInGroup;
  }

  get socialBranding(): string {
    return this.constantsService.constants.SOCIAL.branding;
  }

  get startDate(): string {
    return this.dateTimeService.getStartOfTodayAsString();
  }

  socialsSubscription: Subscription;
  statusToActionMap: Record<InterestStatus, AnalyticsAction> = {
    [InterestStatus.GOING]: AnalyticsAction.SOCIAL_ADD_GOING,
    [InterestStatus.INTERESTED]: AnalyticsAction.SOCIAL_ADD_INTEREST,
    [InterestStatus.NOT_INTERESTED]: AnalyticsAction.SOCIAL_REMOVE_INTEREST
  };

  approveSocial(social: ISocial) {
    const data = { approved: true };
    if (social.locationId != null) {
      this.locationService.setHasSocialFlag(social.locationId, social.uid);
    }
    // TODO: Do we need the nearby notification any more?
    // It could results in members getting duplicate notifications for the same event
    // It also wasn't very effective without calculating nearby places
    //this.sendSocialCreatedNotification(social, NotificationTarget.PLACE);
    this.sendSocialCreatedNotification(social, NotificationTarget.GROUP);
    return this.socialDatabase.updateSocial(social.uid, data);
  }

  clusterSocialsIntoPlaces(socials: ISocial[]) {
    const socialIds = socials.map(x => x.uid);
    const uniqueSocials = socials.filter(({ uid }, index) => !socialIds.includes(uid, index + 1));

    const placeIds: string[] = socials.map(x => x.locationId);
    const uniquePlaces: string[] = Array.from(new Set(placeIds));
    const places: IPlace[] = [];

    uniquePlaces.forEach(placeId => {
      const socialsInPlace = uniqueSocials.filter(social => social.locationId === placeId && social.coordinates != null);
      if (socialsInPlace.length) {
        const place = {
          coordinates: socialsInPlace[0].coordinates,
          zoomTo: 15, // Hardcode this, it doesn't affect the map bounds
          displayName: socialsInPlace[0].locationName,
          hasSocial: socialsInPlace.map(x => x.uid),
          uid: socialsInPlace[0].locationId,
          // Fields required for IPlace, but not used
          containedIn: {},
          country: '',
          geohashes: [],
          importKey: '',
          names: [socialsInPlace[0].locationName],
          placeType: 'place,',
          version: '',
          zoom: []
        } as IPlace;
        places.push(place);
      }
    });
    return places;
  }

  constructor(
    private activityService: ActivityService,
    private analyticsService: AnalyticsService,
    private authService: AuthService,
    private alertController: AlertController,
    private constantsService: ConstantsService,
    private socialDatabase: SocialDatabase,
    private dateTimeService: DateTimeService,
    private emailService: EmailService,
    private environmentService: EnvironmentService,
    private limitService: LimitService,
    private locationService: LocationService,
    private notificationService: NotificationService,
    private router: Router,
    private subscriptionService: SubscriptionService,
    private toastService: ToastService,
    private userService: UserService
  ) {}

  async deleteSocial(social: ISocial) {
    const alert = await this.alertController.create({
      header: `Delete ${social.title}`,
      message: `Are you sure you want to cancel this ${this.socialBranding}?\n This will delete it and send a notification to all members going or interested that it has been cancelled.`,
      buttons: [
        {
          text: 'No',
          role: 'cancel',
          cssClass: 'secondary'
        },
        {
          text: 'Yes',
          handler: data => this.deleteSocialHandler(social)
        }
      ]
    });

    await alert.present();
  }

  deleteSocialHandler(social) {
    // Remove dot from map
    if (social.locationId != null) {
      this.locationService.unsetHasSocialFlag(social.locationId, social.uid);
    }

    const groupId = (social.sharedGroupIds || []).length > 0 ? social.sharedGroupIds[0] : null;
    const groupName = (social.sharedGroupNames || []).length > 0 ? social.sharedGroupNames[0] : null;
    const returnUrl = this.ONLY_IN_GROUP ? ['/groups/socials', groupId] : ['/social'];

    return this.socialDatabase.deleteSocial(social.uid).then(() => {
      this.sendSocialCancelledEmailNotificationToSocialAttendees(social);
      // The cloud function triggered by sendSocialCancelledEmailNotificationToSocialAttendees will delete the notification target
      // this.notificationService.deleteNotification(NotificationTarget.SOCIAL, social.uid);
      this.analyticsService.eventTrack(AnalyticsCategory.SOCIAL, AnalyticsAction.SOCIAL_DELETE, social.uid);
      this.toastService.presentToast(`${social.title} has been deleted.`);
      this.router.navigate(returnUrl, { replaceUrl: true }); // prevent back button from going back to deleted Social
    });
  }

  getAllSocials(country: Country): Observable<ISocial[]> {
    return this.socialDatabase.getAllSocials(country, this.startDate);
  }

  getGoingMembers(interested: Record<string, string>) {
    return this.getAttendeesByStatus(interested, InterestStatus.GOING);
  }

  getInterestedMembers(interested: Record<string, string>) {
    return this.getAttendeesByStatus(interested, InterestStatus.INTERESTED);
  }

  getPlaces(q: ISearchModel): Observable<IPlace[]> {
    return this.socialDatabase.getPlaces(q).pipe(
      //filter out places within queried geohashes which are not visible on the map
      map(places => {
        const visiblePlaces = places.filter(place => this.isPlaceVisibleOnMap(place, q.mapBounds));
        return visiblePlaces;
      })
    );
  }

  getPlacesThenSocials(q: ISearchModel, country: Country): Observable<IResultsModel> {
    return this.getPlaces(q).pipe(
      mergeMap(
        places => this.getSocials(places, country),
        (unfilteredPlaces, items) => {
          // If we pass the places results through, there will be a mismatch between the count on the marker and the list of Socials
          // because centralPlaces.hasSocial.length includes past events
          const places = this.clusterSocialsIntoPlaces(items);
          const results = { items, places } as IResultsModel;
          return results;
        }
      )
    );
  }

  getSocial(uid: string): Observable<ISocial> {
    return this.socialDatabase.getSocial(uid);
  }

  getSocials(places: IPlace[], country: Country): Observable<ISocial[]> {
    return this.socialDatabase.getSocials(places, country, this.startDate);
  }

  getSocialsByDate(startDate: string, endDate: string, country: Country): Observable<ISocial[]> {
    return this.socialDatabase.getAllSocials(country, startDate, endDate);
  }

  getSocialsCreatedByMember(memberId: string): Observable<ISocial[]> {
    return this.socialDatabase.getSocialsCreatedByMember(memberId, this.startDate);
  }

  getSocialsForApproval(): Observable<ISocial[]> {
    return this.socialDatabase.getSocialsForApproval(this.startDate);
  }

  getSocialsOfInterestToMember(memberId: string): Observable<ISocial[]> {
    // Can't add a where condition/sort in the Firestore query as that requires a separate index for each member
    return this.socialDatabase.getSocialsOfInterestToMember(memberId).pipe(
      map(socials => {
        const startDate = this.startDate;
        return socials.filter(x => x.date > startDate).sort((a, b) => a.datetime - b.datetime);
      })
    );
  }

  getSocialsThenPlaces(country: Country): Observable<IResultsModel> {
    return this.getAllSocials(country).pipe(
      map(items => {
        const places = this.clusterSocialsIntoPlaces(items);
        const results = { items, places } as IResultsModel;
        return results;
      })
    );
  }

  hasCatchupsOnSameDate(date: string, groupId: string): Observable<boolean> {
    const useCache = true;
    const cacheKey = `${groupId}-${date}`;
    return this.socialDatabase.getGroupCatchupsOnDate(groupId, date, useCache, cacheKey).pipe(map(catchups => (catchups || []).length > 0));
  }

  hasInterestStatus(social: IBaseEvent, status: InterestStatus): boolean {
    if (this.authService._userProfileSubject.value == null) return false;
    const interested = social.interested || {};
    return interested[this.authService._userProfileSubject.value.uid] === status;
  }

  hasReachedLimit(memberId: string): Observable<boolean> {
    return this.limitService.hasReachedLimitReadOnly$(memberId, AnalyticsAction.SOCIAL_ADD_SOCIAL);
  }

  isClosed(social: ISocial): boolean {
    const today = this.dateTimeService.getStartOfTodayAsString();
    const now = this.dateTimeService.getTimeAsString();
    return today > social.date || (today === social.date && now > social.time);
  }

  isGoing(social: IBaseEvent) {
    return this.hasInterestStatus(social, InterestStatus.GOING);
  }

  isInterested(social: IBaseEvent) {
    return this.hasInterestStatus(social, InterestStatus.INTERESTED);
  }

  isPlaceVisibleOnMap(place: IPlace, mapBounds: LatLngBounds) {
    return mapBounds.contains([place.coordinates.latitude, place.coordinates.longitude]);
  }

  ngOnDestroy() {
    this.subscriptionService.clearSubscription(this.socialsSubscription);
  }

  numberInterested(social: IBaseEvent): string {
    const statuses = [InterestStatus.GOING, InterestStatus.INTERESTED];
    const output = [];

    for (let status of statuses) {
      const memberHasStatus = this.hasInterestStatus(social, status);
      const statusCount = Object.values(social.interested || {}).filter(x => x === status).length;
      if (statusCount === 0) continue;

      const statusString = status.toLowerCase();
      let outputString = `${statusCount} ${statusString}`;
      if (memberHasStatus) {
        if (statusCount === 1) outputString = `You are ${statusString}`;
        else outputString = statusCount === 2 ? `You and 1 other ${statusString}` : `You and ${statusCount - 1} others ${statusString}`;
      }
      output.push(outputString);
    }

    const callToAction = this.INTEREST_ENABLED ? 'Be the first to register interest.' : "Be the first to say you're going";
    return output.length > 0 ? output.join(',') : callToAction;
  }

  rejectSocial(social: ISocial) {
    const data = { approved: false };
    if (social.locationId != null) {
      this.locationService.unsetHasSocialFlag(social.locationId, social.uid);
    }
    return this.socialDatabase.updateSocial(social.uid, data);
  }

  requestSocialReview(social: ISocial, isNew: boolean, oldSocial: ISocial) {
    if (isNew) {
      this.emailService.sendSocialApprovalRequest(social);
      return;
    }

    // If the listing has been updated, show only the changes
    // Don't show fields which are not intended to be human readable, e.g. deletion date, coordinates, etc
    let differences: Record<string, IDifference> = {};
    const significantKeys = ['title', 'date', 'time', 'endTime', 'address', 'locationName', 'description'];

    for (const key of significantKeys) {
      if (social[key] !== oldSocial[key]) {
        differences[key] = {
          old: oldSocial[key],
          new: social[key]
        };
      }
    }
    // Comparing arrays of group names requires special handling
    if (social.sharedGroupNames.sort().join() !== oldSocial.sharedGroupNames.sort().join()) {
      differences.sharedGroupNames = {
        old: oldSocial.sharedGroupNames.sort().join(', '),
        new: social.sharedGroupNames.sort().join(', ')
      };
      // TODO: Notify any added groups?
    }
    this.emailService.sendSocialUpdatedRequest(social, differences);
  }

  setInterested(socialId: string, memberId: string, status: InterestStatus) {
    const data = { interested: { [memberId]: status } };
    const action = this.statusToActionMap[status];
    if (action) this.analyticsService.eventTrack(AnalyticsCategory.SOCIAL, action, socialId);
    this.createNotification(socialId, memberId, status);
    return this.socialDatabase.updateSocial(socialId, data);
  }

  async updateSocial(social: ISocial, oldSocial: ISocial) {
    // Member can't change the Social DateTime to the past, but they can edit other form values
    const oldDateTime = social.datetime;
    const newDateTime = this.dateTimeService.createDate(social.date, social.time);
    const isCreating = social.uid == null;
    const validateDateTime = isCreating || (!isCreating && oldDateTime !== newDateTime) ? newDateTime : null;

    if (!(await this.validateForm(social, validateDateTime))) return false;
    // Don't update date time until it passes validation.
    social.datetime = newDateTime;

    // Recalculate deleteDate if the event date is updated
    social.deleteDate = this.dateTimeService.addDays(social.datetime, 90);

    if (isCreating) {
      social.created = this.dateTimeService.getDateTime();
    }

    // convert first letter to upper case
    social.title = social.title[0].toUpperCase() + social.title.slice(1);

    // When updating, use merge=false so that map-type fields are properly updated
    const ref: Promise<any> = isCreating ? this.socialDatabase.createSocial(social) : this.socialDatabase.updateSocial(social.uid, social, false);

    return ref.then(result => {
      const message = `Your ${this.socialBranding} has been ${isCreating ? 'created' : 'updated'}.`;
      this.toastService.presentToast(message);

      const uid = social.uid || result.id;
      social.uid = uid;
      if (this.constantsService.constants.SOCIAL.requiresAdminApproval) this.requestSocialReview(social, isCreating, oldSocial);

      // Log analytics
      if (isCreating) {
        this.analyticsService.eventTrack(AnalyticsCategory.SOCIAL, AnalyticsAction.SOCIAL_ADD_SOCIAL, uid); // NB This updates the count of socials created per LimitPeriod
      } else {
        this.analyticsService.eventTrack(AnalyticsCategory.SOCIAL, AnalyticsAction.SOCIAL_UPDATE_SOCIAL, uid);
      }

      // NB: { replaceUrl: true } is necessary to trigger ngOnDestroy, so that the back button doesn't take you back to a blank edit social page
      this.router.navigate(['/social', uid], { replaceUrl: true });
    });
  }

  async validateForm(data: ISocial, validateDateTime: number = null) {
    // This shouldn't be necessary, as create social form should not be displayed for Hosts/Cohosts
    if (this.authService.isHost() || this.authService.isCohost()) {
      const message = this.constantsService.constants.SOCIAL.PAGE.cantCreateMessage;
      this.toastService.presentToast(message);
      return false;
    }

    const missingFields = [];
    if (data.title.trim().length === 0) missingFields.push('Title');
    if (data.address.trim().length === 0) missingFields.push('Address');
    if (data.country.trim().length === 0) missingFields.push('Country');
    if (data.description.trim().length === 0) missingFields.push('Description');
    if (this.HAS_MAP && data.locationId.trim().length === 0) missingFields.push('Map location');
    if (data.date.length === 0) missingFields.push('Date');
    if (data.date.length > 0 && data.date > this.dateTimeService.MAX_DATE) missingFields.push(`date within the next ${this.dateTimeService.MAX_DATE_IN_YEARS} years`);
    if (data.time.length === 0) missingFields.push('Start time');
    if (data.endTime.length === 0) missingFields.push('End time');
    if (this.ONLY_IN_GROUP && (data.sharedGroupNames || []).length === 0) missingFields.push('Group');

    if (missingFields.length > 0) {
      const message = 'Please enter a ' + missingFields.join(', ');
      this.toastService.presentToast(message);
      return false;
    }

    if (validateDateTime != null) {
      // Don't let member create a Social in the past, but allow member to update a past Social.
      if (this.dateTimeService.isPastDate(validateDateTime)) {
        const message = 'Date cannot be in the past, please enter a future date.';
        this.toastService.presentToast(message);
        return false;
      }
    }

    // Validate start time is before end time.
    const startDateTime = this.dateTimeService.createDate(data.date, data.time);
    const endDateTime = this.dateTimeService.createDate(data.date, data.endTime);
    const hasValidEndTime = this.dateTimeService.isBefore(startDateTime, endDateTime);
    if (!hasValidEndTime) {
      const message = "Choose an End time that's after the Start time";
      this.toastService.presentToast(message);
      return false;
    }

    // Don't allow social if the group already has a CatchUp on the same day.
    if (this.ONLY_IN_GROUP && data.sharedGroupIds && data.date) {
      const groupId = data.sharedGroupIds[0];
      const hasCatchup = await this.hasCatchupsOnSameDate(data.date, groupId)
        .pipe(first())
        .toPromise();
      if (hasCatchup) {
        const message = `Sorry, the group already has a ${this.constantsService.constants.CATCHUPS.branding} scheduled for this day. Please choose another date.`;
        this.toastService.presentToast(message);
        return false;
      }
    }

    // Don't allow social if member has reached creation limit
    const LIMIT_PERIOD = this.constantsService.constants.SOCIAL.limitPeriod; // Actual value is stored in the database under appOptions/limits/add-social. This constant acts as a flag, and is used in the error message below.
    if (LIMIT_PERIOD) {
      const hasReachedLimit = await this.hasReachedLimit(data.memberId)
        .pipe(first())
        .toPromise();
      if (hasReachedLimit) {
        const message = `Sorry, you have reached your limit for creating socials. Please try again next ${LIMIT_PERIOD}.`;
        this.toastService.presentToast(message);
        return false;
      }
    }

    return true;
  }

  private getAttendeesByStatus(interested: Record<string, string>, status: InterestStatus) {
    const memberIds = [];
    for (let [memberId, memberStatus] of Object.entries(interested)) {
      if (memberStatus === status) {
        memberIds.push(memberId);
      }
    }
    const showUnknown = true;
    return memberIds.length > 0 ? this.userService.getUsers(memberIds, showUnknown).pipe(skipWhile(u => !u)) : of([]);
  }

  private createNotification(socialId: string, memberId: string, status: InterestStatus): void {
    switch (status) {
      case InterestStatus.GOING:
      case InterestStatus.INTERESTED:
        this.notificationService.createNotificationForMember(NotificationTarget.SOCIAL, socialId, memberId);
        break;

      case InterestStatus.NOT_INTERESTED:
        this.notificationService.removeNotificationForMember(NotificationTarget.SOCIAL, socialId, memberId);
        break;

      default:
        break;
    }
  }

  private sendSocialCreatedNotification(social: ISocial, targetType: NotificationTarget): Promise<any> {
    const dateTime = this.dateTimeService.formatDatetime(social.date, social.time, 'ddd D MMM [at] hh:mm a');
    const displayName = (social.memberName || '').split('(')[0].trim();
    const shortPlaceName = (social.locationName || '').split(',')[0].trim();

    let notificationType = '';
    let targetIds = [];

    switch (targetType) {
      case NotificationTarget.GROUP:
        notificationType = 'socialCreatedNotifyGroup';
        targetIds = social.sharedGroupIds;
        break;

      case NotificationTarget.PLACE:
        notificationType = 'socialCreatedNotifyNearby';
        targetIds = [social.locationId]; // TODO: When we have calculated nearby places, use multiple targets here
        break;
    }

    if (targetIds.length === 0) return new Promise(null);

    const activity = {
      activityTypes: [ActivityType.FEED, ActivityType.EMAIL, ActivityType.DIGEST],
      data: {
        baseUrl: this.environmentService.url(social.country),
        dateTime: dateTime,
        memberId: social.memberId,
        memberName: displayName,
        locationName: social.address, //shortPlaceName,
        socialId: social.uid,
        title: social.title
      },
      excludeMembers: [social.memberId],
      message: `<a href="/members/${social.memberId}">${displayName}</a> has listed a <a href="/social/${social.uid}">${this.socialBranding}</a> in ${shortPlaceName} on ${dateTime}:<br><em>${social.title}</em>`,
      notificationType: notificationType,
      targetIds: targetIds,
      targetType: targetType,
      timestamp: Date.now()
    };
    return this.activityService.createActivity(activity);
  }

  private sendSocialCancelledEmailNotificationToSocialAttendees(social: ISocial): Promise<any> {
    const dateTime = this.dateTimeService.formatDatetime(social.date, social.time, 'ddd D MMM [at] hh:mm a');
    const displayName = (social.memberName || '').split('(')[0].trim();
    const activity = {
      activityTypes: [ActivityType.FEED, ActivityType.EMAIL, ActivityType.DIGEST],
      data: {
        baseUrl: this.environmentService.url(social.country),
        social: social,
        dateTime: dateTime
      },
      deleteTarget: true,
      message: `<a href="/members/${social.memberId}">${displayName}</a> has cancelled their ${this.socialBranding} on ${dateTime}:<br><em>${social.title}</em>`,
      notificationType: 'socialCancelledNotifyMember',
      targetIds: [social.uid],
      targetType: NotificationTarget.SOCIAL,
      timestamp: Date.now()
    };
    return this.activityService.createActivity(activity);
  }
}
