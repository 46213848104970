import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AdminGuard } from '@shared/guards/admin-guard';
import { AdminOrHostGuard } from '@shared/guards/admin-or-host-guard';
import { AdminOrHostOrCohostGuard } from '@shared/guards/admin-or-host-or-cohost-guard';
import { MembersGuard } from '@shared/guards/members-guard';
import { GroupChitChatPage } from './group-chit-chat/group-chit-chat.page';
import { GroupDetailPage } from './group-detail/group-detail.page';
import { GroupEditPage } from './group-edit/group-edit.page';
import { GroupMembersPage } from './group-members/group-members.page';
import { GroupMembersReportPage } from './group-members-report/group-members-report.page';
import { GroupNoticesPage } from './group-notices/group-notices.page';
import { GroupNoticesEditPage } from './group-notices-edit/group-notices-edit.page';
import { GroupNotificationsPage } from './group-notifications/group-notifications.page';
import { GroupPhotoCollectionPage } from './group-photo-collection/group-photo-collection.page';
import { GroupPhotoDetailPage } from './group-photo-detail/group-photo-detail.page';
import { GroupPhotoEditPage } from './group-photo-edit/group-photo-edit.page';
import { GroupPhotoUploadPage } from './group-photo-upload/group-photo-upload.page';
import { GroupSocialsPage } from './group-socials/group-socials.page';
import { GroupsPage } from './groups.page';

export const routes: Routes = [
  {
    path: '',
    component: GroupsPage,
    canActivate: [MembersGuard],
    data: {
      title: 'Groups'
    }
  },
  {
    path: 'chit-chat/:id',
    component: GroupChitChatPage,
    canActivate: [MembersGuard],
    data: {
      title: 'Chit-Chat'
    }
  },
  {
    path: 'chit-chat/:id/:title',
    redirectTo: 'chit-chat/:id'
  },
  {
    path: 'create',
    component: GroupEditPage,
    canActivate: [AdminGuard],
    data: {
      title: 'Create group'
    }
  },
  {
    path: 'members/:id',
    component: GroupMembersPage,
    canActivate: [MembersGuard],
    data: {
      title: 'Group members'
    }
  },
  {
    path: 'members/report/:id',
    component: GroupMembersReportPage,
    canActivate: [AdminOrHostOrCohostGuard],
    data: {
      title: 'Group members report'
    }
  },
  {
    path: 'notices/:id',
    component: GroupNoticesPage,
    canActivate: [MembersGuard],
    data: {
      title: 'Group notices'
    }
  },
  {
    path: 'notices/:id/edit',
    component: GroupNoticesEditPage,
    canActivate: [MembersGuard],
    data: {
      title: 'Edit group notices'
    }
  },
  {
    path: 'notifications/:id',
    component: GroupNotificationsPage,
    canActivate: [MembersGuard],
    data: {
      title: 'Group notification settings'
    }
  },
  {
    path: 'photos/:id/:title',
    component: GroupPhotoCollectionPage,
    canActivate: [MembersGuard],
    data: {
      title: 'Group photo collection'
    }
  },
  {
    path: 'photos/:id/:title/detail',
    component: GroupPhotoDetailPage,
    canActivate: [MembersGuard],
    data: {
      title: 'Group photo details'
    }
  },
  {
    path: 'photos/:id/:title/detail/:photoId/edit',
    component: GroupPhotoEditPage,
    canActivate: [MembersGuard],
    data: {
      title: 'Group photo edit metadata'
    }
  },
  {
    path: 'photos/:id/:title/upload',
    component: GroupPhotoUploadPage,
    canActivate: [MembersGuard],
    data: {
      title: 'Group photo upload'
    }
  },
  {
    path: 'socials/:id',
    component: GroupSocialsPage,
    canActivate: [MembersGuard],
    data: {
      title: 'View group socials'
    }
  },
  {
    path: 'update/:id',
    component: GroupEditPage,
    canActivate: [AdminOrHostGuard],
    data: {
      title: 'Update group'
    }
  },
  {
    path: ':id',
    component: GroupDetailPage,
    canActivate: [MembersGuard],
    data: {
      title: 'Group details'
    }
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class GroupsRoutingModule {}
