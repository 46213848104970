import { Component } from '@angular/core';
import { GameType } from '@shared/constants/game-type';
import { IButton } from '@shared/models/button';
import { IDashboardIcon } from '@shared/models/dashboard-icon';
import { AnalyticsAction, AnalyticsCategory, AnalyticsService } from '@shared/services/analytics';
import { AuthService } from '@shared/services/auth.service';
import { GroupService } from '@shared/services/groups/group.service';
import { first } from 'rxjs/operators';
import { Observable, of } from 'rxjs';

@Component({
  selector: 'app-games',
  templateUrl: './games.page.html',
  styleUrls: ['./games.page.scss']
})
export class GamesPage {
  icons: IDashboardIcon[];

  addMemberToGroup(groupId: string) {
    const showToast: boolean = true;
    const useCache: boolean = true;
    this.groupService
      .getGroup(groupId, useCache)
      .pipe(first(x => !!x))
      .subscribe(group => {
        this.groupService.addMemberToGroup(groupId, group.name, group.memberCount, this.authService._userProfileSubject.value.uid, this.authService._userProfileSubject.value.displayName, showToast).then(() => this.initIcons());
      });
  }

  constructor(private analyticsService: AnalyticsService, public authService: AuthService, public groupService: GroupService) {}

  ngOnInit() {
    this.initIcons();
  }

  onClickButton(title: string, icon: IDashboardIcon, index: number, button: IButton = null) {
    this.analyticsService.eventTrack(AnalyticsCategory.GAMES, AnalyticsAction.GAMES_CLICK_BUTTON, title, { type: icon.title }, index);
    if (button && button.click) button.click();
  }

  removeMemberFromGroup(groupId: string) {
    const useCache: boolean = true;
    this.groupService
      .getGroup(groupId, useCache)
      .pipe(first(x => !!x))
      .subscribe(group => {
        this.groupService.removeMemberFromGroupHandler(groupId, group.name, this.authService._userProfileSubject.value.uid, this.authService._userProfileSubject.value.displayName).then(() => this.initIcons());
      });
  }

  private initIcons() {
    this.icons = Object.values(GameType)
      .map(name => {
        const icon: IDashboardIcon = {
          buttons: [
            {
              condition: true,
              routerLink: [`/games/chat/${name}`],
              text: 'Chat'
            },
            {
              condition: true,
              routerLink: [`/games/leaderboard/${name}`],
              text: 'Leaderboards'
            }
          ],
          src: `/assets/chirpy/games/${name}.svg`, // If we allow games in an instance other than chirpy, port the /assets changes from the BizCircle repo
          title: name,
          url: `/games/${name}`
        };

        return icon;
      })
      .concat({
        buttons: [
          {
            condition: true,
            routerLink: ['/groups/chit-chat/ZMuefJlM4mTpNV679Y1W'],
            text: 'Chat'
          },
          {
            click: this.addMemberToGroup.bind(this, 'ZMuefJlM4mTpNV679Y1W'),
            condition: !this.groupService.isGroupMember('ZMuefJlM4mTpNV679Y1W'),
            text: 'Join group'
          },
          {
            click: this.removeMemberFromGroup.bind(this, 'ZMuefJlM4mTpNV679Y1W'),
            condition: this.groupService.isGroupMember('ZMuefJlM4mTpNV679Y1W'),
            text: 'Leave group'
          }
        ],
        src: 'https://firebasestorage.googleapis.com/v0/b/chirpy-central.appspot.com/o/group%2FZMuefJlM4mTpNV679Y1W%2Favatar_200x200?generation=1663212688510498&alt=media',
        title: 'Quizzes',
        url: '/groups/ZMuefJlM4mTpNV679Y1W'
      })
      .concat({
        buttons: [],
        src: '/assets/chirpy/jokes-puzzles/fun.jpg',
        title: 'Fun & Games',
        url: '/pages/fun-and-games'
      });
  }
}
