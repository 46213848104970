import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MembersGuard } from '@shared/guards/members-guard';
import { BnBPage } from './bnb.page';
import { BnBReviewEditPage } from './bnb-review-edit/bnb-review-edit.page';
import { BnBReviewListPage } from './bnb-review-list/bnb-review-list.page';
import { BnBDetailComponent } from './components/bnb-detail/bnb-detail.component';
import { BnBEditComponent } from './components/bnb-edit/bnb-edit.component';
import { BnBGuard } from './guards/bnb.guard';

export const routes: Routes = [
  {
    path: '',
    component: BnBPage,
    canActivate: [BnBGuard],
    data: {
      title: 'Bed & Breakfast listings'
    },
    children: [
      {
        path: 'update/:id',
        component: BnBEditComponent,
        canActivate: [BnBGuard],
        data: {
          title: 'Update Bed & Breakfast listing'
        }
      },
      {
        path: ':id',
        component: BnBDetailComponent,
        canActivate: [BnBGuard],
        data: {
          title: 'Bed & Breakfast listing details'
        }
      }
    ]
  },
  {
    path: ':listingId/reviews',
    component: BnBReviewListPage,
    canActivate: [BnBGuard],
    data: {
      title: 'Bed & Breakfast reviews'
    }
  },
  {
    path: ':listingId/review/:reviewId',
    component: BnBReviewEditPage,
    canActivate: [BnBGuard],
    data: {
      title: 'Verify Bed & Breakfast listing'
    }
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class BnBRoutingModule {}
