import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { LimitPeriod } from '@infrastructure/constants/limit-period';
import { ModalController } from '@ionic/angular';
import { GameType } from '@shared/constants/game-type';
import { MetricType } from '@shared/constants/metric-type';
import { ILeaderboardData, ILeaderboardMetricConfig, LeaderboardMetricKey } from '@shared/models/leaderboards';
import { ConstantsService } from '@shared/services/constants.service';
import { LeaderboardService } from '@shared/services/leaderboards/leaderboard.service';
import { Observable } from 'rxjs';

@Component({
  selector: 'chirpy-game-stats',
  styleUrls: ['chirpy-game-stats.component.scss'],
  templateUrl: 'chirpy-game-stats.component.html'
})
export class ChirpyGameStatsComponent implements OnInit {
  @Input() competitionId: string;
  @Input() gameType: GameType;
  @Input() isModal: boolean = true;
  METRICS: ILeaderboardMetricConfig[];
  readonly MetricType = MetricType;
  @Input() period: LimitPeriod;
  @Input() playerId: string;
  @Input() showSubtitle: boolean = false;
  stats$: Observable<ILeaderboardData>;
  @Input() title: string = 'Statistics';

  constructor(private constantsService: ConstantsService, private leaderboardService: LeaderboardService, private modalController: ModalController) {}

  dismiss() {
    this.modalController.dismiss();
  }

  ngOnInit() {
    this.METRICS = this.constantsService.constants.LEADERBOARDS[this.gameType].metrics;
    this.stats$ = this.leaderboardService.getStatisticsForPlayer(this.playerId, this.gameType, this.period, this.competitionId);
  }
}
