import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CacheService {
  cache: Map<string, Map<string, { data: any; expiresAt: number }>>;

  // Set default to 5 minutes
  static readonly DEFAULT_TTL_IN_MS = 5 * 60 * 1000;

  constructor() {
    this.cache = new Map();
  }

  clear(collection: string): void {
    if (this.cache.has(collection)) {
      this.cache.set(collection, new Map());
    }
  }

  clearAll(): void {
    this.cache.clear();
  }

  getAllEntries(collection: string): Map<string, any> {
    if (this.cache.has(collection)) {
      return this.cache.get(collection);
    } else {
      return new Map();
    }
  }

  getObservable<T>(collection: string, key: string): Observable<T> {
    const value = this.getValue(collection, key);
    return value === null ? null : of(value);
  }

  getValue(collection: string, key: string): any {
    if (this.cache.has(collection) && this.cache.get(collection).has(key)) {
      const item = this.cache.get(collection).get(key);

      if (item.expiresAt < new Date().getTime()) {
        return null;
      }

      return item.data;
    } else {
      return null;
    }
  }

  update(collection: string, key: string, value: any, ttlInMs = CacheService.DEFAULT_TTL_IN_MS): void {
    if (!this.cache.has(collection)) {
      this.cache.set(collection, new Map());
    }

    const collectionCache = this.cache.get(collection);
    collectionCache.set(key, { data: value, expiresAt: new Date().getTime() + ttlInMs });
    this.cache.set(collection, collectionCache);
  }
}
