import { GameType } from '@shared/constants/game-type';
import { ILeaderboardOptions } from '@shared/models/leaderboards';

// Full config of all metrics is in /src/environment/chirpy/chirpy-constants.ts under LEADERBOARDS
export const leaderboardOptions: Record<GameType, ILeaderboardOptions> = {
  bingo: {
    gameType: GameType.BINGO,
    metricKeys: ['metric1', 'metric2'], // metric1 = Points, metric2 = Games played.
    rows: 10,
    sortOptions: [{ sortKey: 'metric1' }, { sortKey: 'metric2' }],
    showPrevious: true
  },
  chirple: {
    gameType: GameType.CHIRPLE,
    metricKeys: ['metric7', 'metric2', 'metric1'], // metric7 = Avg. guesses, metric2 = Win %, metric1 = Games played
    rows: 10,
    sortOptions: [
      { sortKey: 'metric7', sortKey2: 'metric2' },
      { sortKey: 'metric2', sortKey2: 'metric7' },
      { sortKey: 'metric1', sortKey2: 'metric2' }
    ],
    showPrevious: true
  }
};

export const landingPageLeaderboardOptions: Record<GameType, ILeaderboardOptions> = {
  bingo: {
    gameType: GameType.BINGO,
    metricKeys: ['metric1', 'metric2'], // metric1 = Points, metric2 = Games played.
    rows: 10,
    sortOptions: [{ sortKey: 'metric1' }, { sortKey: 'metric2' }],
    showPrevious: true
  },
  chirple: {
    customLabels: { metric5: 'Time', metric7: 'Guesses' },
    gameType: GameType.CHIRPLE,
    metricKeys: ['metric7', 'metric5'], // metric7 = Avg. guesses, metric5 = Time
    rows: 10,
    sortOptions: [
      { sortKey: 'metric7', sortKey2: 'metric5' },
      { sortKey: 'metric5', sortKey2: 'metric7' }
    ],
    showPrevious: true
  }
};
