import { Injectable } from '@angular/core';
import { IAppOptions } from '@shared/models/app-options';
import { IWhereCondition } from '@shared/models/where-condition';
import { IShareListing } from '@shared/models/share/share-listing';
import { BaseDatabase } from '@shared/services/base.database';
import { Observable } from 'rxjs';
import { first } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AppOptionsDatabase extends BaseDatabase {
  getOptions<T>(uid: string): Observable<IAppOptions<T>> {
    return this.getDocument<IAppOptions<T>>(this.COLLECTION.APP_OPTIONS, uid);
  }

  renameValue(collection: string, whereConditions: IWhereCondition[], dataFrom: any, dataTo: any = {}) {
    const orderConditions = [];
    // TODO: Use multiple queries to handle the case where we have > 10000 members matching the condition
    const limit = 9999; // Max is 10000. However this needs to be greater than the number of members in case we rename interests etc
    const queryFunction = this.createQueryFunction(whereConditions, orderConditions, limit);
    this.getDocumentsByQuery<any>(collection, queryFunction)
      .pipe(first())
      .subscribe(results => {
        results.forEach(doc => {
          this.updateDocument(collection, doc.uid, dataFrom);
          if (Object.keys(dataTo).length > 0) this.updateDocument(collection, doc.uid, dataTo);
        });
      });
  }

  updateOptions<T>(uid: string, options: IAppOptions<T>) {
    const merge = false;
    return this.updateDocument(this.COLLECTION.APP_OPTIONS, uid, options, merge);
  }
}
