import { Component, Input } from '@angular/core';
import { AnalyticsAction, AnalyticsCategory, AnalyticsService } from '@shared/services/analytics';
import { IToolbarIcon } from './models/toolbar-icon';
import { AppFooterService } from './services/app-footer.service';

@Component({
  selector: 'app-footer',
  styleUrls: ['./app-footer.component.scss'],
  templateUrl: 'app-footer.component.html'
})
export class AppFooterComponent {
  @Input() showFooter: boolean;

  get branding() {
    return this.appFooterService.branding;
  }

  get toolbarIcons$() {
    return this.appFooterService.toolbarIcons$;
  }

  constructor(private analyticsService: AnalyticsService, private appFooterService: AppFooterService) {}

  onButtonClick(button: IToolbarIcon, index: number) {
    this.analyticsService.eventTrack(AnalyticsCategory.NAVIGATION, AnalyticsAction.NAVIGATION_FOOTER_ICON, button.label, {}, index);
  }
}
