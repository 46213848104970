import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { IContactFormSetting } from '@shared/models/contact-form-setting';
import { INotificationSettings } from '@shared/models/notifications/notification-settings';
import { IAdvancedNotification } from '@shared/models/notifications/advanced-notification';
import { UserObject } from '@shared/models/user-object';
import { IUtcOffset } from '@shared/models/utc-offset';
import { AnalyticsAction, AnalyticsCategory, AnalyticsService } from '@shared/services/analytics';
import { AuthService } from '@shared/services/auth.service';
import { ConstantsService } from '@shared/services/constants.service';
import { GroupService } from '@shared/services/groups/group.service';
import { NotificationTarget, NotificationService } from '@shared/services/notifications';
import { SubscriptionService } from '@shared/services/subscription.service';
import { ToastService } from '@shared/services/toast.service';
import { GroupNotificationsPresenter } from './group-notifications.presenter';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { first, switchMap } from 'rxjs/operators';

@Component({
  selector: 'group-notifications',
  templateUrl: 'group-notifications.page.html'
})
export class GroupNotificationsPage implements OnDestroy, OnInit {
  get form() {
    return this.presenter.form;
  }

  INSTRUCTIONS: string;
  groupId: string;
  groupName: BehaviorSubject<string> = new BehaviorSubject('');
  member: UserObject;
  SETTINGS: IContactFormSetting[];
  savedValues: Record<string, boolean> = {};
  subscription: Subscription;
  timeOptions: Record<string, string> = {};

  constructor(
    private analyticsService: AnalyticsService,
    private authService: AuthService,
    private constantsService: ConstantsService,
    private groupService: GroupService,
    private notificationService: NotificationService,
    private presenter: GroupNotificationsPresenter,
    private route: ActivatedRoute,
    private subscriptionService: SubscriptionService,
    private toastService: ToastService
  ) {}

  ionViewWillEnter() {
    this.groupName.pipe(first(x => !!x)).subscribe(groupName => {
      this.analyticsService.eventTrack(AnalyticsCategory.NOTIFICATION, AnalyticsAction.NOTIFICATION_VIEW_GROUP_SETTINGS, groupName);
    });
  }

  ngOnDestroy() {
    this.subscriptionService.clearSubscription(this.subscription);
  }

  ngOnInit() {
    this.groupId = this.route.snapshot.paramMap.get('id');
    const useCache = true;
    this.groupService
      .getGroup(this.groupId, useCache)
      .pipe(first(x => !!x))
      .subscribe(group => {
        this.groupName.next(group.name);
      });

    this.INSTRUCTIONS = this.constantsService.constants.GROUPS.NOTIFICATIONS.instructions;
    this.SETTINGS = this.constantsService.constants.NOTIFICATIONS.settings[NotificationTarget.GROUP] || [];
    this.presenter.init(this.SETTINGS);

    this.subscription = this.authService._userProfileSubject
      .pipe(
        first(x => x != null),
        switchMap((member: UserObject) => {
          this.member = member;
          const settings: Observable<any> = this.notificationService.getSettingsForMember(member.uid);
          return settings;
        })
      )
      .subscribe((settings: INotificationSettings) => {
        for (let [key, setting] of Object.entries(settings[NotificationTarget.GROUP])) {
          this.savedValues[key] = setting.items.hasOwnProperty(this.groupId) ? setting.items[this.groupId] : setting.default;
        }
        this.presenter.setValue(this.savedValues);
      });
    this.subscriptionService.add(this.subscription);
  }

  onChange(event: any, id: string) {
    this.savedValues[id] = event.detail.checked;
  }

  onSave() {
    const settings = { [NotificationTarget.GROUP]: {} };
    for (let [key, value] of Object.entries(this.savedValues)) {
      settings[NotificationTarget.GROUP][key] = {
        items: {
          [this.groupId]: value
        }
      };
    }

    this.notificationService.updateSettingsForMember(this.member.uid, settings, ['/groups', this.groupId]);
    this.analyticsService.eventTrack(AnalyticsCategory.NOTIFICATION, AnalyticsAction.NOTIFICATION_UPDATE_GROUP_SETTINGS, this.groupName.value);
    this.toastService.presentToast('Notification settings updated');
  }
}
