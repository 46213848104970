import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { Country } from '@shared/constants/country';
import { InterestStatus } from '@shared/constants/interest-status';
import { ICatchup } from '@shared/models/catchups/catchup';
import { IPlace } from '@shared/models/place';
import { ISearchModel } from '@shared/models/search-model';
import { ISocial } from '@shared/models/social/social';
import { IOrderCondition } from '@shared/models/order-condition';
import { IWhereCondition } from '@shared/models/where-condition';
import { BaseDatabase } from '@shared/services/base.database';
import { CacheService } from '@shared/services/cache.service';
import { LocationService } from '@shared/services/location/location.service';
import firebase from 'firebase/app';
import { combineLatest, Observable, of, Subject } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class SocialDatabase extends BaseDatabase {
  readonly MAX_HASH_LENGTH: number = 6;
  readonly MAX_RECORDS: number = 9999;

  constructor(afs: AngularFirestore, cache: CacheService, private locationService: LocationService) {
    super(afs, cache);
  }

  createSocial(social) {
    return this.createDocument(this.COLLECTION.SOCIALS, social);
  }

  deleteSocial(uid: string) {
    return this.deleteDocument(this.COLLECTION.SOCIALS, uid);
  }

  getAllSocials(country: Country, startDate: string, endDate: string = ''): Observable<ISocial[]> {
    const whereConditions: IWhereCondition[] = this.getWhereConditions(country, startDate, endDate);

    const orderConditions: IOrderCondition[] = [{ field: 'date', direction: 'asc' }];

    const queryFn = this.createQueryFunction(whereConditions, orderConditions, this.MAX_RECORDS);
    return this.getDocumentsByQuery<ISocial>(this.COLLECTION.SOCIALS, queryFn);
  }

  getGroupCatchupsOnDate(groupId: string, date: string, useCache: boolean, cacheKey: string = ''): Observable<ICatchup[]> {
    const whereConditions: IWhereCondition[] = [
      { field: 'approved', operator: '==', value: true },
      { field: 'date', operator: '==', value: date }, // use yyyy-mm-dd date rather than 1234567890123 timestamp to avoid timezone issues
      { field: 'groupId', operator: '==', value: groupId }
    ];
    const orderConditions: IOrderCondition[] = [];

    const queryFn = this.createQueryFunction(whereConditions, orderConditions, this.MAX_RECORDS);
    return this.getDocumentsByQuery<ICatchup>(this.COLLECTION.CATCHUPS, queryFn, useCache, cacheKey);
  }

  getPlaces(q: ISearchModel): Observable<IPlace[]> {
    // We are doing a geo search to find places matching the map. Any other conditions will be applied on the second step
    if (q.mapBounds == null || q.mapZoom == null) return of(null);

    //Get the geohashes we need to query
    const hashes = this.locationService.getMinimalGeohash(q.mapBounds, q.mapZoom);

    const { foundObjects: foundPlaces, toFindIds: toFindHashes } = this.maybeGetCachedData<IPlace>(hashes, 'geohashes');

    //Set up array for results
    const outputs$: Array<Observable<IPlace[]>> = [];

    if (foundPlaces.length > 0) {
      outputs$.push(of(foundPlaces));
    }
    if (toFindHashes.length > 0) {
      // Filter out only places which have one or more socials
      const whereConditions: IWhereCondition[] = [
        {
          field: 'hasSocial',
          operator: '!=',
          value: []
        }
      ];
      // Define database queries
      // TODO: Once we know usage numbers/patterns, reevaluate whether it is most efficient to filter here and not cache
      // or cache geohash => places, and filter at the next step
      const queryFns = this.createChunkedQueryFunctions(toFindHashes, 'geohashes', 'array-contains-any', whereConditions);

      //Combine results from multiple database queries
      const newPlaces = this.getDocumentsByMultipleQueries<IPlace>(this.COLLECTION.CENTRAL_PLACES, queryFns);
      /*
      .pipe(
        map((places: IPlace[]) => {

          //Update caches with new results

          //place.geohashes = e.g. [ 'rckq1g', 'rckq1', 'rckq', 'rck', 'rc', 'r' ]
          //toFindHashes = e.g. ['rckq1', 'rckq2', 'rckmg', 'rckmf']
          const hashIndex = this.MAX_HASH_LENGTH - toFindHashes[0].length;
          toFindHashes.forEach(hash => {
            const matchingPlaces = places.filter(x => x.geohashes[hashIndex] === hash); //this also caches hashes with no matching places
            this.cache.update('geohashes', hash, matchingPlaces);
          });

          return places;
        })
      );
      */
      outputs$.push(newPlaces);
    }

    return combineLatest(...outputs$).pipe(
      map(arrays => Array.prototype.concat(...arrays)) //TODO use arrays.flat() when supported
    );
  }

  getSocial(uid: string): Observable<ISocial> {
    return this.getDocument<ISocial>(this.COLLECTION.SOCIALS, uid);
  }

  getSocials(places: IPlace[], country: Country, startDate: string): Observable<ISocial[]> {
    // Empty array of places passed because no visible places within map bounds
    if (places == null || (places !== null && places.length === 0)) {
      // Will never find socials in zero places, so return empty array
      return of([]);
    } else {
      // We have a list of valid places, search for socials matching those placeIds
      const placeIds = places.map(place => place.uid);
      if (placeIds.length === 0) return of([]);

      const whereConditions = this.getWhereConditions(country, startDate);
      const queryFns = this.createChunkedQueryFunctions(placeIds, 'locationId', 'in', whereConditions, true);

      //Set up array for results
      const outputs$: Array<Observable<ISocial[]>> = [];
      const newMembers = this.getDocumentsByMultipleQueries<ISocial>(this.COLLECTION.SOCIALS, queryFns);
      outputs$.push(newMembers);

      return combineLatest(...outputs$).pipe(
        map(arrays => Array.prototype.concat(...arrays)) //TODO use arrays.flat() when supported
      );
    }
  }

  getSocialsCreatedByMember(memberId: string, startDate: string): Observable<ISocial[]> {
    const whereConditions: IWhereCondition[] = [
      {
        field: 'memberId',
        operator: '==',
        value: memberId
      },
      {
        field: 'date',
        operator: '>=',
        value: startDate
      }
    ];

    const orderConditions: IOrderCondition[] = [{ field: 'date', direction: 'asc' }];

    const queryFn = this.createQueryFunction(whereConditions, orderConditions, 999);
    return this.getDocumentsByQuery<ISocial>(this.COLLECTION.SOCIALS, queryFn);
  }

  getSocialsForApproval(startDate: string): Observable<ISocial[]> {
    const whereConditions: IWhereCondition[] = [
      {
        field: 'approved',
        operator: '==',
        value: false
      },
      {
        field: 'date',
        operator: '>=',
        value: startDate
      }
    ];

    const orderConditions: IOrderCondition[] = [{ field: 'date', direction: 'asc' }];

    const queryFn = this.createQueryFunction(whereConditions, orderConditions, 999);
    return this.getDocumentsByQuery<ISocial>(this.COLLECTION.SOCIALS, queryFn);
  }

  getSocialsOfInterestToMember(memberId: string): Observable<ISocial[]> {
    const whereConditions: IWhereCondition[] = [
      {
        field: `interested.${memberId}`,
        operator: 'in',
        value: [InterestStatus.GOING, InterestStatus.INTERESTED]
      }
    ];

    const queryFn = this.createQueryFunction(whereConditions, [], 999);
    return this.getDocumentsByQuery<ISocial>(this.COLLECTION.SOCIALS, queryFn);
  }

  // TODO: This functions throws a funny error on admin/settings-edit if it is moved to BaseDatabase
  maybeGetCachedData<T>(queryIds: string[], cacheCollection: string) {
    //Check which objects we have already read, and only query for new ones
    const foundObjects: T[] = [];
    const toFindIds: string[] = [];
    queryIds.forEach(key => {
      const cachedValue = this.cache.getValue(cacheCollection, key);
      if (cachedValue) {
        foundObjects.push(...cachedValue);
      } else {
        toFindIds.push(key);
      }
    });

    return { foundObjects, toFindIds };
  }

  updateSocial(uid: string, data: any, merge: boolean = true) {
    return this.updateDocument(this.COLLECTION.SOCIALS, uid, data, merge);
  }

  private getWhereConditions(country: Country, startDate: string, endDate: string = '') {
    const whereConditions: IWhereCondition[] = [
      { field: 'approved', operator: '==', value: true },
      { field: 'date', operator: '>=', value: startDate } // use yyyy-mm-dd date rather than 1234567890123 timestamp to avoid timezone issues
    ];

    if (country) {
      whereConditions.push({
        field: 'country',
        operator: '==',
        value: country as string
      });
    }

    if (endDate) {
      whereConditions.push({
        field: 'date',
        operator: '<=',
        value: endDate
      });
    }
    return whereConditions;
  }
}
