import { Injectable } from '@angular/core';
import { GameType } from '@shared/constants/game-type';
import { chunk } from '@shared/functions/chunk';
import { AuthService } from '@shared/services/auth.service';
import { LeaderboardService } from '@shared/services/leaderboards/leaderboard.service';
import { UserService } from '@shared/services/user/user.service';
import firebase from 'firebase/app';
import FieldValue = firebase.firestore.FieldValue;
import { forkJoin, Observable } from 'rxjs';
import { map, mergeMap, skipWhile, take } from 'rxjs/operators';
import { IBingoGame } from '../models/bingo-game';
import { IBingoGameRound } from '../models/bingo-round';
import { IBingoRoundResult } from '../models/bingo-round-result';
import { IBingoUserPreferences } from '../models/bingo-preferences';
import { BingoStrip } from '../models/bingo-strip';
import { IBingoTicket } from '../models/bingo-ticket';
import { BingoWinType } from '../models/bingo-win-type';
import { BingoGameSpeedOption } from '../models/game-speed-option';
import { GameStatus } from '../models/game-status';
import { BingoStripGenerator } from './bingo-strip-generator';
import { BingoDatabase } from './bingo.database';

@Injectable({
  providedIn: 'root'
})
export class BingoService {
  private get currentUser() {
    return this.authService._userProfileSubject.value;
  }

  private readonly BINGO_POINTS_MAP: Record<BingoWinType, number> = {
    [BingoWinType.NO_BINGO]: 0,
    [BingoWinType.ONE_LINE]: 10,
    [BingoWinType.TWO_LINES]: 25,
    [BingoWinType.FULL_HOUSE]: 100
  };
  private readonly COLS_PER_TICKET = 9;
  private readonly ROWS_PER_TICKET = 3;

  constructor(private authService: AuthService, private bingoDatabase: BingoDatabase, private leaderboardService: LeaderboardService, private stripGenerator: BingoStripGenerator, private userService: UserService) {}

  batchEndGames(gameIds: string[]) {
    const documents = gameIds.map(gameId => ({ uid: gameId, status: GameStatus.FINISHED }));

    return this.bingoDatabase.updateGames(documents);
  }

  async createNewGame() {
    const now = new Date().getTime();

    const data: Omit<IBingoGame, 'uid'> = {
      userId: this.currentUser.uid,
      status: GameStatus.PLAYING,
      strip: this.generateGameStrip(),
      rounds: [],
      currentRound: 0,
      version: 2,
      createdAt: now,
      updatedAt: now
    };

    return await this.bingoDatabase.createGame(data);
  }

  endGame(gameId: string) {
    return this.bingoDatabase.updateGame(gameId, {
      status: GameStatus.FINISHED
    });
  }

  generateRoundFromResult(result: IBingoRoundResult): IBingoGameRound {
    const daubed = result.ticket.rows
      .flat()
      .filter(cell => cell.daubed)
      .map(cell => cell.value);

    return {
      index: result.ticket.id,
      called: result.called,
      bingo: result.bingo,
      daubed
    };
  }

  generateTickets(game: IBingoGame): IBingoTicket[] {
    if (!game) {
      return [];
    }

    const ticketData = chunk(game.strip, this.COLS_PER_TICKET * this.ROWS_PER_TICKET);
    const rounds = game.rounds.sort((a, b) => a.index - b.index);

    const tickets: IBingoTicket[] = ticketData.map((cells, i) => {
      const round = rounds[i];
      const daubed = new Set(round ? round.daubed : [] || []);

      const rows = chunk(cells, this.COLS_PER_TICKET).map((row, rowIndex) =>
        row.map((value, cellIndex) => ({
          index: rowIndex * this.COLS_PER_TICKET + cellIndex,
          value,
          daubed: value && daubed.has(value)
        }))
      );

      return { id: i, rows };
    });

    return tickets;
  }

  getGame(gameId: string) {
    return this.bingoDatabase.getGame(gameId);
  }

  getInProgressGames(limit = 50): Observable<IBingoGame[]> {
    const userId = this.currentUser.uid;

    return this.bingoDatabase.getGamesWithStatus(userId, GameStatus.PLAYING, limit).pipe(skipWhile(games => !games || games.length === 0));
  }

  getUserPreferences() {
    const useCache = true;
    const defaultUserPreferences: IBingoUserPreferences = {
      ballDelayInMs: BingoGameSpeedOption.DEFAULT
    };

    return this.bingoDatabase.getUserPreferences(this.currentUser.uid, useCache).pipe(map(userPreferences => userPreferences || defaultUserPreferences));
  }

  incrementRound(gameId: string) {
    return this.bingoDatabase.updateGame(gameId, {
      currentRound: FieldValue.increment(1)
    });
  }

  storeRoundResult(gameId: string, round: IBingoGameRound) {
    return this.bingoDatabase.updateGame(gameId, {
      rounds: FieldValue.arrayUnion(round)
    });
  }

  updateUserPreferences(preferences: Partial<IBingoUserPreferences>) {
    const userId = this.currentUser.uid;
    const merge = true;

    return this.bingoDatabase.updateUserPreferences(userId, preferences, merge);
  }

  async updateLeaderboard({ userId, rounds }: IBingoGame) {
    const pointsScored = rounds.reduce((total, { bingo }) => total + (this.BINGO_POINTS_MAP[bingo] || 0), 0);
    const wins = rounds.map(round => round.bingo).filter(x => !!x); // Don't show non-winning rounds
    const data = {
      metric1: pointsScored,
      metric2: 1, // Number of games
      metric3: wins
    };

    this.leaderboardService.updateLeaderboardForPlayer(userId, this.currentUser.displayName, this.currentUser.region, this.currentUser.country, GameType.BINGO, data);
  }

  private generateGameStrip(): BingoStrip {
    return this.stripGenerator.create();
  }
}
