import { Component } from '@angular/core';

@Component({
  selector: 'chirple-logo',
  templateUrl: './chirple-logo.component.html',
  styleUrls: ['./chirple-logo.component.scss']
})
export class ChirpleLogoComponent {
  tiles = Array(9).fill(null);
}
