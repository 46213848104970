import { NgModule } from '@angular/core';
import { AdminGuard } from '@shared/guards/admin-guard';
import { SharedModule } from '@shared/shared.module';
import { AdminRoutingModule } from './admin-routing.module';
import { AdminPage } from './admin.page';
import { CoinsEditPage } from './coins-edit/coins-edit.page';
import { AddCoinsComponent } from './coins-edit/components/add-coins/add-coins.component';
import { ReverseTransactionComponent } from './coins-edit/components/reverse-transaction/reverse-transaction.component';
import { AddAdvertiserAccessComponent } from './components/add-advertiser-access/add-advertiser-access.component';
import { AddIconBadgeComponent } from './components/add-icon-badge/add-icon-badge.component';
import { AddRemoveCoinsComponent } from './components/add-remove-coins/add-remove-coins.component';
import { AddRomanceAccessComponent } from './components/add-romance-access/add-romance-access.component';
import { AddTextBadgeComponent } from './components/add-text-badge/add-text-badge.component';
import { ApproveMarketplaceComponent } from './components/approve-marketplace/approve-marketplace.component';
import { ApproveMessagesComponent } from './components/approve-messages/approve-messages.component';
import { ApproveShareComponent } from './components/approve-share/approve-share.component';
import { ApproveSocialsComponent } from './components/approve-socials/approve-socials.component';
import { BaseAdminComponent } from './components/base-admin/base-admin.component';
import { BaseApprovalComponent } from './components/base-approval/base-approval.component';
import { BaseLogPage } from './components/base-log/base-log.page';
import { DataSearchComponent } from './components/base-log/components/data-search/data-search.component';
import { BaseReportComponent } from './components/base-report/base-report.component';
import { ChangeRoleComponent } from './components/change-role/change-role.component';
import { DeleteMemberComponent } from './components/delete-member/delete-member.component';
import { DeleteMemberMessagesComponent } from './components/delete-member-messages/delete-member-messages.component';
import { ImpersonateComponent } from './components/impersonate/impersonate.component';
import { PauseMemberComponent } from './components/pause-member/pause-member.component';
import { RemoveAdvertiserAccessComponent } from './components/remove-advertiser-access/remove-advertiser-access.component';
import { RemoveIconBadgeComponent } from './components/remove-icon-badge/remove-icon-badge.component';
import { RemoveRomanceAccessComponent } from './components/remove-romance-access/remove-romance-access.component';
import { RemoveTextBadgeComponent } from './components/remove-text-badge/remove-text-badge.component';
import { RestoreMemberComponent } from './components/restore-member/restore-member.component';
import { SearchAttendanceComponent } from './components/search-attendance/search-attendance.component';
import { SearchCoinsClaimedComponent } from './components/search-coins-claimed/search-coins-claimed.component';
import { SearchMemberComponent } from './components/search-member/search-member.component';
import { SetPasswordComponent } from './components/set-password/set-password.component';
import { EmailBodyComponent } from './email-detail/components/email-body/email-body.component';
import { EmailDataComponent } from './email-detail/components/email-data/email-data.component';
import { EmailErrorComponent } from './email-detail/components/email-error/email-error.component';
import { EmailHeaderComponent } from './email-detail/components/email-header/email-header.component';
import { CancellationLogComponent } from './components/logs/cancellation-log/cancellation-log.component';
import { ChitChatLogComponent } from './components/logs/chit-chat-log/chit-chat-log.component';
import { CoinsLogComponent } from './components/logs/coins-log/coins-log.component';
import { EmailLogComponent } from './components/logs/email-log/email-log.component';
import { MessageLogComponent } from './components/logs/message-log/message-log.component';
import { NoteLogComponent } from './components/logs/note-log/note-log.component';
import { CatchupsAttendedByMemberComponent } from './components/reports/catchups-attended-by-member/catchups-attended-by-member.component';
import { CatchupsByDateComponent } from './components/reports/catchups-by-date/catchups-by-date.component';
import { CatchupsByGroupComponent } from './components/reports/catchups-by-group/catchups-by-group.component';
import { CoinsClaimedByDateComponent } from './components/reports/coins-claimed-by-date/coins-claimed-by-date.component';
import { CoinsClaimedByMemberComponent } from './components/reports/coins-claimed-by-member/coins-claimed-by-member.component';
import { LastActivityByDateComponent } from './components/reports/last-activity-by-date/last-activity-by-date.component';
import { MemberActivityComponent } from './components/reports/member-activity/member-activity.component';
import { NextCatchupComponent } from './components/reports/next-catchup/next-catchup.component';
import { SocialsByDateComponent } from './components/reports/socials-by-date/socials-by-date.component';
import { EmailDetailPage } from './email-detail/email-detail.page';
import { MemberDetailPage } from './member-detail/member-detail.page';
import { SettingsEditPage } from './settings-edit/settings-edit.page';
import { SettingsListPage } from './settings-list/settings-list.page';

@NgModule({
  imports: [AdminRoutingModule, SharedModule],
  declarations: [
    AddAdvertiserAccessComponent,
    AddCoinsComponent,
    AddIconBadgeComponent,
    AddRemoveCoinsComponent,
    AddRomanceAccessComponent,
    AddTextBadgeComponent,
    AdminPage,
    ApproveMarketplaceComponent,
    ApproveMessagesComponent,
    ApproveShareComponent,
    ApproveSocialsComponent,
    BaseAdminComponent,
    BaseApprovalComponent,
    BaseLogPage,
    BaseReportComponent,
    CancellationLogComponent,
    CatchupsAttendedByMemberComponent,
    CatchupsByDateComponent,
    CatchupsByGroupComponent,
    ChangeRoleComponent,
    ChitChatLogComponent,
    CoinsClaimedByDateComponent,
    CoinsClaimedByMemberComponent,
    CoinsEditPage,
    CoinsLogComponent,
    DataSearchComponent,
    DeleteMemberComponent,
    DeleteMemberMessagesComponent,
    EmailBodyComponent,
    EmailDataComponent,
    EmailDetailPage,
    EmailErrorComponent,
    EmailHeaderComponent,
    EmailLogComponent,
    ImpersonateComponent,
    LastActivityByDateComponent,
    MemberActivityComponent,
    MemberDetailPage,
    MessageLogComponent,
    NextCatchupComponent,
    NoteLogComponent,
    PauseMemberComponent,
    RemoveAdvertiserAccessComponent,
    RemoveIconBadgeComponent,
    RemoveRomanceAccessComponent,
    RemoveTextBadgeComponent,
    RestoreMemberComponent,
    ReverseTransactionComponent,
    SearchAttendanceComponent,
    SearchCoinsClaimedComponent,
    SearchMemberComponent,
    SetPasswordComponent,
    SettingsEditPage,
    SettingsListPage,
    SocialsByDateComponent
  ],
  providers: [AdminGuard]
})
export class AdminModule {}
