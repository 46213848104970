import { UserObject } from '@shared/models/user-object';
import { AbstractFooterService } from '../abstract-footer.service';

export class FriendsFooterService extends AbstractFooterService {
  getToolbar(user: UserObject) {
    return [
      {
        image: {
          active: '/assets/friends/footer/home-active.svg',
          inactive: '/assets/friends/footer/home-inactive.svg'
        },
        label: 'HOME',
        routerLink: '/home'
      },
      {
        image: {
          active: '/assets/friends/footer/find-friends-active.svg',
          inactive: '/assets/friends/footer/find-friends-inactive.svg'
        },
        label: 'FRIENDS',
        routerLink: '/find-friends'
      },
      {
        image: {
          active: '/assets/friends/footer/central-active.svg',
          inactive: '/assets/friends/footer/central-inactive.svg'
        },
        label: 'CENTRAL',
        routerLink: '/central'
      },
      {
        badge: {
          counter: user.messageNotificationCount,
          showBadge: user.messageNotificationCount > 0
        },
        image: {
          active: '/assets/friends/footer/messages-active.svg',
          inactive: '/assets/friends/footer/messages-inactive.svg'
        },
        label: 'MESSAGES',
        routerLink: '/messages'
      },
      {
        badge: {
          counter: user.coinsBalance,
          showBadge: user.coinsBalance > 0
        },
        image: {
          active: '/assets/friends/footer/coins-active.svg',
          inactive: '/assets/friends/footer/coins-inactive.svg'
        },
        label: 'WALLET',
        routerLink: '/coins'
      }
    ];
  }
}
