import { Injectable, OnDestroy } from '@angular/core';
import { GroupType } from '@shared/constants/group-type';
import { IPlace } from '@shared/models/place';
import { AuthService } from '@shared/services/auth.service';
import { SubscriptionService } from '@shared/services/subscription.service';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { IGroup } from '@shared/models/groups/group';
import { GroupService } from '@shared/services/groups/group.service';
import { SignupGroupsDatabase } from './groups.database';

@Injectable({
  providedIn: 'root'
})
export class SignupGroupsService implements OnDestroy {
  locationSubscription: Subscription;
  DISTANCE: number = 25.0;

  constructor(private authService: AuthService, private groupService: GroupService, private database: SignupGroupsDatabase, private subscriptionService: SubscriptionService) {}

  findClosestGroups(coordinates): Observable<string[]> {
    const closestGroups = new BehaviorSubject<string[]>([]);
    this.subscriptionService.clearSubscription(this.locationSubscription);
    this.locationSubscription = this.database.loadGroupLocations().subscribe(
      (places: IPlace[]) => {
        const nearbyGroups = places.reduce((groupIds: string[], place: IPlace) => {
          const distance = this.calculateDistance(place, coordinates);
          if (this.isNearby(place, distance)) {
            place.hasGroup.forEach(groupId => {
              groupIds[groupId] = distance;
            });
          }
          return groupIds;
        }, {});

        const sortedGroups = Object.entries(nearbyGroups).sort((a: [string, number], b: [string, number]) => a[1] - b[1]);
        //Only return three closest groups to avoid decision fatigue
        closestGroups.next(sortedGroups.map(([id, distance]) => id).slice(0, 3));
      },
      err => {
        closestGroups.next([]);
      }
    );
    this.subscriptionService.add(this.locationSubscription);
    return closestGroups;
  }

  ngOnDestroy() {
    this.subscriptionService.clearSubscription(this.locationSubscription);
  }

  async setGroups(groups: IGroup[]) {
    for (const group of groups) {
      await this.groupService.addMemberToGroup(group.uid, group.name, group.memberCount, this.authService._userProfileSubject.value.uid, this.authService._userProfileSubject.value.displayName, false);
      const hasHosts = Object.keys(group.hosts).length > 0;
      if (hasHosts) {
        const isChat = group.groupType === GroupType.CHAT_GROUP;
        const isVirtual = group.groupType === GroupType.SPECIAL_INTEREST_GROUP;
        this.groupService.sendMemberJoinedGroupNotifications(group.uid, group.name, group.hosts, this.authService._userProfileSubject.value.uid, isVirtual, isChat);
      }
    }
  }

  private calculateDistance(place: IPlace, coordinates: firebase.firestore.GeoPoint) {
    // If longitudes are on opposite sides of the dateline, need to subtract 360 to get correct difference
    const longitude = place.coordinates.longitude * coordinates.longitude < 0 ? 360 - Math.abs(place.coordinates.longitude - coordinates.longitude) : Math.abs(place.coordinates.longitude - coordinates.longitude);
    // We don't need the exact distance in km, which is more computationally expensive, just a quantity that can be used to sort the groups from nearest to furthest
    return Math.abs(place.coordinates.latitude - coordinates.latitude) + longitude;
  }

  private isNearby(place: IPlace, distance: number) {
    return distance < this.DISTANCE;
  }
}
