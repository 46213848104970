import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { LimitPeriod } from '@infrastructure/constants/limit-period';
import { GameType } from '@shared/constants/game-type';
import { range } from '@shared/functions/range';
import { ILeaderboard, ILeaderboardMetricConfig, ILeaderboardRow, LeaderboardMetricKey } from '@shared/models/leaderboards';
import { UserObject } from '@shared/models/user-object';
import { AnalyticsService, AnalyticsCategory, AnalyticsAction } from '@shared/services/analytics';
import { AuthService } from '@shared/services/auth.service';
import { ConstantsService } from '@shared/services/constants.service';
import { DateTimeService } from '@shared/services/date-time.service';
import { LeaderboardService } from '@shared/services/leaderboards/leaderboard.service';
import { SubscriptionService } from '@shared/services/subscription.service';
import { Subject, Subscription } from 'rxjs';
import { first, switchMap } from 'rxjs/operators';

@Component({
  selector: 'chirpy-leaderboard',
  styleUrls: ['chirpy-leaderboard.component.scss'],
  templateUrl: 'chirpy-leaderboard.component.html'
})
export class ChirpyLeaderboardComponent implements OnChanges, OnDestroy, OnInit {
  @Input() boardId: string;
  @Input() competitionId: string;
  @Input() customLabels: Record<string, string>;
  @Input() gameType: GameType;
  @Input() isPrevious: boolean = false;
  labels: Record<string, string> = {};
  leaderboard: ILeaderboard;
  leaderboardSubscription: Subscription;
  loadingElements: number[] = [];
  member: UserObject;
  metricIndexes: number[] = [];
  @Input() metricKeys: string[];
  METRICS: Partial<Record<LeaderboardMetricKey, ILeaderboardMetricConfig>> = {};
  @Input() noDataMessage: string = 'No data';
  @Input() noEntriesMessage: string = 'Be the first to get on the board!';
  @Input() period: LimitPeriod = LimitPeriod.MONTH;
  @Input() place: string;
  @Input() reload: number;
  @Input() rows: number = 10;
  @Input() showPrevious: boolean = false;
  @Input() showViewAll: boolean = false;
  sortedLeaderboard$: Subject<ILeaderboardRow[]> = new Subject();
  @Input() sortKey: LeaderboardMetricKey;
  @Input() sortKey2: LeaderboardMetricKey;
  subtitle: string;
  title: string;
  viewAllUrl: string;

  private readonly periodLookupTable: Record<LimitPeriod, string> = {
    ever: 'All-time',
    day: 'Daily',
    hour: 'Hourly',
    week: 'Weekly',
    month: 'Monthly',
    year: 'Yearly'
  };

  constructor(private analyticsService: AnalyticsService, private authService: AuthService, private constantsService: ConstantsService, private dateTimeService: DateTimeService, private leaderboardService: LeaderboardService, private subscriptionService: SubscriptionService) {}

  private didChange(changes: SimpleChanges, properties: string[]) {
    return properties.some(property => changes[property] && changes[property].previousValue !== changes[property].currentValue);
  }

  private isFirstChange(changes: SimpleChanges, properties: string[]) {
    return properties.some(property => changes[property] && changes[property].firstChange);
  }

  ngOnChanges(changes: SimpleChanges) {
    // Get new leaderboard from database
    if (this.didChange(changes, ['place', 'reload'])) {
      const isReload = this.didChange(changes, ['reload']);
      const isFirst = this.isFirstChange(changes, ['place']);

      this.initMetrics();
      this.maybeSetBoardId();
      this.setSubtitle();
      this.setTitle();
      this.loadAndSortLeaderboard(isReload || isFirst);
    }
    // Re-sort existing leaderboard
    else if (this.didChange(changes, ['metricKeys', 'sortKey'])) {
      this.initMetrics();
      this.sortLeaderboard();
    }

    this.viewAllUrl = `/games/leaderboard/${this.gameType}`;
  }

  ngOnDestroy() {
    this.subscriptionService.clearSubscription(this.leaderboardSubscription);
  }

  ngOnInit() {
    this.loadingElements = range(1, this.rows);
  }

  onTogglePrevious() {
    if (!this.showPrevious) return;
    this.isPrevious = !this.isPrevious;

    const action = this.isPrevious ? AnalyticsAction.LEADERBOARDS_VIEW_PREVIOUS : AnalyticsAction.LEADERBOARDS_VIEW_CURRENT;
    this.analyticsService.eventTrack(AnalyticsCategory.LEADERBOARDS, action, this.period, { type: this.gameType });

    const force = true;
    this.maybeSetBoardId(force);
    this.setSubtitle();
    this.setTitle();
    this.loadAndSortLeaderboard();
  }

  private initMetrics() {
    const metricsLength = (this.constantsService.constants.LEADERBOARDS[this.gameType].metrics || []).length;
    this.metricIndexes = [];
    for (let i = 0; i < metricsLength; i++) {
      const metric = this.constantsService.constants.LEADERBOARDS[this.gameType].metrics[i];
      if (metric.public) this.METRICS[metric.key] = metric;
      this.labels[metric.key] = metric.label;
    }
    this.metricIndexes = this.metricKeys.map(x => Object.keys(this.METRICS).indexOf(x));

    // Allow override of label text
    for (const [key, label] of Object.entries(this.customLabels || {})) {
      this.labels[key] = label;
    }
  }

  private loadAndSortLeaderboard(isReload: boolean = false) {
    this.subscriptionService.clearSubscription(this.leaderboardSubscription);
    this.leaderboardSubscription = this.authService._userProfileSubject
      .pipe(
        first(x => !!x),
        switchMap(member => {
          this.member = member;
          const useCache = !isReload;
          return this.leaderboardService.getLeaderboardForGame(this.gameType, this.place, this.boardId, this.competitionId, useCache);
        }),
        first()
      )
      .subscribe(leaderboard => {
        this.leaderboard = leaderboard;
        this.sortedLeaderboard$.next(this.leaderboardService.sortLeaderboard(this.leaderboard, this.gameType, this.sortKey, this.rows, this.member.uid, this.sortKey2));
      });
    this.subscriptionService.add(this.leaderboardSubscription);
  }

  private maybeSetBoardId(force: boolean = false) {
    if (!this.boardId || force) {
      const date = this.isPrevious ? this.dateTimeService.getPreviousPeriod() : this.dateTimeService.getPeriod();
      this.boardId = date[this.period] || '';
    }
  }

  private setSubtitle() {
    const date = this.isPrevious ? this.dateTimeService.getPreviousFormattedPeriod() : this.dateTimeService.getFormattedPeriod();
    this.subtitle = date[this.period] || '';
  }

  private setTitle() {
    const period = this.periodLookupTable[this.period];
    const region = this.place.split('_')[1];
    let title = '';

    title = `${period} leaderboard`;

    if (region) {
      title += ` for ${region}`;
    }

    this.title = title;
  }

  private sortLeaderboard() {
    this.sortedLeaderboard$.next(this.leaderboardService.sortLeaderboard(this.leaderboard, this.gameType, this.sortKey, this.rows, this.member.uid, this.sortKey2));
  }
}
