import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { EventType } from '@infrastructure/constants/event-type';
import { AdminRole } from '@shared/constants/admin-role';
import { IButtonGroup } from '@shared/models/button-group';
import { ISocial } from '@shared/models/social/social';
import { IMemberThreadRelatedType } from '@shared/models/messages/member-thread';
import { UserObject } from '@shared/models/user-object';
import { AnalyticsAction, AnalyticsCategory, AnalyticsService } from '@shared/services/analytics';
import { AuthService } from '@shared/services/auth.service';
import { ConstantsService } from '@shared/services/constants.service';
import { MessageHelperService } from '@shared/services/messages/message-helper.service';
import { SocialService } from '@shared/services/social/social.service';
import { SubscriptionService } from '@shared/services/subscription.service';
import { UIService } from '@shared/services/ui.service';
import { Subscription } from 'rxjs';
import { first } from 'rxjs/operators';

@Component({
  selector: 'app-social-detail',
  templateUrl: './social-detail.page.html',
  styleUrls: ['./social-detail.page.scss']
})
export class SocialDetailPage implements OnInit {
  get CONSTANTS() {
    return this.constantsService.constants.SOCIAL.DETAIL;
  }

  get DISCLAIMER() {
    return this.constantsService.constants.SOCIAL.disclaimer;
  }

  get isAdmin() {
    return this.authService.isAdmin([AdminRole.HOSTS]);
  }

  BRANDING: string = '';
  buttonGroups: IButtonGroup[];
  canApproveSocial: boolean = false;
  canUpdateOrDeleteSocial: boolean = false;
  defaultHref: string[];
  errorMessage: string;
  ONLY_IN_GROUP: boolean;
  social: ISocial;
  socialSubscription: Subscription;
  REQUIRES_ADMIN_APPROVAL: boolean;
  title: string;
  user: UserObject;

  constructor(private authService: AuthService, private analyticsService: AnalyticsService, private constantsService: ConstantsService, private socialService: SocialService, private subscriptionService: SubscriptionService, private route: ActivatedRoute, private router: Router) {}

  approveSocial() {
    this.socialService.approveSocial(this.social);
  }

  cancelSocial() {
    this.socialService.deleteSocial(this.social);
  }

  getSocial(uid: string) {
    this.socialSubscription = this.socialService.getSocial(uid).subscribe(social => {
      if (social == null) {
        this.errorMessage = 'Social does not exist';
        return;
      }

      if (social.interested == null) social.interested = {};
      this.social = social;
      this.title = this.social.title;

      this.sendAnalytics(this.social);

      const isOrganiser = this.user.uid === social.memberId;
      this.canApproveSocial = this.REQUIRES_ADMIN_APPROVAL ? this.isAdmin : isOrganiser || this.isAdmin;
      this.canUpdateOrDeleteSocial = isOrganiser || this.isAdmin;
      this.defaultHref = this.getDefaultRouterLink();
      this.initButtons();
    });
    this.subscriptionService.add(this.socialSubscription);
  }

  ngOnInit() {
    this.BRANDING = this.constantsService.constants.SOCIAL.branding;
    this.ONLY_IN_GROUP = this.constantsService.constants.SOCIAL.onlyInGroup;
    this.REQUIRES_ADMIN_APPROVAL = this.constantsService.constants.SOCIAL.requiresAdminApproval;

    // Set fallback title
    this.title = this.CONSTANTS.title;

    this.authService._userProfileSubject.subscribe(user => {
      this.user = user;
      // set permissions to true if the member is an admin otherwise wait until we've loaded the social in getSocial to check if the member is the organiser
      this.canApproveSocial = this.isAdmin;
      this.canUpdateOrDeleteSocial = this.isAdmin;
    });

    const uid = this.route.snapshot.paramMap.get('id');
    if (uid == null) {
      this.social = {
        address: '',
        eventType: EventType.Social,
        coordinates: null,
        country: '',
        created: 0,
        date: '',
        datetime: 0,
        description: '',
        endTime: '',
        interested: {},
        locationId: null,
        locationName: null,
        memberId: '',
        memberName: '',
        time: '',
        title: ''
      };
      return;
    }

    this.getSocial(uid);
  }

  rejectSocial() {
    this.socialService.rejectSocial(this.social);
  }

  sendAnalytics(social: ISocial) {
    this.analyticsService.eventTrack(AnalyticsCategory.SOCIAL, AnalyticsAction.SOCIAL_VIEW_DETAILS, this.social.uid);
  }

  private getDefaultRouterLink() {
    if (this.ONLY_IN_GROUP && this.social.sharedGroupIds) {
      const groupId = this.social.sharedGroupIds[0];
      return ['/groups/socials', groupId];
    } else {
      return ['/social'];
    }
  }

  private getUpdateRouterLink() {
    if (this.ONLY_IN_GROUP && this.social.sharedGroupIds && this.social.sharedGroupNames) {
      const groupId = this.social.sharedGroupIds[0];
      const groupName = this.social.sharedGroupNames[0];
      return ['/social/update', this.social.uid, groupId];
    } else {
      return ['/social/update', this.social.uid];
    }
  }

  private initButtons() {
    if (!this.social) return [];

    this.buttonGroups = [
      {
        condition: this.canApproveSocial,
        buttons: [
          {
            click: this.approveSocial.bind(this),
            condition: !this.social.approved,
            icon: 'checkmark',
            responsive: false,
            text: this.CONSTANTS.approve
          },
          {
            click: this.rejectSocial.bind(this),
            condition: this.social.approved && !this.ONLY_IN_GROUP,
            icon: 'close',
            responsive: false,
            text: this.CONSTANTS.reject
          }
        ]
      },
      {
        condition: this.canUpdateOrDeleteSocial,
        buttons: [
          {
            condition: true,
            icon: 'create',
            routerLink: this.getUpdateRouterLink(),
            text: 'Update'
          },
          {
            click: this.cancelSocial.bind(this),
            condition: true,
            icon: 'close-circle',
            text: `Cancel ${this.BRANDING}`
          }
        ]
      },
      {
        condition: true,
        buttons: [
          {
            condition: this.user.uid !== this.social.memberId,
            icon: 'chatboxes',
            routerLink: ['/messages/compose', this.social.memberId],
            responsive: false,
            text: 'Message Organiser'
          }
        ]
      }
    ];
  }
}
