import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AdminRole } from '@shared/constants/admin-role';
import { IBnBListing } from '@shared/models/bnb/bnb-listing';
import { IBnBReview } from '@shared/models/bnb/bnb-review';
import { UserObject } from '@shared/models/user-object';
import { AuthService } from '@shared/services/auth.service';
import { BnBService } from '@shared/services/bnb/bnb.service';
import { ConstantsService } from '@shared/services/constants.service';
import { Observable } from 'rxjs';
import { first } from 'rxjs/operators';

@Component({
  selector: 'app-bnb-review-list',
  styleUrls: ['./bnb-review-list.page.scss'],
  templateUrl: './bnb-review-list.page.html'
})
export class BnBReviewListPage implements OnInit {
  bnbListing$: Observable<IBnBListing>;
  isAdmin$: Observable<boolean>;
  listingId: string;
  noNoticesMessage: string = `No reviews yet`;
  reviews$: Observable<IBnBReview[]>;

  constructor(private authService: AuthService, private bnbService: BnBService, private route: ActivatedRoute) {}

  ngOnInit() {
    this.isAdmin$ = this.authService.isAdmin$([AdminRole.SUPER, AdminRole.MODERATOR]);
    this.listingId = this.route.snapshot.paramMap.get('listingId');
    this.bnbListing$ = this.bnbService.getBnBListing(this.listingId);
    this.reviews$ = this.bnbService.getBnBReviews(this.listingId);
  }

  onApprove(reviewId: string) {
    this.bnbService.approveBnBReview(this.listingId, reviewId);
  }

  onDelete(review: IBnBReview) {
    this.bnbService.deleteBnBReview(review);
  }

  onReject(reviewId: string) {
    this.bnbService.rejectBnBReview(this.listingId, reviewId);
  }
}
