import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { GameType } from '@shared/constants/game-type';

@Component({
  selector: 'app-end-game-modal',
  templateUrl: './end-game-modal.component.html',
  styleUrls: ['./end-game-modal.component.scss']
})
export class ChirpleEndGameModalComponent implements OnInit {
  @Input() answer: string;
  @Input() didWin: boolean = false;
  @Input() duration: number;
  @Input() numberOfAttempts?: number;
  @Input() playerId: string;
  readonly gameType = GameType.CHIRPLE;
  winningHeadingText = '';

  // Start with null so that the index of each string correlates to the number of attempts taken
  private winnerHeadings = [null, 'Genius', 'Magnificent', 'Impressive', 'Splendid', 'Great', 'Phew'];

  constructor(private modalController: ModalController) {}

  ngOnInit() {
    this.setHeadingText();
  }

  onCloseButtonClicked() {
    this.modalController.dismiss();
  }

  private setHeadingText() {
    if (this.didWin) {
      this.winningHeadingText = this.winnerHeadings[this.numberOfAttempts] || 'Congrats';
    }
  }
}
