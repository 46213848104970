import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { AdminGuard } from '@shared/guards/admin-guard';
import { SharedModule } from '@shared/shared.module';
import { ChatPage } from './chat/chat.page';
import { LeaderboardPage } from './leaderboard/leaderboard.page';
import { GamesPage } from './games.page';

@NgModule({
  declarations: [ChatPage, GamesPage, LeaderboardPage],
  imports: [
    SharedModule,
    RouterModule.forChild([
      {
        path: 'bingo',
        loadChildren: () => import('./bingo/bingo.module').then(m => m.BingoPageModule)
      },
      {
        path: 'chat/:id',
        component: ChatPage
      },
      {
        path: 'chirple',
        loadChildren: () => import('./chirple/chirple.module').then(m => m.ChirpleModule)
      },
      {
        path: 'leaderboard/:gameType',
        component: LeaderboardPage
      },
      {
        path: '',
        component: GamesPage,
        data: {
          title: 'Games'
        }
      }
    ])
  ]
})
export class GamesModule {}
