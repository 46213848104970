import { NgModule } from '@angular/core';
import { LeafletModule } from '@asymmetrik/ngx-leaflet';
import { SharedModule } from '@shared/shared.module';
import { SocialDetailHeaderComponent } from './components/social-detail-header/social-detail-header.component';
import { SocialLocatorComponent } from './components/social-locator-map/social-locator.component';
import { SocialLocatorPinComponent } from './components/social-locator-map/pin/pin.component';
import { SocialLocatorService } from './components/social-locator-map/social-locator.service';
import { SocialRsvpComponent } from './components/social-rsvp/social-rsvp.component';
import { SocialSearchComponent } from './components/social-search/social-search.component';
import { SocialDetailPage } from './social-detail/social-detail.page';
import { SocialEditPage } from './social-edit/social-edit.page';
import { SocialInterestedPage } from './social-interested/social-interested.page';
import { SocialPage } from './social.page';
import { SocialRoutingModule } from './social-routing.module';

@NgModule({
  imports: [LeafletModule, SocialRoutingModule, SharedModule],
  declarations: [SocialDetailHeaderComponent, SocialDetailPage, SocialEditPage, SocialInterestedPage, SocialLocatorComponent, SocialLocatorPinComponent, SocialPage, SocialRsvpComponent, SocialSearchComponent],
  entryComponents: [SocialLocatorPinComponent],
  providers: [SocialLocatorService]
})
export class SocialModule {}
