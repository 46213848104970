import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ICoinsTrigger } from '@infrastructure/models/coins-trigger';
import { AlertController } from '@ionic/angular';
import { IChirpyMemberListButton } from '@shared/components/chirpy-member-list/chirpy-member-list-button';
import { ALL_COUNTRIES } from '@shared/constants/country';
import { CoinsTransactionType } from '@shared/constants/coins-transaction-type';
import { IAttendeeData } from '@shared/models/catchups/attendee-data';
import { ICatchup } from '@shared/models/catchups/catchup';
import { CatchupRsvpStatus } from '@shared/constants/catchup-rsvp-status';
import { ICoinsTransaction } from '@shared/models/coins/coins-transaction';
import { ITransformFunction } from '@shared/models/transform-function';
import { UserObject } from '@shared/models/user-object';
import { AppOptionsService } from '@shared/services/app-options/app-options.service';
import { AnalyticsAction, AnalyticsCategory, AnalyticsService } from '@shared/services/analytics';
import { AuthService } from '@shared/services/auth.service';
import { CatchupRsvpService } from '@shared/services/catchups/catchup-rsvp.service';
import { CatchupService } from '@shared/services/catchups/catchup.service';
import { CoinsService } from '@shared/services/coins/coins.service';
import { DateTimeService } from '@shared/services/date-time.service';
import { EmailService } from '@shared/services/email/email.service';
import { GroupService } from '@shared/services/groups/group.service';
import { SubscriptionService } from '@shared/services/subscription.service';
import { ToastService } from '@shared/services/toast.service';
import { UserService } from '@shared/services/user/user.service';
import { of, Subscription } from 'rxjs';
import { first, skipWhile, switchMap, take } from 'rxjs/operators';

@Component({
  selector: 'app-catchup-attendees',
  templateUrl: './catchup-attendees.component.html'
})
export class CatchupAttendeesComponent implements OnInit {
  attendeeData: Record<string, IAttendeeData> = {};
  attendees: UserObject[];
  buttons: IChirpyMemberListButton[];
  canManageGroup: boolean; // is Admin or Group Host
  catchup: ICatchup;
  catchupSubscription: Subscription;
  dataMap: Record<string, ITransformFunction> = {
    info: data => data,
    status: data => {
      return data === CatchupRsvpStatus.GOING_CLAIMED ? 'Coins claimed' : null;
    }
  };
  largePhotoURL: string;
  user: UserObject;

  constructor(
    private alertController: AlertController,
    private analyticsService: AnalyticsService,
    private appOptionsService: AppOptionsService,
    private authService: AuthService,
    private catchupService: CatchupService,
    private catchupRsvpService: CatchupRsvpService,
    private coinsService: CoinsService,
    private dateTimeService: DateTimeService,
    private emailService: EmailService,
    private groupService: GroupService,
    private subscriptionService: SubscriptionService,
    private toastService: ToastService,
    private userService: UserService,
    private route: ActivatedRoute
  ) {}

  initButtons(catchup: ICatchup = null) {
    if (catchup === null || !this.catchupRsvpService.isClosed(catchup)) {
      this.buttons = [
        {
          canShow: member => this.canManageGroup && this.isMemberAccepted(member),
          command: CatchupRsvpStatus.REMOVED,
          label: 'Remove'
        },
        {
          canShow: member => this.canManageGroup && this.isMemberAccepted(member),
          command: CatchupRsvpStatus.WAITING_FOR_HOST,
          label: 'Waitlist'
        },
        {
          canShow: member => this.canManageGroup,
          command: 'edit-info',
          label: 'Add Info'
        }
      ];
    } else if (this.catchupRsvpService.isClosed(catchup)) {
      this.buttons = [
        {
          canShow: member => this.canManageGroup && (this.isMemberAttended(member) || this.isMemberAccepted(member)),
          command: CatchupRsvpStatus.DID_NOT_ATTEND,
          label: `Mark as absent`
        }
      ];
    } else {
      this.buttons = [];
    }
  }

  isMemberAttended(member: UserObject) {
    return this.catchup.attendees[member.uid] === CatchupRsvpStatus.GOING_CLAIMED;
  }

  isMemberAccepted(member: UserObject) {
    return this.catchup.attendees[member.uid] === CatchupRsvpStatus.GOING; // Shouldn't allow Host to remove members who have claimed coins for attending
  }

  ngOnDestroy() {
    this.subscriptionService.clearSubscription(this.catchupSubscription);
  }

  ngOnInit() {
    this.initButtons();

    const uid = this.route.snapshot.parent.paramMap.get('catchupId');

    // Ideally call eventTrack on ionViewWillEnter, but then have a race condition with loading CatchUp data
    this.analyticsService.eventTrack(AnalyticsCategory.CATCHUPS, AnalyticsAction.CATCHUPS_VIEW_ATTENDEES, uid);

    this.catchupSubscription = this.catchupService
      .getCatchup(uid)
      .pipe(
        switchMap((catchup: ICatchup) => {
          if (catchup == null) return;

          this.catchup = catchup;
          this.initButtons(catchup);
          return this.groupService.canManageGroup$(catchup.groupId);
        }),
        switchMap((canManageGroup: boolean) => {
          this.canManageGroup = canManageGroup;
          return canManageGroup ? this.catchupService.getCatchupAttendeesData(this.catchup.uid) : of({});
        }),
        switchMap((attendeeData: Record<string, IAttendeeData>) => {
          this.attendeeData = attendeeData || {};
          return this.catchupService.getCatchupAttendees(this.catchup.attendees);
        })
      )
      .subscribe((attendees: UserObject[]) => {
        if (attendees == null) return;
        this.attendees = attendees;
      });
    this.subscriptionService.add(this.catchupSubscription);

    this.authService._userProfileSubject
      .pipe(
        skipWhile(u => !u),
        take(1)
      )
      .subscribe(user => (this.user = user));
  }

  onButtonClick({ member, command }) {
    switch (command) {
      case CatchupRsvpStatus.DID_NOT_ATTEND:
        return this.presentRemoveModal(member);

      case CatchupRsvpStatus.REMOVED:
        return this.catchupRsvpService.removeFromAttendees(this.catchup, member);

      case CatchupRsvpStatus.WAITING_FOR_HOST:
        return this.catchupRsvpService.moveToWaitlist(this.catchup, member);

      case 'edit-info':
        return this.onEditInfo(member);
    }
  }

  async onEditInfo(member: UserObject) {
    const memberData = this.attendeeData[member.uid] || {};
    const alert = await this.alertController.create({
      header: `Add info about ${member.displayName}`,
      message: `This is only visible to hosts and co-hosts)`,
      inputs: [
        {
          name: 'info',
          type: 'text',
          value: memberData.info || '',
          placeholder: `Enter info (optional)`
        }
      ],
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
          cssClass: 'secondary'
        },
        {
          text: `Save`,
          handler: data => this.editInfoHandler(member.uid, data)
        }
      ]
    });

    await alert.present();
  }

  onRemoveMember(member: UserObject, data: any) {
    const alreadyClaimedCoins: boolean = this.isMemberAttended(member);

    this.catchupRsvpService.didNotAttend(this.catchup, member);

    // Remove coins if the member has already claimed them
    if (alreadyClaimedCoins) {
      this.appOptionsService
        .getOptionsValues<ICoinsTrigger>('coinsTriggers', ALL_COUNTRIES)
        .pipe(first(x => !!x))
        .subscribe(values => {
          const claimCoinsTrigger: ICoinsTrigger[] = values.filter(x => x.key === AnalyticsAction.COINS_CLAIM_CATCHUP);
          if (claimCoinsTrigger.length > 0 && claimCoinsTrigger[0].setting.value > 0) {
            const transaction: ICoinsTransaction = {
              amount: -claimCoinsTrigger[0].setting.value,
              date: this.dateTimeService.getDateTime(),
              decrementStatusPoints: true,
              description: `Reversed: ${claimCoinsTrigger[0].setting.label}`,
              memberId: member.uid,
              memberName: member.displayName,
              staffId: this.authService.member.uid,
              staffName: this.authService.member.displayName,
              type: CoinsTransactionType.DEBIT
            };
            this.coinsService.addMemberInitiatedTransaction(transaction);
          }
        });
    }

    // Send "sorry we missed you" email if this option was selected
    if (data.length > 0 && data[0] === true) {
      // send email
      this.emailService.sendCatchupSorryWeMissedYouEmail(member, this.catchup);
    }

    const message = `${member.displayName} has been removed from the attendees list.`;
    this.toastService.presentToast(message);
  }

  async presentRemoveModal(member: UserObject) {
    const alert = await this.alertController.create({
      header: 'Mark as absent?',
      cssClass: 'wide-select',
      inputs: [
        {
          label: `Send member a "Sorry we missed you" email`,
          name: 'sendEmail',
          type: 'checkbox',
          value: true
        }
      ],
      message: `${member.displayName} will be removed from the RSVP list and will not be able to claim coins for attending.<br/> If they have already claimed coins these will be deducted.`,
      buttons: [
        {
          text: 'Mark as absent',
          handler: data => {
            this.onRemoveMember(member, data);
          }
        },
        {
          text: 'Cancel',
          role: 'cancel'
        }
      ]
    });

    await alert.present();
  }

  private editInfoHandler(memberId: string, data: any) {
    this.catchupService.updateCatchupAttendeeData(this.catchup.uid, memberId, data);
    this.analyticsService.eventTrack(AnalyticsCategory.CATCHUPS, AnalyticsAction.CATCHUPS_ADD_INFO, this.catchup.uid, { type: 'attendees' });
  }
}
