/**
 * Creates an array of elements split into groups the length of `size`.
 * If `array` can't be split evenly, the final chunk will be the remaining elements.
 * @param arr The array to process.
 * @param size The length of each chunk.
 * @returns Returns the new array of chunks. 
 */
export function chunk<T>(arr: T[], size: number): T[][] {
  const chunks: T[][] = [];

  for (let i = 0; i < arr.length; i += size) {
    const column = arr.slice(i, i + size);

    chunks.push(column);
  }

  return chunks;
}