import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MembersGuard } from '@shared/guards/members-guard';
import { SignupDisplayNamePage } from './signup/display-name/display-name.page';
import { SignupGenderPage } from './signup/gender/gender.page';
import { OnboardingGuard } from './signup/guards/onboarding.guard';
import { SignupGroupsPage } from './signup/groups/groups.page';
import { SignupLocationPage } from './signup/location/location.page';
import { SignupOptionalPage } from './signup/optional/optional.page';
import { SignupWhatsNextPage } from './signup/whats-next/whats-next.page';
import { WelcomePage } from './welcome-page/welcome.page';

const routes: Routes = [
  {
    path: '',
    component: WelcomePage,
    data: {
      title: 'Welcome'
    }
  },
  {
    path: 'signup',
    pathMatch: 'full',
    redirectTo: 'signup/display-name'
  },
  {
    path: 'signup/home',
    redirectTo: '/home'
  },
  {
    path: 'signup/display-name',
    component: SignupDisplayNamePage,
    canActivate: [MembersGuard],
    data: {
      title: 'Signup wizard: Display name'
    }
  },
  {
    path: 'signup/gender',
    component: SignupGenderPage,
    canActivate: [MembersGuard],
    data: {
      title: 'Signup wizard: Gender'
    }
  },
  {
    path: 'signup/groups',
    component: SignupGroupsPage,
    canActivate: [MembersGuard],
    data: {
      title: 'Signup wizard: Groups'
    }
  },
  {
    path: 'signup/location',
    component: SignupLocationPage,
    canActivate: [MembersGuard],
    data: {
      title: 'Signup wizard: Location'
    }
  },
  {
    path: 'signup/optional',
    component: SignupOptionalPage,
    canActivate: [MembersGuard],
    data: {
      title: 'Signup wizard: Optional'
    }
  },
  {
    path: 'signup/whats-next',
    component: SignupWhatsNextPage,
    canActivate: [MembersGuard],
    data: {
      title: `Signup wizard: What's next`
    }
  },
  {
    path: 'signup/pages-whats-next',
    canActivate: [OnboardingGuard],
    children: []
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class WelcomeRoutingModule {}
