import { Component, Input, ViewChild } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { AnalyticsService, AnalyticsCategory, AnalyticsAction } from '@shared/services/analytics';
import { BingoSettingsFormComponent } from '../../../components';
import { IBingoUserPreferences } from '../../../models/bingo-preferences';
import { IPauseMenuDismissedAction } from '../../models/pause-menu-dismissed-event';

@Component({
  selector: 'chirpy-bingo-pause-menu',
  styleUrls: ['./pause-menu.component.scss'],
  templateUrl: './pause-menu.component.html'
})
export class BingoPauseMenuComponent {
  @Input() gameId: string;
  @Input() settings: IBingoUserPreferences;

  @ViewChild(BingoSettingsFormComponent, { static: false }) formComponent: BingoSettingsFormComponent;

  constructor(private analyticsService: AnalyticsService, private modalController: ModalController) {}

  dismiss() {
    this.modalController.dismiss();
  }

  onRestartClicked() {
    this.analyticsService.eventTrack(AnalyticsCategory.BINGO, AnalyticsAction.BINGO_RESTART_GAME, this.gameId);
    this.onActionButtonClicked('restart');
  }

  onResumeClicked() {
    this.analyticsService.eventTrack(AnalyticsCategory.BINGO, AnalyticsAction.BINGO_RESUME_GAME, this.gameId);
    this.onActionButtonClicked('resume');
  }

  private onActionButtonClicked(type: IPauseMenuDismissedAction['type']) {
    const isDirty = this.formComponent.form.dirty;
    const updatedSettings = this.formComponent.formPresenter.settings;

    const action: IPauseMenuDismissedAction = {
      type,
      data: isDirty && updatedSettings
    };

    if (isDirty) {
      this.analyticsService.eventTrack(AnalyticsCategory.BINGO, AnalyticsAction.BINGO_CHANGE_SETTINGS, this.gameId, null, updatedSettings.ballDelayInMs);
      this.formComponent.form.markAsPristine();
    }

    this.modalController.dismiss(action);
  }
}
