import { Component } from "@angular/core";
import { AngularFireFunctions } from "@angular/fire/functions";
import { AlertController } from "@ionic/angular";
import { skipWhile, take } from "rxjs/operators";
import { MembershipStatus } from "@infrastructure/constants/membership-status";
import { EmailService } from "@shared/services/email/email.service";
import { ToastService } from "@shared/services/toast.service";
import { BaseAdminComponent } from "../base-admin/base-admin.component";
import { AuthService } from "@shared/services/auth.service";

@Component({
  selector: 'app-pause-member',
  templateUrl: '../base-admin/base-admin.component.html',
  styleUrls: ['../base-admin/base-admin.component.scss']
})
export class PauseMemberComponent extends BaseAdminComponent {
  confirmHeader = 'Pause member';
  confirmButton = 'Pause';
  placeholder = 'Search for a member to pause their account';
  title = 'Pause member';

  constructor(
    alertController: AlertController,
    private fns: AngularFireFunctions,
    private authService: AuthService,
    private emailService: EmailService,
    private toastService: ToastService
  ) {
    super(alertController);
  }

  confirmMessage(_memberId: string, memberName: string): string {
    return `<p>Pause ${memberName}'s account? This will prevent any access to the site other than the account menu.</p>`;
  }

  async selectMemberHandler({ memberId, memberName }: { memberId: any; memberName: any; data: any; }): Promise<void> {
    let message = '';
    const callable = this.fns.httpsCallable('updateSubscriptionStatus');

    const currentUser = await this.authService._userProfileSubject
      .pipe(
        skipWhile(x => !x),
        take(1)
      )
      .toPromise();

    try {
      await callable({ uid: memberId, status: MembershipStatus.PAUSED });

      const adminName = currentUser.fullName;
      this.emailService.sendMemberPausedByAdminNotification(memberId, memberName, adminName);

      message = `Member ${memberName}'s account has been paused.`;
    }
    catch(err) {
      message = `Couldn't pause member ${memberName}'s account: ${err}`;
    }
    finally {
      this.toastService.presentToast(message);
    }
  }
}