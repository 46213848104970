import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { EventType } from '@infrastructure/constants/event-type';
import { AdminRole } from '@shared/constants/admin-role';
import { IButtonGroup } from '@shared/models/button-group';
import { ICatchup } from '@shared/models/catchups/catchup';
import { CatchupRsvpStatus } from '@shared/constants/catchup-rsvp-status';
import { UserObject } from '@shared/models/user-object';
import { AnalyticsAction, AnalyticsCategory, AnalyticsService } from '@shared/services/analytics';
import { AuthService } from '@shared/services/auth.service';
import { CatchupTypeService } from '@shared/services/catchups/catchup-types.service';
import { CatchupService } from '@shared/services/catchups/catchup.service';
import { ConstantsService } from '@shared/services/constants.service';
import { GroupService } from '@shared/services/groups/group.service';
import { SubscriptionService } from '@shared/services/subscription.service';
import { Subscription } from 'rxjs';
import { first } from 'rxjs/operators';

@Component({
  selector: 'catchup-detail',
  templateUrl: './catchup-detail.component.html',
  styleUrls: ['./catchup-detail.component.scss']
})
export class CatchupDetailComponent implements OnInit {
  get ASSETS() {
    return this.constantsService.constants.ASSETS;
  }

  get canShowMessageHostButton() {
    return this.catchupTypeService.canShowHost(this.catchup.eventType) && this.groupService.isGroupMember(this.catchup.groupId);
  }

  get canShowJoinVirtualCatchupButton() {
    if (this.catchup.attendees == null || this.user == null) return false;
    return this.catchup.eventType === EventType.Virtual && (this.catchup.attendees[this.user.uid] === CatchupRsvpStatus.GOING || this.catchup.attendees[this.user.uid] === CatchupRsvpStatus.GOING_CLAIMED || this.isAdmin);
  }

  get CONSTANTS() {
    return this.constantsService.constants.CATCHUPS.DETAIL;
  }

  get isAdmin() {
    return this.authService.isAdmin([AdminRole.HOSTS]);
  }

  get isHost() {
    return this.authService.isHost();
  }

  get isVirtualCatchup() {
    return this.catchup.eventType === EventType.Virtual;
  }

  get userId() {
    if (this.authService._userProfileSubject != null && this.authService._userProfileSubject.value != null) {
      return this.authService._userProfileSubject.value.uid;
    }

    return '';
  }

  attendeesCount: number = 0;
  branding: string;
  buttonGroups: IButtonGroup[];
  canAddNote: boolean = false;
  canApproveCatchUp: boolean = false;
  canUpdateOrDeleteCatchUp: boolean = false;
  catchup: ICatchup;
  catchupSubscription: Subscription;
  errorMessage: string;
  title = 'View CatchUp';
  user: UserObject;
  waitlistCount: number = 0;

  constructor(
    private authService: AuthService,
    private analyticsService: AnalyticsService,
    private catchupService: CatchupService,
    private groupService: GroupService,
    private catchupTypeService: CatchupTypeService,
    private constantsService: ConstantsService,
    private subscriptionService: SubscriptionService,
    private route: ActivatedRoute,
    private router: Router
  ) {}

  approveCatchup() {
    this.catchupService.approveCatchup(this.catchup);
  }

  cancelCatchup() {
    this.catchupService.deleteCatchup(this.catchup);
  }

  getCatchup(uid: string) {
    this.catchupSubscription = this.catchupService.getCatchup(uid).subscribe(catchup => {
      if (catchup == null) {
        this.errorMessage = 'CatchUp does not exist';
        return;
      }

      if (catchup.attendees == null) catchup.attendees = {};
      this.attendeesCount = this.catchupService.getCatchupAttendeesCount(catchup.attendees);
      this.waitlistCount = this.catchupService.getCatchupWaitlistCount(catchup.attendees);
      this.catchup = catchup;
      this.title = this.catchup.title;

      this.initButtons(); // call here so that buttons load faster for members
      this.sendAnalytics(this.catchup);

      this.canUpdateOrDeleteCatchUp = !this.catchupService.isClosed(catchup) || this.authService.isAdmin([AdminRole.HOSTS]); // prevent hosts from updating after event is past
      if (catchup.groupId) {
        this.groupService
          .getGroup(catchup.groupId)
          .pipe(first())
          .subscribe(group => {
            this.canAddNote =
              (!this.catchupService.isOver(catchup) && this.catchupService.isGoing(catchup)) || // members can add notes until CatchUp has finished
              (!this.catchupService.isReallyOver(catchup) && this.groupService.canManageGroup(group)) || // co-hosts and hosts can read notes until a few days after the CatchUp
              this.authService.isAdmin([AdminRole.HOSTS]); // admins can always access notes
            this.canApproveCatchUp = !this.catchupService.isClosed(catchup) && this.groupService.canManageGroup(group); // admins, hosts, and co-hosts can approve
            this.canUpdateOrDeleteCatchUp = (!this.catchupService.isClosed(catchup) && this.groupService.canManageGroup(group)) || this.authService.isAdmin([AdminRole.HOSTS]); //admins can edit/delete even if catch-up is closed
            this.initButtons();
          });
      }
    });
    this.subscriptionService.add(this.catchupSubscription);
  }

  goBack() {
    // TODO: Relative navigation doesn't seem to be working now that catchup-detail-component uses tabs (which have their own router)
    // this.router.navigate(['../'], { relativeTo: this.router.url });
    const returnURL = this.router.url
      .split('/')
      .slice(0, -2)
      .join('/');
    this.router.navigate([returnURL]);
  }

  ngOnInit() {
    this.branding = this.constantsService.constants.CATCHUPS.branding;
    this.authService._userProfileSubject.subscribe(user => {
      this.user = user;
      // set permissions to true if the member is an admin otherwise wait until we've loaded the group in getCatchup to check if the member is a co/host
      this.canApproveCatchUp = this.isAdmin;
      this.canUpdateOrDeleteCatchUp = this.isAdmin;
    });

    const uid = this.route.snapshot.paramMap.get('catchupId');
    if (uid == null) {
      this.catchup = {
        address: '',
        allGroupIds: [],
        approved: false,
        attendanceCounted: false,
        attendees: {},
        eventType: null,
        country: [],
        created: 0,
        date: '',
        datetime: 0,
        description: '',
        endTime: '',
        groupId: '',
        groupName: '',
        largePhotoURL: null,
        ownerId: '',
        ownerName: '',
        photoURL: null,
        region: '',
        rsvp: false,
        time: '',
        title: ''
      };
      return;
    }

    this.getCatchup(uid);
    this.initButtons();
  }

  rejectCatchup() {
    const uid = this.catchup.uid;
    this.catchupService.rejectCatchup(uid);
  }

  sendAnalytics(catchup: ICatchup) {
    this.analyticsService.eventTrack(AnalyticsCategory.CATCHUPS, AnalyticsAction.CATCHUPS_VIEW_DETAILS, this.catchup.uid);
  }

  private initButtons() {
    if (!this.catchup) return [];

    this.buttonGroups = [
      {
        condition: this.canApproveCatchUp,
        buttons: [
          {
            click: this.approveCatchup.bind(this),
            condition: !this.catchup.approved,
            icon: 'checkmark',
            responsive: true,
            text: 'Approve'
          },
          {
            click: this.rejectCatchup.bind(this),
            condition: this.catchup.approved,
            icon: 'close',
            responsive: true,
            text: 'Reject'
          }
        ]
      },
      {
        condition: this.canUpdateOrDeleteCatchUp,
        buttons: [
          {
            condition: true,
            icon: 'create',
            routerLink: ['/catchups/update', this.catchup.uid, this.catchup.groupId],
            text: 'Update'
          },
          {
            click: this.cancelCatchup.bind(this),
            condition: true,
            icon: 'close-circle',
            text: `Cancel ${this.branding}`
          }
        ]
      },
      {
        condition: this.isVirtualCatchup,
        buttons: [
          {
            condition: this.canShowJoinVirtualCatchupButton,
            icon: 'videocam',
            routerLink: ['/catchups/virtual-catchup', this.catchup.uid],
            text: `Join Virtual ${this.branding}`
          },
          {
            condition: !this.canShowJoinVirtualCatchupButton,
            icon: '',
            responsive: false,
            text: 'RSVP for access'
          }
        ]
      },
      {
        condition: true,
        buttons: [
          {
            condition: this.canShowMessageHostButton && this.user.uid !== this.catchup.ownerId,
            icon: 'chatboxes',
            routerLink: ['/messages/compose', this.catchup.ownerId],
            responsive: false,
            text: 'Message Host'
          }
        ]
      }
    ];
  }
}
