import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { times } from '@shared/functions/times';

@Component({
  selector: 'bingo-called-numbers',
  styleUrls: ['./called-numbers.component.scss'],
  templateUrl: './called-numbers.component.html'
})
export class BingoCalledNumbersComponent implements OnInit, OnChanges {
  @Input() calledNumbers: number[] = [];

  markedNumbers: boolean[];

  ngOnChanges(changes: SimpleChanges) {
    if (changes.calledNumbers) {
      this.updateMarkedNumbers(changes.calledNumbers.currentValue);
    }
  }

  ngOnInit() {
    this.updateMarkedNumbers(this.calledNumbers);
  }

  private updateMarkedNumbers(called: number[]) {
    this.markedNumbers = times(90, i => i + 1).map(n => called.includes(n));
  }
}
