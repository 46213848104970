import { Component, ElementRef, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { BehaviorSubject, Observable, Subject, fromEvent } from 'rxjs';
import { takeUntil, map } from 'rxjs/operators';
import { AnimationType } from '../../models/animation-type';

@Component({ template: '' })
export abstract class BaseAnimatableComponent implements OnInit, OnDestroy {
  @Output() animationEnded = new EventEmitter<AnimationType>();
  @Output() animationStarted = new EventEmitter<AnimationType>();

  protected animateIn = new BehaviorSubject(false);
  animateIn$ = this.animateIn.asObservable();

  protected animateOut = new BehaviorSubject(false);
  animateOut$ = this.animateOut.asObservable();

  protected animationEndEvent$: Observable<[AnimationEvent, AnimationType]>;
  protected animationStartEvent$: Observable<[AnimationEvent, AnimationType]>;

  private onDestroy = new Subject();

  constructor(protected rootEl: ElementRef) {}

  ngOnInit() {
    [this.animationStartEvent$, this.animationEndEvent$] = ['animationstart', 'animationend'].map(eventName => {
      return fromEvent<AnimationEvent>(this.rootEl.nativeElement, eventName).pipe(
        takeUntil(this.onDestroy),
        map<AnimationEvent, [AnimationEvent, AnimationType]>(event => {
          const animationType = this.getAnimationType(event);

          return [event, animationType];
        })
      );
    });

    this.animationStartEvent$.subscribe(([_, type]) => {
      this.animationStarted.emit(type);
    });

    this.animationEndEvent$.subscribe(([_, type]) => {
      this.animationEnded.emit(type);
    });
  }

  ngOnDestroy() {
    this.onDestroy.next();
    this.onDestroy.complete();
  }

  protected abstract getAnimationType(e: AnimationEvent): AnimationType;
}
