import { Component, Input, OnInit, ElementRef, OnDestroy, Output, EventEmitter } from '@angular/core';
import { Observable, Subject, fromEvent } from 'rxjs';
import { takeUntil, skipWhile } from 'rxjs/operators';
import { chunk } from '@shared/functions/chunk';
import { times } from '@shared/functions/times';
import { CHIRPLE_CONSTANTS, STATUS_TO_VARIANT_MAP } from '../../constants';
import { IChirpleAttempt } from '../../models';
import { ChirpleGameBoardRow, ChirpleGameBoardTile, GameBoardAnimation, IAnimateGameBoardRowOptions } from './models';

@Component({
  selector: 'chirple-game-board',
  templateUrl: './game-board.component.html',
  styleUrls: ['./game-board.component.scss']
})
export class ChirpleGameBoardComponent implements OnInit, OnDestroy {
  @Input() attemptsAdded$: Observable<IChirpleAttempt[]>;
  @Input() letterAdded$: Observable<string>;
  @Input() letterRemoved$: Observable<unknown>;
  @Input() rowError$: Observable<unknown>;
  @Output() rowAnimationComplete = new EventEmitter();
  @Output() rowAnimationStarted = new EventEmitter();

  get currentRow() {
    return this.tiles[this.attempts.length];
  }

  get numberOfTiles() {
    return CHIRPLE_CONSTANTS.MAX_ATTEMPTS_PER_GAME * CHIRPLE_CONSTANTS.SOLUTION_LENGTH;
  }

  private animationId: IterableIterator<number>;
  private attempts: IChirpleAttempt[] = [];
  private inFlightAttemptAnimation: Record<number, Set<number>>;
  private nextTileIndex = 0;
  private onDestroy = new Subject();

  tiles: ChirpleGameBoardRow[];

  constructor(private rootEl: ElementRef) {}

  ngOnDestroy() {
    this.onDestroy.next();
    this.onDestroy.complete();
  }

  ngOnInit() {
    this.animationId = this.getAnimationId();

    this.initBoard();
    this.initAnimations();

    this.attemptsAdded$
      .pipe(
        takeUntil(this.onDestroy),
        skipWhile(attempts => !attempts)
      )
      .subscribe(attempts => {
        this.onAttemptsAdded(attempts);
      });

    this.letterRemoved$.pipe(takeUntil(this.onDestroy)).subscribe(_ => {
      this.currentRow.tiles[this.nextTileIndex - 1].letter = undefined;

      this.nextTileIndex--;
    });

    this.letterAdded$.pipe(takeUntil(this.onDestroy)).subscribe(letter => {
      const tile = this.currentRow.tiles[this.nextTileIndex];

      tile.letter = letter;
      tile.animation = {
        id: this.animationId.next().value,
        name: GameBoardAnimation.POP_TILE
      };

      this.nextTileIndex++;
    });

    this.rowError$.pipe(takeUntil(this.onDestroy)).subscribe(_ => {
      this.currentRow.animation = GameBoardAnimation.SHAKE_ROW;
    });
  }

  private animateAddedAttempt(batchId: number, attempt: IChirpleAttempt) {
    this.animateRow(batchId, attempt, GameBoardAnimation.FLIP_TILE_DOWN, {
      delayInMs: 200,
      preflightHandler: (row, letter, i) => {
        row.tiles[i].letter = letter.value;
      }
    });
  }

  private animateCorrectAttempt(attempt: IChirpleAttempt) {
    const batchId = this.animationId.next().value;

    this.rowAnimationStarted.emit();
    this.inFlightAttemptAnimation = { [batchId]: new Set() };

    this.animateRow(batchId, attempt, GameBoardAnimation.BOUNCE_TILE, { delayInMs: 100 });
  }

  private animateRow(batchId: number, attempt: IChirpleAttempt, animationName: GameBoardAnimation, options?: IAnimateGameBoardRowOptions) {
    const row = this.tiles[attempt.index];

    for (let i = 0; i < attempt.letters.length; i++) {
      const letter = attempt.letters[i];

      const animation = {
        name: animationName,
        batchId,
        id: this.animationId.next().value
      };

      this.inFlightAttemptAnimation[batchId].add(animation.id);

      if (options) {
        if (options.preflightHandler) {
          options.preflightHandler(row, letter, i);
        }

        setTimeout(() => {
          row.tiles[i].animation = animation;
        }, i * (options.delayInMs || 0));
      }
    }
  }

  private clearTileAnimation(e: AnimationEvent) {
    const tile = this.getTileFromAnimationEvent(e);

    tile.animation = undefined;
  }

  private getTileFromAnimationEvent(e: AnimationEvent) {
    const target = e.target as HTMLElement;

    const [rowIndex, tileIndex] = ['rowIndex', 'tileIndex'].map(attrName => Number(target.dataset[attrName]));

    return this.tiles[rowIndex].tiles[tileIndex];
  }

  private onAttemptsAdded(attempts: IChirpleAttempt[]) {
    const batchId = this.animationId.next().value;

    this.rowAnimationStarted.emit();
    this.inFlightAttemptAnimation = { [batchId]: new Set() };

    attempts.forEach(attempt => {
      this.attempts[attempt.index] = attempt;

      this.animateAddedAttempt(batchId, attempt);
    });
  }

  private initAnimations() {
    fromEvent<AnimationEvent>(this.rootEl.nativeElement, 'animationend')
      .pipe(takeUntil(this.onDestroy))
      .subscribe(e => {
        const { animationName } = e;

        if (animationName === GameBoardAnimation.POP_TILE) {
          this.clearTileAnimation(e);
        }

        if (animationName === GameBoardAnimation.FLIP_TILE_UP) {
          this.onFlipTileUpAnimationEnd(e);
        }

        if (animationName === GameBoardAnimation.SHAKE_ROW) {
          this.currentRow.animation = undefined;
        }

        if (animationName === GameBoardAnimation.FLIP_TILE_DOWN) {
          this.onFlipTileDownAnimationEnd(e);
        }
      });
  }

  private initBoard() {
    const blanks: ChirpleGameBoardTile[] = times(this.numberOfTiles, i => ({
      rowIndex: Math.floor(i / CHIRPLE_CONSTANTS.SOLUTION_LENGTH),
      tileIndex: i % CHIRPLE_CONSTANTS.SOLUTION_LENGTH,
      variant: 'blank'
    }));

    this.tiles = chunk(blanks, CHIRPLE_CONSTANTS.SOLUTION_LENGTH).map(tiles => ({ tiles }));
  }

  private onAttemptAnimationComplete() {
    this.nextTileIndex = 0;
    this.rowAnimationComplete.emit();
  }

  private onFlipTileDownAnimationEnd(event: AnimationEvent) {
    const tile = this.getTileFromAnimationEvent(event);
    const attempt = this.attempts[tile.rowIndex];
    const { value: letter, status } = attempt.letters[tile.tileIndex];

    tile.letter = letter;
    tile.variant = STATUS_TO_VARIANT_MAP[status];
    tile.animation = {
      ...tile.animation,
      name: GameBoardAnimation.FLIP_TILE_UP
    };
  }

  private onFlipTileUpAnimationEnd(event: AnimationEvent) {
    const tile = this.getTileFromAnimationEvent(event);

    const batchId = tile.animation.batchId;
    const animationId = tile.animation.id;

    this.inFlightAttemptAnimation[batchId].delete(animationId);

    tile.animation = undefined;

    if (this.inFlightAttemptAnimation[batchId].size === 0) {
      this.onAttemptAnimationComplete();

      delete this.inFlightAttemptAnimation[batchId];

      const attempt = this.attempts[tile.rowIndex];

      if (attempt.isCorrect) {
        this.animateCorrectAttempt(attempt);
      }
    }
  }

  private *getAnimationId() {
    let nextId = 0;

    while (true) {
      yield nextId++;
    }
  }
}
