import { Injectable } from '@angular/core';
import { IListingImage } from '@shared/models/image/listing-image';
import { ImageDatabase } from '@shared/services/image/image.database';

@Injectable({
  providedIn: 'root'
})
export class ImageService {
  constructor(private database: ImageDatabase) {}

  deepCopy(photos: Record<string, IListingImage>): Record<string, File> {
    let output: Record<string, File> = {};
    for (let [key, photo] of Object.entries(photos || {})) {
      if (photo.file) output[key] = photo.file;
    }
    return output;
  }

  deleteImage(image: IListingImage) {
    const promises = [];
    if (image.photoURL) promises.push(this.database.deleteImage(image.photoURL));
    if (image.mediumPhotoURL) promises.push(this.database.deleteImage(image.mediumPhotoURL));
    if (image.largePhotoURL) promises.push(this.database.deleteImage(image.largePhotoURL));

    return Promise.all(promises);
  }

  getUrl(image: IListingImage): string {
    return image.largePhotoURL || image.mediumPhotoURL || image.photoURL || image.originalPhotoURL || '';
  }

  async uploadImage(file: File, filePath: string): Promise<any> {
    return this.database.uploadImage(file, filePath);
  }

  uploadImages(uid: string, photos: Record<string, File>, path: string) {
    for (let [key, file] of Object.entries(photos || {})) {
      if (file) {
        const filePath = `${path}/${uid}/${key}`;
        this.uploadImage(file, filePath);
      }
    }
  }
}
