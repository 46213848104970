import { Injectable } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { IContactFormSetting } from '@shared/models/contact-form-setting';
import { IBnBListing } from '@shared/models/bnb/bnb-listing';

@Injectable({
  providedIn: 'root'
})
export class BnBEditPresenter {
  form = this.formBuilder.group({
    cost: ['', Validators.required],
    photos: [{}],
    fields: new FormGroup({}),
    description: ['', Validators.required],
    location: ['', Validators.required],
    locationId: [''], //hidden field
    region: ['', Validators.required],
    title: ['', Validators.required]
  });

  constructor(private formBuilder: FormBuilder) {}

  init(fieldsData: IContactFormSetting[]) {
    // Dynamically add terms and conditions checkboxes
    const fieldsGroup = this.form.controls.fields as FormGroup;
    fieldsData.forEach(field => {
      if (field.required) {
        fieldsGroup.addControl(field.id, new FormControl(false, [Validators.requiredTrue]));
      } else {
        fieldsGroup.addControl(field.id, new FormControl(false));
      }
    });
  }

  bnbListing() {
    return this.form.value as IBnBListing;
  }

  patchValue(data: any) {
    this.form.patchValue(data);
  }

  setValue(bnbListing: IBnBListing) {
    this.form.setValue({
      cost: bnbListing.cost,
      description: bnbListing.description,
      fields: bnbListing.fields,
      location: bnbListing.location,
      locationId: bnbListing.locationId,
      photos: Object.assign({}, bnbListing.photos), // we want a copy, not a reference, so we can compare old vs new to see if images have been removed
      region: bnbListing.region,
      title: bnbListing.title
    });
  }
}
