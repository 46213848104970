import { Injectable } from '@angular/core';
import { GameType } from '@shared/constants/game-type';
import { rot13 } from '@shared/functions/rot13';
import { fromHex } from '@shared/functions/hex';
import { AuthService } from '@shared/services/auth.service';
import { LeaderboardService } from '@shared/services/leaderboards/leaderboard.service';
import firebase from 'firebase/app';
import FieldValue = firebase.firestore.FieldValue;
import { Observable, combineLatest } from 'rxjs';
import { first, map, take, tap } from 'rxjs/operators';
import { ChirpleDatabase } from './chirple.database';
import { CHIRPLE_CONSTANTS } from '../constants';
import { ChirpleLetterStatus, IChirpleAttemptLetter, IChirpleAttempt, IChirpleGame, ChirpleValidWords } from '../models';

@Injectable({
  providedIn: 'root'
})
export class ChirpleGameService {
  private readonly WORD_KEY_SIZE = 2 as const;
  private validWordsLookup: ChirpleValidWords = {};

  constructor(private authService: AuthService, private database: ChirpleDatabase, private leaderboardService: LeaderboardService) {}

  getAttemptFromGuess({ guess, answer, index }: { guess: string; answer: string; index: number }): IChirpleAttempt {
    const attemptLetters = this.getAttemptLetters(answer, guess);

    const attemptIsCorrect = attemptLetters.every(letter => letter.status === ChirpleLetterStatus.CORRECT_POSITION);

    return {
      index,
      letters: attemptLetters,
      isCorrect: attemptIsCorrect
    };
  }

  loadGame(): Observable<IChirpleGame> {
    const validWords$ = this.database.getValidWords();
    const game$ = this.database.getGame();

    return combineLatest([validWords$, game$]).pipe(
      take(1),
      tap(([validWords]) => {
        const allValidWords = validWords['all'] || [];
        this.validWordsLookup = this.createValidWordsLookup(allValidWords);
      }),
      map(([_, game]) => ({
        ...game,
        solution: this.decryptSolution(game.solution)
      }))
    );
  }

  isValidWord(word: string): boolean {
    const key = this.getWordLookupKey(word);
    const wordsToSearch = this.validWordsLookup[key] || [];

    return wordsToSearch.includes(word.toUpperCase());
  }

  storeAttempt(gameId: string, attempt: IChirpleAttempt) {
    const merge = true;

    const data = {
      attempts: FieldValue.arrayUnion(attempt),
      correctAttempt: attempt.isCorrect ? attempt.index : null
    };

    return this.database.updateGame(gameId, data, merge);
  }

  updateGameDuration(gameId: string, durationInSecs: number) {
    const merge = true;
    const data = {
      duration: FieldValue.increment(durationInSecs)
    };

    this.database.updateGame(gameId, data, merge);
  }

  updateLeaderboard(game: IChirpleGame) {
    this.authService._userProfileSubject.pipe(first(x => !!x)).subscribe(profile => {
      const isWin = game.correctAttempt != null;
      const data = {
        metric1: 1, // Games played
        metric2: isWin ? 1 : 0, // Win %
        metric3: isWin, // Streak
        metric4: isWin, // Max streak
        metric5: game.duration, // Time
        metric6: game.duration, // Best time
        metric7: isWin ? game.attempts.length : null, // Number of guesses
        metric8: isWin ? game.attempts.length : null // Guess distribution
      };
      this.leaderboardService.updateLeaderboardForPlayer(game.userId, profile.displayName, profile.region, profile.country, GameType.CHIRPLE, data);
    });
  }

  private createValidWordsLookup(words: string[]) {
    // Since the list of valid words is 12,000+ long we need an efficient way of traversing through the data.
    // This groups words by the first two letters of the words, keeping the resulting array nice and small.
    const validWords = words.reduce((acc, val) => {
      const key = this.getWordLookupKey(val);

      if (!acc[key]) {
        acc[key] = [];
      }

      acc[key].push(val.toUpperCase());

      return acc;
    }, {} as ChirpleValidWords);

    return validWords;
  }

  // Assumes a hex encoded string comprised of a random set of bytes
  // appended to a ROT-13 shifted version of the solution.
  // See `get-chirple-game.ts` for implementation details.
  private decryptSolution(encrypted: string) {
    const decrypted = fromHex(encrypted);

    const solution = decrypted.slice(0, CHIRPLE_CONSTANTS.SOLUTION_LENGTH);

    return rot13(solution);
  }

  private getAttemptLetters(answer: string, guess: string): IChirpleAttemptLetter[] {
    const answerLetters = answer.split('');
    const guessLetters = guess.split('');

    const output = guessLetters.map((letter, i) => ({
      index: i,
      value: letter,
      status: ChirpleLetterStatus.WRONG_LETTER
    }));

    for (let i = 0; i < guessLetters.length; i++) {
      if (answerLetters[i] === guessLetters[i]) {
        output[i].status = ChirpleLetterStatus.CORRECT_POSITION;

        delete answerLetters[i];
        delete guessLetters[i];
      }
    }

    for (let i = 0; i < guessLetters.length; i++) {
      const firstIndex = answerLetters.indexOf(guessLetters[i]);

      if (firstIndex > -1) {
        output[i].status = ChirpleLetterStatus.CORRECT_LETTER;

        delete answerLetters[firstIndex];
      }
    }

    return output;
  }

  private getWordLookupKey(word: string): string {
    return word.slice(0, this.WORD_KEY_SIZE).toUpperCase();
  }
}
