import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import Delimiter from '@editorjs/delimiter';
import EditorJS from '@editorjs/editorjs'; //See https://github.com/codex-team/editor.js/issues/929
import { OutputBlockData } from '@editorjs/editorjs';
import Embed from '@editorjs/embed';
import Header from '@editorjs/header';
import List from '@editorjs/list';
//import SimpleImage from '@editorjs/simple-image';
import ImageTool from '@editorjs/image';
import { IContent } from '@shared/models/content';
import { IListingImage } from '@shared/models/image/listing-image';
import { EnvironmentService } from '@shared/services/environment.service';
import { ImageService } from '@shared/services/image/image.service';
import { Observable } from 'rxjs';
import { first } from 'rxjs/operators';
import ShortUniqueId from 'short-unique-id';
import { AlignmentTune } from './components/alignment.tune';
import { ChirpyLinkListPlugin } from './components/chirpy-link-list.plugin';
import { ChirpyParagraphWithMediaPlugin } from './components/chirpy-paragraph-with-media.plugin';

@Component({
  selector: 'chirpy-content-edit',
  templateUrl: './chirpy-content-edit.component.html'
})
export class ChirpyContentEditComponent implements OnInit {
  @Input() content$: Observable<IContent>;
  editor: any;
  highestImageNumber: number = 0;
  @Input() filePrefix: string;
  idGenerator: ShortUniqueId;
  @Input() keepListening: boolean = false;
  oldImages: OutputBlockData<string, any>[];
  resizableTypes: string[] = ['image/jpeg', 'image/png', 'image/webp'];

  constructor(private environmentService: EnvironmentService, private imageService: ImageService) {}

  ngOnInit() {
    this.initGenerator();
    let firstLoad = true;
    const observable = this.keepListening ? this.content$ : this.content$.pipe(first());
    observable.subscribe(content => {
      if (firstLoad) {
        this.resetImageData(content);
        this.editor = new EditorJS({
          data: content,
          holder: 'editorjs',
          logLevel: 'ERROR' as any, // Should be LogLevels.ERROR, but enum is not exported properly. See https://github.com/codex-team/editor.js/issues/1576
          placeholder: 'Start typing. Press Tab or click on the + to choose a block type.',
          tools: {
            alignment: AlignmentTune,
            delimiter: Delimiter,
            embed: Embed,
            header: { class: Header, inlineToolbar: true },
            list: { class: List, inlineToolbar: true },
            image: {
              class: ImageTool,
              inlineToolbar: true,
              config: {
                //types: 'image/*',
                uploader: {
                  uploadByFile: this.onUploadByFile.bind(this),
                  uploadByUrl: this.onUploadByUrl.bind(this)
                }
              },
              tunes: ['alignment']
            },
            'chirpy-link-list': {
              class: ChirpyLinkListPlugin,
              inlineToolbar: true,
              config: {
                fields: [
                  {
                    id: 'title',
                    type: 'string'
                  },
                  {
                    id: 'imageURL',
                    type: 'string'
                  },
                  {
                    id: 'items',
                    type: 'array',
                    fields: [
                      {
                        id: 'title',
                        type: 'string'
                      },
                      {
                        id: 'url',
                        type: 'string'
                      }
                    ]
                  },
                  {
                    id: 'analytics',
                    type: 'object',
                    fields: [
                      {
                        id: 'category',
                        type: 'string'
                      },
                      {
                        id: 'action',
                        type: 'string'
                      }
                    ]
                  }
                ],
                type: 'Chirpy Link List'
              }
            },
            'chirpy-paragraph-with-media': {
              class: ChirpyParagraphWithMediaPlugin,
              inlineToolbar: true,
              config: {
                fields: [
                  {
                    id: 'text',
                    type: 'textarea'
                  },
                  {
                    id: 'imageURL',
                    type: 'string'
                  },
                  {
                    id: 'align',
                    type: 'string'
                  },
                  {
                    id: 'videoURL',
                    type: 'string'
                  },
                  {
                    id: 'height',
                    type: 'string'
                  },
                  {
                    id: 'width',
                    type: 'string'
                  },
                  {
                    id: 'analytics',
                    type: 'object',
                    fields: [
                      {
                        id: 'category',
                        type: 'string'
                      },
                      {
                        id: 'action',
                        type: 'string'
                      }
                    ]
                  }
                ],
                type: 'Chirpy Paragraph with Media'
              }
            }
          }
        });
        firstLoad = false;
      } else {
        if ((content.blocks || []).length) {
          this.resetImageData(content);
          this.editor.render(content);
        } else {
          // Clears existing content and renders the placeholder
          this.editor.clear();
        }
      }
    });
  }

  async onSave(): Promise<IContent> {
    return this.editor.save().then(content => {
      for (let block of content.blocks) {
        if (block.id == null) block.id = this.generateId();

        if (block.type === 'image' && block.data.file) {
          // File upload has about a 30s delay, between sending it to the server, and the image resize process completing
          // To reduce the perceived delay, render the image as a blob immediately, upload in the background, and replace the url with the predictable url of the image when the page is saved
          if (block.data.file.url.startsWith('blob') && block.data.file.fileName) {
            block.data.file.url = this.generateUrl(block.data.file.fileName, block.data.file.type || '');
          }
          // Editing a page with an existing image causes the URL to be re-encoded, which breaks the query string
          if (block.type === 'image' && block.data.file && block.data.file.url) {
            block.data.file.url = block.data.file.url.replace(/&amp;alt=media/, '&alt=media');
          }
        }
      }

      // Check for removed images; if any delete them from storage
      if (this.oldImages.length > 0) {
        const newKeys = (content.blocks || []).filter(x => x.type === 'image').map(x => x.data.file.fileName || '');
        const imagesToRemove = this.oldImages.filter(x => !newKeys.includes(x.data.file.fileName)).map(x => x.data.file);
        for (const file of imagesToRemove) {
          if (file.type) this.imageService.deleteImage(this.createListingImage(file.url, file.type));
        }
      }
      return content;
    });
  }

  async onUploadByFile(file: File) {
    const count = ++this.highestImageNumber;
    const fileName = `image${count}`;
    const filePath = `${this.filePrefix}/${fileName}`;
    // Upload file in background, return blob now to avoid delays
    this.imageService.uploadImage(file, filePath);

    // Add to oldImages in case this block is deleted before saving
    const blockData: any = {
      type: 'image',
      data: {
        file: {
          fileName: fileName,
          url: this.generateUrl(fileName, file.type)
        }
      }
    };
    this.oldImages.push(blockData);

    // Return blob so image is immediately visible
    const tempUrl = URL.createObjectURL(file);

    let data: any;
    if (tempUrl.startsWith('blob:http')) {
      data = {
        success: 1,
        file: {
          url: tempUrl as string,
          fileName: fileName,
          type: file.type
        }
      };
    } else {
      data = {
        success: 0,
        file: {
          url: ''
        }
      };
    }
    // Get race condition with preview spinner if we return the data too soon
    return this.sleep(1000).then(() => {
      return data;
    });
  }

  async onUploadByUrl(url: string) {
    // If they're pasting a URL, then we don't actually need to sideload it, we can just use it as is
    return this.sleep(1000).then(() => {
      return {
        success: 1,
        file: {
          url: url
        }
      };
    });
  }

  private createListingImage(url: string, type: string): IListingImage {
    if (this.resizableTypes.includes(type)) {
      const largePhotoURL = url;
      const mediumPhotoURL = url.replace('_1200x1200', '_200x200');
      const photoURL = url.replace('_1200x1200', '_80x80');

      return { photoURL, mediumPhotoURL, largePhotoURL };
    } else {
      // SVGs and GIFs are not resized, so do not have multiple URLs to be deleted
      const photoURL = url;
      return { photoURL };
    }
  }

  private initGenerator(): void {
    this.idGenerator = new ShortUniqueId({ length: 10 });
  }

  private generateId(): string {
    return this.idGenerator();
  }

  private generateUrl(fileName: string, fileType: string): string {
    const bucket = this.environmentService.firebase.storageBucket;
    const filePath = encodeURIComponent(`${this.filePrefix}/${fileName}`);
    const suffix = this.resizableTypes.includes(fileType) ? '_1200x1200' : '';
    const generation = Date.now() * 1000; // generation parameter is only for cache-busting; exact value is not critical
    return `https://firebasestorage.googleapis.com/v0/b/${bucket}/o/${filePath}${suffix}?generation=${generation}&alt=media`;
  }

  private resetImageData(content: any) {
    this.oldImages = (content.blocks || []).filter(x => x.type === 'image');
    const imageNumbers = this.oldImages.map(x => +(x.data.file.fileName || '').replace('image', '')); // filenames are image1, image2, image3 etc. but are not guaranteed to be consecutive, e.g. image2 can be deleted. Strip the text out to give an array of numbers.
    this.highestImageNumber = Math.max(0, ...imageNumbers); // need 0 otherwise highestImageNumber will be -Infinity if there are no images
  }

  private sleep(waitTimeInMs: number): Promise<any> {
    return new Promise(resolve => setTimeout(resolve, waitTimeInMs));
  }
}
