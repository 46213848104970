import { Injectable } from '@angular/core';
import { DocumentReference } from '@angular/fire/firestore';
import { CatchupRsvpStatus } from '@shared/constants/catchup-rsvp-status';
import { ALL_COUNTRIES, Country, CountryKey } from '@shared/constants/country';
import { GameType } from '@shared/constants/game-type';
import { Ordinal } from '@shared/constants/ordinal';
import { IBnBListing } from '@shared/models/bnb/bnb-listing';
import { IBnBReview } from '@shared/models/bnb/bnb-review';
import { ICatchup } from '@shared/models/catchups/catchup';
import { IChargebeeSubscription } from '@shared/models/chargebee-subscription';
import { IDifference } from '@shared/models/difference';
import { IEmail } from '@shared/models/email';
import { IEmailOptions } from '@shared/models/email-options';
import { IEvent } from '@shared/models/events/event';
import { IMailTemplate } from '@shared/models/mail-template';
import { IMarketplaceListing } from '@shared/models/marketplace/marketplace-listing';
import { IShareListing } from '@shared/models/share/share-listing';
import { ISocial } from '@shared/models/social/social';
import { ShortUserObject, UserObject } from '@shared/models/user-object';
import { AppOptionsService } from '@shared/services/app-options/app-options.service';
import { DateTimeService } from '@shared/services/date-time.service';
import { EmailDatabase } from '@shared/services/email/email.database';
import { EnvironmentService } from '@shared/services/environment.service';
import { firestore } from 'firebase/app';
import Handlebars from 'handlebars';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { first, map, switchMap, take } from 'rxjs/operators';
import { IRomanceListing } from '../../../modules/romance/models/romance-listing';

@Injectable({
  providedIn: 'root'
})
export class EmailService {
  hasLoadedMailTemplates: boolean = false;
  templateData$: BehaviorSubject<IMailTemplate[]> = new BehaviorSubject<IMailTemplate[]>([]);
  templateNames$: BehaviorSubject<string[]> = new BehaviorSubject<string[]>([]);

  get redirectTestEmail() {
    return this.environmentService.settings.redirectTestEmail;
  }

  constructor(private appOptions: AppOptionsService, private dateTimeService: DateTimeService, private emailDatabase: EmailDatabase, private environmentService: EnvironmentService) {}

  getEmail(uid: string) {
    return this.emailDatabase.getEmail(uid);
  }

  getEmailBody({ title, text, data }) {
    let emailBody = `<h3>${title}</h3>`;

    if ((text || '').trim().length !== 0) {
      emailBody += `<p>${text}</p>`;
    }

    Object.entries(data).forEach(item => {
      if (item.length === 2) {
        emailBody += `<b>${item[0]}:</b> ${item[1]}<br>`;
      }
    });

    return emailBody;
  }

  getEmails(search: IEmailOptions, lastTimestamp: firestore.Timestamp) {
    const limit = 50;
    return this.emailDatabase.getEmails(search, limit, lastTimestamp);
  }

  getMailTemplate(mailTemplateName: string): Observable<IMailTemplate> {
    return this.emailDatabase.getMailTemplate(mailTemplateName);
  }

  getPartialsForTemplates(mailTemplates: IMailTemplate[], recursiveOutput: IMailTemplate[] = []): Observable<IMailTemplate[]> {
    const PARTIAL = /{{\s?>\s?([^}]*)\s?}}/g;

    // Parse partial names from current template(s)
    // TODO: Is it better to store the names of the partials on the template than to use a regex?
    let allMatches = [];
    for (const mailTemplate of mailTemplates) {
      const matches = mailTemplate.html.match(PARTIAL); // TODO: Replace with matchAll when we've upgraded to node >=12
      if (matches) {
        for (const match of matches) {
          const name = match.replace(PARTIAL, '$1').trim();
          if (name.length) allMatches.push(name);
        }
      }
    }

    // Check for unloaded partials
    const partialNames: string[] = [];
    for (const partial of allMatches) {
      // If a partial has already been loaded, don't need to read it from the database and register again
      if (!Handlebars.partials[partial]) partialNames.push(partial);
    }

    // Request partials from database
    if (partialNames.length > 0) {
      return this.emailDatabase.getPartialTemplates(partialNames).pipe(
        // Recurse because partials can include partials
        switchMap(partialTemplates => this.getPartialsForTemplates(partialTemplates, [...recursiveOutput, ...partialTemplates]))
      );
    } else {
      return of(recursiveOutput);
    }
  }

  getToAddresses(email: IEmail): string {
    if (email == null) return '';

    if (email.to) {
      // to can be string or array
      return typeof email.to === 'string' ? email.to : email.to.join(', ');
    }

    // address can be specified via toUids field, in which case get addresses from delivery.info.accepted field
    if (email.delivery != null && email.delivery.info != null && email.delivery.info.accepted != null) {
      return email.delivery.info.accepted.join(', ');
    }

    return '';
  }

  loadMailTemplates(): void {
    // load on demand (i.e. on email log page), but only load once to save DB reads.
    if (this.hasLoadedMailTemplates) return;

    this.emailDatabase
      .getMailTemplates()
      .pipe(first())
      .subscribe((templates: IMailTemplate[]) => {
        const fullTemplates = templates.filter(x => !x.partial);
        this.templateData$.next(fullTemplates);
        this.templateNames$.next(fullTemplates.map(x => x.uid));

        const partials = templates.filter(x => x.partial === true);
        for (const partial of partials) {
          Handlebars.registerPartial(partial.uid, partial.html);
        }
      });
    this.hasLoadedMailTemplates = true;
  }

  maybeRegisterPartialsForTemplate(mailTemplate: IMailTemplate): Observable<IMailTemplate> {
    // If the email-detail page is loaded from the email log, then all partials are already loaded
    if (this.hasLoadedMailTemplates) return of(mailTemplate);

    // If the email-detail page is loaded directly, partials need to be loaded
    return this.getPartialsForTemplates([mailTemplate], []).pipe(
      map(partials => {
        for (const partial of partials) {
          Handlebars.registerPartial(partial.uid, partial.html);
        }
        return mailTemplate;
      })
    );
  }

  populateVariables(htmlBody: string, data: any = {}) {
    const template = Handlebars.compile(htmlBody);
    return template(data);
  }

  sendAbuseReportEmail(reason: string, reportedMember: ShortUserObject, member: ShortUserObject, messages: string[]) {
    const templateName = 'abuseReport';
    const data = { baseUrl: this.environmentService.url(member.country), reason, reportedMember, member, messages };
    const useToUid = false;
    return this.sendEmailTemplate(this.environmentService.settings.supportEmail, templateName, data, useToUid);
  }

  sendBnBApprovalRequest(bnbListing: IBnBListing, member: UserObject) {
    const templateName = 'bnbApprovalRequest';
    const data = { baseUrl: this.environmentService.url(bnbListing.country), bnbListing, member };
    const useToUid = false;
    return this.sendEmailTemplate(this.environmentService.settings.approveContentEmail, templateName, data, useToUid);
  }

  sendBnBUpdatedRequest(bnbListing: IBnBListing, differences: Record<string, IDifference>, member: UserObject) {
    const templateName = 'bnbUpdatedRequest';
    const data = { baseUrl: this.environmentService.url(bnbListing.country), bnbListing, differences, member };
    const useToUid = false;
    return this.sendEmailTemplate(this.environmentService.settings.approveContentEmail, templateName, data, useToUid);
  }

  sendBnBReviewRequest(bnbReview: IBnBReview, bnbListing: IBnBListing) {
    const templateName = 'bnbReviewRequest';
    const data = { baseUrl: this.environmentService.url(bnbListing.country), bnbListing, bnbReview };
    const useToUid = false;
    return this.sendEmailTemplate(this.environmentService.settings.approveContentEmail, templateName, data, useToUid);
  }

  sendCatchupBonusCoinsNotificationToHost(hostId: string, hostName: string, catchupName: string, memberName: string, country: Country, coinsAmount: number, ordinal: Ordinal, days: number) {
    const templateName = 'catchupBonusCoinsNotifyHost';
    // TODO: baseUrl is not actually used in the email. Remove it?
    const data = { baseUrl: this.environmentService.url(country), catchupName, coinsAmount, hostName, memberName };
    return this.sendEmailTemplate(hostId, templateName, data);
  }

  sendCatchupRsvpNotificationToHost(catchup: ICatchup, host: UserObject, member: UserObject, emailProperties: any, catchupRsvpStatus: CatchupRsvpStatus) {
    const templateName =
      'catchupRsvpNotifyHost-' +
      catchupRsvpStatus
        .toString()
        .toLowerCase()
        .replace(/ /g, '');
    const dateTime = this.dateTimeService.formatDatetime(catchup.date, catchup.time);
    let groupName = '';
    if (Object.keys(catchup.sharedGroups).length > 0) {
      const groupIds = catchup.allGroupIds;
      const groupNames = Object.assign({}, catchup.sharedGroups);
      groupNames[catchup.groupId] = catchup.groupName;
      const matchingGroups = (member.catchupGroupIds || []).filter(g => groupIds.includes(g)).map(id => groupNames[id]);
      if (matchingGroups.length >= 0) {
        groupName = `[${matchingGroups.join(', ')}]`;
      }
    }

    const data = { baseUrl: this.environmentService.url(member.country), catchupRsvpStatus, catchup, host, member, dateTime, groupName, ...emailProperties };

    return this.sendEmailTemplate(catchup.ownerId, templateName, data);
  }

  sendCatchupRsvpNotificationToMember(catchup: ICatchup, host: UserObject, member: UserObject, emailProperties: any, catchupRsvpStatus: CatchupRsvpStatus) {
    const templateName =
      'catchupRsvpNotifyMember-' +
      catchupRsvpStatus
        .toString()
        .toLowerCase()
        .replace(/ /g, '');
    const dateTime = this.dateTimeService.formatDatetime(catchup.date, catchup.time);
    const data = { baseUrl: this.environmentService.url(member.country), catchupRsvpStatus, catchup, host, member, dateTime, ...emailProperties };

    return this.sendEmailTemplate(member.uid, templateName, data);
  }

  sendCatchupSorryWeMissedYouEmail(member: UserObject, catchup: ICatchup) {
    const templateName = 'catchupSorryWeMissedYou';
    const data = { baseUrl: this.environmentService.url(member.country), member, catchup };

    return this.sendEmailTemplate(member.uid, templateName, data);
  }

  sendCatchupRequestPaymentEmail(member: UserObject, catchup: ICatchup) {
    const templateName = 'catchupRequestPayment';
    const dateTime = this.dateTimeService.formatDatetime(catchup.date, catchup.time);
    const paymentAccountDetails = (catchup.paymentAccountDetails || '').replace(/\n/g, '<br/>');
    const data = { baseUrl: this.environmentService.url(member.country), catchup, dateTime, member, paymentAccountDetails };

    return this.sendEmailTemplate(member.uid, templateName, data);
  }

  async sendChatLockedByAdminNotification(gameType: GameType, chatName: string, adminName: string, reason?: string) {
    const templateName = 'chatLockedByAdmin';
    const data = { baseUrl: this.environmentService.url(), gameType, chatName, reason, adminName };

    const recipients = await this.appOptions
      .getOptionsValues<string>('groupLockedByAdminNotification', 'ALL')
      .pipe(take(1))
      .toPromise();

    const promises = recipients.map(recipient => {
      return this.sendEmailTemplate(recipient, templateName, data);
    });

    return Promise.all(promises);
  }

  sendCoinsRedemptionRequest(option: string, member: UserObject, formData: any) {
    const templateName = 'coinsRedemptionRequest';
    const data = { baseUrl: this.environmentService.url(member.country), option, member, data: formData };
    const useToUid = false;
    return this.sendEmailTemplate(this.environmentService.settings.supportEmail, templateName, data, useToUid);
  }

  sendEmailToApproveCatchup(catchup: ICatchup, newOrUpdated: string) {
    const templateName = 'catchupApprovalRequest';
    const data = { baseUrl: this.environmentService.url(catchup.country[0]), catchup, newOrUpdated };
    const useToUid = false;
    const country = catchup.country[0] || ALL_COUNTRIES;
    const catchupEmail = this.environmentService.settings.approveCatchupEmail[country] || this.environmentService.settings.approveCatchupEmail['ALL'];
    const email = catchupEmail ? catchupEmail : this.environmentService.settings.approveContentEmail;
    return this.sendEmailTemplate(email, templateName, data, useToUid);
  }

  sendEmailToApproveEvent(event: IEvent, newOrUpdated: string) {
    const templateName = 'eventApprovalRequest';
    const data = { baseUrl: this.environmentService.url(event.country), event, newOrUpdated };
    const useToUid = false;
    const email = this.environmentService.settings.approveContentEmail;
    return this.sendEmailTemplate(email, templateName, data, useToUid);
  }

  sendEmailToApproveRomanceListing(romanceListing: IRomanceListing, member: UserObject, dateOfBirth: string, newOrUpdated: string) {
    const templateName = 'romanceListingApprovalRequest';
    const data = { baseUrl: this.environmentService.url(member.country), romanceListing, member, dateOfBirth, newOrUpdated };
    const useToUid = false;
    return this.sendEmailTemplate(this.environmentService.settings.approveContentEmail, templateName, data, useToUid);
  }

  sendEmailToHostSupport(subject: string, title: string, text: string, fields: any[], country: CountryKey = ALL_COUNTRIES) {
    const templateName = 'contactForm';
    const data = { fields, subject, text, title };
    const useToUid = false;
    const hostEmail = this.environmentService.settings.hostSupportEmail[country] || this.environmentService.settings.hostSupportEmail['ALL'];
    const email = hostEmail ? hostEmail : this.environmentService.settings.supportEmail;
    return this.sendEmailTemplate(email, templateName, data, useToUid);
  }

  sendEmailToSupport(subject: string, title: string, text: string, fields: any[]) {
    const templateName = 'contactForm';
    const data = { fields, subject, text, title };
    const useToUid = false;
    return this.sendEmailTemplate(this.environmentService.settings.supportEmail, templateName, data, useToUid);
  }

  sendEmailToTravel(subject: string, title: string, text: string, fields: any[], country: CountryKey = ALL_COUNTRIES) {
    const templateName = 'contactForm';
    const data = { fields, subject, text, title };
    const useToUid = false;

    const travelEmail = this.environmentService.settings.travelEmail[country] || this.environmentService.settings.travelEmail['ALL'];
    const email = travelEmail ? travelEmail : this.environmentService.settings.supportEmail;

    return this.sendEmailTemplate(email, templateName, data, useToUid);
  }

  async sendGroupLockedByAdminNotification(groupId: string, groupName: string, adminName: string, reason?: string) {
    const templateName = 'groupLockedByAdmin';
    const data = { baseUrl: this.environmentService.url(), groupId, groupName, reason, adminName };

    const recipients = await this.appOptions
      .getOptionsValues<string>('groupLockedByAdminNotification', 'ALL')
      .pipe(take(1))
      .toPromise();

    const promises = recipients.map(recipient => {
      return this.sendEmailTemplate(recipient, templateName, data);
    });

    return Promise.all(promises);
  }

  sendMarketplaceListingApprovalRequest(marketplaceListing: IMarketplaceListing, member: UserObject, newOrUpdated: string) {
    const templateName = 'marketplaceListingApprovalRequest';
    const data = { baseUrl: this.environmentService.url(member.country), marketplaceListing, member, newOrUpdated };
    const useToUid = false;
    return this.sendEmailTemplate(this.environmentService.settings.approveContentEmail, templateName, data, useToUid);
  }

  sendMemberJoinedGroupNotification(groupId: string, groupName: string, member: UserObject, hosts: Record<string, string>) {
    const templateName = 'memberJoinedGroup';
    const data = { baseUrl: this.environmentService.url(member.country), groupId, groupName, member, hosts };

    return this.sendEmailTemplate(member.uid, templateName, data);
  }

  sendMemberJoinedGroupNotificationForHost(groupId: string, groupName: string, member: UserObject, hosts: Record<string, string>) {
    const templateName = 'memberJoinedGroupNotifyHost';
    const isAU = member.country === 'AU' || false; // TODO: Implement more general solution
    const data = { baseUrl: this.environmentService.url(member.country), groupId, groupName, member, isAU };

    const promises = [];
    for (const uid in hosts) {
      promises.push(this.sendEmailTemplate(uid, templateName, data));
    }
    return Promise.all(promises);
  }

  async sendMemberPausedByAdminNotification(memberId: string, memberName: string, adminName: string) {
    const templateName = 'memberPausedByAdmin';
    const data = { baseUrl: this.environmentService.url(), memberId, memberName, adminName };

    const recipients = await this.appOptions
      .getOptionsValues<string>('memberPausedByAdminNotification', 'ALL')
      .pipe(take(1))
      .toPromise();

    const promises = recipients.map(recipient => {
      return this.sendEmailTemplate(recipient, templateName, data);
    });

    return Promise.all(promises);
  }

  sendPhotoReportEmail(reason: string, member: ShortUserObject, photoUrl: string) {
    const templateName = 'photoReport';
    const data = { baseUrl: this.environmentService.url(member.country), reason, member, photoUrl };
    const useToUid = false;
    return this.sendEmailTemplate(this.environmentService.settings.supportEmail, templateName, data, useToUid);
  }

  sendRomanceRegistrationRequest(member: UserObject) {
    const templateName = 'romanceRegistrationRequest';
    const data = { baseUrl: this.environmentService.url(member.country), member };
    const useToUid = false;
    return this.sendEmailTemplate(this.environmentService.settings.supportEmail, templateName, data, useToUid);
  }

  sendRoomShareAgreement(to: string, tripId: string, tripName: string, memberId: string, memberName: string, country: Country) {
    const templateName = 'roomShareAgreement';
    const data = { baseUrl: this.environmentService.url(country), tripId, tripName, memberId, memberName };

    return this.sendEmailTemplate(to, templateName, data);
  }

  sendRoomShareCancellation(to: string, tripId: string, tripName: string, memberId: string, memberName: string, country: Country) {
    const templateName = 'roomShareCancellation';
    const data = { baseUrl: this.environmentService.url(country), tripId, tripName, memberId, memberName };

    return this.sendEmailTemplate(to, templateName, data);
  }

  sendRoomShareRequest(to: string, tripId: string, tripName: string, memberId: string, memberName: string, country: Country) {
    const templateName = 'roomShareRequest';
    const data = { baseUrl: this.environmentService.url(country), tripId, tripName, memberId, memberName };

    return this.sendEmailTemplate(to, templateName, data);
  }

  sendShareListingApprovalRequest(shareListing: IShareListing, member: UserObject, newOrUpdated: string) {
    const templateName = 'shareListingApprovalRequest';
    const data = { baseUrl: this.environmentService.url(member.country), shareListing, member, newOrUpdated };
    const useToUid = false;
    return this.sendEmailTemplate(this.environmentService.settings.approveContentEmail, templateName, data, useToUid);
  }

  sendSocialApprovalRequest(social: ISocial) {
    const templateName = 'socialApprovalRequest';
    const data = { baseUrl: this.environmentService.url(social.country), social };
    const useToUid = false;
    return this.sendEmailTemplate(this.environmentService.settings.approveContentEmail, templateName, data, useToUid);
  }

  sendSocialUpdatedRequest(social: ISocial, differences: Record<string, IDifference>) {
    const templateName = 'socialUpdatedRequest';
    const data = { baseUrl: this.environmentService.url(social.country), social, differences };
    const useToUid = false;
    return this.sendEmailTemplate(this.environmentService.settings.approveContentEmail, templateName, data, useToUid);
  }

  sendSpamAlertToSupport(memberId: string, memberName: string) {
    const templateName = 'spamAlert';
    const data = { baseUrl: this.environmentService.url(), memberId, memberName };
    const useToUid = false;
    return this.sendEmailTemplate(this.environmentService.settings.supportEmail, templateName, data, useToUid);
  }

  sendSubscriptionOfferRequestToSupport(member: UserObject, subscriptions: IChargebeeSubscription[]) {
    const templateName = 'subscriptionOfferRequest';
    const data = { baseUrl: this.environmentService.url(), member, subscriptions };
    const useToUid = false;
    return this.sendEmailTemplate(this.environmentService.settings.supportEmail, templateName, data, useToUid);
  }

  sendSubscriptionHelpRequestToSupport(member: UserObject, subscriptions: IChargebeeSubscription[], message: string) {
    const templateName = 'subscriptionHelpRequest';
    const data = { baseUrl: this.environmentService.url(), member, message, subscriptions };
    const useToUid = false;
    return this.sendEmailTemplate(this.environmentService.settings.supportEmail, templateName, data, useToUid);
  }

  // TODO: Refactor into sendEmailToMember and sendEmailToAddress?
  private sendEmailTemplate(recipient: string, templateName: string, data: any, useToUid: boolean = true, bcc: string = null) {
    if (this.environmentService.isLocalDevelopment) {
      console.log(`Email service is disabled for local development.\n\nTemplate name:${templateName}\nTo: ${recipient}\nBcc: ${bcc}\nData`, data);
      return Promise.resolve({} as DocumentReference);
    }

    let to: Record<string, string | string[]> = useToUid ? { toUids: [recipient] } : { to: recipient.split(',') };

    // Prevent sending emails to real members on Test environment.
    if (this.redirectTestEmail != null) {
      console.log(`Redirecting emails to ${this.redirectTestEmail}`);
      to = { to: this.redirectTestEmail };
    }

    return this.emailDatabase.sendEmailToMember(to, templateName, data, bcc);
  }
}
