/* tslint:disable:member-ordering */
import { Role } from '@infrastructure/constants/role';
import { IDateOfBirth } from '@infrastructure/models/date-of-birth';
import { AccessStatus } from '@shared/constants/access-status';
import { AdminRole } from '@shared/constants/admin-role';
import { Country } from '@shared/constants/country';
import { ICentralMember, ICentralMemberPrivate } from '@shared/models/member';
import { IUtcOffset } from '@shared/models/utc-offset';
import { FirebaseObject } from './firebase-object';

export class UserObject extends FirebaseObject implements ICentralMember, ICentralMemberPrivate {
  // public
  advertiserIds: string[];
  badges: Record<string, any[]>;
  bitAboutMe: string;
  catchupGroupIds: string[];
  coordinates: firebase.firestore.GeoPoint;
  country: Country;
  dateRegistered: number;
  displayName: string;
  domain: string;
  firstName: string;
  gender: string;
  interests: Record<string, boolean>;
  locality: string; // human readable place name
  messageNotificationCount: number;
  organisations: Record<string, boolean>;
  phone?: string; // needs to be public so hosts can see it TODO: make it private and add security rule?
  photoURL: string; // thumbnail
  largePhotoURL: string; // for viewing dating/member profile
  placeId: string;
  region: string;
  role: Role;
  searchName: string; // lower case display name, this is needed because Firebase can't do a case insensitive where clause.
  title?: string;
  utcOffset: IUtcOffset; // timezone offset from UTC, automatically kept in sync with region
  zoomTo: number; // zoom level on map for member's placeId

  // private
  adminRoles: AdminRole[];
  canAccessRomance: AccessStatus;
  chargebeeId: string;
  coinsBalance: number;
  coinsLevel: string;
  dateOfBirth: IDateOfBirth;
  dateTimeNewsLastRead: number;
  email: string;
  isOnboarding: boolean;
  fullName: string;
  isPaypalMember: boolean;
  lastName: string;
  membershipStatus: string;
  membershipType: string;
  notificationOptIn: boolean;
  profileCompleted: string;
  statusPoints: number;
  statusDate: number;

  // test environment
  host: string;

  deserialize(input: any): this {
    super.deserialize(input);
    Object.assign(this, input);
    return this;
  }
}

export class ShortUserObject {
  uid: string;
  displayName: string;
  firstName: string;
  email?: string;
  country: string;
}
