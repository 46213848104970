import { Injectable } from '@angular/core';
import { AlertController } from '@ionic/angular';
import { IAttendeeData } from '@shared/models/catchups/attendee-data';
import { ICatchup } from '@shared/models/catchups/catchup';
import { CatchupRsvpStatus } from '@shared/constants/catchup-rsvp-status';
import { HostOnlyDocument } from '@shared/constants/host-only-document';
import { UserObject } from '@shared/models/user-object';
import { AnalyticsAction, AnalyticsCategory, AnalyticsService } from '@shared/services/analytics';
import { AttendanceService } from '@shared/services/attendance/attendance.service';
import { AuthService } from '@shared/services/auth.service';
import { DateTimeService } from '@shared/services/date-time.service';
import { GroupDatabase } from '@shared/services/groups/group.database';
import { GroupService } from '@shared/services/groups/group.service';
import { EmailService } from '@shared/services/email/email.service';
import { NotificationTarget, NotificationService } from '@shared/services/notifications';
import { ToastService } from '@shared/services/toast.service';
import { UserService } from '@shared/services/user/user.service';
import { firestore } from 'firebase/app';
import { skipWhile, take } from 'rxjs/operators';
import { CatchupDatabase } from './catchup.database';
import FieldValue = firestore.FieldValue;

@Injectable({
  providedIn: 'root'
})
export class CatchupRsvpService {
  constructor(
    private alertController: AlertController,
    private analyticsService: AnalyticsService,
    private attendanceService: AttendanceService,
    private authService: AuthService,
    private dateTimeService: DateTimeService,
    private groupService: GroupService,
    private catchupDatabase: CatchupDatabase,
    private groupDatabase: GroupDatabase,
    private emailService: EmailService,
    private notificationService: NotificationService,
    private userService: UserService,
    private toastService: ToastService
  ) {}

  acceptRsvp(catchup: ICatchup, member: UserObject) {
    // TODO: It is possible to create an event not associated with any group and require RSVPs to be approved
    // we should check whether the catchup has a groupId associated with it and choose canManageGroup$ or isAdmin$ as appropriate
    this.groupService.canManageGroup$(catchup.groupId).subscribe(canManageGroup => {
      if (canManageGroup === true) {
        this.changeRsvp(catchup, member, CatchupRsvpStatus.GOING);
        this.analyticsService.eventTrack(AnalyticsCategory.CATCHUPS, AnalyticsAction.CATCHUPS_ACCEPT_RSVP, catchup.uid);
        const message = `${member.displayName}'s RSVP has been accepted.`;
        this.toastService.presentToast(message);
      }
    });
  }

  cancelRsvp(catchup: ICatchup, member: UserObject): void {
    this.analyticsService.eventTrack(AnalyticsCategory.CATCHUPS, AnalyticsAction.CATCHUPS_CANCEL_RSVP, catchup.uid);
    this.removeAttendeeData(catchup.uid, member.uid);
    this.changeRsvp(catchup, member, CatchupRsvpStatus.NOT_GOING);
  }

  async declineRsvp(catchup: ICatchup, member: UserObject) {
    this.groupService.canManageGroup$(catchup.groupId).subscribe(async canManageGroup => {
      if (canManageGroup === true) {
        const alert = await this.alertController.create({
          header: `Decline RSVP for ${member.displayName}?`,
          inputs: [
            {
              name: 'reason',
              type: 'text',
              placeholder: `Reason...`
            }
          ],
          buttons: [
            {
              text: 'Cancel',
              role: 'cancel',
              cssClass: 'secondary'
            },
            {
              text: `Decline`,
              handler: data => {
                if (data.reason == null) data.reason = '(no reason specified).';
                this.analyticsService.eventTrack(AnalyticsCategory.CATCHUPS, AnalyticsAction.CATCHUPS_DECLINE_RSVP, catchup.uid);
                this.changeRsvp(catchup, member, CatchupRsvpStatus.DECLINED_BY_HOST, data);
                this.removeAttendeeData(catchup.uid, member.uid);
                const message = `You have declined ${member.displayName}'s RSVP.`;
                this.toastService.presentToast(message);
              }
            }
          ]
        });

        await alert.present();
      }
    });
  }

  didNotAttend(catchup: ICatchup, member: UserObject): void {
    this.analyticsService.eventTrack(AnalyticsCategory.CATCHUPS, AnalyticsAction.CATCHUPS_REMOVE_NON_ATTENDEE, catchup.uid);
    this.attendanceService.removeAttendance(member.uid, catchup, { group: catchup.groupName, title: catchup.title });
    this.removeAttendeeData(catchup.uid, member.uid);
    this.changeRsvp(catchup, member, CatchupRsvpStatus.DID_NOT_ATTEND);
  }

  getHost(uid: string) {
    return this.userService.getUserProfile(uid).pipe(
      skipWhile(x => !x),
      take(1)
    );
  }

  // Copy exists in CatchupService, to avoid components needing to inject this service for a single function
  isClosed(catchup: ICatchup): boolean {
    const today = this.dateTimeService.getStartOfTodayAsString();
    const now = this.dateTimeService.getTimeAsString();

    return today > catchup.date || (today === catchup.date && now > catchup.time);
  }

  moveToWaitlist(catchup: ICatchup, member: UserObject): void {
    this.analyticsService.eventTrack(AnalyticsCategory.CATCHUPS, AnalyticsAction.CATCHUPS_MOVE_TO_WAITLIST, catchup.uid);
    this.changeRsvp(catchup, member, CatchupRsvpStatus.WAITING_FOR_HOST);
  }

  async removeFromAttendees(catchup: ICatchup, member: UserObject) {
    const alert = await this.alertController.create({
      header: `Remove attendee`,
      subHeader: `Remove ${member.displayName} from attendees list?`,
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
          cssClass: 'secondary'
        },
        {
          text: `Remove`,
          handler: () => {
            this.changeRsvp(catchup, member, CatchupRsvpStatus.REMOVED);
            this.removeAttendeeData(catchup.uid, member.uid);
            this.analyticsService.eventTrack(AnalyticsCategory.CATCHUPS, AnalyticsAction.CATCHUPS_REMOVE_RSVP, catchup.uid);
            const message = `${member.displayName} has been removed from the attendees list.`;
            this.toastService.presentToast(message);
          }
        }
      ]
    });

    await alert.present();
  }

  rsvpForCatchup(catchup: ICatchup, member: UserObject) {
    // For initial RSVP, check if "Host must accept RSVP's".
    // If TRUE, change status to WAITING_FOR_HOST and notify Hosts that member wants to RSVP.
    // If FALSE, then change to GOING straight away.
    const catchupRsvpStatus = catchup.rsvp ? CatchupRsvpStatus.WAITING_FOR_HOST : CatchupRsvpStatus.GOING;
    this.analyticsService.eventTrack(AnalyticsCategory.CATCHUPS, AnalyticsAction.CATCHUPS_RSVP, catchup.uid);
    const emailProperties = {};
    const isNew = true;
    return this.changeRsvp(catchup, member, catchupRsvpStatus, emailProperties, isNew);
  }

  private changeRsvp(catchup: ICatchup, member: UserObject, catchupRsvpStatus: CatchupRsvpStatus, emailProperties: any = {}, isNew: boolean = false) {
    // Only update catchup.attendees, rather than updating the whole catchup, which might be stale.
    const data = {
      attendees: {
        [member.uid]: catchupRsvpStatus === CatchupRsvpStatus.REMOVED ? FieldValue.delete() : catchupRsvpStatus
      },
      uid: catchup.uid
    };

    this.catchupDatabase.updateCatchup(data).then(async () => {
      this.createNotification(catchup.uid, member.uid, catchupRsvpStatus);
      if (isNew) this.updateCatchupAttendeeData(catchup.uid, member.uid, { dateTime: this.dateTimeService.getDateTime() });
      this.getHost(catchup.ownerId).subscribe(host => {
        this.sendHostNotifications(catchup, host, member, emailProperties, catchupRsvpStatus, isNew);
        this.sendMemberNotifications(catchup, host, member, emailProperties, catchupRsvpStatus, isNew);
      });
    });
  }

  private createNotification(catchupId: string, memberId: string, catchupRsvpStatus: CatchupRsvpStatus): void {
    switch (catchupRsvpStatus) {
      case CatchupRsvpStatus.GOING:
        this.notificationService.createNotificationForMember(NotificationTarget.CATCHUP, catchupId, memberId);
        break;

      case CatchupRsvpStatus.DECLINED_BY_HOST:
      case CatchupRsvpStatus.REMOVED:
      case CatchupRsvpStatus.NOT_GOING:
        this.notificationService.removeNotificationForMember(NotificationTarget.CATCHUP, catchupId, memberId);
        break;

      case CatchupRsvpStatus.WAITING_FOR_HOST:
      default:
        break;
    }
  }

  private sendHostNotifications(catchup: ICatchup, host: UserObject, member: UserObject, emailProperties: any, catchupRsvpStatus: CatchupRsvpStatus, isInitialRSVP: boolean = false) {
    const hostNotifications = [CatchupRsvpStatus.NOT_GOING];
    if (isInitialRSVP) {
      if (catchup.rsvp) hostNotifications.push(CatchupRsvpStatus.WAITING_FOR_HOST);
      else hostNotifications.push(CatchupRsvpStatus.GOING);
    }

    const sendNotificationToHost = hostNotifications.includes(catchupRsvpStatus);
    if (sendNotificationToHost) {
      this.emailService.sendCatchupRsvpNotificationToHost(catchup, host, member, emailProperties, catchupRsvpStatus);
    }
  }

  private sendMemberNotifications(catchup: ICatchup, host: UserObject, member: UserObject, emailProperties: any, catchupRsvpStatus: CatchupRsvpStatus, isInitialRSVP: boolean = false) {
    const memberNotifications = [CatchupRsvpStatus.DECLINED_BY_HOST, CatchupRsvpStatus.GOING, CatchupRsvpStatus.REMOVED];
    if (!isInitialRSVP) {
      memberNotifications.push(CatchupRsvpStatus.WAITING_FOR_HOST); //notify the member if they get put back on the waitlist
    }

    const sendNotificationToMember = memberNotifications.includes(catchupRsvpStatus);
    if (sendNotificationToMember) {
      this.emailService.sendCatchupRsvpNotificationToMember(catchup, host, member, emailProperties, catchupRsvpStatus);
    }
  }

  private removeAttendeeData(catchupId: string, memberId: string): void {
    const attendeeData = {
      list: {
        [memberId]: FieldValue.delete()
      }
    };
    this.catchupDatabase.updateHostOnlyDocument(catchupId, HostOnlyDocument.DATA, attendeeData);
  }
  // Keep in sync with copy in CatchupService
  // NB data is of type IAttendeeData, but need to declare as any to be able to use FieldValue.arrayUnion
  private updateCatchupAttendeeData(catchupId: string, memberId: string, data: any): void {
    const attendeeData = {
      list: {
        [memberId]: data
      }
    };
    this.catchupDatabase.updateHostOnlyDocument(catchupId, HostOnlyDocument.DATA, attendeeData);
  }
}
