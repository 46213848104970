import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { IOrderCondition } from '@shared/models/order-condition';
import { IWhereCondition } from '@shared/models/where-condition';
import { BaseDatabase } from '@shared/services/base.database';
import { CacheService } from '@shared/services/cache.service';
import { Observable } from 'rxjs';
import { IBingoGame } from '../models/bingo-game';
import { IBingoUserPreferences } from '../models/bingo-preferences';
import { GameStatus } from '../models/game-status';

@Injectable({
  providedIn: 'root'
})
export class BingoDatabase extends BaseDatabase {
  private readonly COLLECTION_PATHS = {
    GAMES: '/games/bingo/games',
    PREFERENCES: '/games/bingo/preferences'
  } as const;

  constructor(afs: AngularFirestore, cache: CacheService) {
    super(afs, cache);
  }

  async createGame(data: Omit<IBingoGame, 'uid'>): Promise<IBingoGame> {
    const newGame = await this.createDocument(this.COLLECTION_PATHS.GAMES, data);

    return {
      ...data,
      uid: newGame.id
    };
  }

  getAllGames(userId: string, recordsToFetch?: number): Observable<IBingoGame[]> {
    const whereConditions: IWhereCondition[] = [
      {
        field: 'userId',
        operator: '==',
        value: userId
      },
      {
        field: 'version',
        operator: '==',
        value: 2
      }
    ];

    const orderConditions: IOrderCondition[] = [
      {
        field: 'createdAt',
        direction: 'desc'
      }
    ];

    const queryFn = this.createQueryFunction(whereConditions, orderConditions, recordsToFetch);
    return this.getDocumentsByQuery(this.COLLECTION_PATHS.GAMES, queryFn);
  }

  getGame(gameId: string, useCache = false): Observable<IBingoGame> {
    return this.getDocument<IBingoGame>(this.COLLECTION_PATHS.GAMES, gameId, useCache);
  }

  getGamesWithStatus(userId: string, status: GameStatus, recordsToFetch?: number): Observable<IBingoGame[]> {
    const whereConditions: IWhereCondition[] = [
      {
        field: 'userId',
        operator: '==',
        value: userId
      },
      {
        field: 'version',
        operator: '==',
        value: 2
      },
      {
        field: 'status',
        operator: '==',
        value: status
      }
    ];

    const orderConditions: IOrderCondition[] = [
      {
        field: 'createdAt',
        direction: 'asc'
      }
    ];

    const queryFn = this.createQueryFunction(whereConditions, orderConditions, recordsToFetch);
    return this.getDocumentsByQuery(this.COLLECTION_PATHS.GAMES, queryFn);
  }

  getUserPreferences(userId: string, useCache = false): Observable<IBingoUserPreferences> {
    return this.getDocument<IBingoUserPreferences>(this.COLLECTION_PATHS.PREFERENCES, userId, useCache);
  }

  updateGame<T extends {}>(gameId: string, data: T, merge = true) {
    const updateCache = true;

    const document = {
      ...data,
      uid: gameId,
      updatedAt: Date.now()
    };

    return this.updateDocument(this.COLLECTION_PATHS.GAMES, gameId, document, merge, updateCache);
  }

  async updateGames<T extends { uid: string }>(games: T[], merge = true) {
    const updateCache = true;
    const collectionName = this.COLLECTION_PATHS.GAMES;

    let batch = this.afs.firestore.batch();
    let count = 0;
    const extraCount = 0;

    for (const game of games) {
      const uid = game.uid;
      const ref = this.afs.collection(collectionName).doc(uid).ref;

      const document = {
        ...game,
        updatedAt: Date.now()
      };

      ({ batch, count } = await this.batchOperation('set', ref, batch, count, extraCount, document));

      if (updateCache) {
        this.updateCache(collectionName, uid, document, merge);
      }
    }

    return batch.commit();
  }

  updateUserPreferences(userId: string, data: unknown, merge = true) {
    const updateCache = true;

    const document = {
      ...data,
      uid: userId,
      updatedAt: Date.now()
    };

    return this.updateDocument(this.COLLECTION_PATHS.PREFERENCES, userId, document, merge, updateCache);
  }
}
