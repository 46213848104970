import { Injectable } from '@angular/core';
import { ALL_COUNTRIES } from '@shared/constants/country';
import { AppOptionsService } from '@shared/services/app-options/app-options.service';
import { Observable, of } from 'rxjs';
import { first } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class InterestsService {
  interests: string[] = [];

  constructor(private appOptionsService: AppOptionsService) {
    this.init();
  }

  init(): void {
    this.appOptionsService
      .getOptionsValues<string>('interests', ALL_COUNTRIES)
      .pipe(first())
      .subscribe(interests => {
        this.interests = interests;
      });
  }

  search(search: string): Observable<string[]> {
    if (search == null || search.length === 0) return of(this.interests);

    return of(this.interests.filter(i => i.toLowerCase().indexOf(search.toLowerCase()) > -1));
  }
}
