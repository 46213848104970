import { Component, EventEmitter, Input, Output, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ICatchup } from '@shared/models/catchups/catchup';
import { IGroup } from '@shared/models/groups/group';
import { AuthService } from '@shared/services/auth.service';
import { CatchupService } from '@shared/services/catchups/catchup.service';
import { ConstantsService } from '@shared/services/constants.service';
import { GroupService } from '@shared/services/groups/group.service';
import { SubscriptionService } from '@shared/services/subscription.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'group-catchups',
  templateUrl: './group-catchups.component.html'
})
export class GroupCatchupsComponent implements OnInit, OnDestroy {
  BRANDING: string;
  @Input() canUpdateGroup: boolean;
  catchups: ICatchup[] = [];
  catchupsSubscription: Subscription;
  createCatchupButton: string;
  @Input() heading: string;
  @Input() group: IGroup;
  @Output() linkClicked = new EventEmitter();
  loading: boolean = false;
  manageTemplateButton: string = 'Manage templates';
  noCatchupsMessage: string;
  recordsLoaded: boolean = false;
  readonly RECORDS_TO_FETCH: number = 6;
  routerLink: string[] = [];
  viewAllLabel: string = 'View all catchups';

  constructor(private authService: AuthService, private catchupService: CatchupService, private constantsService: ConstantsService, private groupService: GroupService, private router: Router, private subscriptionService: SubscriptionService) {}

  loadCatchups() {
    if (this.loading) return;
    this.loading = true;

    const shared = true;
    this.catchupsSubscription = this.groupService.getGroupCatchups(this.group.uid, this.RECORDS_TO_FETCH, shared).subscribe(catchups => {
      if (catchups == null) return;

      this.catchups = catchups;
      this.recordsLoaded = true;
      this.loading = false;
    });
    this.subscriptionService.add(this.catchupsSubscription);
  }

  ngOnDestroy() {
    this.subscriptionService.clearSubscription(this.catchupsSubscription);
  }

  ngOnInit() {
    this.BRANDING = this.constantsService.constants.CATCHUPS.branding;
    this.createCatchupButton = `Create ${this.BRANDING}`;
    this.noCatchupsMessage = `This group does not have any ${this.BRANDING}s organised at the moment.`;
    this.viewAllLabel = `View all ${this.BRANDING}s`;
    this.loadCatchups();
  }

  onClick(title: string) {
    this.linkClicked.emit(title);
  }
}
