import { Component, OnInit } from '@angular/core';
import { AlertController } from '@ionic/angular';
import { Country } from '@shared/constants/country';
import { ConstantsService } from '@shared/services/constants.service';
import { SocialService } from '@shared/services/social/social.service';
import { map, mergeMap } from 'rxjs/operators';
import { IInput } from '@shared/models/input';
import { BaseReportComponent } from '../../base-report/base-report.component';

@Component({
  selector: 'app-reports-socials-by-date',
  templateUrl: '../../base-report/base-report.component.html'
})
export class SocialsByDateComponent extends BaseReportComponent {
  columns = [
    { label: 'Group Name', field: 'name', class: 'data-table__column-responsive-bold' },
    { label: 'Number of socials', field: 'count' }
  ];
  config = {
    routerLink: {
      path: '/groups',
      id: [':uid']
    }
  };
  country: Country;
  dateFrom: string = '';
  dateTo: string = '';
  parametersHeader = 'Select dates';
  parametersMessage = '<p>Enter start date, end date, and country for the report.</p><p class="ion-no-margin">N.B. Socials are automatically deleted 90 days after the date of the event.</p>';
  parametersInputs: IInput[] = [
    {
      label: 'From: ',
      name: 'dateFrom',
      placeholder: 'YYYY-MM-DD',
      type: 'date'
    },
    {
      label: 'To: ',
      name: 'dateTo',
      placeholder: 'YYYY-MM-DD',
      type: 'date'
    }
  ];
  instructions = '<p>Click on "select parameters" to choose dates for the report</p>';
  title = 'Socials Report by Date';

  constructor(alertController: AlertController, private constantsService: ConstantsService, private socialService: SocialService) {
    super(alertController);
    this.loadCountries();
  }

  loadCountries() {
    // TODO: Can't mix input types (text, radio) on ion-alert component. Ideally would use radio/select to only allow valid country input
    const countries = Object.values(this.constantsService.constants.APP.countries).join(', ');
    const countryParameter = {
      label: `Country (${countries}): `,
      name: 'country',
      placeholder: `Enter country code (${countries}): `,
      type: 'text'
    };
    this.parametersInputs.push(countryParameter);
  }

  loadData() {
    if (!this.dateFrom || !this.dateTo) return;

    this.data$ = this.socialService.getSocialsByDate(this.dateFrom, this.dateTo, this.country).pipe(
      map(socials => {
        const groups = {};
        for (const social of socials) {
          const groupCount = (social.sharedGroupNames || []).length;
          for (let i = 0; i < groupCount; i++) {
            const id = social.sharedGroupIds[i];
            if (!groups[id]) groups[id] = { count: 0, name: social.sharedGroupNames[i], uid: id };
            groups[id].count += 1;
          }
        }
        return Object.values(groups).length ? Object.values(groups) : [{ count: 0, name: '-', uid: 'none' }];
      })
    );
  }

  selectParametersHandler(data: any) {
    this.country = data.country;
    this.dateFrom = data.dateFrom;
    this.dateTo = data.dateTo;
    this.parametersInputs[0].value = this.dateFrom;
    this.parametersInputs[1].value = this.dateTo;
    this.parametersInputs[2].value = this.country;
    this.loadData();
  }
}
