import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { EventType } from '@infrastructure/constants/event-type';
import { CatchupRsvpStatus } from '@shared/constants/catchup-rsvp-status';
import { IBaseEvent } from '@infrastructure/models/base-event';
import { CatchupTypeService } from '@shared/services/catchups/catchup-types.service';
import { BaseEventService } from '@shared/services/base.event.service';
import { Observable } from 'rxjs';

@Component({
  selector: 'event-card',
  styleUrls: ['./event-card.component.scss'],
  templateUrl: './event-card.component.html'
})
export class EventCardComponent {
  @Input() branding: string;
  @Input() events$: Observable<IBaseEvent[]> | null;
  @Input() groupId: string = '';
  @Input() groupName: string = '';
  @Input() hideIfEmpty: boolean = false;
  @Input() link: string;
  @Output() linkClicked = new EventEmitter();
  @Input() placeholder: string;
  @Input() showBadge: boolean;

  constructor(private catchupTypeService: CatchupTypeService, private baseEventService: BaseEventService) {}

  badgeColor(event: IBaseEvent) {
    return this.baseEventService.isSocial(event) ? 'tertiary' : 'primary';
  }

  badgeText(event: IBaseEvent): string {
    return this.baseEventService.getTypeName(event);
  }

  canShowAddress(event: IBaseEvent): boolean {
    return this.catchupTypeService.canShowAddress(event.eventType);
  }

  canShowTime(event: IBaseEvent): boolean {
    return this.catchupTypeService.canShowTime(event.eventType);
  }

  getRouterLink(event: IBaseEvent): string[] {
    if (this.baseEventService.isSocial(event)) {
      return ['/social', event.uid];
    } else if (this.baseEventService.isTrip(event)) {
      return ['/trips', event.uid];
    } else {
      return this.groupId !== '' ? ['/catchups/group', this.groupId, this.groupName, event.uid] : ['/catchups', event.uid];
    }
  }

  isGoing(event: IBaseEvent): boolean {
    return this.baseEventService.isGoing(event);
  }

  isInterested(event: IBaseEvent): boolean {
    return this.baseEventService.isInterested(event);
  }

  numberGoing(event: IBaseEvent): string {
    return this.baseEventService.numberGoing(event);
  }

  onClick(type: EventType | string) {
    this.linkClicked.emit(type);
  }
}
