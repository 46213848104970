import { NgModule } from '@angular/core';
import { LeafletModule } from '@asymmetrik/ngx-leaflet';
import { SharedModule } from '@shared/shared.module';
import { GroupLocatorComponent } from './components/group-locator-map/group-locator.component';
import { GroupLocatorService } from './components/group-locator-map/group-locator.service';
import { GroupLocatorPinComponent } from './components/group-locator-map/pin/pin.component';
import { GroupMemberListComponent } from './components/group-member-list/group-member-list.component';
import { GroupSearchComponent } from './components/group-search/group-search.component';
import { GroupChitChatPage } from './group-chit-chat/group-chit-chat.page';
import { ChitChatComponent } from './group-detail/components/chit-chat/chit-chat.component';
import { GroupCatchupsComponent } from './group-detail/components/group-catchups/group-catchups.component';
import { NoticesComponent } from './group-detail/components/notices/notices.component';
import { PixComponent } from './group-detail/components/pix/pix.component';
import { SocialsComponent } from './group-detail/components/socials/socials.component';
import { TripsComponent } from './group-detail/components/trips/trips.component';
import { GroupDetailPage } from './group-detail/group-detail.page';
import { GroupEditPage } from './group-edit/group-edit.page';
import { GroupMembersPage } from './group-members/group-members.page';
import { GroupMembersReportPage } from './group-members-report/group-members-report.page';
import { GroupNoticesPage } from './group-notices/group-notices.page';
import { GroupNoticesEditPage } from './group-notices-edit/group-notices-edit.page';
import { GroupNotificationsPage } from './group-notifications/group-notifications.page';
import { GroupPhotoCollectionPage } from './group-photo-collection/group-photo-collection.page';
import { GroupPhotoDetailPage } from './group-photo-detail/group-photo-detail.page';
import { GroupPhotoEditPage } from './group-photo-edit/group-photo-edit.page';
import { GroupPhotoUploadPage } from './group-photo-upload/group-photo-upload.page';
import { GroupSocialsPage } from './group-socials/group-socials.page';
import { GroupsRoutingModule } from './groups-routing.module';
import { GroupsPage } from './groups.page';

@NgModule({
  imports: [GroupsRoutingModule, LeafletModule, SharedModule],
  declarations: [
    ChitChatComponent,
    GroupCatchupsComponent,
    GroupsPage,
    GroupChitChatPage,
    GroupDetailPage,
    GroupEditPage,
    GroupLocatorComponent,
    GroupLocatorPinComponent,
    GroupMemberListComponent,
    GroupMembersPage,
    GroupMembersReportPage,
    GroupNoticesPage,
    GroupNoticesEditPage,
    GroupNotificationsPage,
    GroupPhotoCollectionPage,
    GroupPhotoEditPage,
    GroupPhotoDetailPage,
    GroupPhotoUploadPage,
    GroupSearchComponent,
    GroupSocialsPage,
    NoticesComponent,
    PixComponent,
    SocialsComponent,
    TripsComponent
  ],
  entryComponents: [GroupLocatorPinComponent, GroupSearchComponent],
  providers: [GroupLocatorService]
})
export class GroupsModule {}
