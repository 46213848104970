import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '@shared/services/auth.service';
import { UIService } from '@shared/services/ui.service';

@Component({
  selector: 'app-bnb',
  templateUrl: './bnb.page.html',
  styleUrls: ['./bnb.page.scss']
})
export class BnBPage implements OnInit {
  hasDetail: boolean;

  constructor(private authService: AuthService, private cd: ChangeDetectorRef, private route: ActivatedRoute, private router: Router, private uiService: UIService) {}

  hasUserDetails(): boolean {
    return this.authService._userProfileSubject.value != null;
  }

  ngOnInit() {
    //Get current parameters immediately
    this.parseRoute(this.router.url);

    //Listen for changes to the ActivatedRoute. For some reason it's only working on the initial page load :(
    this.route.url.subscribe(parentRoute => {
      this.parseRoute(this.router.url);
      //This seems to be necessary, otherwise the changes to ion-hide class don't take effect
      this.cd.detectChanges();
    });

    setInterval(() => this.parseRoute(this.router.url), 400); // hack because I can't figure out why the route.url.subscribe() isn't being called.
  }

  parseRoute(url: string): void {
    const segments = url.split('/');

    if (segments.length === 2) {
      this.hasDetail = false;
      return;
    }

    this.hasDetail = typeof segments[2] === 'undefined' ? false : true;
  }

  showDetailView(): boolean {
    return this.hasDetail !== false;
  }

  showMasterView(): boolean {
    return this.uiService.isMediumDisplay || this.hasDetail === false;
  }
}
