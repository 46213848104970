import { Component, ElementRef, Input, OnChanges, SimpleChanges } from '@angular/core';
import { BaseAnimatableComponent } from '../base-animatable/base-animatable.component';
import { AnimationType } from '../../models/animation-type';
import { BallColour, BallColourExtensions } from '../../models/ball-colour';

@Component({
  selector: 'bingo-ball',
  styleUrls: ['./bingo-ball.component.scss'],
  templateUrl: './bingo-ball.component.html'
})
export class BingoBallComponent extends BaseAnimatableComponent implements OnChanges {
  @Input() value: number;

  ballColour: BallColour;
  displayValue: number;

  constructor(protected rootEl: ElementRef) {
    super(rootEl);
  }

  ngOnInit() {
    super.ngOnInit();

    this.animationStartEvent$.subscribe(([_, animationType]) => {
      // Delay updating number and ball colour until right before
      // the entry animation starts.
      if (animationType === 'entry') {
        this.updateDisplayValues();
      }
    });

    this.animationEndEvent$.subscribe(([_, animationType]) => {
      if (animationType === 'exit') {
        this.animateOut.next(false);
        this.animateIn.next(true);
      }

      if (animationType === 'entry') {
        this.animateIn.next(false);
      }
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.value) {
      if (!changes.value.previousValue) {
        this.animateIn.next(true);
      } else {
        this.animateOut.next(true);
      }
    }
  }

  protected getAnimationType(e: AnimationEvent): AnimationType {
    if (e.animationName === 'pop') return 'entry';
    if (e.animationName === 'roll-out') return 'exit';
  }

  private updateDisplayValues() {
    this.displayValue = this.value;
    this.ballColour = BallColourExtensions.getColourForValue(this.value);
  }
}
