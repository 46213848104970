import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ModalController } from '@ionic/angular';
import { LimitPeriod } from '@infrastructure/constants/limit-period';
import { ChirpyGameStatsComponent } from '@shared/components/chirpy-game-stats/chirpy-game-stats.component';
import { GameType } from '@shared/constants/game-type';
import { AnalyticsService, AnalyticsCategory, AnalyticsAction } from '@shared/services/analytics';
import { AuthService } from '@shared/services/auth.service';
import { Subject } from 'rxjs';
import { takeUntil, skipWhile } from 'rxjs/operators';
import { BingoChangeSettingsModalComponent, BingoHelpNotesComponent } from './components';
import { BingoService } from '../services/bingo.service';

@Component({
  selector: 'app-bingo-start',
  templateUrl: 'start.page.html'
})
export class StartPage implements OnInit, OnDestroy {
  readonly bannerUrl = '/assets/chirpy/bingo/bingo-balls.svg';
  readonly gameType = GameType.BINGO;
  readonly leaderboardPeriod = LimitPeriod.MONTH;

  inProgressGameIds: string[] = [];
  onDestroy = new Subject();

  constructor(private analyticsService: AnalyticsService, private authService: AuthService, private bingoService: BingoService, private modalController: ModalController, private router: Router) {}

  ngOnDestroy() {
    this.onDestroy.next();
    this.onDestroy.complete();
  }

  ngOnInit(): void {
    this.bingoService
      .getInProgressGames()
      .pipe(
        takeUntil(this.onDestroy),
        skipWhile(games => !games || games.length === 0)
      )
      .subscribe(games => {
        this.inProgressGameIds = games.map(game => game.uid);
      });
  }

  onResumeGame(gameId: string) {
    this.analyticsService.eventTrack(AnalyticsCategory.BINGO, AnalyticsAction.BINGO_RESUME_GAME, gameId);

    this.navigateToGame(gameId);
  }

  async onStartNewGame() {
    // Create the game data
    const newGame = await this.bingoService.createNewGame();
    this.analyticsService.eventTrack(AnalyticsCategory.BINGO, AnalyticsAction.BINGO_START_GAME, newGame.uid);

    const inProgressGameIds = this.inProgressGameIds.filter(gameId => gameId !== newGame.uid);

    if (inProgressGameIds.length > 0) {
      this.bingoService.batchEndGames(inProgressGameIds);
    }

    // Navigate to new game
    this.navigateToGame(newGame.uid);
  }

  async showHelpNotes() {
    const modal = await this.modalController.create({
      component: BingoHelpNotesComponent
    });

    return await modal.present();
  }

  async showSettings() {
    const modal = await this.modalController.create({
      component: BingoChangeSettingsModalComponent
    });

    await modal.present();
  }

  async showStatistics() {
    const modal = await this.modalController.create({
      component: ChirpyGameStatsComponent,
      componentProps: {
        gameType: this.gameType,
        playerId: this.authService._userProfileSubject.value.uid
      }
    });
    this.analyticsService.eventTrack(AnalyticsCategory.LEADERBOARDS, AnalyticsAction.LEADERBOARDS_VIEW_STATS, this.gameType);
    await modal.present();
  }

  private navigateToGame(gameId: string) {
    this.router.navigate(['/games/bingo/game/', gameId]);
  }
}
