import { Component } from '@angular/core';
import { Platform } from '@ionic/angular';
import { Country } from '@shared/constants/country';
import { IconSize } from '@shared/constants/icon-size';
import { UserObject } from '@shared/models/user-object';
import { AnalyticsAction, AnalyticsCategory, AnalyticsService } from '@shared/services/analytics';
import { AuthService } from '@shared/services/auth.service';
import { EnvironmentService } from '@shared/services/environment.service';
import { UIService } from '@shared/services/ui.service';
import { UrlHelperService } from '@shared/services/url-helper.service';
import { of, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.page.html',
  styleUrls: ['./dashboard.page.scss']
})
export class DashboardPage {
  icons = [
    {
      title: 'Central',
      url: '/central',
      size: IconSize.LARGE,
      src: '/assets/friends/dashboard/central.jpg'
    },
    {
      title: 'Global Friends Chat',
      url: '/groups/chit-chat/pwK3tJxKJYfC2vkNyEGs',
      size: IconSize.LARGE,
      src: '/assets/friends/dashboard/groups.jpg'
    },
    {
      title: 'Coins',
      url: '/coins',
      size: IconSize.LARGE,
      src: '/assets/friends/dashboard/coins.jpg'
    },
    {
      title: 'Find Friends',
      url: '/meeting-place',
      size: IconSize.LARGE,
      src: '/assets/friends/dashboard/find-friends.jpg'
    },
    {
      title: 'Travel',
      url: '/travel', // redirected to travel page in only-friends-routing.module
      size: IconSize.LARGE,
      src: '/assets/friends/dashboard/travel.jpg'
    },
    {
      title: 'Share',
      url: '/share/intro',
      size: IconSize.LARGE,
      src: '/assets/friends/dashboard/share.jpg'
    },

    {
      title: 'My Profile',
      url: '/members/me',
      size: IconSize.SMALL,
      src: '/assets/friends/dashboard/my-profile.jpg'
    },

    {
      title: 'Help',
      url: 'https://only-friends.helpscoutdocs.com/',
      size: IconSize.SMALL,
      src: '/assets/friends/dashboard/help.jpg',
      fullExternalUrl: true,
      external: true,
      lightText: true
    },
    {
      title: 'Coins Balance',
      url: '/coins',
      size: IconSize.SMALL,
      src: '/assets/friends/dashboard/coins-balance.jpg',
      lightText: true
    }
  ];

  uiServiceSubscription: Subscription;
  size = { LARGE: 6, SMALL: 4 };

  constructor(private analyticsService: AnalyticsService, public authService: AuthService, private environmentService: EnvironmentService, public platform: Platform, public uiService: UIService, private urlHelperService: UrlHelperService) {}

  getSize(iconSize: string): any {
    return this.size[iconSize];
  }

  isAU$() {
    return this.authService.userProfileObservable.pipe(map((user: UserObject) => user != null && user.country === Country.AUSTRALIA));
  }

  ngOnInit() {
    this.uiServiceSubscription = this.uiService.isMediumDisplay$.subscribe(isMediumDisplay => {
      if (isMediumDisplay) {
        this.size = { LARGE: 3, SMALL: 2 };
      } else {
        this.size = { LARGE: 6, SMALL: 4 };
      }
    });
  }

  ngOnDestroy() {
    this.uiServiceSubscription.unsubscribe();
  }

  onClickIcon(icon: any, index: number) {
    this.analyticsService.eventTrack(AnalyticsCategory.NAVIGATION, AnalyticsAction.NAVIGATION_DASHBOARD_ICON, icon.title, { type: icon.size }, index);
  }

  showLoadingOverlay() {
    this.uiService.showLoadingOverlay();
  }
}
