import { Injectable, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { Role } from '@infrastructure/constants/role';
import { AlertController } from '@ionic/angular';
import { AdminRole } from '@shared/constants/admin-role';
import { Country } from '@shared/constants/country';
import { GroupType } from '@shared/constants/group-type';
import { ICatchup } from '@shared/models/catchups/catchup';
import { IGroup } from '@shared/models/groups/group';
import { ISocial } from '@shared/models/social/social';
import { INotice } from '@shared/models/groups/notice';
import { IListingImage } from '@shared/models/image/listing-image';
import { ITrip } from '@shared/models/trips/trip';
import { IValueWithId } from '@shared/models/value-with-id';
import { UserObject } from '@shared/models/user-object';
import { ActivityService, ActivityType } from '@shared/services/activity';
import { AuthService } from '@shared/services/auth.service';
import { CatchupDatabase } from '@shared/services/catchups/catchup.database';
import { ConstantsService } from '@shared/services/constants.service';
import { DateTimeService } from '@shared/services/date-time.service';
import { EmailService } from '@shared/services/email/email.service';
import { EnvironmentService } from '@shared/services/environment.service';
import { ImageService } from '@shared/services/image/image.service';
import { LocationService } from '@shared/services/location/location.service';
import { NotificationTarget, NotificationService } from '@shared/services/notifications';
import { SubscriptionService } from '@shared/services/subscription.service';
import { ToastService } from '@shared/services/toast.service';
import { TripDatabase } from '@shared/services/trips/trip.database';
import { UserDatabase } from '@shared/services/user/user.database';
import firebase from 'firebase/app';
import { BehaviorSubject, combineLatest, Observable, Subject, Subscription } from 'rxjs';
import { first, map, skipWhile, switchMap, take } from 'rxjs/operators';
import { GroupDatabase } from './group.database';

@Injectable({
  providedIn: 'root'
})
export class GroupService implements OnDestroy {
  groupIdSubscriptions: Record<string, Subscription> = {};
  groupIdSubscriptionsWithNotices: Record<string, Subscription> = {};
  groupsSubscription: Subscription;
  isAddingMember: boolean;
  isRemovingMember: boolean;
  LARGE_GROUP_SIZE: number = 500;

  constructor(
    private activityService: ActivityService,
    private authService: AuthService,
    private alertController: AlertController,
    private catchupDatabase: CatchupDatabase,
    private constantsService: ConstantsService,
    private groupDatabase: GroupDatabase,
    private dateTimeService: DateTimeService,
    private emailService: EmailService,
    private environmentService: EnvironmentService,
    private imageService: ImageService,
    private locationService: LocationService,
    private notificationService: NotificationService,
    private router: Router,
    private subscriptionService: SubscriptionService,
    private toastService: ToastService,
    private tripDatabase: TripDatabase,
    private userDatabase: UserDatabase
  ) {}

  async addMemberToGroup(groupId: string, groupName: string, groupMemberCount: number, memberId: string, memberName: string, showToast: boolean = false): Promise<boolean> {
    if (this.isAddingMember === true) return Promise.resolve(false);
    this.isAddingMember = true;

    // Add member to the Group
    const publicMemberData = { catchupGroupIds: firebase.firestore.FieldValue.arrayUnion(groupId) };
    return this.userDatabase
      .updatePublicMemberData(memberId, publicMemberData)
      .then(() => {
        // Increment groups member count
        const catchupGroupData = { memberCount: firebase.firestore.FieldValue.increment(1) };
        return this.groupDatabase.updateGroup(groupId, catchupGroupData).then(async (data: any) => {
          const isMe = this.authService._userProfileSubject.value.uid === memberId;
          const message = isMe ? `You have joined ${groupName}.` : `${memberName} has been added to the group.`;
          if (showToast) await this.toastService.presentToast(message);
          this.isAddingMember = false;
          const notificationKey = '';
          const isLargeGroup = groupMemberCount >= this.LARGE_GROUP_SIZE;
          this.notificationService.createNotificationForMember(NotificationTarget.GROUP, groupId, memberId, notificationKey, isLargeGroup);
          return true; // Need to return true for GroupMemberListService:onAddMemberToGroup
        });
      })
      .catch(() => {
        this.isAddingMember = false;
        return false;
      });
  }

  canMakeMediaPublic(uid: string): Observable<boolean> {
    const useCache = true;
    return this.getGroup(uid, useCache).pipe(
      first(x => !!x),
      map(group => {
        let canMakePublic = false;
        const PUBLIC_COUNTRIES = this.constantsService.constants.MEDIA.publicCountries;
        for (const country of group.country) {
          if (PUBLIC_COUNTRIES.includes(country as Country)) {
            canMakePublic = true;
            break;
          }
        }
        return canMakePublic;
      })
    );
  }

  canManageGroup(group: IGroup): boolean {
    const groupIsLocked = !!group.isLocked;
    const isAdminOrSupport = this.authService.isAdmin([AdminRole.SUPER, AdminRole.SUPPORT]);

    if (groupIsLocked && !isAdminOrSupport) {
      return false;
    }

    return this.isGroupHostOrCohost(group);
  }

  canManageGroup$(group: string | IGroup): Subject<boolean> {
    const subject$ = new Subject<boolean>();
    if (this.isGroupObject(group)) {
      const canManageGroup = this.canManageGroup(group);
      subject$.next(canManageGroup);
    } else {
      // retrieve Group from Firebase if we've passed groupId into canManageGroup
      this.groupDatabase.getGroup(group).subscribe(g => {
        const canManageGroup = this.canManageGroup(g);
        subject$.next(canManageGroup);
      });
    }
    return subject$;
  }

  async deleteGroup(group: IGroup) {
    const alert = await this.alertController.create({
      header: `Delete ${group.name}`,
      message: `Are you sure you want to delete this group? All members will be removed from the group, and all upcoming events for this group will be deleted.`,
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
          cssClass: 'secondary'
        },
        {
          text: 'OK',
          handler: data => this.deleteGroupHandler(group)
        }
      ]
    });

    await alert.present();
  }

  deleteGroupHandler(group) {
    // Delete list of members to be notified about group activity
    this.notificationService.deleteNotification(NotificationTarget.GROUP, group.uid);

    // Remove all members from the Group (group membership is stored in /centralMembers/uid)
    // TODO: Don't create activity entries for members "leaving" the group
    this.groupDatabase
      .getGroupMembers(group.uid)
      .pipe(first()) // TODO: This may be susceptible to caching, in which case some members wouldn't be deleted
      .subscribe(members => {
        const memberIds = members.map(m => m.uid);
        return this.removeMembersFromGroup(group.uid, memberIds);
      });

    // Delete associated chit-chat thread (+ messages)
    this.groupDatabase.markGroupThreadForDeletion(group.uid);

    // For all future catchups associated with this group the deleteDate is set to now and approved is set to false (to immediately hide from catchup lists)
    const startDate = this.dateTimeService.getDateTime();
    const sharedGroups = false;
    this.groupDatabase
      .getGroupCatchups(999, group.uid, startDate, sharedGroups)
      .pipe(first())
      .subscribe(catchups => {
        return this.removeCatchups(catchups);
      });

    // Remove dot(s) from map
    const locations = Object.keys(group.locations || {});
    if (locations.length > 0) {
      for (const locationId of locations) {
        this.locationService.unsetHasGroupFlag(locationId, group.uid);
      }
    }

    // onGroupDeleted Cloud function will delete any notices when the group is deleted
    return this.groupDatabase.deleteGroup(group.uid).then(() => {
      this.toastService.presentToast(`${group.name} has been deleted.`);
      this.router.navigate(['/groups']);
    });
  }

  deleteNotice(groupId: string, noticeId: string) {
    return this.groupDatabase.deleteNotice(groupId, noticeId);
  }

  getAllCatchups(groups: string[]): Observable<ICatchup[]> {
    return this.groupDatabase.getAllCatchups(groups);
  }

  getAllGroupNames(useCache: boolean): Observable<IValueWithId[]> {
    return this.groupDatabase.getAllGroupNames(useCache).pipe(map(x => x.ALL));
  }

  getAllGroups(useCache: boolean): Observable<IGroup[]> {
    return this.groupDatabase.getAllGroups(useCache);
  }

  getGroup(uid: string, useCache = false): Observable<IGroup> {
    return this.groupDatabase.getGroup(uid, useCache);
  }

  getGroupCatchups(groups: string, recordsToFetch: number, shared: boolean = false) {
    const today = this.dateTimeService.getDay();
    return this.groupDatabase.getGroupCatchups(recordsToFetch, groups, today, shared);
  }

  getGroupMembers(uid: string) {
    return this.groupDatabase.getGroupMembers(uid);
  }

  getGroupNotices(groupId: string): Observable<INotice[]> {
    // TODO: Why don't we order database query by dateTimeLastUpdated?
    return this.groupDatabase.getGroupNotices(groupId).pipe(
      map((notices: INotice[]) => {
        return notices.sort((a, b) => b.dateTimeLastUpdated - a.dateTimeLastUpdated);
      })
    );
  }

  getGroups() {
    const subject = new BehaviorSubject(null);
    const country = this.isAdmin() || this.constantsService.constants.APP.allowOtherCountries ? null : this.authService._userProfileSubject.value.country;
    let myGroupIds = this.authService._userProfileSubject.value.catchupGroupIds || [];

    this.subscriptionService.clearSubscription(this.groupsSubscription);
    this.groupsSubscription = combineLatest(this.groupDatabase.getGroups(country), this.authService.isCohostOrHostOrAdmin$([AdminRole.HOSTS])).subscribe(([groups, isCohostOrHostOrAdmin]) => {
      if (groups == null) return;
      if (!isCohostOrHostOrAdmin) groups = groups.filter(g => g.groupType !== GroupType.HOST_ONLY_GROUP);
      groups = groups.sort((a, b) => (a.name > b.name ? 1 : -1));

      // display my groups first.
      myGroupIds = this.authService._userProfileSubject.value.catchupGroupIds || []; // Make sure the list is up to date, in case you just joined a group
      const myGroups = groups.filter(group => myGroupIds.includes(group.uid));
      const otherGroups = groups.filter(group => !myGroupIds.includes(group.uid));
      const sortedGroups = myGroups.concat(otherGroups);

      subject.next(sortedGroups);
    });
    this.subscriptionService.add(this.groupsSubscription);
    return subject;
  }

  getGroupsById(ids: string[]) {
    const subject = new BehaviorSubject([]);
    if ((ids || []).length > 0) {
      const key = ids.join('-');
      // To allow multiple chirpy-group-list-widget components on the same page we don't do subscriptionService.clearSubscription each time this function is called
      if (this.groupIdSubscriptions[key] != null) this.subscriptionService.clearSubscription(this.groupIdSubscriptions[key]);
      this.groupIdSubscriptions[key] = this.groupDatabase.getGroupsById(ids).subscribe(groups => {
        subject.next(groups);
      });
      this.subscriptionService.add(this.groupIdSubscriptions[key]);
    }
    return subject;
  }

  getGroupsWithNoticesById(ids: string[], groupType: GroupType = null) {
    const subject = new BehaviorSubject([]);
    if ((ids || []).length > 0) {
      const key = ids.join('-');
      // To allow multiple chirpy-group-list-widget components on the same page we don't do subscriptionService.clearSubscription each time this function is called
      if (this.groupIdSubscriptionsWithNotices[key] != null) this.subscriptionService.clearSubscription(this.groupIdSubscriptionsWithNotices[key]);
      this.groupIdSubscriptions[key] = this.groupDatabase.getGroupsWithNoticesById(ids, groupType).subscribe(groups => {
        groups = groups
          .filter(group => (groupType !== null ? group.groupType === groupType : group.groupType !== GroupType.HIDDEN_GROUP))
          .map(group => {
            if (group.Notices) {
              group.Notices.sort((a, b) => b.dateTimeLastUpdated - a.dateTimeLastUpdated);
            }
            return group;
          });
        subject.next(groups);
      });
      this.subscriptionService.add(this.groupIdSubscriptionsWithNotices[key]);
    }
    return subject;
  }

  getGroupSocials(group: string, recordsToFetch: number, showDrafts: boolean = false): Observable<ISocial[]> {
    const today = this.dateTimeService.getStartOfTodayAsString();
    return this.groupDatabase.getGroupSocials(recordsToFetch, group, today, showDrafts);
  }

  getGroupTrips(group: string, recordsToFetch: number): Observable<ITrip[]> {
    const today = this.dateTimeService.getStartOfTodayAsString();
    const isAdmin = false;
    return this.tripDatabase.getGroupTrips(recordsToFetch, group, today, isAdmin);
  }

  getGroupsHostedByMember$(memberId: string): Observable<IGroup[]> {
    return combineLatest(this.groupDatabase.getGroupsByHost(memberId), this.groupDatabase.getGroupsByCohost(memberId)).pipe(map(([hostGroups, cohostGroups]) => [...hostGroups, ...cohostGroups]));
  }

  getHostsAndCohosts$(uid: string): Observable<Record<string, string>> {
    return this.getGroup(uid).pipe(map((group: IGroup) => Object.assign({}, group.hosts, group.cohosts)));
  }

  isAdmin(): boolean {
    return this.authService.isAdmin([AdminRole.EDITOR, AdminRole.HOSTS, AdminRole.TRAVEL]);
  }

  isAdmin$(): Observable<boolean> {
    return this.authService.isAdmin$([AdminRole.EDITOR, AdminRole.HOSTS, AdminRole.TRAVEL]);
  }

  isGroupMember(uid: string): boolean {
    if (uid == null || this.authService._userProfileSubject == null || this.authService._userProfileSubject.value == null) return null;
    return (this.authService._userProfileSubject.value.catchupGroupIds || []).some(g => g === uid);
  }

  isGroupMember$(uid: string): Observable<boolean> {
    return this.authService._userProfileSubject.pipe(map(profile => (profile.catchupGroupIds || []).some(g => g === uid)));
  }

  isGroupMemberOfAny(groupIds: string[]): boolean {
    if (groupIds == null || this.authService._userProfileSubject == null || this.authService._userProfileSubject.value == null) return null;
    return (this.authService._userProfileSubject.value.catchupGroupIds || []).some(g => groupIds.includes(g));
  }

  isGroupHost(group: IGroup) {
    if (group == null) return this.isAdmin();
    const isGroupHost = Object.keys(group.hosts || {}).includes(this.authService._userProfileSubject.value.uid);
    return isGroupHost || this.isAdmin();
  }

  isGroupHost$(member: UserObject, groupId: string): Observable<boolean> {
    const subject$ = new Subject<boolean>();
    if (member == null) {
      subject$.next(false);
    } else if (groupId == null || groupId === '') {
      subject$.next(this.isAdmin());
    } else {
      this.groupDatabase
        .getGroup(groupId)
        .pipe(first())
        .subscribe(group => {
          const isGroupHost = Object.keys(group.hosts || {}).includes(member.uid);
          subject$.next(isGroupHost || this.isAdmin());
        });
    }
    return subject$;
  }

  async lockGroup(group: IGroup) {
    const alert = await this.alertController.create({
      header: `Lock ${group.name}`,
      message: 'Are you sure you want to lock this group? Hosts will be unable to post new notices and all members will be unable to post in chit-chat.',
      inputs: [
        {
          name: 'reason',
          placeholder: 'Reason for locking group',
          type: 'text'
        }
      ],
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
          cssClass: 'secondary'
        },
        {
          text: 'OK',
          handler: data => {
            this.lockGroupHandler(group, data.reason);
          }
        }
      ]
    });

    await alert.present();
  }

  private async lockGroupHandler(group: IGroup, reason?: string): Promise<void> {
    const currentUser = this.authService._userProfileSubject.value;

    const updatedGroup = {
      ...group,
      isLocked: true,
      lockedReason: reason
    };
    const merge = true;
    const updateCache = true;

    await this.groupDatabase.updateGroup(group.uid, updatedGroup, merge, updateCache);

    this.emailService.sendGroupLockedByAdminNotification(group.uid, group.name, currentUser.fullName, reason);
  }

  ngOnDestroy() {
    for (let sub of Object.values(this.groupIdSubscriptions)) {
      this.subscriptionService.clearSubscription(sub);
    }
    this.subscriptionService.clearSubscription(this.groupsSubscription);
  }

  async removeMemberFromGroup(groupId: string, groupName: string, memberId: string, memberName: string, handler: any = null) {
    const isMe = this.authService._userProfileSubject.value.uid === memberId;
    const header = isMe ? `Leave ${groupName}` : `Remove ${memberName}`;
    const buttonText = isMe ? 'Leave' : 'Remove';
    const message = isMe ? `Are you sure you want to leave?` : `Remove ${memberName} from ${groupName}?`;

    const alert = await this.alertController.create({
      header,
      message,
      buttons: [
        {
          text: buttonText,
          handler: () => {
            this.removeMemberFromGroupHandler(groupId, groupName, memberId, memberName).then(() => {
              if (handler != null) handler();
            });
          }
        },
        {
          text: 'Cancel',
          role: 'cancel'
        }
      ]
    });

    await alert.present();
  }

  removeMemberFromGroupHandler(groupId: string, groupName: string, memberId: string, memberName: string) {
    // Remove member from Group
    const publicMemberData = { catchupGroupIds: firebase.firestore.FieldValue.arrayRemove(groupId) };
    return this.userDatabase.updatePublicMemberData(memberId, publicMemberData).then(async () => {
      // Decrement groups member count
      const catchupGroupData = { memberCount: firebase.firestore.FieldValue.increment(-1) };
      await this.groupDatabase.updateGroup(groupId, catchupGroupData);

      const isMe = this.authService._userProfileSubject.value.uid === memberId;
      const message = isMe ? `You have left ${groupName}.` : `${memberName} has been removed from the group.`;
      this.notificationService.removeNotificationForMember(NotificationTarget.GROUP, groupId, memberId);
      this.toastService.presentToast(message);
    });
  }

  searchAdvisors(startsWith: string) {
    return this.groupDatabase.searchMembersByRole(startsWith, [Role.ADVISOR]);
  }

  searchCohosts(startsWith: string) {
    return this.groupDatabase.searchMembersByRole(startsWith, [Role.COHOST, Role.HOST, Role.ADMIN]); // A host of one group can be assigned as the co-host of another group
  }

  searchGroups(startsWith: string | null, country: string = '', exclude: string[] = [], isTravel: boolean = false): Observable<Record<string, string>> {
    startsWith = startsWith ? startsWith.toLowerCase() : '';
    const excludedTypes = [GroupType.HIDDEN_GROUP, GroupType.HOST_ONLY_GROUP];
    const useCache = true;
    return this.groupDatabase.getAllGroups(useCache).pipe(
      map(groups => {
        return groups.reduce((output, group) => {
          let match = true;
          if (!group.name || !group.country || excludedTypes.includes(group.groupType)) return output;
          if (startsWith) match = match && group.name.toLowerCase().includes(startsWith);
          if (country) match = match && group.country.includes(country);
          if (exclude.length) match = match && !exclude.includes(group.uid);
          if (isTravel) match = match && group.canShowTrips; // ideally we would filter on groupType, but currently travel groups are classed as SIGs
          if (match) output[group.uid] = group.name;
          return output;
        }, {});
      })
    );
  }

  searchGroupsWithoutCatchups(startsWith: string | null, date: string, country: string = ''): Observable<Record<string, string>> {
    const useCache = true;
    const cacheKey = `${country}-${date}`;
    return this.searchGroups(startsWith, country).pipe(
      switchMap(
        groups => this.catchupDatabase.getCatchupsByDate(date, date, country, useCache, cacheKey),
        (groups, catchups) => {
          for (const catchup of catchups) {
            if (groups[catchup.groupId]) delete groups[catchup.groupId];
          }
          return groups;
        }
      )
    );
  }

  searchHosts(startsWith: string) {
    return this.groupDatabase.searchMembersByRole(startsWith, [Role.HOST, Role.ADMIN]);
  }

  async sendMemberJoinedGroupNotifications(groupId: string, groupName: string, hosts: Record<string, string>, memberId: string, isVirtualGroup: boolean, isChatGroup: boolean) {
    const member = await this.userDatabase
      .getPublicMemberData(memberId)
      .pipe(
        skipWhile(u => !u),
        take(1)
      )
      .toPromise();

    await this.emailService.sendMemberJoinedGroupNotification(groupId, groupName, member, hosts); // NB: This email requires host info for OFG, but not Chirpy
    // Don't send notifications to hosts if (a) emails are disabled and (b) it's a virtual group
    if (!(this.constantsService.constants.GROUPS.disableMemberJoinedNotifyHost && isVirtualGroup) && !isChatGroup) {
      await this.emailService.sendMemberJoinedGroupNotificationForHost(groupId, groupName, member, hosts);
    }
  }

  async unlockGroup(group: IGroup) {
    const alert = await this.alertController.create({
      header: `Unlock ${group.name}`,
      message: 'Are you sure you want to unlock this group? Hosts will be able to resume posting notices and all members will be able to post in chit-chat.',
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
          cssClass: 'secondary'
        },
        {
          text: 'OK',
          handler: _ => this.unlockGroupHandler(group)
        }
      ]
    });

    await alert.present();
  }

  private async unlockGroupHandler(group: IGroup): Promise<void> {
    const updatedGroup = {
      ...group,
      isLocked: false,
      lockedReason: null
    };
    const merge = true;
    const updateCache = true;

    await this.groupDatabase.updateGroup(group.uid, updatedGroup, merge, updateCache);
  }

  updateGroup(group: IGroup) {
    const isCreating = group.uid == null;

    if (isCreating) {
      group.created = this.dateTimeService.getDateTime();

      this.groupDatabase.createGroup(group).then(result => {
        this.updateGroupName(result.id, group.name);
        const message = `Group has been created.`;
        this.toastService.presentToast(message);
        this.router.navigate(['/groups', result.id]);
      });
    } else {
      // Use merge=false so that map-type fields are properly updated
      const merge = false,
        updateCache = true;
      this.groupDatabase.updateGroup(group.uid, group, merge, updateCache).then(() => {
        this.updateGroupName(group.uid, group.name);
        const message = `Group has been updated.`;
        this.toastService.presentToast(message);
        this.router.navigate(['/groups', group.uid]);
      });
    }
  }

  updateGroupName(groupId: string, groupName: string) {
    this.groupDatabase.updateGroupName(groupId, groupName);
  }

  updateNotice(groupId: string, notice: INotice, photosToRemove: IListingImage[]) {
    const isCreating = notice.uid === 'new';

    // convert first letter to upper case
    notice.title = notice.title[0].toUpperCase() + notice.title.slice(1);

    // Delete images removed from listing
    if (photosToRemove.length > 0) {
      for (const photo of photosToRemove) {
        this.imageService.deleteImage(photo).catch(err => console.error(JSON.stringify(err)));
      }
    }

    // copy photos data for later upload, and remove any File objects (Can't upload them to Firestore, we can't upload the image to Cloud storage until we have a marketplaceListing uid)
    const photos = this.imageService.deepCopy(notice.photos);
    for (let [key, photo] of Object.entries(notice.photos)) {
      if (photo.file) {
        delete notice.photos[key].file;
        notice.photos[key].photoURL = '';
      }
    }

    const ref: Promise<any> = isCreating ? this.groupDatabase.createNotice(groupId, notice) : this.groupDatabase.updateNotice(groupId, notice.uid, notice, false);

    return ref.then(result => {
      const uid = notice.uid === 'new' ? result.id : notice.uid;
      // upload photos
      this.imageService.uploadImages(uid, photos, `notice/${groupId}`);

      //TODO: Do we want to do any sort of notification for notices being updated?
      // Will usually be fixing errors, unless hosts reuse the same message rather than deleting
      if (isCreating) {
        const group = this.getGroup(groupId)
          .pipe(first(x => !!x))
          .subscribe(g => {
            const activity = {
              activityTypes: [ActivityType.FEED, ActivityType.EMAIL, ActivityType.DIGEST],
              data: {
                baseUrl: this.environmentService.url(g.country[0]),
                groupId: groupId,
                groupName: g.name,
                senderName: notice.memberName
              },
              message: `<a href="/members/${notice.memberId}">${notice.memberName}</a> added a <a href="/groups/notices/${groupId}">new notice</a> in <a href="/groups/${groupId}">${g.name}</a>:<br><em>${notice.title}</em>`,
              notificationType: 'newNotice',
              targetIds: [groupId],
              targetType: NotificationTarget.GROUP,
              timestamp: Date.now()
            };
            this.activityService.createActivity(activity);
          });
      }
    });
  }

  private isGroupHostOrCohost(group: IGroup) {
    if (this.isAdmin()) {
      return true;
    }

    if (!this.authService.isHost() && !this.authService.isCohost()) {
      return false;
    }

    if (group == null) {
      return false;
    }

    const hostAndCohostMemberIds = new Set(Object.keys({ ...group.hosts, ...group.cohosts }));
    const isGroupHostOrCohost = hostAndCohostMemberIds.has(this.authService._userProfileSubject.value.uid);

    return isGroupHostOrCohost;
  }

  private isGroupObject(value: any): value is IGroup {
    return value.hasOwnProperty('uid');
  }

  private removeCatchups(catchups: ICatchup[]) {
    const promises = [];
    catchups.forEach(catchup => {
      // This catchup will be deleted by a scheduled cloud function, cleanupCatchupsWatcherFunction
      catchup.deleteDate = this.dateTimeService.getDateTime();
      // Hide from the user immediately.
      catchup.approved = false;
      promises.push(this.catchupDatabase.updateCatchup(catchup));
    });
    return Promise.all(promises);
  }

  private removeMembersFromGroup(groupId: string, memberIds: string[]) {
    const publicMemberData = { catchupGroupIds: firebase.firestore.FieldValue.arrayRemove(groupId) };
    const promises = [];
    memberIds.forEach(async memberId => {
      promises.push(this.userDatabase.updatePublicMemberData(memberId, publicMemberData));
      promises.push(this.notificationService.removeNotificationForMember(NotificationTarget.GROUP, groupId, memberId));
    });
    return Promise.all(promises);
  }
}
