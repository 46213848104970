import { Injectable } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { BehaviorSubject } from 'rxjs';
import { IBingoUserPreferences } from '../../models/bingo-preferences';
import { BingoGameSpeedOption } from '../../models/game-speed-option';

@Injectable({
  providedIn: 'root'
})
export class BingoSettingsFormPresenter {
  get settings(): IBingoUserPreferences {
    const { ballDelay } = this.form.controls;

    return {
      ballDelayInMs: this.delayInMsFromFormValue(ballDelay.value)
    };
  }

  form = this.formBuilder.group({
    ballDelay: [0, Validators.required]
  });

  private ballDelayDisplayValue = new BehaviorSubject('');
  ballDelayDisplayValue$ = this.ballDelayDisplayValue.asObservable();

  constructor(private formBuilder: FormBuilder) {}

  setValue(settings: IBingoUserPreferences) {
    this.form.setValue({
      ballDelay: this.delayInMsToFormValue(settings.ballDelayInMs)
    });

    this.updateBallDelayDisplayValue();
  }

  updateBallDelayDisplayValue() {
    const delayInMs = this.delayInMsFromFormValue(this.form.controls.ballDelay.value);

    this.ballDelayDisplayValue.next(`${(delayInMs / 1000).toFixed(1)}s`);
  }

  private delayInMsToFormValue(delayInMs: number) {
    return Math.max((BingoGameSpeedOption.SLOWEST - delayInMs) / 1000 + 1, 1);
  }

  private delayInMsFromFormValue(value: number) {
    return Math.max(BingoGameSpeedOption.SLOWEST - (value - 1) * 1000, BingoGameSpeedOption.FASTEST);
  }
}
