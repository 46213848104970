import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ModalController } from '@ionic/angular';
import { AdminRole } from '@shared/constants/admin-role';
import { Country } from '@shared/constants/country';
import { ICatchupOptions } from '@shared/models/catchups/catchup-options';
import { CatchupShowOption } from '@shared/constants/catchup-show-option';
import { AnalyticsAction, AnalyticsCategory, AnalyticsService } from '@shared/services/analytics';
import { AuthService } from '@shared/services/auth.service';
import { CatchupService } from '@shared/services/catchups/catchup.service';
import { ConstantsService } from '@shared/services/constants.service';
import { RegionService } from '@shared/services/regions/region.service';
import { CatchupSearchPresenter } from './catchup-search.presenter';

@Component({
  selector: 'app-catchup-search',
  templateUrl: './catchup-search.component.html',
  styleUrls: ['./catchup-search.component.scss'],
  viewProviders: [CatchupSearchPresenter],
  encapsulation: ViewEncapsulation.None
})
export class CatchupSearchComponent implements OnInit {
  get isAdmin() {
    return this.authService.isAdmin([AdminRole.HOSTS]);
  }

  get isHost() {
    return this.authService.isCohostOrHostOrAdmin();
  }

  get searchForm() {
    return this.presenter.form;
  }

  COUNTRIES: any;
  isCountrySearchEnabled: boolean;
  @Input() catchupOptions: ICatchupOptions;
  // TODO: How to handle branding of CatchUps here? At the moment plants does not allow searching on catch-ups so it's fine
  catchupShowOptions = [CatchupShowOption.EVERYTHING, CatchupShowOption.MY_GROUPS, CatchupShowOption.CATCHUPS, CatchupShowOption.VIRTUAL];
  groupName: string;
  regionLabel: string;
  regions: any[];
  @Output() search = new EventEmitter<any>();
  title: string;

  constructor(
    private route: ActivatedRoute,
    private analyticsService: AnalyticsService,
    private authService: AuthService,
    private catchupService: CatchupService,
    private presenter: CatchupSearchPresenter,
    private constantsService: ConstantsService,
    private modalController: ModalController,
    private regionService: RegionService
  ) {}

  dismiss() {
    this.modalController.dismiss();
  }

  ngOnInit() {
    if (this.isHost) this.catchupShowOptions = [CatchupShowOption.HOSTED_BY_ME, ...this.catchupShowOptions];
    if (this.isAdmin) this.catchupShowOptions = [CatchupShowOption.PENDING, ...this.catchupShowOptions];
    this.COUNTRIES = this.constantsService.constants.APP.countries;
    this.isCountrySearchEnabled = this.constantsService.constants.APP.allowOtherCountries;
    const country = this.catchupOptions.country as Country;
    this.updateRegions(country);
    this.title = `Search ${this.constantsService.constants.CATCHUPS.branding}s`;
    if (this.catchupOptions != null) {
      this.presenter.setValue(this.catchupOptions);
    } else {
      this.presenter.reset();
    }
  }

  onChangeCountry(event: any) {
    const country = event.detail.value;
    this.updateRegions(country);
  }

  onSearch() {
    const search = this.presenter.search();
    this.analyticsService.eventTrack(AnalyticsCategory.CATCHUPS, AnalyticsAction.CATCHUPS_SEARCH, null, { term1: search.region, term2: search.show });
    this.search.emit({ type: 'search', data: search });
  }

  reset() {
    this.presenter.reset();
    const search = this.presenter.search();
    this.analyticsService.eventTrack(AnalyticsCategory.CATCHUPS, AnalyticsAction.CATCHUPS_CLEAR_SEARCH);
    this.search.emit({ type: 'reset', data: search });
  }

  private updateRegions(country: Country) {
    this.regionLabel = this.regionService.getRegionLabelForCountry(country);
    const prependSelectAll = true;
    this.regions = this.regionService.getRegionsForCountry(country, prependSelectAll);
  }
}
