import { NgModule } from '@angular/core';
import { AdminGuard } from '@shared/guards/admin-guard';
import { GamesSharedModule } from '../shared/shared.module';
import { BingoRoutingModule } from './bingo-routing.module';
import { BingoSettingsFormComponent } from './components';
import { BaseAnimatableComponent, BingoBallComponent, BingoCalledNumbersComponent, BingoFireworksOverlayComponent, BingoInfoPaneComponent, BingoPauseMenuComponent, BingoTicketComponent, BingoTileHeadingComponent, NoBingoIndicatorComponent } from './game/components';
import { GamePage } from './game/game.page';
import { BingoHelpNotesComponent, BingoPlayGameComponent, BingoChangeSettingsModalComponent } from './start/components';
import { StartPage } from './start/start.page';

@NgModule({
  imports: [BingoRoutingModule, GamesSharedModule],
  entryComponents: [BingoChangeSettingsModalComponent, BingoHelpNotesComponent, BingoPauseMenuComponent],
  declarations: [
    // Start
    StartPage,
    BingoChangeSettingsModalComponent,
    BingoHelpNotesComponent,
    BingoPlayGameComponent,

    // Game
    // @ts-ignore
    BaseAnimatableComponent,
    BingoBallComponent,
    BingoCalledNumbersComponent,
    BingoFireworksOverlayComponent,
    BingoInfoPaneComponent,
    BingoPauseMenuComponent,
    BingoTicketComponent,
    BingoTileHeadingComponent,
    GamePage,
    NoBingoIndicatorComponent,

    // Shared
    BingoSettingsFormComponent
  ],
  providers: [AdminGuard]
})
export class BingoPageModule {}
