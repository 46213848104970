import { Component, ViewChild, OnInit, OnDestroy } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Subscription } from 'rxjs';
import { AnalyticsService, AnalyticsCategory, AnalyticsAction } from '@shared/services/analytics';
import { AuthService } from '@shared/services/auth.service';
import { BingoSettingsFormComponent } from '../../../components';
import { IBingoUserPreferences } from '../../../models/bingo-preferences';
import { BingoService } from '../../../services/bingo.service';

@Component({
  selector: 'bingo-settings-modal',
  templateUrl: './settings-modal.component.html',
  styleUrls: ['./settings-modal.component.scss']
})
export class BingoChangeSettingsModalComponent implements OnInit, OnDestroy {
  private settingsSubscription: Subscription;

  settings: IBingoUserPreferences;

  @ViewChild(BingoSettingsFormComponent, { static: false }) formComponent: BingoSettingsFormComponent;

  constructor(private analyticsService: AnalyticsService, private authService: AuthService, private bingoService: BingoService, private modalController: ModalController) {}

  dismiss() {
    this.modalController.dismiss();
  }

  ngOnDestroy() {
    this.settingsSubscription.unsubscribe();
  }

  ngOnInit() {
    this.settingsSubscription = this.bingoService.getUserPreferences().subscribe(prefs => (this.settings = prefs));
  }

  onSaveSettingsClicked() {
    if (!this.formComponent) {
      return;
    }

    if (this.formComponent.form.dirty) {
      const updatedSettings = this.formComponent.formPresenter.settings;
      const userId = this.authService._userProfileSubject.value.uid;

      this.analyticsService.eventTrack(AnalyticsCategory.BINGO, AnalyticsAction.BINGO_CHANGE_SETTINGS, userId, null, updatedSettings.ballDelayInMs);

      this.bingoService.updateUserPreferences(updatedSettings);
      this.formComponent.form.markAsPristine();
    }

    this.modalController.dismiss();
  }
}
