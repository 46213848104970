import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { distinctUntilChanged, skipWhile, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'bingo-info-pane',
  styleUrls: ['./info-pane.component.scss'],
  templateUrl: './info-pane.component.html'
})
export class BingoInfoPaneComponent implements OnInit, OnDestroy {
  isLandscape = new BehaviorSubject(null);
  isLandscape$: Observable<boolean>;

  onDestroy$ = new Subject();

  @Input() subtitle: string = '';
  @Input() title: string = '';

  ngOnDestroy() {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  ngOnInit() {
    this.isLandscape$ = this.isLandscape.pipe(
      skipWhile(x => x == null),
      takeUntil(this.onDestroy$),
      distinctUntilChanged()
    );
  }

  // Didn't want to use `any` but `ResizeObserverEntry` type is not available
  onResize(e: any) {
    if (typeof e !== 'object' || !('contentRect' in e)) {
      return;
    }

    const { height, width } = e.contentRect as DOMRectReadOnly;

    this.isLandscape.next(height < width);
  }
}
