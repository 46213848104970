import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ALL_COUNTRIES, CountryKey } from '@shared/constants/country';
import { AppOptionType } from '@shared/models/app-options';
import { IEdit } from '@shared/models/edit';
import { IRenameSettings } from '@shared/models/rename-settings';
import { AppOptionsService } from '@shared/services/app-options/app-options.service';
import { ConstantsService } from '@shared/services/constants.service';
import { Observable } from 'rxjs';
import { first } from 'rxjs/operators';
import { SettingsEditPresenter } from './settings-edit.presenter';

@Component({
  selector: 'app-settings-edit',
  styleUrls: ['./settings-edit.page.scss'],
  templateUrl: './settings-edit.page.html'
})
export class SettingsEditPage implements OnInit {
  get form() {
    return this.presenter.form;
  }

  get keys() {
    return Object.keys(this.form.controls);
  }

  appOptionKeys: CountryKey[] = [ALL_COUNTRIES];
  dataKeys: Record<string, any> = {};
  editedValues: Record<string, IEdit[]> = {};
  existingValues: Record<string, string | null> = {};
  formatString: string = '';
  hasDataKeys: boolean = false;
  isOpen: Record<string, number | null> = {};
  name: string;
  renameSettings: IRenameSettings;
  uid: string;

  constructor(private appOptionsService: AppOptionsService, private constantsService: ConstantsService, private presenter: SettingsEditPresenter, private route: ActivatedRoute, private router: Router) {}

  formatValue(data: AppOptionType): string {
    if (typeof data === 'string') {
      return data;
    } else if (this.formatString !== '') {
      return this.formatByString(this.formatString, data);
    } else {
      //Fallback: separate fields with a colon
      return Object.keys(this.dataKeys)
        .reduce((output, current) => {
          output += `${data[current]}: `;
          return output;
        }, '')
        .replace(/: $/, ''); //trim trailing colon
    }
  }

  isObject(data: any) {
    return typeof data === 'object';
  }

  ngOnInit() {
    this.uid = this.route.snapshot.paramMap.get('id');
    this.name = this.uidToName(this.uid);

    this.appOptionKeys.push(...Object.values(this.constantsService.constants.APP.countries));

    for (const a of this.appOptionKeys) {
      this.isOpen[a] = null;
    }

    this.appOptionsService
      .getOptions(this.uid)
      .pipe(first(x => !!x))
      .subscribe(settings => {
        this.formatString = settings.format || '';
        const firstKey = Object.keys(settings.data)[0];
        const firstValue = settings.data[firstKey][0];
        if (typeof firstValue !== 'string') {
          this.dataKeys = this.getKeysRecursively(firstValue);
          this.hasDataKeys = Object.keys(this.dataKeys).length > 0;
        }

        this.presenter.init(settings.data, this.dataKeys);
        this.renameSettings = settings.rename;
      });
  }

  onAddValue(key: string) {
    this.presenter.addNewControl(key, this.dataKeys);
  }

  onClose(key: string, index: number) {
    this.presenter.setValue(key, index, this.existingValues[key]);
    this.isOpen[key] = null;
    this.existingValues[key] = null;
  }

  onOpen(key: string, index: number) {
    this.isOpen[key] = index;
    this.existingValues[key] = this.presenter.getValue(key, index);
  }

  onRemoveValue(key: string, index: number) {
    this.presenter.removeControl(key, index);
  }

  onUpdateValue(key: string, index: number, event: any = null) {
    if (event) event.target.blur(); // Remove input valid highlight if closed by pressing Enter
    if (!this.editedValues[key]) this.editedValues[key] = [];
    const newValue = this.presenter.getValue(key, index);
    const valueIsEmpty = Object.values(newValue).filter(x => x).length === 0; // Works for both string and object.
    if (valueIsEmpty) {
      // Remove value if it is empty
      this.onRemoveValue(key, index);
    } else if (Object.values(this.existingValues[key]).length === 0) {
      // Don't rename if there's no existing value, e.g. we've just added a new field
    } else {
      // Automatically update timestamp field
      // TODO: Only update timestamp if value has changed?
      if (Object.keys(newValue).includes('timestamp')) {
        newValue.timestamp = Date.now();
        this.presenter.setValue(key, index, newValue);
      }
      this.editedValues[key].push({ from: this.existingValues[key], to: newValue });
    }
    this.isOpen[key] = null;
    this.existingValues[key] = null;
  }

  onReorder(event: any, key: string) {
    // TODO: event is of type CustomEvent<ItemReorderEventDetail> but can't find proper import. Should be @ionic/core
    event.detail.complete([]); // Required to let component know reordering has finished

    let values = [...this.presenter.getValues(key)];
    const from = event.detail.from - 1;
    const to = event.detail.to - 1;
    const fromValue = values.splice(from, 1)[0];
    const newValues = [...values.slice(0, to), fromValue, ...values.slice(to)];

    this.presenter.setValues(key, newValues);
  }

  uidToName(uid: string) {
    return uid
      .replace(/([A-Z])/g, match => ` ${match}`)
      .replace(/^./, match => match.toUpperCase())
      .trim();
  }

  updateSettings() {
    // Record any edits where the input is still open
    for (const key in this.isOpen) {
      if (this.isOpen[key] != null) this.onUpdateValue(key, this.isOpen[key]); // key is numeric, so can be 0, hence check for not null
    }

    // Save entered values to database
    const formValue = this.presenter.settings();
    this.appOptionsService.updateOptions(this.uid, { data: formValue, format: this.formatString, rename: this.renameSettings });

    // Check if any existing terms were changed
    for (const key in this.editedValues) {
      let currentValues = this.editedValues[key]; // as this is a reference not a copy, any changes we make to this affect this.editedValues
      // Deliberately get currentValues.length on each iteration, as it can change within while loop
      for (let index = 0; index < currentValues.length; index++) {
        let edit = currentValues[index];
        let otherIndex = currentValues.slice(index + 1).findIndex(x => x.from === edit.to);
        while (otherIndex > -1) {
          edit.to = currentValues[index + otherIndex + 1].to;
          currentValues[index] = edit;
          currentValues.splice(index + otherIndex + 1, 1);
          otherIndex = currentValues.slice(index + 1).findIndex(x => x.from === edit.to);
        }
        // If a chain of edits results in the original value, ignore it
        if (edit.from === edit.to) currentValues.splice(index, 1);
      }
      if (currentValues.length === 0) delete this.editedValues[key];
    }

    if (Object.keys(this.editedValues).length > 0 && this.renameSettings.enabled === true) {
      this.appOptionsService.renameValues(this.editedValues, this.renameSettings);
    }

    this.router.navigate(['/admin/settings']);
  }

  private getKeysRecursively(data: any) {
    const results: Record<string, any> = {};
    for (const key of Object.keys(data)) {
      if (typeof data[key] !== 'object') {
        results[key] = '';
      } else {
        results[key] = this.getKeysRecursively(data[key]);
      }
    }
    return results;
  }

  private formatByString(template: string, data: any) {
    return template.replace(/\${([^.}]*)\.?([^}]*)}/g, (match, group1, group2) => {
      return group2 ? data[group1][group2] : data[group1];
    });
  }
}
