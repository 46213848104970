import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Country } from '@shared/constants/country';
import { IBnBListing } from '@shared/models/bnb/bnb-listing';
import { IBnBOptions } from '@shared/models/bnb/bnb-options';
import { ISelectOption } from '@shared/models/select-option';
import { AnalyticsAction, AnalyticsCategory, AnalyticsService } from '@shared/services/analytics';
import { ConstantsService } from '@shared/services/constants.service';
import { BnBService } from '@shared/services/bnb/bnb.service';
import { RegionService } from '@shared/services/regions/region.service';
import { BehaviorSubject, Subscription } from 'rxjs';
import { BnBSearchPresenter } from './bnb-search.presenter';

@Component({
  selector: 'app-bnb-search',
  templateUrl: './bnb-search.component.html',
  styleUrls: ['./bnb-search.component.scss'],
  viewProviders: [BnBSearchPresenter],
  encapsulation: ViewEncapsulation.None
})
export class BnBSearchComponent implements OnInit {
  get CONSTANTS() {
    return this.constantsService.constants.BNB.SEARCH;
  }

  get searchForm() {
    return this.presenter.form;
  }

  COUNTRIES: Record<string, Country> = {};
  interfaceOptions: any = { cssClass: 'wide-select' };
  isCountrySearchEnabled: boolean;
  @Input() options: IBnBOptions | null;
  regionLabel: string = '';
  REGIONS: ISelectOption[] = [];
  @Output() search = new EventEmitter<any>();

  constructor(private analyticsService: AnalyticsService, private constantsService: ConstantsService, private bnbService: BnBService, private presenter: BnBSearchPresenter, private modalController: ModalController, private regionService: RegionService) {}

  dismiss() {
    this.modalController.dismiss();
  }

  ngOnInit() {
    this.COUNTRIES = this.constantsService.constants.APP.countries;
    this.isCountrySearchEnabled = this.constantsService.constants.APP.allowOtherCountries;
    this.REGIONS = this.regionService.searchCatchupRegions;
    this.regionLabel = this.regionService.regionLabel;

    if (this.options != null) {
      this.presenter.setValue(this.options);
    } else {
      this.presenter.reset();
    }
  }

  onChangeCountry(event: CustomEvent) {
    const country = event.detail.value;
    this.updateRegions(country);
  }

  onSearch() {
    const search = this.presenter.search();
    this.analyticsService.eventTrack(AnalyticsCategory.BNB, AnalyticsAction.BNB_SEARCH, null, { term1: search.region, term2: search.country });
    this.search.emit(search);
  }

  reset() {
    this.presenter.reset();
    this.analyticsService.eventTrack(AnalyticsCategory.BNB, AnalyticsAction.BNB_CLEAR_SEARCH);
    this.onSearch();
  }

  private updateRegions(country: Country) {
    this.regionLabel = this.regionService.getRegionLabelForCountry(country);
    const prependSelectAll = true;
    this.REGIONS = this.regionService.getRegionsForCountry(country, prependSelectAll);
  }
}
