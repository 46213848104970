import { Injectable } from '@angular/core';
import { EventType } from '@infrastructure/constants/event-type';
import { IEventAttendance } from '@infrastructure/models/event-attendance';
import { Ordinal } from '@shared/constants/ordinal';
import { IAttendance } from '@shared/models/attendance/attendance';
import { IAttendanceData } from '@shared/models/attendance/attendance-data';
import { IBaseEvent } from '@infrastructure/models/base-event';
import { IWhereCondition } from '@shared/models/where-condition';
import { AttendanceDatabase } from './attendance.database';
import firebase from 'firebase/app';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AttendanceService {
  constructor(private attendanceDatabase: AttendanceDatabase) {}

  getAttendanceByQuery(whereCondition: IWhereCondition): Observable<IAttendance[]> {
    return this.attendanceDatabase.getAttendanceByQuery(whereCondition);
  }

  getAttendanceByMember(memberId: string): Observable<IAttendance> {
    return this.attendanceDatabase.getAttendance(memberId);
  }

  getAttendanceWithType(memberId: string): Observable<IEventAttendance[]> {
    return this.attendanceDatabase.getAttendance(memberId).pipe(
      map(data => {
        delete (data as any).uid; //BaseDatabase.getDocument appends uid, can't work out how to define it as optional param in IAttendance
        let output: IEventAttendance[] = [];
        for (const eventType of Object.values(EventType)) {
          const values = data[eventType] || [];
          const attendances = values.map(x => Object.assign(x, { eventType }) as IEventAttendance);
          output = [...output, ...attendances];
        }
        return output;
      })
    );
  }

  getCounts(events: IEventAttendance[]): Record<EventType, number> {
    const counts: Record<EventType, number> = {} as Record<EventType, number>;
    for (const event of events) {
      if (!counts[event.eventType]) counts[event.eventType] = 0;
      counts[event.eventType]++;
    }
    return counts;
  }

  getCountsByIds(uids: string[], eventType: EventType): Observable<IAttendanceData[]> {
    return this.attendanceDatabase.getAttendanceByIds(uids).pipe(
      map(attendances =>
        attendances.map(x => {
          return {
            uid: x.uid,
            attendanceCount: (x[eventType] || []).length,
            firstEventAttended: (x.firstEvent || {})[eventType] ? x.firstEvent[eventType].hasAttended : '',
            firstEventOrganiser: (x.firstEvent || {})[eventType] ? x.firstEvent[eventType].organiserName : '',
            secondEventAttended: (x.secondEvent || {})[eventType] ? x.secondEvent[eventType].hasAttended : '',
            secondEventOrganiser: (x.secondEvent || {})[eventType] ? x.secondEvent[eventType].organiserName : ''
          };
        })
      )
    );
  }

  // TODO: Return array, or define interface?
  hasAttendedEvents(memberId: string, eventType: EventType = EventType.CatchUp): Observable<Record<Ordinal, boolean>> {
    return this.attendanceDatabase.getAttendance(memberId).pipe(
      map(data => {
        return {
          [Ordinal.FIRST]: data.firstEvent && data.firstEvent[eventType] ? !!data.firstEvent[eventType].hasAttended : false,
          [Ordinal.SECOND]: data.secondEvent && data.secondEvent[eventType] ? !!data.secondEvent[eventType].hasAttended : false
        };
      })
    );
  }

  removeAttendance(memberId: string, event: IBaseEvent, extras: any = {}) {
    let eventAttendance: IEventAttendance = {
      datetime: event.datetime,
      title: event.title,
      uid: event.uid
    };
    Object.assign(eventAttendance, extras);
    this.attendanceDatabase.removeAttendance(memberId, event.eventType, eventAttendance);
  }

  updateTrackedEvent(ordinal: Ordinal, memberId: string, organiserName: string = '', eventType: EventType = EventType.CatchUp) {
    const key = `${ordinal}Event`;
    const data = {
      [key]: {
        [eventType]: {
          hasAttended: true,
          organiserName: organiserName
        }
      }
    };
    this.attendanceDatabase.updateAttendance(memberId, data);
  }
}
