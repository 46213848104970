import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { STATUS_TO_VARIANT_MAP } from '../../constants';
import { ChirpleLetterStatus, ChirpleTileVariant, IChirpleAttempt } from '../../models';

@Component({
  selector: 'chirple-virtual-keyboard',
  templateUrl: './virtual-keyboard.component.html',
  styleUrls: ['./virtual-keyboard.component.scss']
})
export class ChirpleVirtualKeyboardComponent implements OnInit {
  @Input() attempts: IChirpleAttempt[];
  @Output() keyClicked = new EventEmitter<string>();

  readonly keys = [
    { keys: ['Q', 'W', 'E', 'R', 'T', 'Y', 'U', 'I', 'O', 'P'], class: 'first-row' },
    { keys: ['A', 'S', 'D', 'F', 'G', 'H', 'J', 'K', 'L'], class: 'second-row' },
    { keys: ['Z', 'X', 'C', 'V', 'B', 'N', 'M'], class: 'third-row' }
  ];

  keyVariantsMap: Record<string, ChirpleTileVariant> = {};

  ngOnChanges(changes: SimpleChanges) {
    if (changes.attempts) {
      const attempts: IChirpleAttempt[] = changes.attempts.currentValue;

      if (attempts.length > 0) {
        this.keyVariantsMap = this.generateKeyVariantsMap(changes.attempts.currentValue);
      }
    }
  }

  ngOnInit() {}

  onBackspaceClicked() {
    this.keyClicked.emit('Backspace');
  }

  onEnterClicked() {
    this.keyClicked.emit('Enter');
  }

  onKeyClicked(key: string) {
    this.keyClicked.emit(key);
  }

  private generateKeyVariantsMap(attempts: IChirpleAttempt[]) {
    // Determine correct status for each letter featured in an attempt
    const statusMap = attempts
      .flatMap(attempt => attempt.letters)
      .reduce((acc, { value, status }) => {
        // In the case of words with multiples of the same letter, show the highest
        // precedent status possible. In other words, if at least one instance of that letter
        // is in the correct position then show the "correct position" status. If none are in
        // the correct position but the letter is included in an attempt at least once, show
        // the "correct letter" status.
        //
        // This status is also preserved across attempts. If the correct letter in the correct position
        // was guessed in an attempt but in a subsequent attempt it was not, the "correct position"
        // status will remain.
        const letter = value.toUpperCase();

        if ((acc[letter] && status > acc[letter]) || !acc[letter]) {
          acc[letter] = status;
        }

        return acc;
      }, {} as Record<string, ChirpleLetterStatus>);

    // Map letter status to variant
    return Object.entries(statusMap).reduce((acc, [letter, status]) => {
      acc[letter] = STATUS_TO_VARIANT_MAP[status];

      return acc;
    }, {} as Record<string, ChirpleTileVariant>);
  }
}
