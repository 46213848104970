import { NgModule } from '@angular/core';
import { GamesSharedModule } from '../shared/shared.module';
import { ChirpleRoutingModule } from './chirple-routing.module';
import { ChirpleGameBoardComponent, ChirpleHowToPlayComponent, ChirpleTileComponent, ChirpleVirtualKeyboardComponent, ChirpleLogoComponent, ChirpleEndGameModalComponent } from './components';
import { ChirpleGamePageComponent, ChirpleLandingPageComponent } from './pages';

@NgModule({
  imports: [ChirpleRoutingModule, GamesSharedModule],
  entryComponents: [ChirpleHowToPlayComponent, ChirpleEndGameModalComponent],
  declarations: [ChirpleEndGameModalComponent, ChirpleGameBoardComponent, ChirpleGamePageComponent, ChirpleHowToPlayComponent, ChirpleLogoComponent, ChirpleLandingPageComponent, ChirpleTileComponent, ChirpleVirtualKeyboardComponent]
})
export class ChirpleModule {}
