import { Injectable } from '@angular/core';
import { EventType } from '@infrastructure/constants/event-type';
import { CatchupRsvpStatus } from '@shared/constants/catchup-rsvp-status';
import { Country } from '@shared/constants/country';
import { HostOnlyDocument } from '@shared/constants/host-only-document';
import { ICatchup } from '@shared/models/catchups/catchup';
import { ICatchupTemplate } from '@shared/models/catchups/catchup-template';
import { IGuest } from '@shared/models/catchups/guest';
import { ICatchupOptions } from '@shared/models/catchups/catchup-options';
import { CatchupShowOption } from '@shared/constants/catchup-show-option';
import { IOrderCondition } from '@shared/models/order-condition';
import { IWhereCondition } from '@shared/models/where-condition';
import { BaseDatabase } from '@shared/services/base.database';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class CatchupDatabase extends BaseDatabase {
  readonly FETCH_ALL_RECORDS: number = 9999;
  readonly MAX_RECORDS: number = 30;
  readonly TWO_DAYS_IN_MS: number = 2 * 24 * 60 * 60 * 1000;

  createCatchup(catchup) {
    return this.createDocument(this.COLLECTION.CATCHUPS, catchup);
  }

  createCatchupTemplate(groupId: string, data: ICatchupTemplate) {
    return this.createSubDocument(this.COLLECTION.CATCHUP_GROUPS, groupId, this.COLLECTION.CATCHUP_TEMPLATES, data);
  }

  deleteCatchup(uid: string) {
    // TODO: this doesnt delete HostOnly subcollection
    return this.deleteDocument(this.COLLECTION.CATCHUPS, uid);
  }

  deleteCatchupTemplate(groupId: string, templateId: string): Promise<any> {
    return this.deleteSubDocument(this.COLLECTION.CATCHUP_GROUPS, groupId, this.COLLECTION.CATCHUP_TEMPLATES, templateId);
  }

  getCatchup(uid: string) {
    return this.getDocument<ICatchup>(this.COLLECTION.CATCHUPS, uid);
  }

  getCatchups(isAdmin: boolean, fetchAllRecords: boolean, catchupOptions: ICatchupOptions, startDate: number, groupIds: string[], isHost: boolean, memberId: string): Observable<ICatchup[]> {
    // TODO: When using CatchupShowOption.MY_GROUPS && groupIds.length > 10 && MAX_RECORDS, getDocumentByMultipleQueries will miss catchups (See CM-1240)
    const recordsToFetch = fetchAllRecords ? this.FETCH_ALL_RECORDS : this.MAX_RECORDS;
    const queryFns = this.getCatchupsQuery(catchupOptions, recordsToFetch, isAdmin, startDate, groupIds, isHost, memberId);
    return queryFns.length > 1 ? this.getDocumentsByMultipleQueries<ICatchup>(this.COLLECTION.CATCHUPS, queryFns) : this.getDocumentsByQuery<ICatchup>(this.COLLECTION.CATCHUPS, queryFns[0]);
  }

  getCatchupsByDate(dateFrom: string, dateTo: string, country: string = '', useCache: boolean = false, cacheKey: string = ''): Observable<ICatchup[]> {
    const queryFn = this.getCatchupsByDateQuery(dateFrom, dateTo, country);
    return this.getDocumentsByQuery<ICatchup>(this.COLLECTION.CATCHUPS, queryFn, useCache, cacheKey);
  }

  getCatchupsById(ids: string[]): Observable<ICatchup[]> {
    const whereConditions: IWhereCondition[] = [];
    const RECORDS_TO_FETCH = 10;

    if (ids.length === 0) {
      const queryFn = this.createQueryFunction(whereConditions, [], RECORDS_TO_FETCH); // Set number of records to fetch to 10, because "in" queries are limited a list of 10 values, so the max # of ids this query will return is 10.
      return this.getDocumentsByQuery<ICatchup>(this.COLLECTION.CATCHUPS, queryFn);
    } else {
      //__name__ is a special keyword for document uid; an alternative is to use firebase.firestore.FieldPath.documentId()
      const queryFns = this.createChunkedQueryFunctions(ids, '__name__', 'in', whereConditions, false);
      return this.getDocumentsByMultipleQueries<ICatchup>(this.COLLECTION.CATCHUPS, queryFns);
    }
  }

  getCatchupsForMember(memberId: string, rsvpStatus: CatchupRsvpStatus[]): Observable<ICatchup[]> {
    const queryFn = this.getCatchupsForMemberQuery(memberId, rsvpStatus);
    return this.getDocumentsByQuery<ICatchup>(this.COLLECTION.CATCHUPS, queryFn);
  }

  getCatchupTemplate(groupId: string, templateId: string): Observable<ICatchupTemplate> {
    return this.getSubDocument<ICatchupTemplate>(this.COLLECTION.CATCHUP_GROUPS, groupId, this.COLLECTION.CATCHUP_TEMPLATES, templateId);
  }

  getCatchupTemplates(groupId: string): Observable<ICatchupTemplate[]> {
    return this.getDocuments<ICatchupTemplate>(`${this.COLLECTION.CATCHUP_GROUPS}/${groupId}/${this.COLLECTION.CATCHUP_TEMPLATES}`);
  }

  getHostOnlyDocument(catchupId: string, docId: string): Observable<any> {
    return this.getDocument<any>(`${this.COLLECTION.CATCHUPS}/${catchupId}/${this.COLLECTION.HOST_ONLY}`, docId);
  }

  strictUpdateCatchup(catchup: ICatchup) {
    return this.strictUpdateDocument(this.COLLECTION.CATCHUPS, catchup.uid, catchup);
  }

  updateCatchup(catchup, merge: boolean = true) {
    return this.updateDocument(this.COLLECTION.CATCHUPS, catchup.uid, catchup, merge);
  }

  updateCatchupTemplate(groupId: string, templateId: string, data: any): Promise<any> {
    return this.updateSubDocument(this.COLLECTION.CATCHUP_GROUPS, groupId, this.COLLECTION.CATCHUP_TEMPLATES, templateId, data);
  }

  updateHostOnlyDocument(catchupId: string, docId: HostOnlyDocument, data: any, merge: boolean = true): Promise<any> {
    return this.updateSubDocument(this.COLLECTION.CATCHUPS, catchupId, this.COLLECTION.HOST_ONLY, docId, data, merge);
  }

  private getCatchupsByDateQuery(dateFrom: string, dateTo: string, country: string) {
    const whereConditions: IWhereCondition[] = [{ field: 'date', operator: '>=', value: dateFrom }];
    if (dateTo != null && dateTo != '') whereConditions.push({ field: 'date', operator: '<=', value: dateTo });
    if (!!country) whereConditions.push({ field: 'country', operator: '==', value: country });
    const orderConditions: IOrderCondition[] = [];
    return this.createQueryFunction(whereConditions, orderConditions, this.FETCH_ALL_RECORDS);
  }

  private getCatchupsForMemberQuery(memberId: string, rsvpStatus: CatchupRsvpStatus[]) {
    const whereConditions: IWhereCondition[] = [{ field: `attendees.${memberId}`, operator: 'in', value: rsvpStatus }];
    // Can't order by datetime here, as this requires an index for every attendees.{memberId} value
    const orderConditions: IOrderCondition[] = [];
    return this.createQueryFunction(whereConditions, orderConditions, this.FETCH_ALL_RECORDS);
  }

  private getCatchupsQuery(catchupOptions: ICatchupOptions, recordsToFetch: number, isAdmin: boolean, startDate: number, groupIds: string[], loadPastCatchups: boolean, memberId: string) {
    const whereConditions: IWhereCondition[] = [];

    if (!isAdmin) {
      whereConditions.push({ field: 'approved', operator: '==', value: true });
    }

    if (catchupOptions.country !== null && (groupIds || []).length === 0) {
      whereConditions.push({ field: 'country', operator: 'array-contains', value: catchupOptions.country });
    }

    if ((catchupOptions.region || '').length > 0) {
      whereConditions.push({ field: 'region', operator: '==', value: catchupOptions.region });
    }

    if (loadPastCatchups) {
      // Let the host of a group or an admin see CatchUps which have just finished
      startDate = startDate - this.TWO_DAYS_IN_MS;
    }
    whereConditions.push({ field: 'datetime', operator: '>', value: startDate });

    switch (catchupOptions.show) {
      case CatchupShowOption.CATCHUPS:
        whereConditions.push({ field: 'eventType', operator: '==', value: EventType.CatchUp });
        break;

      case CatchupShowOption.HOSTED_BY_ME:
        whereConditions.push({ field: 'ownerId', operator: '==', value: memberId });
        break;

      case CatchupShowOption.PENDING:
        whereConditions.push({ field: 'approved', operator: '==', value: false });
        break;

      case CatchupShowOption.VIRTUAL:
        whereConditions.push({ field: 'eventType', operator: '==', value: EventType.Virtual });
        break;

      case CatchupShowOption.EVERYTHING:
      case CatchupShowOption.MY_GROUPS:
        break;
    }

    const orderConditions: IOrderCondition[] = [];
    orderConditions.push({ field: 'datetime', direction: 'asc' });

    // If there are more than 10 groups to search we need to use multiple queries
    const groupIdsLength = (groupIds || []).length;
    if (groupIdsLength === 0) {
      return [this.createQueryFunction(whereConditions, orderConditions, recordsToFetch)];
    }
    if (groupIdsLength > 0 && groupIdsLength <= 10) {
      // array-contains-any query crashes with more than 10 values.
      whereConditions.push({ field: 'allGroupIds', operator: 'array-contains-any', value: groupIds });
      return [this.createQueryFunction(whereConditions, orderConditions, recordsToFetch)];
    } else if (groupIdsLength > 10) {
      return this.createChunkedQueryFunctions(groupIds, 'allGroupIds', 'array-contains-any', whereConditions, true);
    }
  }
}
