import { Injectable } from '@angular/core';
import { Country } from '@shared/constants/country';
import { ILink } from '@shared/models/link';
import { UserObject } from '@shared/models/user-object';
import { AppOptionsService } from '@shared/services/app-options/app-options.service';
import { AuthService } from '@shared/services/auth.service';
import { combineLatest, Observable, of } from 'rxjs';
import { first, map, switchMap } from 'rxjs/operators';
import { MineRTDatabase } from './mine.rtdatabase';

@Injectable({
  providedIn: 'root'
})
export class MineService {
  constructor(private authService: AuthService, private appOptionsService: AppOptionsService, private mineRTDatabase: MineRTDatabase) {}

  hasUnreadNotice(): Observable<boolean> {
    return this.authService._userProfileSubject.pipe(
      first(x => !!x),
      switchMap((member: UserObject) => combineLatest(this.mineRTDatabase.getNoticeLastRead(member.uid), this.getNoticeLastUpdated(member.country))),
      map(([lastRead, lastUpdated]) => {
        // Default to showing notice, unless confirmed as hidden
        if (lastRead == null) return true;
        return (lastUpdated || 0) >= (lastRead || 0);
      })
    );
  }

  updateNoticeLastRead(uid: string) {
    this.mineRTDatabase.updateNoticeLastRead(uid, Date.now());
  }

  private getNoticeLastUpdated(country: Country): Observable<number> {
    // TODO: Make the name myChirpyNotice configurable, or more generic
    return this.appOptionsService.getOptionsValues<ILink>('myChirpyNotice', country).pipe(map(notices => Math.max(...notices.map(x => x.timestamp || 0))));
  }
}
