import { Component, Input, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { IBingoUserPreferences } from '../../models/bingo-preferences';
import { BingoSettingsFormPresenter } from './settings-form.presenter';

@Component({
  selector: 'bingo-settings-form',
  templateUrl: './settings-form.component.html',
  styleUrls: ['./settings-form.component.scss'],
  viewProviders: [BingoSettingsFormPresenter]
})
export class BingoSettingsFormComponent implements OnInit {
  @Input() settings: IBingoUserPreferences;

  get form() {
    return this.formPresenter.form;
  }

  ballDelay$: Observable<string>;

  constructor(public formPresenter: BingoSettingsFormPresenter) {}

  ngOnInit() {
    this.ballDelay$ = this.formPresenter.ballDelayDisplayValue$;

    this.formPresenter.setValue(this.settings);
  }

  onBallDelayUpdated() {
    this.formPresenter.updateBallDelayDisplayValue();
  }
}
