import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AdminRole } from '@shared/constants/admin-role';
import { AvatarSize } from '@shared/constants/avatar-size';
import { IBnBListing } from '@shared/models/bnb/bnb-listing';
import { IButtonGroup } from '@shared/models/button-group';
import { UserObject } from '@shared/models/user-object';
import { AnalyticsAction, AnalyticsCategory, AnalyticsService } from '@shared/services/analytics';
import { AuthService } from '@shared/services/auth.service';
import { BnBService } from '@shared/services/bnb/bnb.service';
import { ConstantsService } from '@shared/services/constants.service';
import { DateTimeService } from '@shared/services/date-time.service';
import { MessageHelperService } from '@shared/services/messages/message-helper.service';
import { SubscriptionService } from '@shared/services/subscription.service';
import { UserService } from '@shared/services/user/user.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'bnb-detail',
  templateUrl: './bnb-detail.component.html',
  styleUrls: ['./bnb-detail.component.scss']
})
export class BnBDetailComponent implements OnInit, OnDestroy {
  get CONSTANTS() {
    return this.constantsService.constants.BNB.DETAIL;
  }

  get FIELDS() {
    return this.constantsService.constants.BNB.EDIT.fields;
  }

  get isAdmin(): boolean {
    return this.authService.isAdmin([AdminRole.SUPER, AdminRole.MODERATOR]);
  }

  get placeholderImage(): string {
    return this.constantsService.constants.ASSETS.placeholderImage;
  }

  get member(): UserObject | null {
    if (this.authService._userProfileSubject != null && this.authService._userProfileSubject.value != null) {
      return this.authService._userProfileSubject.value;
    }
    return null;
  }

  get memberId(): string {
    return this.member ? this.member.uid : '';
  }

  avatarUrl: string;
  errorMessage: string;
  bnbListing: IBnBListing;
  bnbListingRef: Subscription;
  headerButtons: IButtonGroup[];
  size: AvatarSize = AvatarSize.LARGE;
  title: string;

  constructor(
    private analyticsService: AnalyticsService,
    private authService: AuthService,
    private bnbService: BnBService,
    private constantsService: ConstantsService,
    private dateTimeService: DateTimeService,
    private messageHelperService: MessageHelperService,
    private route: ActivatedRoute,
    private router: Router,
    private subscriptionService: SubscriptionService,
    private userService: UserService
  ) {}

  approveListing() {
    const uid = this.bnbListing.uid;
    this.bnbService.approveBnBListing(uid);
  }

  deleteBnBListing() {
    this.bnbService.deleteBnBListing(this.bnbListing);
  }

  getBnBListing(uid: string) {
    this.bnbListingRef = this.bnbService.getBnBListing(uid).subscribe(bnbListing => {
      if (bnbListing == null) {
        this.title = this.CONSTANTS.pageHeading;
        this.errorMessage = this.CONSTANTS.doesNotExist;
        return;
      }

      this.bnbListing = bnbListing;
      this.title = this.bnbListing.title;

      this.initButtons();
      this.sendAnalytics(this.bnbListing);
    });
    this.subscriptionService.add(this.bnbListingRef);
  }

  goBack() {
    this.router.navigate([`/bed-and-breakfast`]);
  }

  ngOnDestroy() {
    this.subscriptionService.clearSubscription(this.bnbListingRef);
  }

  ngOnInit() {
    const uid = this.route.snapshot.paramMap.get('id');
    if (uid == null) return;
    this.getBnBListing(uid);
  }

  onSendMessage() {
    this.analyticsService.eventTrack(AnalyticsCategory.BNB, AnalyticsAction.BNB_SEND_MESSAGE, this.bnbListing.title);
  }

  rejectListing() {
    const uid = this.bnbListing.uid;
    this.bnbService.rejectBnBListing(uid);
  }

  private initButtons() {
    if (!this.bnbListing) return;

    this.headerButtons = [
      {
        condition: this.isAdmin,
        buttons: [
          {
            click: this.approveListing.bind(this),
            condition: !this.bnbListing.approved,
            text: 'Approve',
            icon: 'checkmark'
          },
          {
            click: this.rejectListing.bind(this),
            condition: this.bnbListing.approved,
            text: 'Reject',
            icon: 'close'
          }
        ]
      },
      {
        condition: this.isAdmin || this.bnbListing.memberId === this.memberId,
        buttons: [
          {
            condition: true,
            text: 'Update',
            icon: 'create',
            routerLink: ['/bed-and-breakfast/update', this.bnbListing.uid]
          },
          {
            click: this.deleteBnBListing.bind(this),
            condition: true,
            text: 'Delete',
            icon: 'trash'
          }
        ]
      },
      {
        condition: true,
        buttons: [
          {
            condition: !!this.bnbListing,
            text: `Reviews (${this.bnbListing.reviewCount || 0})`,
            icon: 'star',
            routerLink: ['/bed-and-breakfast', this.bnbListing.uid, 'reviews']
          }
        ]
      }
    ];
  }

  private sendAnalytics(bnbListing: IBnBListing) {
    if (bnbListing.memberId === this.memberId) {
      this.analyticsService.eventTrack(AnalyticsCategory.BNB, AnalyticsAction.BNB_VIEW_OWN_LISTING_DETAIL, bnbListing.title);
    } else {
      this.analyticsService.eventTrack(AnalyticsCategory.BNB, AnalyticsAction.BNB_VIEW_LISTING_DETAIL, bnbListing.title);
    }
  }
}
