import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AlertController } from '@ionic/angular';
import { ALL_COUNTRIES } from '@shared/constants/country';
import { Direction } from '@shared/constants/direction';
import { MediaCollectionType } from '@shared/constants/media-collection-type';
import { IListingImage } from '@shared/models/image/listing-image';
import { ICollectionCount } from '@shared/models/media/collection-count';
import { IMedia } from '@shared/models/media/media';
import { IMediaCategoryOption } from '@shared/models/media/media-category-option';
import { IMediaCollection } from '@shared/models/media/media-collection';
import { UserObject } from '@shared/models/user-object';
import { AnalyticsAction, AnalyticsCategory, AnalyticsService } from '@shared/services/analytics';
import { AppOptionsService } from '@shared/services/app-options/app-options.service';
import { ConstantsService } from '@shared/services/constants.service';
import { EmailService } from '@shared/services/email/email.service';
import { EnvironmentService } from '@shared/services/environment.service';
import { SubscriptionService } from '@shared/services/subscription.service';
import { ToastService } from '@shared/services/toast.service';
import { UserService } from '@shared/services/user/user.service';
import { PhotoDatabase } from './photo.database';
import { firestore } from 'firebase/app';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { first, map, skipWhile, take } from 'rxjs/operators';
import FieldValue = firestore.FieldValue;

@Injectable({
  providedIn: 'root'
})
export class PhotoService {
  categoriesSubscription: Subscription;
  mediaCategories: BehaviorSubject<IMediaCategoryOption[]> = new BehaviorSubject<IMediaCategoryOption[]>([]);
  NO_QUOTA: number = -1;

  constructor(
    private alertController: AlertController,
    private analyticsService: AnalyticsService,
    private appOptionsService: AppOptionsService,
    private constantsService: ConstantsService,
    private database: PhotoDatabase,
    private emailService: EmailService,
    private environmentService: EnvironmentService,
    private router: Router,
    private subscriptionService: SubscriptionService,
    private toastService: ToastService,
    private userService: UserService
  ) {
    this.loadMediaCategories();
  }

  createPhoto(photo: IMedia) {
    return this.database.createPhoto(photo);
  }

  async deletePhoto(media: IMedia, route: ActivatedRoute) {
    const alert = await this.alertController.create({
      header: `Delete photo`,
      message: `Are you sure you want to delete this photo? This cannot be undone.`,
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
          cssClass: 'secondary'
        },
        {
          text: 'OK',
          handler: data => this.deletePhotoHandler(media, route)
        }
      ]
    });

    await alert.present();
  }

  deletePhotoHandler(media: IMedia, route: ActivatedRoute) {
    const increments: Record<string, number> = {};
    for (const collection of media.collections) {
      increments[collection] = -1;
    }

    return this.database.deletePhoto(media.uid).then(() => {
      this.updateCollections(media, increments).then(() => {
        this.toastService.presentToast(`The photo has been deleted.`);
        this.router.navigate(['../'], { relativeTo: route });
      });
    });
  }

  getAdjacentPhoto(collection: string, timestamp: number, direction: Direction, isAdmin: boolean = false): Observable<string> {
    return this.database.getAdjacentPhoto(collection, timestamp, direction, isAdmin).pipe(
      map((photos: IMedia[]) => {
        if (photos.length === 0) {
          return null;
        } else {
          return photos[0].uid;
        }
      })
    );
  }

  getCollection(id: string, type: MediaCollectionType): Observable<IMediaCollection> {
    const uid = this.getCollectionKey(id, type);
    return this.database.getCollection(uid);
  }

  getCollectionCount(id: string, type: MediaCollectionType): Observable<ICollectionCount> {
    const CONSTANTS = this.constantsService.constants.MEDIA;
    return this.getCollection(id, type).pipe(
      map(collection => {
        if (collection) {
          const quota = collection.quota || CONSTANTS.quotas[type] || this.NO_QUOTA;
          const collectionCount: ICollectionCount = {
            count: collection.count || 0,
            hasMediaRemaining: quota === this.NO_QUOTA ? true : collection.count < quota,
            hasQuota: quota !== this.NO_QUOTA,
            quota: quota,
            mediaRemaining: quota - collection.count // will be negative for collections without quota
          };
          return collectionCount;
        } else {
          const quota = CONSTANTS.quotas[type] || this.NO_QUOTA;
          const collectionCount: ICollectionCount = {
            count: 0,
            hasMediaRemaining: true,
            hasQuota: quota === this.NO_QUOTA,
            quota: quota,
            mediaRemaining: quota
          };
          return collectionCount;
        }
      })
    );
  }

  getCollectionKey(id: string, type: MediaCollectionType) {
    return type === MediaCollectionType.PUBLIC ? id : `${type}_${id}`;
  }

  getPhoto(uid: string): Observable<IMedia> {
    return this.database.getPhoto(uid);
  }

  getPhotosInCollection(collectionId: string, collectionType: MediaCollectionType, isAdmin: boolean = false, maxPhotos: number = 9999): Observable<IListingImage[]> {
    return this.getCollection(collectionId, collectionType).pipe(
      map((collection: IMediaCollection) => {
        if (!collection) return [] as IListingImage[];
        const media: IListingImage[] = Object.entries(collection.media || {})
          .map(([uid, data]) => {
            data.uid = uid;
            return data;
          })
          .sort((a, b) => b.dateTimeUploaded - a.dateTimeUploaded);

        return media.filter(x => isAdmin || !x.isHidden).slice(0, maxPhotos);
      })
    );
  }

  // TODO: Do we need to store ID for quota collection separately?
  getRelatedId(collections: string[]): string {
    const nonPublicCollections = collections.filter(x => x !== MediaCollectionType.PUBLIC);
    return nonPublicCollections.length > 0 ? nonPublicCollections[0].split('_')[1] : '';
  }

  getRelatedType(collections: string[]): MediaCollectionType {
    const nonPublicCollections = collections.filter(x => x !== MediaCollectionType.PUBLIC);
    return nonPublicCollections.length > 0 ? (nonPublicCollections[0].split('_')[0] as MediaCollectionType) : null;
  }

  getUploader(uid: string): Observable<UserObject> {
    return this.userService.getUserProfile(uid).pipe(
      skipWhile(x => !x),
      take(1)
    );
  }

  // TODO: pass last item or upload time so we can implement load more button
  queryPhotosInCollection(uid: string, isAdmin: boolean = false, maxPhotos: number = 9999): Observable<IMedia[]> {
    return this.database.getPhotosInCollection(uid, isAdmin, maxPhotos);
  }

  async reportPhoto(uid: string, member: UserObject, route: ActivatedRoute) {
    const alert = await this.alertController.create({
      header: 'Report photo',
      message: `<p>If you believe this photo is inappropriate, please enter the reason below.</p><p>The photo will be hidden until our Support team has reviewed it.</p>`,
      inputs: [
        {
          name: 'reason',
          type: 'text',
          placeholder: `Reason...`
        }
      ],
      buttons: [
        {
          text: 'Report',
          handler: data => {
            if (data.reason.trim().length === 0) {
              this.toastService.presentToast('Please enter a reason.');
              return false;
            }
            return this.reportPhotoHandler(uid, data, member, route);
          }
        },
        {
          text: 'Cancel',
          role: 'cancel'
        }
      ]
    });

    await alert.present();
  }

  reportPhotoHandler(uid: string, data: any, member: UserObject, route: ActivatedRoute) {
    this.sendReportEmail(data, member, route);

    return this.updateHiddenStatus(uid, true).then(() => {
      const collectionTitle = route.snapshot.params.title || '';
      this.toastService.presentToast(`The photo has been reported to our Support team.`);
      this.router.navigate(['../'], { relativeTo: route });

      this.analyticsService.eventTrack(AnalyticsCategory.PIX, AnalyticsAction.PIX_REPORT_MEDIA, uid, { type: collectionTitle });
    });
  }

  sendReportEmail(data: any, member: UserObject, route: ActivatedRoute) {
    const subject = `Report of an inappropriate photo from ${member.displayName} (${member.country})`;
    const baseUrl = this.environmentService.url;
    const photoUrl = this.environmentService.url + route.snapshot.url.join('/');
    const emailBody = `<h3>Reported Photo</h3>
      <a href="${photoUrl}">${photoUrl}</a><br>

      <h3>Member</h3>
      <b>Display name:</b> ${member.displayName}<br>
      <b>Full name:</b> ${member.fullName}<br>
      <b>Email:</b> ${member.email}<br>
      <b>Country:</b> ${member.country}<br>
      <b>uid:</b> ${member.uid}<br>

      <h3>Reason:</h3>
      ${data.reason}<br>`;
    return this.emailService.sendPhotoReportEmail(data.reason, member, route.snapshot.url.join('/'));
  }

  updateCollections(media: IMedia, increments: Record<string, number>): Promise<any> {
    if (Object.keys(increments || {}).length === 0) return Promise.all([]);

    const ADD: number = 1;
    const MODIFY: number = 0;
    const REMOVE: number = -1;
    const data: Record<number, any> = {
      [ADD]: {
        caption: media.caption,
        category: media.category,
        collections: media.collections,
        dateTimeUploaded: media.dateTimeUploaded,
        largePhotoURL: media.largePhotoURL,
        mediumPhotoURL: media.mediumPhotoURL,
        originalPhotoURL: media.originalPhotoURL,
        photoURL: media.photoURL
      },
      [MODIFY]: {
        caption: media.caption,
        collections: media.collections,
        category: media.category
      },
      [REMOVE]: FieldValue.delete()
    };
    const promises: any[] = [];
    for (let [collectionId, increment] of Object.entries(increments)) {
      const collectionData = {
        media: {
          [media.uid]: data[increment]
        },
        count: FieldValue.increment(increment)
      };
      promises.push(this.database.updateCollection(collectionId, collectionData));
    }

    return Promise.all(promises);
  }

  updatePhoto(uid: string, data: any) {
    return this.database.updatePhoto(uid, data);
  }

  updateHiddenStatus(uid: string, isHidden: boolean): Promise<void> {
    return this.database.updatePhoto(uid, { isHidden }).then(() => {
      this.database
        .getPhoto(uid)
        .pipe(first(x => !!x))
        .subscribe((media: IMedia) => {
          const collections = media.collections || [];
          if (collections.length === 0) return;

          const data = { media: { [uid]: { isHidden } } };
          for (let collection of collections) {
            this.database.updateCollection(collection, data);
          }
        });
    });
  }

  private loadMediaCategories() {
    // TODO: Explicitly call this if categories are updated in Manage Settings?
    this.categoriesSubscription = this.appOptionsService.getOptionsValues<IMediaCategoryOption>('mediaCategories', ALL_COUNTRIES).subscribe((options: IMediaCategoryOption[]) => {
      this.mediaCategories.next(options || []);
    });
    this.subscriptionService.add(this.categoriesSubscription);
  }
}
