import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { LimitPeriod } from '@infrastructure/constants/limit-period';
import { Country } from '@shared/constants/country';
import { GameType } from '@shared/constants/game-type';
import { ILeaderboardDataConfig, ILeaderboardOptions, LeaderboardMetricKey, ILeaderboardSortOption } from '@shared/models/leaderboards';
import { ISelectOption } from '@shared/models/select-option';
import { IValueWithId } from '@shared/models/value-with-id';
import { AnalyticsService, AnalyticsCategory, AnalyticsAction } from '@shared/services/analytics';
import { AuthService } from '@shared/services/auth.service';
import { ConstantsService } from '@shared/services/constants.service';
import { RegionService } from '@shared/services/regions/region.service';

@Component({
  selector: 'chirpy-leaderboard-list',
  templateUrl: 'chirpy-leaderboard-list.component.html',
  styleUrls: ['chirpy-leaderboard-list.component.scss']
})
export class ChirpyLeaderboardListComponent implements OnChanges, OnInit {
  @Input() boards: Partial<Record<LimitPeriod, string>> = {};
  readonly CACHE_TTL: number = 5 * 60 * 1000; // 5 minutes
  CONSTANTS: Partial<Record<GameType, ILeaderboardDataConfig>>;
  COUNTRIES: IValueWithId[];
  countryHeading: string;
  currentView: string = 'region-view';
  isCountryView: boolean = false;
  isRegionView: boolean = false;
  lastUpdated: number;
  @Input() options: ILeaderboardOptions;
  place: string;
  regionHeading: string;
  regionLabel: string = '';
  REGIONS: ISelectOption[] = [];
  @Input() reload: number;
  sort: ILeaderboardSortOption;
  sortOptions: ILeaderboardSortOption[] = [];

  compareById(a: ILeaderboardSortOption, b: ILeaderboardSortOption) {
    return a && b ? a.sortKey === b.sortKey : a === b;
  }

  constructor(private authService: AuthService, private analyticsService: AnalyticsService, private constantsService: ConstantsService, private regionService: RegionService) {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes.reload && changes.reload.currentValue !== changes.reload.previousValue && changes.reload.currentValue > this.lastUpdated) this.lastUpdated = Date.now();
  }

  ngOnInit() {
    this.CONSTANTS = this.constantsService.constants.LEADERBOARDS;

    this.countryHeading = this.authService._userProfileSubject.value.country;
    this.regionHeading = this.authService._userProfileSubject.value.region;
    const first = true;
    const viewEvent = { detail: { value: this.currentView } } as CustomEvent;
    const trackAnalytics = false;
    this.onChangeView(viewEvent, trackAnalytics);

    this.COUNTRIES = Object.entries(this.constantsService.constants.APP.countries).map(x => {
      return { uid: x[1], name: x[0] };
    });
    this.REGIONS = this.regionService.catchupRegions;
    this.regionLabel = this.regionService.regionLabel;

    this.sortOptions = this.options.sortOptions.map(sortOption => {
      if (!sortOption.name) {
        const metric = this.CONSTANTS[this.options.gameType].metrics.filter(x => x.key === sortOption.sortKey);
        if (metric.length > 0) Object.assign(sortOption, { name: metric[0].label });
      }
      return sortOption;
    });

    this.sort = this.sortOptions[0];
    this.lastUpdated = Date.now();
    this.analyticsService.eventTrack(AnalyticsCategory.LEADERBOARDS, AnalyticsAction.LEADERBOARDS_VIEW_PAGE, this.options.gameType);
  }

  onChangeView(event: CustomEvent, trackAnalytics: boolean = true) {
    switch (event.detail.value) {
      case 'region-view':
        this.isCountryView = false;
        this.isRegionView = true;
        this.place = `${this.authService._userProfileSubject.value.country}_${this.authService._userProfileSubject.value.region}`;
        if (trackAnalytics) this.analyticsService.eventTrack(AnalyticsCategory.LEADERBOARDS, AnalyticsAction.LEADERBOARDS_CHANGE_TAB, 'region', { type: this.options.gameType });
        break;

      case 'country-view':
        this.isCountryView = true;
        this.isRegionView = false;
        this.place = `${this.authService._userProfileSubject.value.country}`;
        if (trackAnalytics) this.analyticsService.eventTrack(AnalyticsCategory.LEADERBOARDS, AnalyticsAction.LEADERBOARDS_CHANGE_TAB, 'country', { type: this.options.gameType });
        break;
    }

    // Because we cache the results for 5 minutes, don't change lastUpdated time if member is just flicking between region and country tabs
    const now = Date.now();
    if (now - this.lastUpdated > this.CACHE_TTL) this.lastUpdated = now;
  }

  onSelectCountry(event) {
    const country = event.detail.value;
    this.countryHeading = country;
    this.place = country;
    this.lastUpdated = Date.now();
    this.analyticsService.eventTrack(AnalyticsCategory.LEADERBOARDS, AnalyticsAction.LEADERBOARDS_CHANGE_COUNTRY, country, { type: this.options.gameType });
  }

  onSelectRegion(event) {
    const region = event.detail.value;
    this.regionHeading = region;
    const countryForRegion = this.regionService.getCountryForRegion(region);
    this.place = `${countryForRegion}_${region}`;
    this.lastUpdated = Date.now();
    this.analyticsService.eventTrack(AnalyticsCategory.LEADERBOARDS, AnalyticsAction.LEADERBOARDS_CHANGE_REGION, region, { type: this.options.gameType });
  }

  onSort(event) {
    this.sort = event.detail.value;
    this.analyticsService.eventTrack(AnalyticsCategory.LEADERBOARDS, AnalyticsAction.LEADERBOARDS_SORT, this.sort.sortKey, { type: this.options.gameType });
  }

  sortNull() {}
}
