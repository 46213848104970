import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { IPlace } from '@shared/models/place';
import { ISearchModel } from '@shared/models/search-model';
import { UserObject } from '@shared/models/user-object';
import { IWhereCondition } from '@shared/models/where-condition';
import { BaseDatabase } from '@shared/services/base.database';
import { CacheService } from '@shared/services/cache.service';
import { LocationService } from '@shared/services/location/location.service';
import { UserService } from '@shared/services/user/user.service';
import { combineLatest, Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class MeetingPlaceDatabase extends BaseDatabase {
  readonly HOUR_IN_MS = 60 * 60 * 1000;
  readonly MAX_SEARCH_RESULTS = 30;
  readonly MAX_HASH_LENGTH = 6;

  constructor(afs: AngularFirestore, cache: CacheService, private locationService: LocationService, private userService: UserService) {
    super(afs, cache);
  }

  clearCaches(): void {
    const toClear = ['centralPostcodes', 'members', 'places'];
    toClear.forEach(collection => {
      this.cache.clear(collection);
    });
  }

  getCacheKey(q: ISearchModel): string {
    // TODO: Handle other cases and/or just serialise/hash search model or where conditions
    return `${q.memberName}`;
  }

  getMembers(q: ISearchModel, places: IPlace[], allowOtherCountries: boolean) {
    const whereConditions = this.getWhereConditions(q, allowOtherCountries);
    let queryFns: any[] = [];
    let foundMembers: UserObject[] = [];
    let toFindPlaces: string[] = [];
    let toFindMembers: string[] = [];

    // Empty array of places passed because no visible places within map bounds
    if (places !== null && places.length === 0) {
      // Will never find members in zero places, so return empty array
      const outputs$: Observable<UserObject[]> = of([]);
      return outputs$;
    }
    // We have a list of valid placeIds, search for members matching those placeIds, and optionally other conditions
    if (places !== null && places.length > 0) {
      const placeIds = places.map(place => place.uid);
      // TODO: be clever about limits, e.g.for geo-only query sum counts on places and don't query more than MAX_SEARCH_RESULTS
      if (whereConditions.length === 1) {
        //Check cache for results first, only when there are no further conditions
        ({ foundObjects: foundMembers, toFindIds: toFindPlaces } = this.maybeGetCachedData<UserObject>(placeIds, 'places'));
        //Define query function for remaining queries
        queryFns = toFindPlaces.length ? this.createChunkedQueryFunctions(placeIds, 'placeId', 'in', whereConditions, true) : [];
      } else {
        //Don't use cache if there are any other conditions
        queryFns = this.createChunkedQueryFunctions(placeIds, 'placeId', 'in', whereConditions, true);
      }
    } else if (whereConditions.length > 1) {
      //Check cache for results first
      const cacheKey = this.getCacheKey(q);
      ({ foundObjects: foundMembers, toFindIds: toFindMembers } = this.maybeGetCachedData<UserObject>([cacheKey], 'members'));

      //Define query function if needed
      if (!foundMembers.length) {
        const queryFunction = ref => {
          let query: firebase.firestore.CollectionReference | firebase.firestore.Query = ref;
          whereConditions.forEach(condition => {
            query = query.where(condition.field, condition.operator, condition.value);
          });
          query = query.limit(this.MAX_SEARCH_RESULTS);
          return query;
        };
        //The other cases return an array of query functions, so do so here too
        queryFns = [queryFunction];
      }
    }

    //Set up array for results
    const outputs$: Array<Observable<UserObject[]>> = [];

    if (foundMembers.length > 0) {
      outputs$.push(of(foundMembers));
    }
    if (queryFns.length > 0) {
      //Combine results from multiple database queries
      const newMembers = this.getDocumentsByMultipleQueries<UserObject>('centralMembers', queryFns).pipe(
        map((members: UserObject[]) => {
          //add photoURL to UserObjects
          members = members.map(user => {
            user.photoURL = this.userService.getAvatarUrl(user);
            return user;
          });

          //Update caches
          toFindPlaces.forEach(placeId => {
            const matchingMembers = members.filter(x => x.placeId === placeId); //this also caches places with no members
            this.cache.update('places', placeId, matchingMembers);
          });

          if (toFindMembers.length > 0) {
            this.cache.update('members', toFindMembers[0], members);
          }

          return members;
        })
      );

      outputs$.push(newMembers);
    }

    return combineLatest(...outputs$).pipe(
      map(arrays => Array.prototype.concat(...arrays)) //TODO use arrays.flat() when supported
    );
  }

  getPlaces(q: ISearchModel) {
    // We are doing a geo search to find places matching the map. Any other conditions will be applied on the second step
    if (q.mapBounds == null || q.mapZoom == null) return of(null);

    //Get the geohashes we need to query
    const hashes = this.locationService.getMinimalGeohash(q.mapBounds, q.mapZoom);

    const { foundObjects: foundPlaces, toFindIds: toFindHashes } = this.maybeGetCachedData<IPlace>(hashes, 'geohashes');

    //Set up array for results
    const outputs$: Array<Observable<IPlace[]>> = [];

    if (foundPlaces.length > 0) {
      outputs$.push(of(foundPlaces));
    }
    if (toFindHashes.length > 0) {
      //Define database queries
      const queryFns = this.createChunkedQueryFunctions(toFindHashes, 'geohashes', 'array-contains-any');

      //Combine results from multiple database queries
      const newPlaces = this.getDocumentsByMultipleQueries<IPlace>(this.COLLECTION.CENTRAL_PLACES, queryFns).pipe(
        map((places: IPlace[]) => {
          //Update caches with new results

          //place.geohashes = e.g. [ 'rckq1g', 'rckq1', 'rckq', 'rck', 'rc', 'r' ]
          //toFindHashes = e.g. ['rckq1', 'rckq2', 'rckmg', 'rckmf']
          const hashIndex = this.MAX_HASH_LENGTH - toFindHashes[0].length;
          toFindHashes.forEach(hash => {
            const matchingPlaces = places.filter(x => x.geohashes[hashIndex] === hash); //this also caches hashes with no matching places
            this.cache.update('geohashes', hash, matchingPlaces, this.HOUR_IN_MS);
          });

          return places.map(place => {
            this.cache.update('centralPlaces', place.uid, place); // NB this cache doesn't seem to be used directly here, but may save reads on subsequent queries
            return place;
          });
        })
      );

      outputs$.push(newPlaces);
    }

    return combineLatest(...outputs$).pipe(
      map(arrays => Array.prototype.concat(...arrays)) //TODO use arrays.flat() when supported
    );
  }

  getWhereConditions(q: ISearchModel, allowOtherCountries: boolean) {
    const whereConditions: IWhereCondition[] = [];
    //Only display visible members
    whereConditions.push({
      field: 'isVisible',
      operator: '==',
      value: true
    });

    if (!this.isNullOrEmpty(q.memberName)) {
      whereConditions.push({
        field: 'searchName',
        operator: '>=',
        value: q.memberName
      });

      whereConditions.push({
        field: 'searchName',
        operator: '<=',
        value: q.memberName + '~' // Add character with high unicode value to provide upper range to search string
      });

      /*
  // TODO: If we don't want Chirpy members to see members from other countries, add this back conditionally
      whereConditions.push({
        field: 'country',
        operator: '==',
        value: q.country
      });
*/
    }
    return whereConditions;
  }

  isNullOrEmpty(query: string) {
    return query == null || query.trim().length === 0;
  }

  maybeGetCachedData<T>(queryIds: string[], cacheCollection: string) {
    //Check which objects we already read, and only query for new ones
    const foundObjects: T[] = [];
    const toFindIds: string[] = [];
    queryIds.forEach(key => {
      const cachedValue = this.cache.getValue(cacheCollection, key);
      if (cachedValue) {
        foundObjects.push(...cachedValue); //add the object to the array
      } else {
        toFindIds.push(key); //add the id to the array
      }
    });
    return { foundObjects, toFindIds };
  }
}
