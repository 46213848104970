import { Injectable } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ISocial } from '@shared/models/social/social';

@Injectable({
  providedIn: 'root'
})
export class SocialEditPresenter {
  form = this.formBuilder.group({
    address: ['', Validators.required],
    coordinates: [''], // only required if hasMap == true
    date: ['', Validators.required],
    description: ['', Validators.required],
    endTime: ['', Validators.required],
    locationId: [''],
    locationName: [''],
    sharedGroups: [''],
    title: ['', Validators.required],
    time: ['', Validators.required]
  });

  constructor(private formBuilder: FormBuilder) {}

  patchValue(data: any) {
    this.form.patchValue(data);
  }

  setValue(social: ISocial) {
    this.form.setValue({
      address: social.address || '',
      coordinates: social.coordinates || '',
      date: social.date || '',
      description: social.description || '',
      endTime: social.endTime || '',
      locationId: social.locationId || '',
      locationName: social.locationName || '',
      sharedGroups: (social.sharedGroupNames || []).join(', '),
      time: social.time || '',
      title: social.title || ''
    });
  }

  social() {
    let formValue = this.form.value;

    // sharedGroups is comma separated list of group names so the member can see what they have selected. We store the actual group names and ids on the component, but only show the groupNames on the form control
    // Remove it here to so that it doesn't overwrite the map when we do Object.assign
    if (formValue.sharedGroups) {
      delete formValue.sharedGroups;
    }

    return formValue;
  }
}
