import { AfterViewInit, Component, Input } from '@angular/core';
import { AdminRole } from '@shared/constants/admin-role';
import { IMemberThreadRelatedType } from '@shared/models/messages/member-thread';
import { UserObject } from '@shared/models/user-object';
import { AuthService } from '@shared/services/auth.service';
import { GroupMemberListService } from './group-member-list.service';

@Component({
  selector: 'group-member-list',
  templateUrl: './group-member-list.component.html',
  styleUrls: ['./group-member-list.component.scss'],
  viewProviders: [GroupMemberListService]
})
export class GroupMemberListComponent implements AfterViewInit {
  get groupMembers(): UserObject[] {
    return this.groupMemberListService.groupMembers;
  }

  get hasMembers(): boolean {
    return this.groupMemberListService.hasMembers;
  }

  get header() {
    if (this.memberCount == null) return `Members`;

    if (this.showGroupName) {
      const members = this.memberCount === 1 ? `(1 member)` : `(${this.memberCount} members)`;
      return `${this.groupMemberListService.groupName} ${members}`;
    } else {
      return this.memberCount === 1 ? `Member (1)` : `Members (${this.memberCount})`;
    }
  }

  get isLoading(): boolean {
    return this.groupId == null;
  }

  get memberCount(): number {
    return this.groupMemberListService.memberCount;
  }

  get showLoadingMembersSpinner(): boolean {
    return this.groupMemberListService.showLoadingMembersSpinner;
  }

  canManageGroupMembers: boolean; // Only Admins or Hosts for this group can add/remove members.
  canShowViewAllMembersLink: boolean;
  @Input() displayLimit: number; // display the top n members
  @Input() groupId: string;
  memberThreadType = IMemberThreadRelatedType.Member;
  @Input() showGroupName = false;

  constructor(private authService: AuthService, private groupMemberListService: GroupMemberListService) {}

  canRemoveMember(memberId: string) {
    return this.canManageGroupMembers || memberId === this.authService._userProfileSubject.value.uid;
  }

  ngAfterViewInit() {
    this.groupMemberListService.initGroupDetails(this.groupId).subscribe(group => {
      this.canManageGroupMembers = this.authService.isAdmin([AdminRole.HOSTS]);
      this.canShowViewAllMembersLink = group.memberCount > this.displayLimit;
      this.groupMemberListService.getGroupMembers(group.memberCount);
    });
  }

  onRemoveMemberFromGroup(member: any) {
    this.groupMemberListService.onRemoveMemberFromGroup(member);
  }

  async presentAddMembersModal() {
    await this.groupMemberListService.presentChooseMembersModal();
  }

  searchMembers(startsWith: string) {
    return this.groupMemberListService.searchMembers(startsWith);
  }

  recalculateMemberCount() {
    this.groupMemberListService.recalculateMemberCount();
  }
}
