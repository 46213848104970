import { Component, ElementRef, NgZone, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { ModalController } from '@ionic/angular';
import { Firework } from './models/fireworks';
import { Particle } from './models/particle';

@Component({
  selector: 'bingo-fireworks-overlay',
  templateUrl: 'fireworks-overlay.component.html',
  styleUrls: ['fireworks-overlay.component.scss']
})
export class BingoFireworksOverlayComponent implements OnInit, OnDestroy {
  @ViewChild('canvas', { static: true })
  canvas: ElementRef<HTMLCanvasElement>;
  fireworks: Firework[] = [];
  hue = 120;
  interval: ReturnType<typeof setInterval>;
  limiterTick = 0;
  // when launching fireworks with a click, too many get launched at once without a limiter, one launch per 5 loop ticks
  limiterTotal = 5;
  mouseDown = false;
  particles: Particle[] = [];
  requestId: number;
  timerTick = 0;
  // this will time the auto launches of fireworks, one launch per 80 loop ticks
  timerTotal = 80;
  private ctx: CanvasRenderingContext2D;

  constructor(public modalController: ModalController, private router: Router, private ngZone: NgZone) {}

  animate() {
    // this function will run endlessly with requestAnimationFrame
    this.requestId = requestAnimationFrame(() => this.animate);

    // create random color
    this.hue = this.random(0, 360);

    // normally, clearRect() would be used to clear the canvas
    // we want to create a trailing effect though
    // setting the composite operation to destination-out will allow us to clear
    // the canvas at a specific opacity, rather than wiping it entirely
    this.ctx.globalCompositeOperation = 'destination-out';
    // decrease the alpha property to create more prominent trails
    this.ctx.fillStyle = 'rgba(0, 0, 0, 0.5)';
    this.ctx.fillRect(0, 0, this.ctx.canvas.width, this.ctx.canvas.height);
    // change the composite operation back to our main mode
    // lighter creates bright highlight points as the fireworks and particles overlap each other
    this.ctx.globalCompositeOperation = 'lighter';

    // loop over each firework, draw it, update it
    let fI = this.fireworks.length;
    while (fI--) {
      this.fireworks[fI].draw();
      if (this.fireworks[fI].update()) {
        this.createParticles(this.fireworks[fI].tx, this.fireworks[fI].ty);
        // remove the firework, use the index passed into the update function to determine which to remove
        this.fireworks.splice(fI, 1);
      }
    }

    // loop over each particle, draw it, update it
    let pI = this.particles.length;
    while (pI--) {
      this.particles[pI].draw();
      if (this.particles[pI].update()) {
        this.particles.splice(pI, 1);
      }
    }

    // launch fireworks automatically to random coordinates, when the mouse isn't down
    if (this.timerTick >= this.timerTotal) {
      if (!this.mouseDown) {
        // start the firework at the bottom middle of the screen, then set the random target coordinates,
        // the random y coordinates will be set within the range of the top half of the screen
        this.fireworks.push(new Firework(this.ctx, this.ctx.canvas.width / 2, this.ctx.canvas.height, this.random(0, this.ctx.canvas.width), this.random(0, this.ctx.canvas.height / 2), this.hue));
        this.timerTick = 0;
      }
    } else {
      this.timerTick++;
    }

    // limit the rate at which fireworks get launched when mouse is down
    if (this.limiterTick >= this.limiterTotal) {
      if (this.mouseDown) {
        // start the firework at the bottom middle of the screen, then set the current mouse coordinates as the target
        // this.fireworks.push( new Firework(this.ctx, cw / 2, ch, mx, my ) );
        this.limiterTick = 0;
      }
    } else {
      this.limiterTick++;
    }
  }

  // create particle group/explosion
  createParticles(x: number, y: number) {
    // increase the particle count for a bigger explosion, beware of the canvas performance hit with the increased particles though
    let particleCount = 30;
    while (particleCount--) {
      this.particles.push(new Particle(this.ctx, x, y, this.hue));
    }
  }

  ngOnDestroy() {
    clearInterval(this.interval);
    cancelAnimationFrame(this.requestId);
  }

  ngOnInit(): void {
    this.canvas.nativeElement.width = window.innerWidth;
    this.canvas.nativeElement.height = window.innerHeight;

    this.ctx = this.canvas.nativeElement.getContext('2d');
    this.ngZone.runOutsideAngular(() => this.animate());

    setInterval(() => {
      this.animate();
    }, 15);
  }

  random(min: number, max: number) {
    return Math.random() * (max - min) + min;
  }
}
