// NB Adding keys here requires adding them to @shared/models/notification-settings too.

export enum NotificationTarget {
  ADVERTISER = 'Advertiser',
  CATCHUP = 'Catchup',
  COUNTRY = 'Country',
  GAME = 'Game',
  GROUP = 'Group',
  MEMBER = 'Member',
  MESSAGE = 'Message',
  PLACE = 'Place',
  ROMANCE = 'Romance',
  SOCIAL = 'Social'
}
