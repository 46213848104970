import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { EventType } from '@infrastructure/constants/event-type';
import { IEventAttendance } from '@shared/models/attendance/event-attendance';
import { UserObject } from '@shared/models/user-object';
import { AttendanceService } from '@shared/services/attendance/attendance.service';
import { AuthService } from '@shared/services/auth.service';
import { Observable, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

@Component({
  selector: 'catchups-box',
  styleUrls: ['./catchups-box.component.scss'],
  templateUrl: './catchups-box.component.html'
})
export class CatchupsBoxComponent implements OnInit {
  attendance$: Observable<IEventAttendance | null>;
  counts: Partial<Record<EventType, number>> = {
    [EventType.CatchUp]: 0,
    [EventType.Virtual]: 0
  };
  readonly EventType = EventType;
  @Input() link: string;
  @Output() linkClicked = new EventEmitter();
  @Input() placeholder: string;

  constructor(private attendanceService: AttendanceService, public authService: AuthService) {}

  ngOnInit() {
    this.attendance$ = this.authService._userProfileSubject.pipe(
      switchMap((member: UserObject) => {
        if (member == null) return of(null);
        return this.attendanceService.getAttendanceWithType(member.uid);
      }),
      map((attendanceData: IEventAttendance[]) => {
        this.counts = this.attendanceService.getCounts(attendanceData);
        return attendanceData.sort((a, b) => b.datetime - a.datetime)[0];
      })
    );
  }

  onClick(title: string) {
    this.linkClicked.emit(title);
  }
}
