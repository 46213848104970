import { Component, ViewChild } from '@angular/core';
import { IonTabs } from '@ionic/angular';
import { ActivityService } from '@shared/services/activity/activity.service';
import { EnvironmentService } from '@shared/services/environment.service';
import { ConstantsService } from '@shared/services/constants.service';
import { NewsService } from './components/news/services/news.service';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-my-chirpy',
  templateUrl: './my-chirpy.page.html',
  styleUrls: ['./my-chirpy.page.scss']
})
export class MyChirpyPage {
  branding: string;
  CONSTANTS: any;
  hasUnread$: Record<string, Observable<boolean>> = {};
  selectedTab: string = '';
  @ViewChild('tabs', { static: false }) tabs: IonTabs;

  constructor(private activityService: ActivityService, private constantsService: ConstantsService, private environmentService: EnvironmentService, private newsService: NewsService) {}

  ngOnInit() {
    this.CONSTANTS = this.constantsService.constants.MY_CHIRPY;
    this.branding = this.environmentService.settings.constants;
    this.hasUnread$['activity'] = this.activityService.hasUnreadActivity();
    this.hasUnread$['news'] = this.newsService.hasUnreadNews();
  }

  setSelectedTab() {
    this.selectedTab = this.tabs.getSelected();
  }

  sortNull() {}
}
