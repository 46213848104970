import { Injectable } from '@angular/core';
import { ToastController } from '@ionic/angular';

// TODO: Find a better place for this
type ToastOptions = Pick<Parameters<ToastController['create']>[0], 'duration' | 'position' | 'showCloseButton'>;

@Injectable({
  providedIn: 'root'
})
export class ToastService {
  constructor(private toastController: ToastController) {}

  async presentToast(messageText: string, options?: ToastOptions) {
    // dismiss the previous toast before showing the next to avoid overlapping toasts.
    try {
      await this.toastController.dismiss();
    } catch {
      // catch "Value: overlay does not exist" exception when there is no toast to dismiss.
    }

    const toast = await this.toastController.create({
      message: messageText,
      color: 'toast',
      duration: 6000,
      showCloseButton: true,
      ...options
    });

    toast.present();
  }
}
