import { Component } from '@angular/core';
import { Platform } from '@ionic/angular';
import { Country } from '@shared/constants/country';
import { IconSize } from '@shared/constants/icon-size';
import { UserObject } from '@shared/models/user-object';
import { AnalyticsAction, AnalyticsCategory, AnalyticsService } from '@shared/services/analytics';
import { AuthService } from '@shared/services/auth.service';
import { EnvironmentService } from '@shared/services/environment.service';
import { UIService } from '@shared/services/ui.service';
import { UrlHelperService } from '@shared/services/url-helper.service';
import { of, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.page.html',
  styleUrls: ['./dashboard.page.scss']
})
export class DashboardPage {
  icons = [
    {
      title: 'My Greenhouse',
      url: '/my-greenhouse',
      size: IconSize.LARGE,
      src: '/assets/plants/dashboard/my-greenhouse.svg'
    },
    {
      title: 'Groups',
      url: '/groups',
      size: IconSize.LARGE,
      src: '/assets/plants/dashboard/groups.svg'
    },
    {
      title: 'Marketplace',
      url: '/marketplace/intro',
      size: IconSize.LARGE,
      src: '/assets/plants/dashboard/marketplace.svg'
    },
    {
      title: 'Classifieds',
      url: '/classifieds/intro',
      size: IconSize.LARGE,
      src: '/assets/plants/dashboard/classifieds.svg'
    },
    {
      title: 'Care Guides',
      url: '/pages/care-guides',
      size: IconSize.SMALL,
      src: '/assets/plants/dashboard/care-guides.svg'
    },
    {
      title: 'Events',
      url: '/events',
      size: IconSize.SMALL,
      src: '/assets/plants/dashboard/events.svg'
    },
    {
      title: 'Plant Pals',
      url: '/plant-pals',
      size: IconSize.SMALL,
      src: '/assets/plants/dashboard/plant-pals.svg'
    },
    {
      title: 'Retailers',
      url: '/retailers',
      size: IconSize.SMALL,
      src: '/assets/plants/dashboard/retailers.svg'
    },
    /*
    {
      title: 'Planty Pix',
      url: '/pix/public/Planty Pix',
      size: IconSize.SMALL,
      src: '/assets/plants/dashboard/planty-pix.svg'
    },
    */
    {
      title: 'My Messages',
      url: '/messages',
      size: IconSize.SMALL,
      src: '/assets/plants/dashboard/my-messages.svg'
    },
    {
      title: 'My Profile',
      url: '/members/me',
      size: IconSize.SMALL,
      src: '/assets/plants/dashboard/my-profile.svg'
    },
    {
      title: 'My Account',
      url: '/account',
      size: IconSize.SMALL,
      src: '/assets/plants/dashboard/my-account.svg'
    },
    {
      title: 'Upgrade',
      url: '/pages/upgrade-subscription',
      size: IconSize.SMALL,
      src: '/assets/plants/dashboard/upgrade.svg'
    },
    {
      title: 'Help',
      url: '/pages/help',
      size: IconSize.SMALL,
      src: '/assets/plants/dashboard/help.svg'
    },
    {
      title: 'Admin',
      url: '/admin',
      size: IconSize.SMALL,
      src: '/assets/plants/dashboard/admin.svg',
      canShow: this.authService.isAdmin$()
    }
  ];

  uiServiceSubscription: Subscription;
  size = { LARGE: 6, SMALL: 4 };

  constructor(private analyticsService: AnalyticsService, public authService: AuthService, private environmentService: EnvironmentService, public platform: Platform, public uiService: UIService, private urlHelperService: UrlHelperService) {}

  getSize(iconSize: string): number {
    return this.size[iconSize];
  }

  isAU$() {
    return this.authService.userProfileObservable.pipe(map((user: UserObject) => user != null && user.country === Country.AUSTRALIA));
  }

  isNZ$() {
    return this.authService.userProfileObservable.pipe(map((user: UserObject) => user != null && user.country === Country.NEW_ZEALAND));
  }

  ngOnInit() {
    this.uiServiceSubscription = this.uiService.isMediumDisplay$.subscribe(isMediumDisplay => {
      if (isMediumDisplay) {
        this.size = { LARGE: 3, SMALL: 2 };
      } else {
        this.size = { LARGE: 6, SMALL: 4 };
      }
    });
  }

  ngOnDestroy() {
    this.uiServiceSubscription.unsubscribe();
  }

  onClickIcon(icon: any, index: number) {
    this.analyticsService.eventTrack(AnalyticsCategory.NAVIGATION, AnalyticsAction.NAVIGATION_DASHBOARD_ICON, icon.title, { type: icon.size }, index);
  }

  showLoadingOverlay() {
    this.uiService.showLoadingOverlay();
  }
}
