import { Component, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core';
import { AdminRole } from '@shared/constants/admin-role';
import { SelectType } from '@shared/constants/select-type';
import { AnalyticsAction, AnalyticsCategory, AnalyticsService } from '@shared/services/analytics';
import { AuthService } from '@shared/services/auth.service';
import { ConstantsService } from '@shared/services/constants.service';
import { GroupService } from '@shared/services/groups/group.service';
import { LocationService } from '@shared/services/location/location.service';
import { IValueWithId } from '@shared/models/value-with-id';
import { map } from 'rxjs/operators';
import { IGroupSearchModel } from '../../models/group-search-model';
import { GroupSearchPresenter } from './group-search.presenter';

@Component({
  selector: 'group-search',
  templateUrl: './group-search.component.html',
  viewProviders: [GroupSearchPresenter],
  encapsulation: ViewEncapsulation.None
})
export class GroupSearchComponent {
  get CONSTANTS() {
    return this.constantsService.constants.GROUPS.SEARCH;
  }

  get isAdmin$() {
    return this.groupService.isAdmin$();
  }

  get searchForm() {
    return this.presenter.form;
  }

  allowOtherCountries: boolean;
  country: string;
  selectType: string = SelectType.PLACE;
  @Output() search = new EventEmitter<IGroupSearchModel>();
  selectedItems: Record<string, string>;

  constructor(private analyticsService: AnalyticsService, public authService: AuthService, private groupService: GroupService, public presenter: GroupSearchPresenter, private constantsService: ConstantsService, private locationService: LocationService) {
    this.authService.userProfileObservable.subscribe(user => {
      if (user != null) {
        this.country = user.country;
      }
    });
    this.allowOtherCountries = this.constantsService.constants.APP.allowOtherCountries;
  }

  isNullSearch(searchModel: IGroupSearchModel) {
    if (Object.keys(searchModel).length === 0) return true;
    return Object.values(searchModel).filter(x => x).length === 0;
  }

  onAddPlace(item: IValueWithId) {
    this.presenter.patchValue({ place: item.name });
    this.presenter.patchValue({ placeId: item.uid });
    this.selectedItems = { [item.uid]: item.name };
  }

  onSearch() {
    const searchModel = this.presenter.search();
    // Make query term lower case to compare against lowercase searchName
    if (searchModel.groupName) searchModel.groupName = searchModel.groupName.toLowerCase();
    // Don't add a country if we are not searching for anything, otherwise we can't trigger a reset
    if (!this.isNullSearch(searchModel)) {
      searchModel.country = this.country;
      this.analyticsService.eventTrack(AnalyticsCategory.GROUPS, AnalyticsAction.GROUPS_SEARCH, null, { term1: searchModel.place, term2: searchModel.groupName, term3: searchModel.virtualGroups });
    }
    this.search.emit(searchModel);
  }

  reset() {
    this.presenter.reset();
    this.selectedItems = null;
    this.analyticsService.eventTrack(AnalyticsCategory.GROUPS, AnalyticsAction.GROUPS_CLEAR_SEARCH);
    this.onSearch();
  }

  searchPlaces(startsWith: string) {
    return this.locationService.search(startsWith, this.country, this.allowOtherCountries || this.groupService.isAdmin());
  }
}
