import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Align } from '@shared/constants/align';
import { IGroup } from '@shared/models/groups/group';
import { ISocial } from '@shared/models/social/social';
import { ConstantsService } from '@shared/services/constants.service';
import { GroupService } from '@shared/services/groups/group.service';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

@Component({
  selector: 'socials',
  templateUrl: './socials.component.html'
})
export class SocialsComponent {
  align: Align = Align.LEFT;
  @Input() button: string;
  canCreateSocials: boolean = false;
  CONSTANTS: Record<string, string | boolean | Object>;
  @Input() createSocialsMessage: string;
  createSocialLink: string;
  @Input() group: IGroup;
  @Input() heading: string;
  @Input() isAdmin: boolean;
  @Input() isGroupMember: boolean;
  @Input() isHost: boolean;
  @Output() linkClicked = new EventEmitter();
  MAX_SOCIALS: number = 999; // Show all socials for the moment
  @Input() noSocialsMessage: string;
  ONLY_IN_GROUP: boolean;
  socialCount: string = '';
  socials$: Observable<ISocial[]>;
  viewAllLabel: string;

  constructor(private constantsService: ConstantsService, private groupService: GroupService) {}

  ngOnInit() {
    this.canCreateSocials = (this.isGroupMember || this.isAdmin) && !this.isHost;
    this.CONSTANTS = this.constantsService.constants.SOCIAL;
    this.ONLY_IN_GROUP = !!this.CONSTANTS.onlyInGroup;
    this.createSocialLink = this.constantsService.constants.GROUPS.SOCIALS.createButtonLink;
    if (this.ONLY_IN_GROUP) this.createSocialLink += `/${this.group.uid}`;

    this.socials$ = this.groupService.getGroupSocials(this.group.uid, this.MAX_SOCIALS).pipe(
      tap(socials => {
        this.socialCount = socials.length > 0 ? `(${socials.length})` : '';
      })
    );
    this.viewAllLabel = `View all ${this.CONSTANTS.branding}s`;
  }

  onClick(title: string) {
    this.linkClicked.emit(title);
  }
}
