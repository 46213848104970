import { Injectable } from '@angular/core';
import { chunk } from '@shared/functions/chunk';
import { range } from '@shared/functions/range';
import { shuffle } from '@shared/functions/shuffle';
import { sum } from '@shared/functions/sum';
import { times } from '@shared/functions/times';
import { withRetries } from '@shared/functions/with-retries';
import { BingoStrip } from '../models/bingo-strip';

@Injectable({
  providedIn: 'root'
})
export class BingoStripGenerator {
  /**
   * Finds all permutations for a given input string.
   * @param input The input string.
   * @example
   * // Returns ["abc", "acb", "bac", "bca", "cab", "cba"]
   * BingoStripGenerator.findPermuations('abc');
   * @returns {Array<string>} An array containing all possible permuations.
   */
  private static findPermutations(input: string): string[] {
    if (!input) {
      return [];
    }
    if (!!input.length && input.length < 2) {
      return [input];
    }

    const results: string[] = [];

    for (let i = 0; i < input.length; i++) {
      const char = input[i];

      if (input.indexOf(char) !== i) {
        continue;
      }

      const remainder = input.slice(0, i) + input.slice(i + 1, input.length);

      for (const permutation of this.findPermutations(remainder)) {
        results.push(char + permutation);
      }
    }

    return results;
  }

  // Store every valid way of distributing the numbers across a given column of a strip.
  // The six numbers refer to each of the six 9x3 tickets in a strip. The sum of the six
  // numbers equals the number of numbers each column must have.
  // Example: '222111' means there can be 3 tickets with two numbers
  // in a given column and 3 tickets with one number.
  private static readonly COLUMN_PERMUTATIONS = [
    ['222111', '321111'], // The first column has 9 number (1 -> 9)
    ['331111', '322111', '222211'], // The middle columns have 10 numbers (10 -> 19, 20 -> 29, etc.)
    ['332111', '322211', '222221'] // The last column has 11 numbers (80 -> 90)
  ].map(column => column.flatMap(config => BingoStripGenerator.findPermutations(config)));
  private readonly COLUMNS_PER_ROW = 9;
  private readonly NUMS_PER_ROW = 5;
  private readonly ROWS_PER_TICKET = 3;
  private readonly TICKETS_PER_STRIP = 6;

  create(): BingoStrip {
    return withRetries(
      5,
      () => {
        // All 90 numbers organised into columns and shuffled
        const numberSeries = this.generateNumberSeries();

        // An intermediary format that represents how many numbers
        // to distribute across each column of a ticket
        const templateSeries = this.generateTemplateSeries();

        const tickets = times(this.TICKETS_PER_STRIP, i => {
          // Translate the template series into a ticket template
          const ticket = this.generateTicketTemplate(templateSeries[i]);

          return ticket.flatMap(row => {
            return row.flatMap((shouldTakeNumber, columnIndex) => {
              return shouldTakeNumber ? numberSeries[columnIndex].pop() || null : null;
            });
          });
        });

        return tickets.flatMap(ticket => ticket);
      },
      {
        onFailure: () => {
          throw new Error('Exceeded maximum attempts at strip generation.');
        }
      }
    );
  }

  /**
   * Generate all 90 numbers, split into columns and shuffled.
   */
  private generateNumberSeries() {
    const columns = [range(1, 9), ...chunk(range(10, 79), 10), range(80, 90)];

    return columns.map(column => shuffle(column));
  }

  private generateTemplateSeries() {
    const templateSeries = times(this.TICKETS_PER_STRIP, () => Array.from<number>({ length: this.COLUMNS_PER_ROW }).fill(0));

    for (let columnIndex = 0; columnIndex < this.COLUMNS_PER_ROW; columnIndex++) {
      const distributions = this.getColumnDistributions(columnIndex);

      while (distributions.length > 0) {
        const candidate = distributions.pop() || '';

        candidate.split('').forEach((x, rowIndex) => {
          templateSeries[rowIndex][columnIndex] = Number(x);
        });

        // If the series is valid thus far, move on to the next column
        if (this.isValidTemplateSeries(templateSeries)) {
          break;
        }

        // Undo what we just did so we can try again
        times(templateSeries.length, ticketNum => {
          templateSeries[ticketNum][columnIndex] = 0;
        });
      }

      if (distributions.length === 0) {
        throw new Error('Exhausted all possible column distributions. Aborting.');
      }
    }

    return templateSeries;
  }

  private generateTicketTemplate(columnTemplate: number[]) {
    const maxAttempts = 50;

    // Check that column template is valid
    if (columnTemplate.length !== this.COLUMNS_PER_ROW || !columnTemplate.every(x => [1, 2, 3].includes(x))) {
      throw new Error('Invalid column template supplied.');
    }

    return withRetries(
      maxAttempts,
      () => {
        // Three rows of nine cells
        const template = times(this.ROWS_PER_TICKET, () => Array.from<boolean>({ length: this.COLUMNS_PER_ROW }).fill(false));

        columnTemplate.forEach((numColumnsToPopulate, columnIndex) => {
          const availableRows = template
            .map((row, rowIndex) => ({ value: row[columnIndex], rowIndex, count: row.filter(x => x).length }))
            .filter(row => !row.value && row.count < this.NUMS_PER_ROW)
            .map(row => row.rowIndex);

          const shuffled = shuffle(availableRows).slice(0, numColumnsToPopulate);

          shuffled.forEach(rowIndex => {
            template[rowIndex][columnIndex] = true;
          });
        });

        const isValid = template.every(row => sum(row.map(x => Number(x))) === this.NUMS_PER_ROW);

        if (isValid) {
          return template;
        } else {
          throw new Error('Invalid ticket template generated.');
        }
      },
      {
        onFailure: () => {
          throw new Error('Max attempts exceeded to generate ticket template.');
        }
      }
    );
  }

  private getColumnDistributions(columnIndex: number) {
    let distributionIndex = 0;

    // First column
    if (columnIndex === 0) {
      distributionIndex = 0;
    }
    // Last column
    else if (columnIndex === 8) {
      distributionIndex = 2;
    }
    // Middle columns
    else {
      distributionIndex = 1;
    }

    return shuffle([...BingoStripGenerator.COLUMN_PERMUTATIONS[distributionIndex]]);
  }

  private isValidTemplateSeries(series: number[][]) {
    // Each row of a ticket must have 4 blank spaces.
    // If the row is 9 cells long then 9 - 4 = 5.
    // There are three rows in a ticket so 3 x 5 = 15.
    const requiredNumbersPerTicket = this.NUMS_PER_ROW * this.ROWS_PER_TICKET;

    return series.every(row => {
      const columnsYetToBeFilled = row.filter(cell => cell === 0).length;
      const numbersDistributed = sum(row);

      // Each column must have at least 1 number so if, for example,
      // there are 3 columns yet to be filled and the tally is already
      // at 13, then 13 + 3 exceeds the maximum allowable numbers in a ticket (15).
      const min = numbersDistributed + columnsYetToBeFilled;

      // Likewise, if each of the remaining columns were to have the maximum
      // allowable distribution (3 numbers) and the tally does not reach 15
      // then this is not a valid series.
      const max = numbersDistributed + columnsYetToBeFilled * 3;

      return min <= requiredNumbersPerTicket && max >= requiredNumbersPerTicket;
    });
  }
}
